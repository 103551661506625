<template>
    <div style="height: 100%">

        <div>
            <div class="courses-banner">
                <h4>My Courses</h4>
            </div>
            <div class="row select-courses">
                <div class="select-box-container">
                    <div class="form-group select-boxes pr-2">
                        <label>Category</label>
                        <select
                            v-on:change="getCourses()"
                            class="form-control"
                            id="category_id"
                            v-model="query.category_id"
                        >
                            <option value="0">Category</option>
                            <!-- eslint-disable -->
                            <option
                                v-for="category in categories"
                                :value="category.id"
                            >
                                {{ category.name }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group select-boxes pr-2">
                        <label>Sort By</label>
                        <select
                            v-on:change="getCourses()"
                            class="form-control"
                            id="exampleFormControlSelect1"
                            v-model="query.sort"
                        >
                            <option value="ASC">Ascending</option>
                            <option value="DESC">Descending</option>
                        </select>
                    </div>

                    <div class="form-group select-boxes pr-2">
                        <label>Status</label>
                        <select
                            v-on:change="getCourses()"
                            class="form-control"
                            id="exampleFormControlSelect1"
                            v-model="query.status"
                        >
                            <option value="completed">Completed</option>
                            <option value="approved">In progress</option>
                        </select>
                    </div>

                    <div class="form-group select-boxes pr-2">
                        <label>Instructor</label>
                        <select
                            v-on:change="getCourses()"
                            class="form-control"
                            id="exampleFormControlSelect1"
                            v-model="query.tutor_id"
                        >
                            <option value="0">Choose Instructor</option>
                            <option v-for="tutor in tutors" :value="tutor.id">
                                {{ tutor.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="search-input-btn">
                    <div class="form-group marginForSearch position-relative">
                        <input
                            type="text"
                            v-on:change="getCourses()"
                            v-model="query.keyword"
                            class="form-control searchDiv"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Search My Courses"
                        />
                        <i class="fas fa-search searchIcon"></i>
                    </div>
                </div>
            </div>
            <div class="row cards_parent purchase-courses mt-3">
                <div
                    class="col-6 col-md-6 col-lg-4 col-xl-3"
                    v-for="course in courses"
                    :key="course.id"
                >
                    <div class="card">
                        <div class="position-relative overflow-hidden card-img">
                            <a
                                :href="'/course/' + course.course.uuid"
                                :title="course.course.title"
                            >
                                <img
                                    class="card-img-top"
                                    :src="'/storage' + course.course.thumbnail"
                                    alt="Card image cap"
                                />
                            </a>
                            <i class="fas fa-play play-icon"></i>
                        </div>
                        <div class="card-body">
                            <a
                                :href="'/course/' + course.course.uuid"
                                :title="course.course.title"
                            >
                                <h5
                                    class="card-title m-0 text-truncate mt-sm-0 mt-2"
                                >
                                    {{ course.course.title }}
                                </h5>
                            </a>
                            <small>{{ course.course.tutor.name }} </small>
                            <div
                                v-if="!!sessions[course.course.id]"
                                class="progress my-1"
                                style="height: 5px"
                            >
                                <div
                                    class="progress-bar"
                                    role="progressbar"
                                    :style="
                                        'width: ' +
                                        sessions[course.course.id].progress +
                                        '%;'
                                    "
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            <small class="main_color">{{
                                courseProgress(course.course)
                            }}</small>
                            <div class="">
                                <star-rating
                                    @rating-selected="
                                        updateRating(course.course)
                                    "
                                    :read-only="
                                        course.course.reviews
                                            .map((_) => _.user_id)
                                            ?.includes(loggedInUser.id)
                                    "
                                    :show-rating="false"
                                    v-model="course.course.rating"
                                    :increment="0.25"
                                    v-bind:star-size="20"
                                ></star-rating>
                            </div>
                            <div class="mt-sm-3 mt-1 purchase-coruse-bottom">
                                <small class="main_color"
                                    ><a
                                        :href="'/course/' + course.course.uuid"
                                        target="_blank"
                                        >Courses Detail</a
                                    ></small
                                >
                                <div class="float-right">
                                    <a
                                        class="text-white"
                                        target="_blank"
                                        :href="
                                            '/course/' +
                                            course.course.id +
                                            '/forum'
                                        "
                                    >
                                        <button class="btn join_btn">
                                            Forum
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form
                        action="/initiate-bbb"
                        target="_blank"
                        method="post"
                        :id="'meeting' + course.course.id"
                        class="d-none"
                    >
                        <input
                            type="text"
                            name="course_id"
                            id="course_id"
                            v-model="course.course.id"
                        />
                    </form>
                </div>
                <div v-if="no_record">
                    <p class="p-2">No Record Found</p>
                </div>
            </div>
            <div class="text-center">
                <pagination
                    v-show="query.total > query.limit"
                    v-model="query.page"
                    :records="query.total"
                    :per-page="query.limit"
                    @paginate="getCompletedCourses"
                />
            </div>
            <ReviewFormComponent
                v-if="showFeedBackModal"
                :slot_id="selectedCourse.id"
                :model_id="selectedCourse.id"
                model="Course"
            ></ReviewFormComponent>
        </div>
    </div>
</template>

<script>

import StarRating from "vue-star-rating";
import ReviewFormComponent from "@/components/utilities/review/ReviewFormComponent";
import moment from "moment-timezone";
import endpoints from "@/assets/js/api";
import axiosInstance from "@/assets/js/axios";
import {toast} from "vue3-toastify";
import {hideLoader, showLoader} from "@/assets/js/swal";

export default {
    name: "CoursesComponent",
    props: [],
    components: {
        StarRating,
        ReviewFormComponent,
    },
    directives: {},
    data() {
        return {
            loggedInUser: {},
            no_record: false,
            course_id: 0,
            selectedCourse: {},
            tutors: [],
            categories: [],
            educationBoards: [],
            educationLevels: [],
            courses: [],
            ongoing: [],
            completed: [],
            sessions: [],
            showFeedBackModal: false,
            base_url: "",
            query: {
                tutor_id: 0,
                category_id: 0,
                status: "approved",
                priceRange: [0, 20000],
                price: "",
                role: "",
                board_id: 0,
                education_level: 0,
                level: 0,
                language: "",
                keyword: "",
                rating: 0,
                sort: "DESC",
                page: 1,
                limit: 8,
                total: 0,
            },
        };
    },
    computed: {},
    async created() {
        await this.getFilterContent();
        await this.getCourses();
        await this.getUserDetail();
    },
    methods: {
        async getUserDetail() {
            // const user = localStorage.getItem("detail");
            // if (user) {
            //     this.loggedInUser = JSON.parse(user).user;
            // }
        },
        async getCourses() {
            try {
              showLoader();
              const { data } = await axiosInstance.get(endpoints.getCourses, {params: this.query});
              this.tutors = data.data.tutors;
              this.courses = data.data.courses.data;
              this.ongoing = data.data.ongoing;
              this.completed = data.data.completed;
              this.sessions = data.data.sessions;
              this.query.total = data.data.courses.total;
              this.no_record = this.courses.length === 0;

              hideLoader();

            } catch (e) {
              hideLoader();
              toast.error('Server Error')
            }
        },
        async getPurchasedCourses() {
            const { data } = await axiosInstance.get(endpoints.getCourses, this.query);
            this.courses = data.courses;
            this.ongoing = data.ongoing;
            this.completed = data.completed;
            this.sessions = data.sessions;
            this.query.total = data.courses.total;
        },
        async getCompletedCourses() {
            const { data } = await axiosInstance.get(endpoints.getCourses, this.query);
            this.courses = data.courses;
            this.ongoing = data.ongoing;
            this.completed = data.completed;
            this.sessions = data.sessions;
            this.query.total = data.courses.total;
        },
        queryCheck() {
            return !!(
                this.query.category_id ||
                this.query.status ||
                this.query.price ||
                this.query.role ||
                this.query.board_id ||
                this.query.education_level ||
                this.query.level ||
                this.query.language ||
                this.query.keyword ||
                this.query.rating
            );
        },
        clearFilter() {
            this.query.category_id = "";
            this.query.status = "";
            this.query.price = "";
            this.query.role = "";
            this.query.board_id = "";
            this.query.education_level = "";
            this.query.level = "";
            this.query.language = "";
            this.query.keyword = "";
            this.query.rating = "";
        },
        async getFilterContent() {
            try {
              showLoader();
              const { data } = await axiosInstance.get(endpoints.filterContent);
              this.categories = data.data.categories;
              this.educationBoards = data.data.boards;
              this.educationLevels = data.data.educationLevels;
              hideLoader();
            } catch (e) {
              hideLoader();
              toast.error('Server Error')
            }
        },
        async setRating(rating, id) {
            let formData = new FormData();
            formData.append("course_id", id);
            formData.append("rating", rating);
            await axiosInstance.post(endpoints.courseRatingRoute, formData);
        },
        async updateRating(course) {
            this.selectedCourse = course;
            this.showFeedBackModal = !this.showFeedBackModal;
        },
        courseProgress(course) {
            let start = moment(course.start_date + " " + course.start_time);
            let today = moment();

            let duration = moment.duration(today.diff(start));
            let hours = duration.hours();
            let days = duration.days();
            let weeks = duration.weeks();
            let months = duration.months();
            if (months < 0) {
                return "Course Will Start in " + Math.abs(months) + " Months";
            } else if (weeks < 0) {
                return "Course Will Start in " + Math.abs(weeks) + " Weeks";
            } else if (days < 0) {
                return "Course Will Start in " + Math.abs(days) + " Days";
            } else if (hours < 0) {
                return "Course Will Start in " + Math.abs(hours) + " Hours";
            } else {
                return this.calculateCourseDays(
                    course,
                    hours,
                    days,
                    weeks,
                    months
                );
            }
        },
        calculateCourseDays(course, hours, days, weeks, months) {
            if (months > 0) {
                return "Course Started " + Math.abs(months) + " Month ago.";
            } else if (weeks > 0) {
                return "Course Started " + Math.abs(weeks) + " Week ago.";
            } else if (days > 0) {
                return "Course Started " + Math.abs(days) + " Day ago.";
            } else if (hours > 0) {
                return "Course Started " + Math.abs(hours) + " Hour ago.";
            }
        },
        checkCourseStatus() {
            // let today = moment().tz(timezone).format("YYYY-MM-DD");
            // let time = moment().tz(timezone).format("HH:mm");
            //
            // let start_time = this.timeFormat(
            //     course.start_date + "T" + course.start_time
            // );
            // let end_time = this.timeFormat(
            //     course.end_date + "T" + course.end_time
            // );
            // if (this.sessions[course.id][today]) {
            //     return !(time >= start_time && time <= end_time);
            // } else {
            //     return true;
            // }
        },
        joinSession(id) {
            this.joinZoomPersonalSession(id, "Session");
        },
        async joinZoomPersonalSession(session_id, model) {
            let formData = new FormData();
            formData.append("session_id", session_id);
            formData.append("model", model);
            const { data } = await axiosInstance.post(endpoints.zoomSessionInitiateRoute, formData);
            if (data.type === "success") {
                if (data.medium === "Zoom") window.open(data.contact, "_blank");
            } else {
                toast.error(data.message);
            }
        },
    },
};
</script>
<style scoped>
.courses_parent_width .form-group.select-boxes:first-child {
    margin-right: 0;
}
.courses_parent_width .courses-banner {
    background: transparent;
}
.courses_parent_width .marginForSearch .searchIcon {
    top: 9px !important;
}
</style>
