<template>
  <div>
    <div class="container instructor-newtheme">
      <div class="instructor-page-title">
        <h2>Get Verified Tutor</h2>
        <p>Instructor Application Form</p>
      </div>
      <div class="course-adding-form box-style-coruse">
        <ul id="progressbar" class="progressbar-box student-dashboard">
          <li id="general"><strong class="color-light-blue">Upload Educational Document</strong><span>1</span></li>
          <li id="duration"><strong class="color-light-blue">Payment</strong><span>2</span></li>
          <li class="active" id="courseoutline"><strong>Under Reviewed</strong><span>3</span></li>
        </ul>
        <div class="container-fluid" id="grad1">
          <div class="row">
            <div class="col-md-12">
              <h6 class="font-weight-bold mt-4 mb-3 theme-color">Info Submitted Successfully</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";

export default {
  name: "Step3Component",
  data() {
    return {
        info: {
          can_teach_online: false,
          can_teach_in_person: false,
        },
    }
  },
  created() {},
  methods: {
    async checkCourseForm () {
      let self = this
      let formdata = new FormData()
      formdata.append('can_teach_online', self.info.can_teach_online)
      formdata.append('can_teach_in_person', self.info.can_teach_in_person)
      const data = await axiosInstance.post(endpoints.updateInfoRoute, formdata)
      toast.success(data.message)
    }
  }
}
</script>

<style scoped>
.card-header-plusminus[aria-expanded="true"]::after {
    content: none !important;
}
/* @media screen and (min-width: 1400px) {
  .container {
    max-width:1450px !important;

  }
} */
@media screen and (min-width: 1850px) {
  .container {
    max-width:2150px  !important;

  }
}
/*.progressbar-box li {
  width: 33%;
}*/
.theme-color{
  color: #623a6c;
}
</style>
