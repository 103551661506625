<template>
  <div class="modal" id="AppointmentsModal" style="display: block; background-color: rgba(0,0,0, 0.4); overflow: scroll; overflow-x: hidden;"  tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" >
    <form action="" @submit.prevent="refundRequest()">
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content parent_modal" style="background-image: none !important;">
          <div class="modal-header py-1">
            <h5 class="modal-title">Refund Request</h5>
            <button v-on:click="$parent.refundModal = !$parent.refundModal" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div id="scheduleDaily" class="tab-pane active modal1 col-md-12 p-0">
                <table>
                  <!-- eslint-disable -->
                  <tr> <th>Slot Time</th> <td>{{ refundDetail.slot.slot.start_date_time | DDMMMhhmmA }} - {{ refundDetail.slot.slot.end_date_time | hhmmA }}</td> </tr>
                  <tr> <th>Transaction Id</th> <td>{{ refundDetail.transaction.trx_id }}</td> </tr>
                  <!-- <tr> <th>Transaction Amount</th> <td>{{ refundDetail.transaction.amount }} PKR</td> </tr> -->
                  <tr> <th>Slot Amount</th> <td>{{ refundDetail.slot.slot.price }} PKR</td> </tr>
                  <tr v-if="!!refundDetail.refund"> <th>Refund Request Status</th> <td>{{ refundDetail.refund.status }}</td> </tr>
                  <tr v-if="!!refundDetail.refund && refundDetail.refund.tutor_comment"> <th>Tutor Comment</th> <td>{{ refundDetail.refund.tutor_comment }}</td> </tr>
                </table>
              </div>
              <div class="form-group col-md-12 p-0">
                <label for="description">Details</label>
                <textarea v-model="description" rows="5" required id="description" class="form-control"></textarea>
              </div>
            </div>
          </div>
          <div v-if="!refundDetail.refund" class="modal-footer" style="justify-content: flex-end;">
            <button type="submit" class="btn btn-primary" style="height: 32px !important; width: auto !important;">Submit Refund Request</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
  name: "RefundScheduledSlotComponent",
  props: ['refundDetail'],
  data() {
    return {
      description: '',
    }
  },
  created() {
    if(this.refundDetail.refund) {
      this.description = this.refundDetail.refund.description
    }
  },
  mounted() {
  },
  methods: {
    async refundRequest() {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let formData = new FormData();
          formData.append('trx_id', this.refundDetail.transaction.trx_id)
          formData.append('slot_id', this.refundDetail.slot.slot.id)
          formData.append('model', this.refundDetail.model)
          formData.append('description', this.description)
          const { data } = await axiosInstance.post(endpoints.refundRequestRoute, formData);
          if (data.type === 'success') {
            this.$parent.refundModal = !this.$parent.refundModal
            this.$parent.getSlots();
          }
        }
      })

    },
  }
}
</script>

<style scoped>
table td {
  padding: 0 25px 0 5px !important;
}
h5 {
  font-weight: 500 !important;
}
th {
  width: 250px !important;
}
.dashboard-newtheme form label {
  font-weight: 500;
}
</style>
