<template>
  <div class="row" style="justify-content: space-between;">
    <div class="col-md-4 col-12 newcourse-box">
      <h3 class="total-courses">{{ enrolled_courses }}</h3>
      <p class="course-cate">Total Enrolled Courses</p>
    </div>
    <div class="col-md-4 col-12 newcourse-box">
      <h3 class="total-courses">{{ in_progress_courses }}</h3>
      <p class="course-cate">In Progress Courses</p>
    </div>
    <div class="col-md-4 col-12 newcourse-box">
      <h3 class="total-courses">{{ completed_courses }}</h3>
      <p class="course-cate">Completed Courses</p>
    </div>
  </div>
</template>

<script>

import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
  name: "CardGadgetComponent",
  data() {
    return {
      enrolled_courses: 0,
      in_progress_courses: 0,
      completed_courses: 0,
    }

  },
  created() {

    try {
      const courses_stats = axiosInstance.get(endpoints.getCoursesStats);

      if(courses_stats.status === true) {
          self.enrolled_courses = courses_stats.data.enrolled_courses
          self.in_progress_courses = courses_stats.data.in_progress_courses
          self.completed_courses = courses_stats.data.completed_courses
      }
    } catch (e) {
      console.log(e)
    }

  },
}
</script>

<style scoped>

</style>