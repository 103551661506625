<template>
    <Splide :options="{ rewind: true }">

      <SplideSlide v-bind="settings" v-if="data.length > 0 && slider === 'tutor'" :id="slider">
        <!-- eslint-disable -->
        <div v-for="data in data" class="">
          <TutorCardComponent :data="data"></TutorCardComponent>
        </div>
      </SplideSlide>

      <SplideSlide v-bind="settings" v-else-if="data.length > 0 && slider === 'course'" :id="slider">
        <img src="@/assets/images/mobile_banner.png" alt="">
        <img src="@/assets/images/mobile_banner.png" alt="">
        <img src="@/assets/images/mobile_banner.png" alt="">
        <img src="@/assets/images/mobile_banner.png" alt="">
        <img src="@/assets/images/mobile_banner.png" alt="">
<!--        <div v-for="data in data" class="col-md-12">-->
<!--          <CourseCardComponent :data="data"></CourseCardComponent>-->
<!--        </div>-->
      </SplideSlide>

      <SplideSlide v-bind="settings2" v-else-if="data.length > 0 && slider === 'testimonial'" :id="slider">
        <!-- eslint-disable -->
        <div v-for="data in data" class="col-md-12">
          <TestimonialCardComponent :data="data"></TestimonialCardComponent>
        </div>
      </SplideSlide>

    </Splide>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import TutorCardComponent from '@/components/frontend/home/card/TutorCardComponent.vue'
import TestimonialCardComponent from '@/components/frontend/home/card/TestimonialCardComponent.vue'
export default {
  name: "SliderComponent",
  props: ['data', 'slider', 'show'],
  components: {
    Splide,
    SplideSlide,
    TestimonialCardComponent,
    TutorCardComponent
  },
  data() {
    return {
      settings: {
        "dots": true,
        "infinite": false,
        "speed": 500,
        "slidesToShow": this.show,
        "slidesToScroll": this.show,
        "initialSlide": 0,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
              "infinite": true,
              "dots": true
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
              "initialSlide": 2
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
      },
      settings2: {
        "autoplay": true,
        "dots": true,
        "infinite": true,
        "speed": 500,
        "slidesToShow": this.show,
        "slidesToScroll": this.show,
        "initialSlide": 0,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "infinite": true,
              "dots": true
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
              "initialSlide": 2
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
      },
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>
