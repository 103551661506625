<template>
    <div style="height: 100%">
        <div class="home_content xxx mb-5" v-if="!loading">
            <!-- ================= Appointments Table ================== -->
            <div class="appointment_header">
                <p class="sort_by">Filter By Status</p>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6">
                        <div class="form-group">
                            <select class="form-control" v-model="query.status">
                                <option value="0">All</option>
                                <option value="enable">Available</option>
                                <option value="booked">Booked</option>
                                <option value="completed">Completed</option>
                                <option value="expired">Expired</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>

             Table

            <section class="ftco-section mt-4">
                <div class="row pt-sm-4 pt-0 px-3">
                    <div class="col-md-12 slot-container">
                        <div class="table-wrap">
                            <div class="">
                                <table class="table text-center">
                                    <thead class="thead-primary border-0">
                                        <tr>
                                            <th>Date</th>
                                            <th>Sessions time</th>
                                            <th>Status</th>
                                            <th>Session Price</th>
                                            <th>Student Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr
                                            v-for="slot in paginatedItems"
                                            :key="slot.id"
                                        >
                                            <td>
                                                <div class="table_date">
                                                    {{ slot.start_date_time }}
                                                </div>
                                            </td>
                                            <td>
                                                <div class="table_timing">
                                                    {{ slot.start_date_time }}
                                                    -
                                                    {{ slot.end_date_time }}
                                                </div>
                                            </td>
                                            <td>
                                                {{ slot.status && slotStatus[slot.status] }}
                                            </td>
                                            <td>
                                                {{ slot.price }} Pkr
                                            </td>

                                            <td v-if="!slot.booked">-</td>
                                            <td v-else>
                                                <a
                                                    href="javascript:void(0)"
                                                    v-on:click="
                                                        showStudentDetail( slot.id )
                                                    "
                                                    >{{ slot.booked?.user?.name }}</a
                                                >
                                            </td>

                                            <td class="booking_table_dropdown">
                                                <span class="">
                                                    <i
                                                        class="fas fa-trash ellipsis_icon"
                                                        v-if="!slot.booked"
                                                        v-on:click="(!slot.booked)? delSlot(slot.id) : ''"
                                                    ></i>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div v-if="no_record">
                                    <p> No Record Found </p>
                                </div>
                            </div>
                            <!-- =============== Pagination Section =============== -->
                            <div class="row my-3">
                                <pagination
                                    v-show="query.total > query.limit"
                                    v-model="query.page"
                                    :records="query.total"
                                    :per-page="query.limit"
                                    @paginate="(e) => {}"
                                />
                            </div>
                            <!-- Pagination Row -->
                        </div>
                    </div>
                </div>
            </section>


          <div
              class="modal"
              v-if="studentInfoModal"
              id="AppointmentsModal"
              style="
                  display: block;
                  background-color: rgba(0, 0, 0, 0.4);
                  overflow: scroll;
                  overflow-x: hidden;
              "
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
          >
                <div
                    class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
                    role="document"
                >
                    <div class="modal-content parent_modal">
                        <div class="modal-header border-0">
                            <div
                                class="nav nav-tabs border-0 custom-tabs"
                                role="tablist"
                            >
                                Student Details
                            </div>
                            <button
                                v-on:click=" studentInfoModal = !studentInfoModal "
                                type="button"
                                class="close cross_btn"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span class="cross_btn" aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body">
                            <div class="tab-content">
                                <div
                                    id="invoice"
                                    class="tab-pane active modal1"
                                >
                                    <div class="responsive-scroll">
                                        <table class="table tables text-center table-style-01">

                                            <thead class="thead-primary thead-primarys border-0">
                                                <tr class="th">
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>{{ user.name }}</td>
                                                    <td>{{ user.email }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <TransactionDetailComponent
                v-if="transactionModal"
                :selectedTransaction="selectedTransaction"
            ></TransactionDetailComponent>

            <div
                class="modal"
                v-if="slotModal"
                id="AppointmentsModal"
                style="
                    display: block;
                    background-color: rgba(0, 0, 0, 0.4);
                    overflow: scroll;
                    overflow-x: hidden;
                "
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <form method="post" @submit.prevent="saveSlot()">
                    <div
                        class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
                        role="document"
                    >
                        <div class="modal-content parent_modal">
                            <div class="modal-header border-0">
                                <div
                                    class="nav nav-tabs border-0 custom-tabs"
                                    role="tablist"
                                >
                                    <div class="modal-appointment-check">
                                        <input
                                            id="optDaily"
                                            checked
                                            name="intervaltype"
                                            value="single"
                                            type="radio"
                                            data-target="#scheduleDaily"
                                            v-model="slot.slot_type"
                                        />
                                        <label for="optDaily"
                                            >Create Single Appointment</label
                                        >
                                    </div>
                                    <div
                                        class="ml-sm-4 modal-appointment-check"
                                    >
                                        <input
                                            id="optWeekly"
                                            name="intervaltype"
                                            value="recurring"
                                            type="radio"
                                            data-target="#scheduleWeekly"
                                            v-model="slot.slot_type"
                                        />
                                        <label for="optWeekly"
                                            >Recurring Appointment</label
                                        >
                                    </div>
                                </div>
                                <button
                                    v-on:click="slotModal = !slotModal"
                                    type="button"
                                    class="close cross_btn"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span class="cross_btn" aria-hidden="true"
                                        >&times;</span
                                    >
                                </button>
                            </div>

                            <div
                                class="modal-body pt-0"
                                style="position: initial"
                            >
                                <div class="tab-content">
                                    <div
                                        id="scheduleDaily"
                                        class="tab-pane active modal1"
                                        v-if="slot.slot_type === 'single'"
                                    >
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="start_date"
                                                        >Date</label
                                                    >
                                                    <input
                                                        type="date"
                                                        v-model="
                                                            slot.start_date
                                                        "
                                                        id="start_date"
                                                        class="form-control"
                                                        :min="minDate"
                                                        :max="maxDate"
                                                        placeholder="Start date"
                                                        v-on:focus="errorMessages.start_date = []"
                                                    />
                                                    <ul
                                                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                        v-if="
                                                            errorMessages
                                                                .start_date
                                                                .length > 0
                                                        "
                                                    >
                                                        <li v-for="err in errorMessages.start_date" :key="err">
                                                            <small>{{ err }}</small>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="start_time_"
                                                        >Start Time ({{
                                                            this.timezone
                                                        }})</label
                                                    >
                                                    <select
                                                        class="form-control w-100"
                                                        id="timezone"
                                                        v-model="
                                                            slot.start_time
                                                        "
                                                    >
                                                        <option
                                                            :value="item"
                                                            v-for="item in generateDefaultTimeSlots(
                                                                slot.start_date,
                                                                []
                                                            )" :key="item"
                                                        >
                                                            {{ item }}
                                                        </option>
                                                    </select>
                                                    <ul
                                                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                        v-if="
                                                            errorMessages
                                                                .start_time
                                                                .length > 0
                                                        "
                                                    >
                                                        <li v-for="err in errorMessages.start_time" :key="err">
                                                            <small>{{ err }}</small>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="duration" >Duration (in minutes)</label>
                                                    <input
                                                        type="number"
                                                        disabled
                                                        class="form-control"
                                                        placeholder="05 Minutes"
                                                        v-model="slot.duration"
                                                        id="duration"
                                                        required
                                                    />
                                                    <ul
                                                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                        v-if="
                                                            errorMessages
                                                                .duration
                                                                .length > 0
                                                        "
                                                    >
                                                        <li v-for="err in errorMessages.duration" :key="err">
                                                            <small>{{ err }}</small>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="price"
                                                        >Hourly Rate (Pkr)</label
                                                    >
                                                    <input
                                                        type="number"
                                                        class="form-control"
                                                        placeholder="1000 Rs."
                                                        v-model="slot.price"
                                                        id="price"
                                                        required
                                                    />
                                                    <ul
                                                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                        v-if=" errorMessages.price.length > 0"
                                                    >
                                                        <li v-for="err in errorMessages.price" :key="err">
                                                            <small>{{ err }}</small>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="my-3">
                                            <small
                                                class="text-danger"
                                                v-if="errorLabel"
                                                >{{ errorMessage }}</small
                                            >
                                        </div>
                                        <div
                                            class="mx-auto save-btn-container my-3"
                                        >
                                            <button
                                                class="btn save_btn"
                                                :disabled="!!singleSlotLoading"
                                            >
                                                <div
                                                    v-if="!!singleSlotLoading"
                                                    class="spinner-border text-primary"
                                                    style="
                                                        color: white !important;
                                                        width: 20px !important;
                                                        height: 20px !important;
                                                    "
                                                    role="status"
                                                ></div>
                                                <span v-if="!!singleSlotLoading === false">
                                                    Save
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <RecuringSlotComponent v-if="slot.slot_type === 'recurring'"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div
                class="modal fade"
                id="editSlot"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <form method="post" @submit.prevent="editSlot()">
                    <div
                        class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
                        role="document"
                    >
                        <div class="modal-content parent_modal">
                            <div class="modal-header border-0">
                                <button
                                    type="button"
                                    class="close cross_btn"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span class="cross_btn" aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div
                                class="modal-body pt-0"
                                style="position: initial"
                            >
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="start_date_">Start Date</label>
                                            <input
                                                type="date"
                                                class="form-control"
                                                id="start_date_"
                                                :min="minDate"
                                                :max="maxDate"
                                                v-model="editslot.start_date"
                                            />
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="start_time">Start Time</label>
                                            <input
                                                type="time"
                                                class="form-control"
                                                id="start_time"
                                                v-model="editslot.start_time"
                                            />
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="end_time"
                                                >End Time</label
                                            >
                                            <input
                                                type="time"
                                                class="form-control"
                                                id="end_time"
                                                v-model="editslot.end_time"
                                            />
                                        </div>
                                    </div>

                                    <div class="col-md-6 ignore-overlap">
                                        <div class="form-group">
                                            <label for="overlapping"
                                                >Ignore overlapping</label
                                            >
                                            <input
                                                type="checkbox"
                                                class="form-check-input"
                                                value="1"
                                                id="overlapping"
                                                placeholder="05 Minutes"
                                                v-model="editslot.overlapping"
                                            />
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="price_" >Price Per Session (Pkr)</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="price_"
                                                placeholder="1000 Rs."
                                                v-model="editslot.price"
                                            />
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="notes">Additional Information (If Any)</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="notes"
                                                placeholder="Please join the session on time"
                                                v-model="editslot.notes"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Status</label>
                                            <select
                                                v-model="editslot.status"
                                                id="status"
                                                class="form-control"
                                            >
                                                <option value="enable">
                                                    Enable
                                                </option>
                                                <option value="disable">
                                                    disable
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="mx-auto text-center my-3">
                                        <button
                                            class="btn btn-primary"
                                            :disabled="loading"
                                        >
                                            Edit {{ loading === true ? "...." : "" }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <br />
            <br />
        </div>
    </div>
</template>

<script>

import moment from "moment-timezone";
import Swal from "sweetalert2";
import _ from "lodash";
import $ from "jquery";
import TransactionDetailComponent from "../../frontend/payment/TransactionDetailComponent";
import RecuringSlotComponent from "./RecuringSlotComponent.vue";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {hideLoader, showLoader} from "@/assets/js/swal";
import {toast} from "vue3-toastify";
import {useUserStore} from "@/store/store";

export default {
    name: "BookedAppointments",
    props: [],
    components: {
        TransactionDetailComponent,
        RecuringSlotComponent,
    },
    data() {
        return {
            singleSlotLoading: false,
            errorMessage: "",
            slotStatus: {
                enable: "Available",
                expired: "Expired",
                booked: "Booked",
                completed: "Completed",
            },
            errorMessages: {
                overlapping: [],
                available_days: [],
                slot_type: [],
                start_date: [],
                end_date: [],
                start_time: [],
                end_time: [],
                duration: [],
                number_of_students: [],
                break_per_slot: [],
                price: [],
                notes: [],
            },
            no_record: false,
            minDate: "",
            maxDate: "",
            user: {},
            selectedTransaction: {},
            errors: [],
            betweenDatesDays: [],
            days: [],
            transactionModal: false,
            studentInfoModal: false,
            slotModal: false,
            loading: false,
            errorLabel: false,
            timezone: "",
            slots: [],
            slot: {
                overlapping: false,
                available_days: [],
                slot_type: "single",
                start_date: "",
                end_date: null,
                start_time: "",
                end_time: "",
                duration: "",
                number_of_students: 1,
                break_per_slot: 0,
                price: "",
                notes: "",
            },
            editslot: {
                overlapping: false,
                start_date: "",
                start_time: "",
                end_date: "",
                duration: "",
                number_of_students: 1,
                price: "",
                notes: "",
            },
            query: {
                page: 1,
                limit: 10,
                id: "",
                keyword: "",
                role: "",
                total: 0,
                status: 0,
            },
        };
    },
    computed: {
        paginatedItems() {
            const startIndex = (this.query.page - 1) * this.query.limit;
            const endIndex = startIndex + this.query.limit;
            let ourSlots = [...this.slots];
            if (this.query.status !== 0) {
                ourSlots = ourSlots?.filter(
                    (_) => _.status === this.query.status
                );
            }

            return ourSlots.slice(startIndex, endIndex);
        },
    },
    watch: {
        "slot.start_date"(new_value) {
            this.slot.start_time = this.generateLatestTimeSlot({
                date: new_value,
                timeSlots: [],
            })?.from;

            if (!!this.slot.end_date && this.slot.end_date === new_value) {
                document
                    .getElementById(
                        moment(new_value).format("dddd").toLowerCase().trim()
                    )
                    .click();
            } else if (this.slot.end_date) {
                this.enumerateDaysBetweenDates(
                    this.slot.start_date,
                    this.slot.end_date
                );
            }
            if (!!this.slot.start_date && this.slot.slot_type === "single") {
                this.slot.available_days = [];
                this.slot.available_days.push(
                    moment(this.slot.start_date)
                        .format("dddd")
                        .toLowerCase()
                        .trim()
                );
            }
            this.errorLabel = false;
            this.errorMessage = "";
        },
        "slot.end_date"(new_value) {

            if (!!this.slot.start_date && this.slot.start_date === new_value) {
                document
                    .getElementById(
                        moment(new_value).format("dddd").toLowerCase().trim()
                    )
                    .click();
            } else if (this.slot.start_date) {
                this.enumerateDaysBetweenDates(
                    this.slot.start_date,
                    this.slot.end_date
                );
            }

            this.errorLabel = false;
            this.errorMessage = "";
        },
    },
    created() {
        this.minDate = moment().format("YYYY-MM-DD");
        this.maxDate = moment().add(90, "days").format("YYYY-MM-DD");
        this.days = moment.weekdays();
        this.getSlots();
    },
    mounted() {
        let self = this;

        const userStore = useUserStore();
        self.user = userStore.currentUser;
        self.role = self.user.role;
        self.timezone = userStore.currentTimeZone;

    },
    methods: {
        futureDate() {
            return moment().add(3, "months").format("YYYY-MM-DD");
        },
        todayDate() {
            return moment().format("YYYY-MM-DD");
        },
        todayTime() {
            return moment().format("h:mm a");
        },
        logValue(...props) {
            console.log(...props);
        },
        moment(...props) {
            return moment(...props);
        },
        addOneHour(value) {
            let hour = value.split(":")?.at(0);
            let minutes = value.split(":")?.at(1);
            let newHour = parseInt(hour);
            return `${newHour.toString().padStart(2, "0")}:${minutes}`;
        },
        generateLatestTimeSlot(week) {
            console.log("week--------------------------------------");
            console.log(week);
            let latestTimeSlot = this.generateDefaultTimeSlots(
                week.date,
                week.timeSlots
            )?.at(0);
            return {
                id: Math.random() * 1000,
                from: latestTimeSlot,
                to: this.addOneHour(latestTimeSlot),
            };
        },
        generateDefaultTimeSlots(dateToGenerateTimeSlot, addedSlots) {
            let timeslots = this.generateTimes();

            let oldSlots = this.slots
                ?.filter((item) => {
                    return (
                        moment(item?.start_date_time)
                            .hour(0)
                            .minute(0)
                            .second(0)
                            .unix() ===
                        moment(dateToGenerateTimeSlot)
                            .hour(0)
                            .minute(0)
                            .second(0)
                            .unix()
                    );
                })
                ?.map((item) => {
                    return {
                        from: moment(item?.start_date_time).format("HH:mm"),
                        to: moment(item?.end_date_time).format("HH:mm"),
                        id: Math.random() * 1000,
                    };
                });

            timeslots = timeslots?.filter((timeslot) => {
                return (
                    oldSlots.filter((oldSlot) => {
                        // 2:00 >= 2:00 && 2:00 <= 3:00
                        // 2:30 >= 2:00 && 2:30 <= 3:00
                        return (
                            this.createNumberFromTime(timeslot) >=
                                this.createNumberFromTime(oldSlot.from) &&
                            this.createNumberFromTime(timeslot) <
                                this.createNumberFromTime(
                                    this.addOneHour(oldSlot.from)
                                )
                        );
                    })?.length === 0 && addedSlots?.filter((oldSlot) => {
                        // 2:00 >= 2:00 && 2:00 <= 3:00
                        // 2:30 >= 2:00 && 2:30 <= 3:00
                        return (
                            this.createNumberFromTime(timeslot) >=
                                this.createNumberFromTime(oldSlot.from) &&
                            this.createNumberFromTime(timeslot) <=
                                this.createNumberFromTime(
                                    this.addOneHour(oldSlot.from)
                                )
                        );
                    })?.length === 0
                );
            });

            // Remove Today's timeslots that are passed from now
            timeslots = timeslots?.filter((timeslot) => {
                if (
                    moment(dateToGenerateTimeSlot)
                        .hour(0)
                        .minute(0)
                        .second(0)
                        .unix() === moment().hour(0).minute(0).second(0).unix()
                ) {
                    return (
                        this.createNumberFromTime(timeslot) >
                        this.createNumberFromTime(moment().format("HH:mm"))
                    );
                } else {
                    return true;
                }
            });

            // check if whole hour exists of slot
            timeslots = timeslots?.filter((timeslot) => {
                return timeslots?.includes(this.addOneHour(timeslot));
            });

            return timeslots;
        },
        createNumberFromTime(value) {
            return +value.replace(":", "");
        },
        generateTimes() {
            const times = [];
            for (let hour = 0; hour <= 24; hour++) {

                // for (let minute of [0, 30]) {

                // 30 min time gap removed from the slot creation
                for (let minute of [0]) {
                    const time = `${hour.toString().padStart(2, "0")}:${minute
                        .toString()
                        .padStart(2, "0")}`;
                    times.push(time);
                }
            }
            return times;
        },
        async getSlots(page) {

            try {
              showLoader();

              this.query.page = page ? page : this.query.page;
              const { data } = await axiosInstance.get(endpoints.slotsPro, {params: { limit: Number.MAX_VALUE }} );
              this.slots = data.data.slots.data;
              this.slot.duration = parseInt(data.data.duration);
              this.query.total = data.data.slots.total;
              this.no_record = this.slots.length === 0;

              hideLoader();

            } catch (e) {

              hideLoader();
              console.log(e);

            }

        },

        enumerateDaysBetweenDates(startDate, endDate) {
            this.slot.available_days = [];
            while (moment(startDate) <= moment(endDate)) {
                this.betweenDatesDays.push(
                    moment(startDate).format("dddd").toLowerCase().trim()
                );
                this.slot.available_days.push(
                    moment(startDate).format("dddd").toLowerCase().trim()
                );

                startDate = moment(startDate).add(1, "days").format("YYYY-MM-DD");
            }
            this.slot.available_days = [...new Set(this.slot.available_days)];
            this.betweenDatesDays = [...new Set(this.betweenDatesDays)];
        },

        checkDayExistBetweenDates() {
            this.errorMessages.available_days = [];
            if (this.slot.available_days.length < 1) {
                this.errorMessages.available_days.push(
                    "Must Select One Day Between Dates"
                );
                return false;
            } else {

                let matched = this.betweenDatesDays.filter((element) =>
                    this.slot.available_days.includes(element)
                );

                if (matched.length < 1) {
                    this.errorMessages.available_days.push( "Must Select One Day Between Dates" );
                    return false;
                }

                return true;
            }
        },
        async saveSlot() {
            let self = this;

            self.errorMessages.start_date = [];

            this.errorLabel = false;
            this.errorMessage = "";

            if (this.slot.slot_type === "recurring") {
                if (!this.slot.start_date) {
                    self.errorMessages.start_date.push("Choose start date...!");
                }
                if (!this.slot.end_date) {
                    self.errorMessages.end_date.push("Choose End date...!");
                }
                let result = this.checkDayExistBetweenDates();
                if (!result) return;
            }
            if ( this.slot.start_date === this.slot.end_date && !this.slot.end_time
            ) {
                this.slot.end_time = "23:59";
            }
            let formData = new FormData();

            _.each(this.slot, (value, key) => {
                if (key === "available_days")
                    formData.append(key, JSON.stringify(value));
                formData.append(key, value);
            });

            formData.append("available_days", JSON.stringify(this.slot.available_days) );

            this.singleSlotLoading = true;

            try {
              showLoader();

              let { data } = await axiosInstance.post(endpoints.slotsProStore, formData);
              this.singleSlotLoading = false;

              if (data.type === "success") {

                this.slotModal = false;
                await this.getSlots();
                toast.success(data.message);
                this.slot.available_days = [];
                this.slot.slot_type = "single";
                this.slot.start_date = "";
                this.slot.end_date = "";
                this.slot.start_time = "";
                this.slot.end_time = "";
                this.slot.number_of_students = 1;
                this.slot.break_per_slot = 0;
                this.slot.notes = "";

              } else if (data.type === "validation") {

                Object.keys(data.data).forEach(function (key) {
                  data.data[key].forEach(function () {
                    self.errorMessages[key] = data.data[key];
                  });
                });

              } else if (data.type === "error") {
                self.errorMessages.start_date.push(data.message);
              }

              hideLoader();

            } catch (e) {
              hideLoader();
              console.log(e)
            }


        },
        async showEditSlot(id) {
            let self = this;
            try {

              showLoader();

              const { data } = await axiosInstance.get(endpoints.slotsPro, {params: id});
              this.editslot = data;
              this.editslot.start_date = moment(data.start_date_time).format("yyyy-MM-DD");
              this.editslot.start_time = moment(data.start_date_time).format("HH:mm");
              this.editslot.end_time = moment(data.end_date_time).format("HH:mm");

              self.slot.start_time = moment(data.start_date_time).format("hh:mm");
              self.slot.end_time = moment(data.end_date_time).format("hh:mm");

              self.slot.start_date = moment(data.start_date_time).format("YYYY-MM-DD");
              self.slot.end_date = moment(data.end_date_time).format("YYYY-MM-DD");

              hideLoader();

            } catch (e) {

              hideLoader();
              console.log(e);

            }
        },

        async editSlot() {

          try {

            showLoader();

            await axiosInstance.patch(this.editslot.id, this.editslot)
                .then((response) => {
                  if (response.type === "error") {
                    toast.error(response.message);
                    return false;
                  }

                  this.getSlots();
                  $("#editSlot").modal("hide");
                  toast.error(response.message);
                })
                .catch((error) => {
                  console.log(error)
                })
                .finally(() => {
                  this.customerForm = false;
                });

            hideLoader();

          } catch (e) {
            hideLoader();
            console.log(e);
          }

        },

        async delSlot(id) {
            let query = {};
            query.id = id;
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Delete!",
            }).then(async (result) => {

                if (result.isConfirmed) {
                    await axiosInstance.get(endpoints.slotsProDelete, {params: query});
                    await this.getSlots();
                    toast.success("Slot Deleted Successfully.");
                }

            });
        },

        async markAsComplete(id) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Completed!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let formData = new FormData();
                    formData.append("slot_id", id);
                    await axiosInstance.post(endpoints.slotMarkAsCompletedRoute, formData);
                    await this.getSlots();
                }
            });
        },
        availableDays(day) {

            let self = this;
            this.errorLabel = false;
            this.errorMessage = "";

            if (document.querySelector("#" + day).checked) {
                self.slot.available_days.push(day);
            } else {
                let ind = self.slot.available_days.indexOf(day);
                if (ind > -1) {
                    self.slot.available_days.splice(ind, 1);
                }
            }
        },
        showStudentDetail(id) {
            this.user = this.slots.find((s) => s.id === id).booked.user;
            this.studentInfoModal = !this.studentInfoModal;
        },
        async showTransactionDetail(id) {
            let query = {};
            query.id = this.slots.find(
                (s) => s.id === id
            ).booked.transaction_id;

            console.log(query);

            const { data } = await axiosInstance.get(endpoints.getTransactionDetailRoute, {params: query});
            this.selectedTransaction = data.transaction;
            this.transactionModal = !this.transactionModal;
        },
    },
};
</script>

<style scoped>

.radio-container {
  display: flex !important;
  gap: 10px !important;
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

[type="radio"] + label:after{
  opacity: 0;
}

[type="radio"] + label:before{
  opacity: 0;
}

.radio-label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  cursor: pointer;
  border: 2px solid #ccc;
  border-radius: 8px;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  font-weight: bold;
}

.radio-label i {
  margin-right: 8px !important;
  font-size: 20px;
}

input[type="radio"]:checked + .radio-label {
  background-color: #ffff;
  color: #8354C8;
  border-color: #8354C8;
}

.VuePagination__pagination-item {
    cursor: pointer;
}

.availible_days {
    display: flex;
    flex-wrap: wrap;
    width: 490px;
    margin-top: 26px;
}

.listStyle {
    padding: 12px !important;
    padding-top: 0 !important;
}

@media only screen and (max-width: 800px) {
    .availible_days {
        display: flex;
        flex-wrap: wrap;
        width: 441px;
    }

    .listStyle {
        padding: 10px !important;
        padding-top: 0 !important;
    }

}

@media only screen and (max-width: 800px) {

    .availible_days {
        display: flex;
        flex-wrap: wrap;
        width: 441px;
    }

    .listStyle {
        padding: 10px !important;
        padding-top: 0 !important;
    }

}
@media only screen and (max-width: 576px) {

    .container {
        max-width: 100% !important;
    }

    .availible_days {
        display: block;
        width: 200px;
    }
}
</style>
