<template>

  <div class="rows head p-3">
    <h4>Date-Wise Schedule</h4>
    <a class="btn btn-sm custom-add-dates-btn" v-on:click="this.$emit('addAvailability', 1)"> + | Add Schedule</a>
  </div>

  <div class="card-shape p-2">

    <template v-for="(date, avaKey) in availability" :key="avaKey">
      <template v-if="date.type === 1">

        <div class="row mt-2 ava-row">

          <div class="col-md-4">
            <div class="d-inline-flex gap-5">
              <a href="#" class="text-danger p-2" v-on:click="this.$emit('deleteAvailability', avaKey)">
                <i class="fa fa-trash fa-md"></i>
              </a>
              <input
                  class="form-control small"
                  type="date"
                  v-model="date.available"
                  @change="this.$emit('valuesUpdate','available', date.available, null, avaKey)"
                  :min="minDate"
              >
            </div>

          </div>

          <div class="col-md-7">

            <div class="row" v-for="(dur, durKey) in date.duration" :key="durKey">

              <div class="col-md-5">
                <div class="d-inline-flex">
                  <p class="small my-3 mx-1">{{ this.$parent.currency_symbol}}.</p>
                  <input class="form-control"
                         type="number"
                         name="price"
                         v-model="dur.price"
                         placeholder="Price"
                         @input="this.$emit('valuesUpdate', 'price', dur.price, durKey, avaKey)"
                  />
                </div>
                <p class="small text-danger" v-if="avaKey in availabilityMessages">{{ availabilityMessages[avaKey][durKey]?.price }}</p>

              </div>

              <div class="col-md-3">
                <VueTimepicker
                    format="HH:mm"
                    placeholder="Start"
                    v-model="dur.start"
                    @close="this.$emit('valuesUpdate','start', dur.start, durKey, avaKey)"
                    input-width="4em"
                    input-class="rounded"
                    close-on-complete
                    hide-clear-button
                >
                </VueTimepicker>
                <p class="small text-danger" v-if="avaKey in availabilityMessages">{{ availabilityMessages[avaKey][durKey]?.start }}</p>
              </div>

              <div class="col-md-3">
                <VueTimepicker
                    format="HH:mm"
                    placeholder="End"
                    v-model="dur.end"
                    @close="this.$emit('valuesUpdate','end', dur.end, durKey, avaKey)"
                    input-width="4em"
                    input-class="rounded"
                    close-on-complete
                    hide-clear-button
                >
                </VueTimepicker>
                <p class="small text-danger" v-if="avaKey in availabilityMessages">{{ availabilityMessages[avaKey][durKey]?.end }}</p>
              </div>

              <div class="col-md-1">

                <div class="my-2">
                  <a class="text-danger" v-on:click="this.$emit('deleteDuration', avaKey, durKey)">
                    <i class="fa fa-times fa-md"></i>
                  </a>
                </div>

              </div>

            </div>

          </div>

          <div class="col-md-1">
              <div class="my-2">
                <a class="text-success" v-on:click="this.$emit('addDuration', avaKey)">
                  <i class="fa fa-plus fa-md"></i>
                </a>
              </div>
          </div>

        </div>

      </template>
    </template>

  </div>



</template>

<script>

import VueTimepicker from "vue3-timepicker";
import 'vue3-timepicker/dist/VueTimepicker.css';
import moment from "moment-timezone";

export default {

  name: "DateWiseScheduleComponent",
  components: {VueTimepicker},

  props: {
    availability: {
      type: Array,
      required: true,
    },
    availabilityMessages: {
      type: Array,
      required: true,
    }
  },

  emits: ['addAvailability', 'deleteAvailability', 'addDuration', 'deleteDuration', 'valuesUpdate'],

  data() {

    return {
      minDate: moment().format('YYYY-MM-DD')
    }

  },

  computed: {
    datesSlots() {
      return this.$props.availability
          ? this.$props.availability.filter(slot => slot.type === 1).map(slot => slot.available)
          : [];
    },
  },

  methods: {
  }
}

</script>

<style scoped>

  .custom-add-dates-btn {
    border: 1px solid #834BB2;
    border-radius: 20px !important;
    outline: none;
    transition: border-color 0.3s ease;

    color: #ffff !important;
  }

  .custom-add-dates-btn:hover {
    background: #834BB2;
  }

</style>
<script setup>
</script>