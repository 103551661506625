<template>
  <div>
    <div class="container pt-4 pb-4 add-qualification">

      <div class="course-adding-form box-style-coruse">
        <ul id="progressbar" class="progressbar-box">
          <li class="active" id="general"><strong class="color-light-blue">General </strong><span>1</span></li>
          <li class="active" id="duration"><strong class="color-light-blue">Duration</strong><span>2</span></li>
          <li class="active" id="courseoutline"><strong class="color-light-blue">Where can you teach?</strong><span>3</span></li>
        </ul> <!-- fieldsets -->
        <!-- MultiStep Form -->
        <div class="container-fluid" id="grad1">
          <div class="row">
            <div class="col-md-12 mx-0">
              <form id="form-box" class="form-box">
                <!-- progressbar -->

                <fieldset>
                  <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                    <div class="form-check where-you-teach">
                      <input class="form-check-input" id="hide_applied" type="checkbox" value="1" v-model="info.can_teach_online">
                      <label class="form-check-label" for="hide_applied">
                        Can Tech Online
                      </label>
                    </div>
                    </div>
                    <div class="col-md-6">
                    <div class="form-check where-you-teach">
                      <input class="form-check-input" id="verified_only" type="checkbox" value="1"  v-model="info.can_teach_in_person">
                      <label class="form-check-label" for="verified_only">
                        Can Teach In Person
                      </label>
                    </div>
                    </div>
                  </div>
                  </div>
                  <div class="row px-3 mb-3">
                    <div class="col-sm-5"><a v-on:click="$parent.steps--" href="javascript:void(0)" class="btn back btn_fontsize  btn-outline-primary btn-lg">Back</a></div>
                    <div class="col-sm-6 text-right"><a  v-on:click="checkCourseForm"  href="javascript:void(0)" class="next action-button btn btn-primary btn_fontsize btn-lg">Update</a></div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div> <!-- Home Content End -->
  </div>
</template>

<script>

import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";

export default {
  name: "Step3Component",
  data() {
    return {
        info: {
          can_teach_online: false,
          can_teach_in_person: false,
        },
    }
  },
  created() {

  },
  methods: {
    async checkCourseForm () {
      let self = this
      let formdata = new FormData()
      formdata.append('can_teach_online', self.info.can_teach_online)
      formdata.append('can_teach_in_person', self.info.can_teach_in_person)
      const data = await axiosInstance.post(endpoints.updateInfoRoute, formdata)
      toast.success(data.message)
    }
  }
}
</script>

<style scoped>

.card-header-plusminus[aria-expanded="true"]::after {
    content: none !important;
}

@media screen and (min-width: 1850px) {
  .container {
    max-width:2150px  !important;

  }
}
.progressbar-box li {
  width: 33%;
}
</style>
