<template>
  <div>
    <div class="home_content tutor-billing-newtheme">
      <div class="billing_parent_student tutor-dashboard tutor_billing">
        <div class="billing_container">
          <!-- =================== Link buttons =================== -->
          <div class="row parent_links justify-content-around">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-lg-0 mt-4 m-0 link first_link">
              <router-link to="billing/deposit">
                <a href="">
                  <div class="text-center py-3">
                    <div class="billing-img">
                      <img src="@/assets/new-theme-images/history.png" alt="img-fluid">
                    </div>
                    <h6 class="mt-3">Make a Deposit</h6>
                  </div>
                </a>
              </router-link>
            </div>

            <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-lg-0 mt-4 m-0 link third_link">
              <router-link to="/tutor/billing/withdraw/request">
                <a href="javascript:void (0)">
                  <div class="text-center py-3">
                    <div class="billing-img">
                      <img src="/new-theme-images/atm.png" alt="img-fluid">
                    </div>
                    <h6 class="mt-3">Withdrawal Request</h6>
                  </div>
                </a>
              </router-link>
            </div> -->

            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3  mt-lg-0 mt-4 m-0 link third_link">
              <router-link to="/tutor/billing/transaction">
                <a href="javascript:void (0)">
                  <div class="text-center py-3">
                    <div class="billing-img">
                      <img src="@/assets/new-theme-images/deposit.png" alt="img-fluid">
                    </div>
                    <h6 class="mt-3">Transaction History</h6>
                  </div>
                </a>
              </router-link>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3  mt-lg-0 mt-4 m-0 link third_link">
              <router-link to="/tutor/billing/requests">
                <a href="javascript:void(0)">
                  <div class="text-center py-3">
                    <div class="billing-img">
                      <img src="@/assets/new-theme-images/atm.png" alt="img-fluid">
                    </div>
                    <h6 class="mt-3">Requests</h6>
                  </div>
                </a>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
  name: "BillingComponent",
  props: [],
  components: { },
  directives: { },
  data() {
    return {
      logs: [],
      wallet: 0.00,
      transaction: 0.00,
      pending_withdraws: 0.00,
      approved_withdraws: 0.00,
      withdraws: 0.00
    };
  },
  computed: {
  },
  created() {
    this.getUserDetails(),
    this.getUserPendingWithraws(),
    this.getUserApprovedWithraws(),
    this.getUserWithraws()
  },
  methods: {
    async getUserDetails() {
      const {data} = await axiosInstance.get(endpoints.getUserDetails);
      this.wallet = parseFloat(data.wallet);
      this.transaction = parseFloat(data.transaction);
    },
    async getUserPendingWithraws() {
      const {data} = await axiosInstance.get(endpoints.getUserPendingWithraws);
      this.pending_withdraws = data.data;
    },
    async getUserApprovedWithraws() {
      const {data} = await axiosInstance.get(endpoints.getUserApprovedWithraws);
      this.approved_withdraws = data.data;
    },
    async getUserWithraws() {
      const {data} = await axiosInstance.get(endpoints.getUserWithraws);
      this.withdraws = data.data;
    },
  },
}
</script>

<style scoped>

</style>
