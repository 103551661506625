<template>
    <div>
      <div class="table-wrap">
        <div class="">
          <table class="table text-center" >
            <thead class="thead-primary border-0">
            <tr>
              <th></th>
              <th>Transaction Id</th>
              <th>Slot Time</th>
              <th>Price</th>
              <th>status</th>
              <th>Created At</th>
              <th></th>
            </tr>
            </thead>

            <tbody >
            <!-- eslint-disable -->
            <tr v-for="req in reqs" :key="req.id">
              <td></td>
              <td><a href="javascript:void (0)" v-on:click="$parent.getTransactionDetail(req.trx_id)">{{req.trx_id}}</a></td>
              <td> <span v-if="!!req.transaction">{{req.transaction.slots.find(s => s.slot.id === req.model_id).slot.start_date_time | DDMMMYYYYHHmmA}} - {{req.transaction.slots.find(s => s.slot.id === req.model_id).slot.end_date_time | hhmmA}}</span></td>
              <td> <span v-if="!!req.transaction">{{req.transaction.slots.find(s => s.slot.id === req.model_id).slot.price}}</span></td>
              <td>{{req.status }}</td>
              <td>{{req.created_at | DDMMMhhmmA}}</td>
              <td>
                <router-link :to="'/student/refund/'+req.id">Details</router-link>
<!--                <a href="javascript:void(0)" v-on:click="showDetails(req)">Details</a>-->
              </td>
            </tr>
            </tbody>
          </table>
          <div v-if="no_record">
            <p class="p-2">{{$t("text.no_record")}}</p>
          </div>
        </div>
        <div class="row">
          <pagination v-show="query.total > query.limit" v-model="query.page" :records="query.total" :per-page="query.limit" @paginate="getRefundRequest()"/>
        </div>
      </div>
      <RefundRequestDetailComponent v-if="refundDetailModal" :refundDetail="transaction"></RefundRequestDetailComponent>
    </div>
</template>

<script>
import RefundRequestDetailComponent from "@/components/student/billings/RefundRequestDetailComponent";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
  name: "RefundRequestComponent",
  components: {
    RefundRequestDetailComponent,
  },
  data() {
    return {
      no_record: false,
      refundDetailModal: false,
      transaction: {},
      reqs: [],
      balance: 0.00,
      query: {
        status: '',
        keyword: '',
        sort: 'DESC',
        page: 1,
        limit: 6,
        total: 0,
      },
    }
  },
  mounted() {
  },
  created() {
    this.getRefundRequest()
  },
  methods: {
    async getRefundRequest() {
      const {data} = await axiosInstance.get(endpoints.getRefundRequestRoute, this.query)
      this.reqs = data.data.data
      this.query.total = data.data.total
      if (this.reqs.length === 0) {
        this.no_record = true
      }
    },
    showDetails(req) {
      this.transaction = req
      this.refundDetailModal = !this.refundDetailModal
    },
  }
}
</script>

<style scoped>

</style>
