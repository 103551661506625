<template>
    <div style="height: 100%" v-if="loading">
        <div
            style="
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            "
        >
            <div
                class="spinner-border text-primary"
                style="
                    width: 50px;
                    height: 50px;
                    color: rgb(129, 43, 178) !important;
                "
                role="status"
            ></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoadingComponent",
    props: {
        loading: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
