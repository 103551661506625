<template>
  <div>
    <section class="website-bg-banner">
      <!-- Learn on Schedule -->
      <section class="banner-bg-img bg-newtheme">
        <div class="padding_cover">
          <div class="container-fluid text-center">
            <div class="container banner-container">
              <div class="row">
                <div class="col-md-6 text-left">
                  <h1 class="display-7 bold">Learn anything, anytime, anywhere</h1>
                  <span class="study">Explore thousands of courses at the best price anywhere.</span>
                  <div class="text-group-field choose-time">
                    <div class="inner-block">
                      <button @click="navigateTo('/tutors')" class="tutor-btn">
                        <span><img src="@/assets/new-theme-images/tutors-white.png" alt="tutor icon" width="20" height="22" /></span>
                        Tutors
                      </button>
                    </div>
                    <div class="inner-block">
                      <button @click="navigateTo('/courses')" class="courses-btn">
                        <span><img src="@/assets/new-theme-images/courses.png" alt="tutor icon" width="22" height="18" /></span>
                        Courses
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="banner-image">
                    <img height="393" width="575" class="desktop-img" src="@/assets/images/banner.png" alt="" />
                    <img height="235" width="384" class="mobile-img" src="@/assets/images/mobile_banner.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Learn on Schedule -->

      <!-- Technology -->
      <section class="bg_color sec-2 sec-newstyle">
        <div class="container">
          <div class="row text-center">
            <div class="col-md-2 col-sm-4 col-xs-6" v-for="tech in techDetails" :key="tech.title">
              <div class="learn-courses">
                <div class="tech-img">
                  <img :src="tech.img" height="32" width="32" alt="" />
                </div>
                <h6>{{ tech.title }}</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Technology -->

      <!-- Top Course -->
      <section class="top-courses sec-3 home-newpage">
        <div class="top_course text-center">
          <h2>Trending Courses</h2>
        </div>

        <div class="container padding_course courses-slider">
          <slider-component :data="topCourses" slider="course" :show="4"></slider-component>
        </div>
      </section>
      <!-- Top Course -->

      <!-- Top Instructors -->
      <section class="top-instructor-container sec-4">
        <div class="top_course text-center">
          <h2>Top Instructors</h2>
        </div>

        <div class="container padding_course slider-container instructors-slider">
          <slider-component :data="topInstructors" slider="tutor" :show="4"></slider-component>
        </div>
      </section>
      <!-- Top Instructors -->

      <!-- Testimonial -->
      <section v-if="topReviews.length > 0">
        <div class="top_course testimonial text-center">
          <h2>Testimonials</h2>
          <div class="testimonial_p">
            <p>Torquem detraxit hosti et quidem faciunt, ut et negent satis esse appetendum, alterum. Si <br />
              sine causa? quae fuerit causa, mox videro; interea hoc epicurus in liberos.</p>
          </div>
          <div class="container padding_course slider-container testimonial-course-details home-testimonials">
            <slider-component :data="topReviews" slider="testimonial" :show="4"></slider-component>
          </div>
        </div>
      </section>
      <!-- Testimonial -->

      <!-- Become an instructor today -->
      <section class="become_instructor">
        <div class="container">
          <div class="text-center">
            <h2>Become An Instructor Today </h2>
            <p>Begin earning today!</p>
            <button @click="joinAsTutor" class="btn btn-primary text-white" style="color: #fff;">Get Started</button>
          </div>
        </div>
      </section>
      <!-- Become an instructor today -->

      <!-- Source helps creator -->
      <section class="help_creator">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-4 help-info-p">
              <div>
                <h3>The Platform connecting expertise with those who need it. or We connect expertise with those who need it. Connecting expertise with those who need it.</h3>
              </div>
            </div>
            <div class="col-12 col-lg-6 help-bg-img">
              <img height="362" width="620" src="@/assets/images/source-help.jpg" class="source-img"  alt=""/>
            </div>
          </div>
        </div>
      </section>
      <!-- Source helps creator -->
    </section>
  </div>
</template>

<script>
import SliderComponent from '@/components/frontend/home/SliderComponent.vue'
import graphIncrease from '@/assets/new-theme-images/system-uicons_graph-increase.png'
import et_video from '@/assets/new-theme-images/et_video.png'
import icons_projects from '@/assets/new-theme-images/grommet-icons_projects.png'
import gormetIcons from '@/assets/new-theme-images/grommet-icons.png'
import liveClasses from '@/assets/new-theme-images/Group 188.png'

export default {
  components: { SliderComponent },
  data() {
    return {
      techDetails: [
        { img: graphIncrease, title: 'Latest Technologies & Trending Topics' },
        { img: et_video, title: this.courses + '+ Courses' },
        { img: icons_projects, title: 'Project Oriented Study' },
        { img: gormetIcons, title: 'Expert Guidance' },
        { img: liveClasses, title: 'Live Classes', link: '/courses' }
      ],
      topCourses: [], // Fetch from API or props
      topInstructors: [], // Fetch from API or props
      topReviews: [], // Fetch from API or props
    };
  },
  methods: {
    navigateTo(url) {
      window.location.href = url;
    },
    joinAsTutor() {
      document.getElementById('signup').click();
      setTimeout(() => {
        document.getElementById('Tutor').click();
      }, 1000);
    }
  },
  mounted() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    let guardian_email = params.guardian_email;
    let role = params.role;

    if (guardian_email && role) {
      document.getElementById('signup').click();
    }
  }
};
</script>
