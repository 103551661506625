<template>
    <div>
        <div class="container pt-4 pb-4">
            <div class="course-adding-form box-style-coruse">
                <ul id="progressbar" class="progressbar-box six-steps">
                    <li class="active" id="general">
                        <strong class="color-light-blue">Basic Course Info</strong
                        ><span>1</span>
                    </li>
                    <li class="active" id="duration">
                        <strong class="color-light-blue">Proposed Schedule</strong
                        ><span>2</span>
                    </li>
                    <!--        <li class="active"  id="classsessionroomsetting"><strong>Class / Session Room Setting</strong><span>3</span></li>-->
                    <li class="active" id="pricingrequirements">
                        <strong>Pricing & Requirements </strong><span>3</span>
                    </li>
                    <li class="active" id="courseoutline">
                        <strong class="color-light-blue">Course Outline</strong
                        ><span>4</span>
                    </li>
                    <li id="mediaseo">
                        <strong>Media & SEO</strong><span>5</span>
                    </li>
                    <!--          <li id="quiz"><strong>Quiz</strong><span>6</span></li>-->
                </ul>
                <div
                    class="container-fluid course-outline-container"
                    id="grad1"
                >
                    <div class="row">
                        <div class="col-md-12 mx-0 course-outline-container-2">
                            <form id="form-box" class="form-box">
                                <!-- progressbar -->
                                <fieldset>
                                    <div
                                        class="weekly-detail-tabs px-2 py-sm-4 py-0"
                                    >
                                      <!-- eslint-disable -->
                                        <div id="accordion">
                                            <div
                                                class="card collapse-style-01 mb-4"
                                                v-for="(
                                                    week, index
                                                ) in course.count_weeks"
                                            >
                                                <div
                                                    class="card-header card-header-plusminus"
                                                    id="headingOne"
                                                    data-toggle="collapse"
                                                    :data-target="
                                                        '#collapse-' + index
                                                    "
                                                    aria-expanded="false"
                                                    aria-controls="collapseOnes"
                                                >
                                                    <div
                                                        class="weekly-detail-heading"
                                                    >
                                                        <h3>
                                                            <div
                                                                class="weekly-detail-left"
                                                            >
                                                                <img
                                                                    src="/new-theme-images/yellow-plus.png"
                                                                    class="new-plus"
                                                                />
                                                                Week {{ week }}
                                                            </div>

                                                            <div
                                                                class="weekly-detail-right"
                                                            >
                                                              <!-- eslint-disable -->
                                                                <span>{{
                                                                    course
                                                                        | getDatesByWeeks(
                                                                            index
                                                                        )
                                                                }}</span>
                                                                <img
                                                                    src="@/assets/new-theme-images/edit-yellow.png"
                                                                    v-on:click="
                                                                        showOutlineModal(
                                                                            week
                                                                        )
                                                                    "
                                                                    class="float-right"
                                                                />
                                                            </div>
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div
                                                    :id="'collapse-' + index"
                                                    class="collapse"
                                                    aria-labelledby="headingOne"
                                                    data-parent="#accordion"
                                                >
                                                  <!-- eslint-disable -->
                                                    <div class="card-body">
                                                        <div
                                                            v-for="outline in outlines[
                                                                week
                                                            ]"
                                                            v-if="
                                                                !!outlines[week]
                                                            "
                                                        >
                                                          <!-- eslint-disable -->
                                                            <h4>
                                                                {{
                                                                    outline.date
                                                                        | ddddLL
                                                                }}
                                                            </h4>
                                                            <h5>
                                                                {{
                                                                    outline.topic
                                                                }}
                                                            </h5>
                                                            <p
                                                                v-html="
                                                                    outline.outline
                                                                "
                                                            ></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="row px-3 mt-3 mb-3"
                                        id="course-outline-bottom"
                                    >
                                        <div class="col-sm-6 col-6">
                                            <a
                                                v-on:click="$parent.steps--"
                                                href="javascript:void(0)"
                                                class="btn btn-outline-primary btn-lg btn_fontsize back-btn"
                                                >Back</a
                                            >
                                        </div>
                                        <div class="col-sm-6 col-6 text-right">
                                            <a
                                                v-on:click="
                                                    validateCourseOutlines
                                                "
                                                href="javascript:void(0)"
                                                class="next action-button btn btn-primary btn-lg btn_fontsize"
                                                :disabled="!!loading"
                                                ><div
                                                    v-if="!!loading"
                                                    class="spinner-border text-primary"
                                                    style="
                                                        color: white !important;
                                                        width: 20px !important;
                                                        height: 20px !important;
                                                    "
                                                    role="status"
                                                ></div>
                                                <span v-if="!!loading == false">
                                                    Save and Continue</span
                                                ></a
                                            >
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!----------  Modal ------------>
        <div
            v-if="outlineModal"
            class="modal editCourseOutline bd-example-modal-lg"
            style="
                display: block;
                background-color: rgba(0, 0, 0, 0.4);
                overflow: auto;
            "
            id="exampleModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div
                class="modal-dialog modal-dialog-centered modal-lg"
                role="document"
            >
                <div class="modal-content modal-lg">
                    <div class="modal-header">
                        <h3 class="title" v-if="outlineState === 'update'">
                            Update Outline
                        </h3>
                        <h3 class="title" v-else>Add Outline</h3>
                        <button
                            v-on:click="outlineModal = !outlineModal"
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form
                            ref="form"
                            @submit.prevent="validateCourseOutlineForm"
                            method="POST"
                            enctype="multipart/form-data"
                        >
                          <!-- eslint-disable -->
                            <div v-for="(session, index) in sessions">
                                <h4>{{ session.date | ddddLL }}</h4>
                                <div class="form-group col-md-12 px-0">
                                    <label for="topic">Topic(s)</label>
                                    <input
                                        required
                                        type="text"
                                        id="topic"
                                        v-model="topic[index]"
                                        placeholder="Add Topic"
                                        v-on:focus="errorMessages.topic = []"
                                    />
                                    <ul
                                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                        v-if="
                                            errorMessages.topic.length > 0 &&
                                            topic[index] === 0
                                        "
                                    >
                                      <!-- eslint-disable -->
                                        <li v-for="err in errorMessages.topic">
                                            <small>{{ err }}</small>
                                        </li>
                                    </ul>
                                </div>
                                <div class="form-group">
                                    <label for="topic">Details</label>
<!--                                    <ckeditor-->
<!--                                        :editor="editor"-->
<!--                                        v-model="editorData[index]"-->
<!--                                        :config="editorConfig"-->
<!--                                    ></ckeditor>-->
                                  <textarea v-model="editorData[index]"></textarea>
                                    <ul
                                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                        v-if="errorMessages.detail.length > 0"
                                    >
                                      <!-- eslint-disable -->
                                        <li v-for="err in errorMessages.detail">
                                            <small>{{ err }}</small>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button
                                    v-on:click="outlineModal = !outlineModal"
                                    class="btn lgx-btn btn-danger"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    Close
                                </button>
                                <button
                                    type="submit"
                                    class="btn lgx-btn btn-primary"
                                    :disabled="!!outlineSaveLoading"
                                >
                                    <div
                                        v-if="!!outlineSaveLoading"
                                        class="spinner-border text-primary"
                                        style="
                                            color: white !important;
                                            width: 20px !important;
                                            height: 20px !important;
                                        "
                                        role="status"
                                    ></div>
                                    <span v-if="!!outlineSaveLoading == false">
                                        <i class="fas fa-sd-card"></i>
                                        Submit</span
                                    >
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";

export default {
    name: "Step4Component",
    data() {
        return {
            outlineSaveLoading: false,
            loading: false,
            position: 0,
            topics: [],
            topic: [],
            editorData: [],
            has_attachments: [],
            editor: null,
            assignment: [],
            attachments: [],
            editorConfig: {},
            course: {
                count_weeks: 0,
            },
            count_weeks: 0,
            outlineModal: false,
            has_assignment: [],
            showModal: false,
            selected_week: 0,
            selected_outline: [],
            sessions: [],
            outlines: [],
            outline: "",
            course_outlines: [],
            outlineState: "",
            start_at: "",
            end_at: "",
            errorMessages: {
                topic: [],
                detail: [],
            },
        };
    },
    created() {
        if (this.$route.params.id) {
            this.getCourseDetails();
        }
    },
    mounted() {},
    methods: {
      getDatesByWeeks(value, number) {
        if (value) {
          let start = 7 * number;
          let end = 7 + start;
          return (
              moment(value.start_at).add(start, "d").format("DD MMMM YYYY") +
              " to " +
              moment(value.start_at).add(end, "d").format("DD MMMM YYYY")
          );
        }
      },
        addNewTopic() {
            this.topics.push({...this.requirement});
        },
        removeApartment(index) {
          this.topics.splice(index, 1);
        },
        handleFileUpload() {
            this.assignment = this.$refs.stdImage.files[0];
        },
        handleFileUploadAttachment(e) {
            this.attachments[this.position] = e.target.files[0];
            this.has_attachments[this.position] = true;
        },
        async showOutlineModal(week) {
            this.outlineModal = !this.outlineModal;
            let course_id = this.$route.params.id;
            let formdata = new FormData();
            formdata.append("week", week);
            formdata.append("course_id", course_id);
            let { data } = await axiosInstance.post(endpoints.getOutline, formdata);
            if (data.type === "success") {
                this.sessions = data.sessions;
                this.editorData = data.outlines;
                this.topic = data.topics;
                // this.addNewTopic()
                // this.attachments = data.attachments
                // this.has_assignment = data.has_assignment
                // if (this.has_assignment) {
                //   this.start_at = moment(data.assignment.start_at).format('YYYY-MM-DDThh:mm')
                //   this.end_at = moment(data.assignment.end_at).format('YYYY-MM-DDThh:mm')
                // }
            } else {
                this.outline = "";
            }
            this.showModal = true;
            this.selected_week = week;

            this.outline = "";
        },
        async validateCourseOutlines() {
            let self = this;
            let formData = new FormData();
            formData.append("course_id", this.$route.params.id);
            self.loading = true;
            const { data } = await axiosInstance.post(endpoints.validateOutlineRoute, formData);
            self.loading = false;
            console.log("Data Outlines", data.outlines);
            console.log(self.course.count_weeks, JSON.parse(self.course.days));
            console.log(
                self.course.count_weeks * JSON.parse(self.course.days).length
            );
            if (
                data.outlines <
                self.course.count_weeks * JSON.parse(self.course.days).length
            ) {
                this.$toasted.error("Enter Outlines For All Weeks");
            } else {
                self.$parent.steps++;
            }
        },
        async validateCourseOutlineForm() {
            let self = this;
            console.log(
                self.checkTopic(),
                self.checkDetail(),
                self.checkTopic() && self.checkDetail()
            );
            self.errorMessages.detail = [];
            if (!self.checkDetail())
                self.errorMessages.detail.push("Add Outline Details.");
            else {
                if (self.checkTopic() && self.checkDetail())
                    await this.storeOutline();
            }
        },
        checkTopic() {
            let result = true;
            // let self = this
            // this.topic.forEach(function (t, index) {
            //   self.errorMessages.topic[index] = []
            //   if(!!t) {
            //     result = false
            //     self.errorMessages.topic[index].push('Enter topic detail...!')
            //   }
            // })
            return result;
        },
        checkDetail() {
            let result = true;
            this.editorData.forEach(function (t) {
                if (!t) {
                    result = false;
                }
            });
            return result;
        },
        async storeOutline() {
            let self = this;
            let outline_id = 0;
            let formData = new FormData();
            formData.append("outlines", JSON.stringify(self.editorData));
            formData.append("topics", JSON.stringify(self.topic));
            formData.append("course_id", self.$route.params.id);
            formData.append("week", self.selected_week);
            formData.append("id", outline_id);
            formData.append(
                "has_attachments",
                JSON.stringify(self.has_attachments)
            );
            formData.append("start_at", self.start_at);
            formData.append("end_at", self.end_at);
            formData.append("assignment", self.assignment);
            formData.append("attachments", JSON.stringify(self.attachments));
            // self.loading = true;
            this.outlineSaveLoading = true;
            const { data } = await axiosInstance.post(endpoints.storeOutlineRoute, formData);
            this.outlineSaveLoading = false;
            // self.loading = false;
            self.outline = [];
            self.outline = data.outlines;
            self.outlineModal = !self.outlineModal;
            self.breakCourseOutlines();

            this.editorData = [];
            this.topic = [];
            this.has_assignment = [];
            this.start_at = "";
            this.end_at = "";
        },
        getCourseDetails() {
            let id = this.$route.params.id;
            let self = this;
            axiosInstance
                .get("/v1/course/edit/" + id)
                .then(function (response) {
                    // handle success
                    if (response.data.type === "success") {
                        self.outline = response.data.data.outlines;
                        console.log(response.data.data.outlines);
                        self.course = response.data.data;
                        self.course.count_weeks = parseInt(
                            response.data.data.count_weeks
                        );
                    } else {
                        toast.error(response.data.message);
                        self.$router.replace("/tutor/courses");
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .then(function () {
                    self.breakCourseOutlines();
                });
        },
        breakCourseOutlines() {
            let self = this;
            self.outlines = [];
            self.outline.forEach(function (outline) {
                if (!self.outlines[outline.week]) {
                    self.outlines[outline.week] = [];
                }
                self.outlines[outline.week] = [];
                console.log(
                    "Outline",
                    self.outline.filter((out) => out.week === outline.week)
                );
                self.outlines[outline.week] = self.outline.filter(
                    (out) => out.week === outline.week
                );
            });
        },
    },
};
</script>

<style scoped>
.weekly-detail-heading {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}
@media screen and (min-width: 1400px) {
    .container {
        max-width: 1450px !important;
    }
}
@media screen and (min-width: 1850px) {
    .container {
        max-width: 2150px !important;
    }
}
.dashboard-newtheme form input {
    width: 100%;
    margin-bottom: 2px;
    /*border-radius: 50px !important;*/
}
input#requirement {
    width: 80%;
}
</style>
