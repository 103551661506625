<template>
  <div class="modal" id="AppointmentsModal" style="display: block; background-color: rgba(0,0,0, 0.4); overflow: scroll; overflow-x: hidden;"  tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content parent_modal" style="background-image: none !important;">
          <div class="modal-header py-1">
            <h5 class="modal-title">Refund Request Details</h5>
            <button v-on:click="$parent.refundModal = !$parent.refundModal" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div id="scheduleDaily" class="tab-pane active modal1 col-md-12">
                <table>
                  <tr><th>Student Name</th> <td>{{ refundDetail.transaction.slots.find(s => s.slot.id === refundDetail.model_id).user.name }}</td></tr>
                  <tr><th>Tutor Name</th> <td>{{ refundDetail.transaction.slots.find(s => s.slot.id === refundDetail.model_id).slot.tutor.name }}</td></tr>
                  <tr><th>Transaction Id</th> <td>{{ refundDetail.transaction.trx_id }}</td></tr>
                  <tr><th>Transaction Amount</th> <td>{{ refundDetail.transaction.amount }} PKR</td></tr>
                  <tr><th>Requested Refund Amount</th> <td>{{ refundDetail.transaction.slots.find(s => s.slot.id === refundDetail.model_id).slot.price }} PKR</td></tr>
                  <tr>
                    <!-- eslint-disable -->
                    <th>Slot Time</th><td>
                    {{ refundDetail.transaction.slots.find(s => s.slot.id === refundDetail.model_id).slot.start_date_time | DDMMMhhmmA }}
                    -
                    {{ refundDetail.transaction.slots.find(s => s.slot.id === refundDetail.model_id).slot.end_date_time | hhmmA }}
                  </td></tr>
<!--                  <tr><th>Refund Request Status</th> <td>{{ refundDetail.transaction.status }}</td></tr>-->
                  <tr><th>Refund Request Status</th> <td>{{ refundDetail.status }}</td></tr>
                  <tr><th style="position: absolute;">Details</th> <td>{{ refundDetail.description }}</td></tr>
                </table>
                <div class="form-group col-md-12 px-0 mt-3">
                  <label for="description">Comment</label>
                  <textarea rows="8" v-model="comment" required="required" id="description" class="form-control"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div v-if="refundDetail.status === 'pending'" class="modal-footer" style="justify-content: flex-end;">
            <button v-on:click="changeStatus('denied')" type="button" class="btn btn-secondary" style="height: 32px !important; width: auto !important; line-height: 18px;">Decline</button>
            <button v-on:click="changeStatus('resolved')" type="submit" class="btn btn-primary" style="height: 32px !important; width: auto !important;">Approve</button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
  name: "RefundRequestDetailComponent",
  props: ['refundDetail'],
  data() {
    return {
      comment: '',
    }
  },
  created() {
    this.comment = this.refundDetail.tutor_comment
  },
  mounted() {
  },
  methods: {
    async changeStatus(status) {
      let formData = new FormData();
      formData.append('refund_id', this.refundDetail.id)
      formData.append('status', status)
      formData.append('tutor_comment', this.comment)
      await axiosInstance.post(endpoints.refundRequestRoute, formData);
      this.$parent.refundModal = !this.$parent.refundModal
      this.$parent.getRefundRequest()
    }
  }
}
</script>

<style scoped>
table td {
  padding: 0 25px 0 5px !important;
}
th {
  width: 250px !important;
  line-height: 2;
}
</style>
