<template>
  <div class="container container-coursedetail transaction-table student-trans-sec transaction-newtheme">
    <ul class="nav nav-tabs p-0" id="myTab" role="tablist">
      <li v-on:click="modal = 'refund'" class="nav-item" role="presentation">
        <a class="nav-link active" id="refund-tab" data-toggle="tab" href="#refund-requests" role="tab" aria-controls="home" aria-selected="true">Refund</a>
      </li>
      <li v-on:click="modal = 'dispute'" class="nav-item" role="presentation">
        <a class="nav-link" id="dispute-tab" data-toggle="tab" href="#dispute-requests" role="tab" aria-controls="home" aria-selected="true">Dispute</a>
      </li>
      <li v-on:click="modal = 'withdraw'" class="nav-item" role="presentation">
        <a class="nav-link" id="withdraw-tab" data-toggle="tab" href="#withdraw-requests" role="tab" aria-controls="home" aria-selected="true">Withdraw</a>
      </li>
    </ul>

    <div class="tab-style-01">
      <div v-if="modal === 'refund'" class="tab-content" id="refund">
        <div class="tab-pane fade show active " id="refund-requests" role="tabpanel" aria-labelledby="home-tab">
          <RefundRequestComponent></RefundRequestComponent>
        </div>
      </div>
      <div v-if="modal === 'dispute'" class="tab-content" id="dispute">
        <div class="tab-pane fade show active " id="dispute-requests" role="tabpanel" aria-labelledby="home-tab">
          <DisputeRequestComponent></DisputeRequestComponent>
        </div>
      </div>
      <div v-if="modal === 'withdraw'" class="tab-content" id="withdraw">
        <div class="tab-pane fade show active " id="withdraw-requests" role="tabpanel" aria-labelledby="home-tab">
          <WithdrawListingComponent></WithdrawListingComponent>
        </div>
      </div>
    </div>
    <TransactionDetailComponent v-if="transactionModal" :selectedTransaction="selectedTransaction"></TransactionDetailComponent>
  </div>
</template>

<script>
import RefundRequestComponent from "@/components/tutor/billings/RefundRequestComponent";
import DisputeRequestComponent from "@/components/tutor/billings/DisputeRequestComponent";
import TransactionDetailComponent from "@/components/frontend/payment/TransactionDetailComponent";
import WithdrawListingComponent from "@/components/tutor/billings/WithdrawListingComponent";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
  name: "RequestsComponent",
  components: {
    RefundRequestComponent,
    DisputeRequestComponent,
    TransactionDetailComponent,
    WithdrawListingComponent
  },
  data() {
    return {
      transactionModal: false,
      selectedTransaction: {},
      modal: 'refund',
      balance: 0.00,
      query: {
        status: '',
        keyword: '',
        sort: 'DESC',
        page: 1,
        limit: 6,
        total: 0,
      },
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
    async getTransactionDetail(id) {
      let q = {}
      q.trx_id = id
      const {data} = await axiosInstance.get(endpoints.getTransactionDetailRoute, {params: q});
      this.selectedTransaction = data.transaction
      this.transactionModal = !this.transactionModal
    }
  }
}
</script>

<style scoped>
h3, h5 {
  color: #812bb2 !important;
}
</style>
