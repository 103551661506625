<template>
  <div>
    <div class="hero-course-wrap bg-offwhite newtheme-cart-component">
      <div class="container">
        <div class="hero-course-con py-4">
          <h2 class="font-weight-bold color_black mb-2 mt-4">Sign Up for Student</h2>
        </div>
      </div>
    </div>

    <div class="container mt-5">
      <h4>Student Registration</h4>
      <p>
        ILMzone allows students from every field to take courses and lessons
        online in their respective fields of study. Students can also find
        suitable tutors for academic assistance. Registering on ILMZone is easy
        and takes only a few minutes. Simply follow these steps:
      </p>

      <p>
        <b>Step 1:</b> Go to
        <a href="https://www.ilmzone.com">www.Ilmzone.com</a> homepage. At the
        top-right corner click on Sign Up. Select Student in the popup.
      </p>
      <img
          src="@/assets/images/signup-student-1.png"
          width="500"
          alt="signup-student-step-1"
          class="mb-5"
      />

      <p>
        <b>Step 2:</b>If you click Continue with Email, you will enter Name, a
        Username, Email, and create and confirm Password.
      </p>
      <img
          src="@/assets/images/signup-student-2.png"
          width="500"
          alt="signup-student-step-2"
          class="mb-5"
      />
      <p>Clicking the sign up button will complete your registration process.</p>
      <p>You can also sign up with Google.</p>

      <p>
        <b>Step 3:</b> After successful registration, you will be asked to
        complete your profile by providing:
      </p>
      <ul>
        <li>Name</li>
        <li>Profile picture</li>
        <li>Gender</li>
        <li>Mobile number</li>
        <li>CNIC</li>
        <li>Residential address</li>
        <li>Education level</li>
      </ul>
      <img
          src="@/assets/images/signup-student-3.png"
          width="500"
          alt="signup-student-step-3"
          class="mb-5"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "StudentSignUp",
};
</script>

<style scoped>
</style>