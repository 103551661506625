<template>
  <div>

    <!-- Modal -->
    <div class="modal fade thankspopup" id="thanksmodel" tabindex="-1" role="dialog" aria-labelledby="thanksmodel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header border-before success-color">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="thankspopup-box text-center mt-3 mb-5">
              <p class="thankspopup-box text-center mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="134.263" height="128.425" viewBox="0 0 134.263 128.425">
                  <path id="Vector" d="M64.839,0C29.087,0,0,28.806,0,64.213s29.087,64.213,64.839,64.213,64.839-28.806,64.839-64.213a63.253,63.253,0,0,0-3.508-20.656l-10.5,10.4a50.946,50.946,0,0,1,1.038,10.259c0,28.324-23.271,51.37-51.871,51.37S12.968,92.537,12.968,64.213s23.271-51.37,51.871-51.37A51.907,51.907,0,0,1,93.5,21.433l9.283-9.193A64.877,64.877,0,0,0,64.839,0Zm60.255,8.3L58.355,74.4,37,53.251l-9.169,9.08,30.52,30.225,75.907-75.174Z" fill="#00d983"/>
                </svg>
              </p>
              <h4  class="mb-3">Thank You !</h4>
              <p>Successfuly Added</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Step7Component"
}
</script>

<style scoped>

</style>