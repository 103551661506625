<template>
  <div>
    <div class="card-header">
      <div class="d-flex justify-content-between">
        <h3 class="card-title">Dispute Requests</h3>
        <div class="row">
          <form @submit.prevent="getDispute()" >
            <input type="text" v-model="query.keyword" placeholder="Search.." name="search">
            <button type="submit" class="btn btn-secondary"><i class="fa fa-search"></i></button>
          </form>
          <button class="btn btn-success ml-2" style="height: 40px;" v-on:click="getDispute()" type="button"><i class="fa fa-refresh"></i></button>
        </div>
      </div>
    </div>

    <div class="table-wrap">
      <table class="table text-center" >
        <thead class="thead-primary border-0">
        <tr>
          <th></th>
          <th>Id</th>
          <th>User</th>
          <th>Transaction Id</th>
          <th>Slot Time</th>
          <th>Price</th>
          <th>Status</th>
          <th>Created At</th>
          <th></th>
        </tr>
        </thead>

        <tbody >
        <tr v-for="dispute in disputes" :key="dispute.id">
          <td></td>
          <td>{{dispute.id}}</td>
          <td>{{dispute.user.name}}</td>
          <td><a href="javascript:void (0)" v-on:click="$parent.getTransactionDetail(dispute.trx_id)">{{dispute.trx_id}}</a></td>
          <td>
            <span v-if="!!dispute.transaction.slots.find(s => s.slot.id === dispute.model_id)">
              {{dispute.transaction.slots.find(s => s.slot.id === dispute.model_id).slot.start_date_time}} - {{dispute.transaction.slots.find(s => s.slot.id === dispute.model_id).slot.end_date_time}}
            </span>
          </td>
          <td>
            <span v-if="!!dispute.transaction.slots.find(s => s.slot.id === dispute.model_id)">
              {{dispute.transaction.slots.find(s => s.slot.id === dispute.model_id).slot.price}} PKR
            </span>
          </td>
          <td>{{dispute.status }}</td>
          <td>{{dispute.created_at}}</td>
          <td><router-link :to="'/tutor/dispute/'+dispute.id">Detail</router-link></td>
        </tr>
        </tbody>
      </table>
      <div v-if="no_record">
        <p class="p-2">{{"No Record Found."}}</p>
      </div>
      <div class="row">
        <pagination v-show="query.total > query.limit" v-model="query.page" :records="query.total" :per-page="query.limit" @paginate="getDispute()"/>
      </div>
    </div>
  </div>
</template>

<script>


import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
  name: "DisputeRequestComponent",
  data() {
    return {
      no_record: false,
      disputes: [],
      query: {
        status: '',
        keyword: '',
        sort: 'DESC',
        page: 1,
        limit: 6,
        total: 0,
      },
    }
  },
  created() {
    this.getDispute();
  },
  mounted() {
  },
  methods: {
    async getDispute() {
      const disputeListing = await axiosInstance.get(endpoints.getTutorDisputeRoute, {params: this.query})
      this.disputes = disputeListing.data.data.disputes.data
      this.query.total = disputeListing.data.data.disputes.total
      this.no_record = this.disputes.length === 0
    }
  }
}
</script>

<style scoped>

</style>
