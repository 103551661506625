<template>
  <div class="container_home_section tutor-dashboard-newtheme">
    <div v-if="loaded" class="row">
      <div class="col-xl-12 main-col">
        <div class="row">
          <div class="col-md-8 col-12">
            <h3>Refund Details</h3>
            <div class="hide-total_earning_parent">
              <div class="total_earning_parent">
                <div class="rows">
                  <h5 class="total_text"> Detail </h5>
                  <p class="total_text pl-0 m-0">{{ refund.detail }}</p>
                </div>
                <div class="rows">
                  <h5 class="total_text"> Created By </h5>
                  <p class="total_text pl-0 m-0">{{ refund.user.name }}</p>
                </div>
                <div class="rows">
                  <h5 class="total_text"> Status </h5>
                  <p class="total_text pl-0 m-0">{{ refund.status }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-12 px-0">
            <div class="row p-0 m-0">
              <div v-if="!!refund.transaction" class="col-md-12">
                <h5>Refund Requested Items</h5>
                <div class="hide-total_earning_parent">
                  <!-- eslint-disable -->
                  <div class="total_earning_parent">
                    <div v-if="refund.model_id === slot.slot.id" v-for="slot in refund.transaction.slots" class="rows">
                      <div class="coursesdetail">
                        <p class="total_text pl-0 m-0">
                          {{ slot.slot.start_date_time | DDMMMM }}
                          {{ slot.slot.start_date_time | hhmmA }} - {{ slot.slot.end_date_time | hhmmA }}
                          {{ slot.slot.price }} PKR
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!!refund.transaction" class="col-md-12 mt-2">
                <h5>Transaction Details</h5>
                <div class="hide-total_earning_parent">
                  <div class="total_earning_parent">
                    <div v-for="slot in refund.transaction.slots" class="rows">
                      <div class="coursesdetail">
                        <p class="total_text pl-0 m-0">
                          {{ slot.slot.start_date_time | DDMMMM }}
                          {{ slot.slot.start_date_time | hhmmA }} - {{ slot.slot.end_date_time | hhmmA }}
                          {{ slot.slot.price }} PKR
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import endpoints from "@/assets/js/api";
import axiosInstance from "@/assets/js/axios";

export default {
  name: "RefundDetailComponent",
  data() {
    return {
      loaded: false,
      refund: {},
      message: '',
      threads: [],
      query: {
        total: 0,
        sort: 'DESC',
        page: 1,
        limit: 6,
      },
    }
  },
  created() {
    this.getRefundDetail()
  },
  mounted() {
  },
  methods: {
    async getRefundDetail() {
      let formData = new FormData();
      formData.append('refund_id', this.$route.params.id)
      const {data} = await axiosInstance.post(endpoints.getStudentRefundDetailRoute, formData);
      if (data.type === 'success') {
        this.refund = data.refund
        this.loaded = true
      }
    },
  }
}
</script>

<style scoped>
.tutor-dashboard-newtheme .total_earning_parent .rows {
  display: flow-root !important;
}
img.img-bordered-sm {
  width: 40px;
}
div.user-block {
  padding-left: 16px;
}
</style>
