<template>
    <div id="experiences">
        <div class="experience-header">
            <h2>Experience</h2>
            <img
                src="@/assets/new-theme-images/bi_plus-square.png"
                alt="plus"
                @click="openAddExp()"
            />
        </div>
        <div v-for="experience in experiences" class="row experience-item" :key="experience.id">
            <div class="col-md-6">
                <h5 class="experience-title">{{ experience.job_title }}</h5>
                <p class="experience-desc">
                    <u>{{ experience.company }}</u>
                </p>
                <p class="experience-desc">
                    <u
                        >{{ experience.start_date }} -
                        {{
                            experience.job_continue
                                ? " Present "
                                : experience.end_date
                        }}
                        . {{ calculateDuration(experience) }}</u
                    >
                </p>
                <p class="experience-desc">
                    <u>{{ experience.headline }}</u>
                </p>
            </div>

            <div class="col-md-6 experience-edit">
                <img
                    @click="editExp(experience.id)"
                    src="@/assets/new-theme-images/clarity_edit-line.png"
                    alt="edit"
                    class="mr-3"
                />
                <img
                    @click="deleteExp(experience.id)"
                    src="@/assets/new-theme-images/trash.png"
                    alt="trash"
                />
            </div>
        </div>
        <div
            v-if="experienceModal"
            class="modal bd-example-modal-lg"
            style="
                display: block;
                background-color: rgba(0, 0, 0, 0.4);
                overflow: auto;
            "
            id="addexpriences"
            tabindex="-1"
            role="dialog"
        >
            <form action="" @submit.prevent="addExperience()">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Add Experiences</h5>
                            <button
                                v-on:click="experienceModal = !experienceModal"
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <!--                <div class="col-md-6">-->
                                <div class="form-group col-md-6">
                                    <label for="job_title">Designation</label>
                                    <input
                                        type="text"
                                        v-model="experience.job_title"
                                        id="job_title"
                                        class="form-control"
                                        aria-describedby="emailHelp"
                                        placeholder="Title"
                                        v-on:focus="
                                            errorMessages.job_title = []
                                        "
                                    />
                                    <ul
                                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                        v-if="
                                            errorMessages.job_title.length > 0
                                        "
                                    >
                                        <li
                                            v-for="err in errorMessages.job_title" :key="err"
                                        >
                                            <small>{{ err }}</small>
                                        </li>
                                    </ul>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="organization"
                                        >Organization</label
                                    >
                                    <input
                                        type="text"
                                        v-model="experience.company"
                                        id="organization"
                                        class="form-control"
                                        aria-describedby="organization"
                                        placeholder="Organization"
                                        v-on:focus="errorMessages.company = []"
                                    />
                                    <ul
                                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                        v-if="errorMessages.company.length > 0"
                                    >
                                        <li
                                            v-for="err in errorMessages.company" :key="err"
                                        >
                                            <small>{{ err }}</small>
                                        </li>
                                    </ul>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="start_date">Start Date</label>
                                    <input
                                        type="date"
                                        v-model="experience.start_date"
                                        id="start_date"
                                        class="form-control"
                                        aria-describedby="start_date"
                                        placeholder="Start Date"
                                        v-on:focus="
                                            errorMessages.start_date = []
                                        "
                                    />
                                    <ul
                                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                        v-if="
                                            errorMessages.start_date.length > 0
                                        "
                                    >
                                        <li
                                            v-for="err in errorMessages.start_date" :key="err"
                                        >
                                            <small>{{ err }}</small>
                                        </li>
                                    </ul>
                                </div>

                                <div
                                    class="form-group col-md-6"
                                    v-show="!experience.job_continue"
                                >
                                    <label for="end_date">End Date</label>
                                    <input
                                        type="date"
                                        id="end_date"
                                        v-model="experience.end_date"
                                        class="form-control"
                                        aria-describedby="end_date"
                                        placeholder="End Date"
                                        v-on:focus="errorMessages.end_date = []"
                                    />
                                    <ul
                                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                        v-if="errorMessages.end_date.length > 0"
                                    >
                                        <li
                                            v-for="err in errorMessages.end_date" :key="err"
                                        >
                                            <small>{{ err }}</small>
                                        </li>
                                    </ul>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="employment_type"
                                        >Employment Type</label
                                    >
                                    <select
                                        class="form-control"
                                        name="employment_type"
                                        v-model="experience.employment_type"
                                        id="employment_type"
                                        v-on:focus="
                                            errorMessages.employment_type = []
                                        "
                                    >
                                        <option value="0">
                                            Choose employment type
                                        </option>
                                        <option value="Government">
                                            Government
                                        </option>
                                        <option value="Private">Private</option>
                                        <option value="Self Employee">
                                            Self Employee
                                        </option>
                                    </select>
                                    <ul
                                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                        v-if="
                                            errorMessages.employment_type
                                                .length > 0
                                        "
                                    >
                                        <li
                                            v-for="err in errorMessages.employment_type" :key="err"
                                        >
                                            <small>{{ err }}</small>
                                        </li>
                                    </ul>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="headline">Headline</label>
                                    <input
                                        type="text"
                                        id="headline"
                                        v-model="experience.headline"
                                        class="form-control"
                                        aria-describedby="headline"
                                        placeholder="Headline"
                                        v-on:focus="errorMessages.headline = []"
                                    />
                                    <ul
                                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                        v-if="errorMessages.headline.length > 0"
                                    >
                                        <li
                                            v-for="err in errorMessages.headline" :key="err"
                                        >
                                            <small>{{ err }}</small>
                                        </li>
                                    </ul>
                                </div>

                                <div class="form-group col-md-6">
                                    <label>Job Description</label>
                                    <textarea
                                        class="form-control"
                                        v-model="experience.summary"
                                        id="summary"
                                        rows="3"
                                        placeholder="Detail"
                                        v-on:focus="errorMessages.summary = []"
                                    ></textarea>
                                    <ul
                                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                        v-if="errorMessages.summary.length > 0"
                                    >
                                        <li
                                            v-for="err in errorMessages.summary" :key="err"
                                        >
                                            <small>{{ err }}</small>
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-md-12">
                                    <div
                                        class="form-check mb-2 form-check-label-tutor-profile"
                                    >
                                        <input
                                            type="checkbox"
                                            id="job_continue"
                                            v-model="experience.job_continue"
                                            class="form-check-input"
                                            value="true"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="job_continue"
                                            >Current position</label
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                v-on:click="experienceModal = !experienceModal"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="submit"
                                class="btn btn-primary text-dark"
                                :disabled="loading"
                            >
                                {{
                                    loading
                                        ? "Saving..."
                                        : experience.id
                                        ? "Update"
                                        : "Save"
                                }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="d-flex align-items-center justify-content-end mt-3">
            <span></span>
            <button
                type="submit"
                class="btn custom-button d-flex align-items-center justify-content-center"
                style="width: 100px"
                v-on:click="$parent.nextTab()"
            >
                <span>Next</span>
            </button>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
import Swal from "sweetalert2";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";

export default {
    name: "ExperienceComponent",
    props: [],
    components: {},
    directives: {},
    data() {
        return {
            loading: false,
            experienceModal: false,
            experience: {
                job_title: "",
                company: "",
                designation: "",
                employment_type: 0,
                start_date: "",
                job_continue: false,
                end_date: "",
                headline: "",
                summary: "",
            },
            errorMessages: {
                job_title: [],
                company: [],
                designation: [],
                employment_type: [],
                start_date: [],
                job_continue: [],
                end_date: [],
                headline: [],
                summary: [],
            },
            experiences: [],
        };
    },
    computed: {},
    created() {
        if (!this.experiences.length > 0) this.getList();
    },
    methods: {
        openAddExp() {
            // $('#addexpriences').modal('show');
            this.experienceModal = !this.experienceModal;
        },
        async deleteExp(id) {
            let self = this;

            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axiosInstance.post(endpoints.expPro, id);
                    await self.getList();
                    toast.error("Experience has been deleted.");
                }
            });
        },
        async editExp(id) {
            const data = await axiosInstance.get(endpoints.expPro, {params: id});
            this.experience = data.data;
            this.openAddExp();
        },
        async getList() {
            const expRes = await axiosInstance.get(endpoints.expPro);
            this.experiences = expRes.data.data.data;
        },
        checkTitle() {
            this.errorMessages.job_title = [];
            if (this.experience.job_title.length === 0) {
                this.errorMessages.job_title.push(
                    "The job title field is required."
                );
                return false;
            } else {
                this.errorMessages.job_title = [];
                return true;
            }
        },
        checkOrganization() {
            this.errorMessages.company = [];
            if (this.experience.company.length === 0) {
                this.errorMessages.company.push(
                    "The organization field is required."
                );
                return false;
            } else {
                this.errorMessages.company = [];
                return true;
            }
        },
        checkStartDate() {
            this.errorMessages.start_date = [];
            if (!this.experience.start_date) {
                this.errorMessages.start_date.push(
                    "The start date field is required."
                );
                return false;
            } else {
                this.errorMessages.start_date = [];
                return true;
            }
        },
        checkEndDate() {
            this.errorMessages.end_date = [];
            if (!this.experience.end_date) {
                this.errorMessages.end_date.push(
                    "The end date field is required."
                );
                return false;
            } else {
                this.errorMessages.end_date = [];
                return true;
            }
        },
        checkEmploymentType() {
            this.errorMessages.employment_type = [];
            if (!this.experience.employment_type) {
                this.errorMessages.employment_type.push(
                    "The employment type field is required."
                );
                return false;
            } else {
                this.errorMessages.employment_type = [];
                return true;
            }
        },
        async addExperience() {
            let self = this;
            this.checkTitle();
            this.checkOrganization();
            this.checkStartDate();
            // this.checkEndDate();
            this.checkEmploymentType();

            if (
                this.checkTitle() &&
                this.checkOrganization() &&
                this.checkStartDate() &&
                // this.checkEndDate() &&
                this.checkEmploymentType()
            ) {
                this.loading = true;
                if (this.experience.id) {
                    console.log("id------------------------------------");
                    console.log(this.experience.id);
                    console.log("experience----------------------------");
                    console.log(this.experience);

                    await axiosInstance.put(endpoints.infoPro+'/'+this.experience.id, this.experience)
                        .then((response) => {
                            console.log("response-----------------------------------");
                            console.log(response);
                            if (response.data.type === "validation") {
                                Object.keys(response.data.message).forEach(
                                    function (key) {
                                        console.log(key);
                                        self.errorMessages[key] =
                                            response.data.message[key];
                                    }
                                );
                            } else if (response.data.type === "success") {
                                toast.success(response.message);
                                this.loading = false;
                                this.experienceModal = !this.experienceModal;
                                this.getList();
                                this.experience = {};
                            } else {
                                toast.success(response.message);
                            }
                        })
                        .catch(() => {
                            this.loading = false;
                        })
                        .finally(() => {
                            this.customerForm = false;
                            this.loading = false;
                        });
                } else {
                    await axiosInstance.post(endpoints.expPro, this.experience)
                        .then((response) => {
                            console.log(response);
                            if (response.data.type === "validation") {
                                Object.keys(response.data.message).forEach(
                                    function (key) {
                                        console.log(key);
                                        self.errorMessages[key] =
                                            response.data.message[key];
                                    }
                                );
                            } else if (response.data.type === "success") {
                                toast.success(response.message);
                                this.loading = false;
                                this.experienceModal = !this.experienceModal;
                                this.getList();
                                this.experience = {};
                            } else {
                                toast.success(response.message);
                            }
                        })
                        .catch(() => {
                            this.loading = false;
                        })
                        .finally(() => {
                            this.customerForm = false;
                            this.loading = false;
                            this.$parent.checkProfileStatus();
                        });
                }
            }
        },
        calculateDuration(experience) {
            let start = moment(experience.start_date);
            let end = !experience.job_continue
                ? moment(experience.end_date)
                : moment();
            return moment.duration(end.diff(start)).humanize();
        },
    },
};
</script>
<style scoped>
button {
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom-button {
    background-color: #812bb2;
    font-size: 15px;
    font-weight: 700;
    line-height: 5px;
    font-family: "Roboto", sans-serif;
    color: #fccd05 !important;
    height: 32px !important;
    border-radius: 5px !important;
    margin-top: 15px;
}
</style>
