<template>
  <div>
    <div class="table-wrap">
      <table class="table text-center" >
        <thead class="thead-primary border-0">
        <tr>
          <th></th>
          <th>Transaction Id</th>
          <th>Slot Time</th>
          <th>Price</th>
          <th>Status</th>
          <th>Created At</th>
          <th></th>
        </tr>
        </thead>

        <tbody >
        <tr v-for="dispute in disputes" :key="dispute.id">
          <td></td>
          <td><a href="javascript:void (0)" v-on:click="$parent.getTransactionDetail(dispute.trx_id)">{{dispute.trx_id}}</a></td>
          <td>
            <!-- eslint-disable -->
            <span v-if="!!dispute.transaction.slots.find(s => s.slot.id === dispute.model_id)">
              {{dispute.transaction.slots.find(s => s.slot.id === dispute.model_id).slot.start_date_time | DDMMMhhmmA}} - {{dispute.transaction.slots.find(s => s.slot.id === dispute.model_id).slot.end_date_time | hhmmA}}
            </span>
          </td>
          <td>
            <span v-if="!!dispute.transaction.slots.find(s => s.slot.id === dispute.model_id)">
              {{dispute.transaction.slots.find(s => s.slot.id === dispute.model_id).slot.price}}
            </span>
          </td>
          <td>{{dispute.status }}</td>
          <td>{{dispute.created_at | DDMMMhhmmA}}</td>
          <td><router-link :to="'/student/dispute/'+dispute.id">Detail</router-link></td>
        </tr>
        </tbody>
      </table>
      <div v-if="no_record">
        <p class="p-2">{{$t("text.no_record")}}</p>
      </div>
      <div class="row">
        <pagination v-show="query.total > query.limit" v-model="query.page" :records="query.total" :per-page="query.limit" @paginate="getDispute()"/>
      </div>
    </div>
  </div>
</template>

<script>

import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
  name: "DisputeRequestComponent",
  data() {
    return {
      no_record: false,
      disputes: [],
      query: {
        status: '',
        keyword: '',
        sort: 'DESC',
        page: 1,
        limit: 6,
        total: 0,
      },
    }
  },
  created() {
    this.getDispute();
  },
  mounted() {
  },
  methods: {
    async getDispute() {
      const {data} = await axiosInstance.get(endpoints.getDisputeRoute, this.query)
      this.disputes = data.disputes.data
      this.query.total = data.disputes.total
      if (this.disputes.length === 0) {
        this.no_record = true
      }
    }
  }
}
</script>
