<template>
  <div>
    <div class="modal" style="display: block; background-color: rgba(0,0,0, 0.4);overflow-y: scroll;" id="postajob-04" tabindex="-1" role="dialog">
      <div class="modal-dialog job_alert_modal modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Apply to Tuition</h5>
            <button v-on:click="$parent.applyJobFrom = !$parent.applyJobFrom" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <img src="@/assets/new-theme-images/red-cross.png" alt="close" />
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-12">
                <div class="media tuition-listing-media">
                  <img class="mr-3 rounded-circle" :src="'/storage'+selected_job.user.image" alt="user profile">
                  <div class="media-body">
                    <h5 class="mt-0 mb-0">
                      {{ selected_job.user.name }}
                      <svg xmlns="http://www.w3.org/2000/svg" width="12.915" height="15.785" viewBox="0 0 12.915 15.785">
                        <path id="Icon_material-verified-user" data-name="Icon material-verified-user" d="M10.958,1.5,4.5,4.37V8.675a8.929,8.929,0,0,0,6.458,8.61,8.929,8.929,0,0,0,6.458-8.61V4.37ZM9.523,12.98l-2.87-2.87L7.664,9.1,9.523,10.95l4.728-4.728L15.263,7.24Z" transform="translate(-4.5 -1.5)" fill="#812bb2"></path>
                      </svg>
                    </h5>
                    <small> Posted {{ postedAt(selected_job.created_at) }} ago </small>
                  </div>
                </div>
              </div>
            </div>
            <p class="mt-2" v-html="selected_job.description"></p>

            <div class="apply-tuition-description">
              <!-- eslint-disable -->
              <p class="subject mr-2"><strong>Subjects: </strong> <span v-for="subject in selected_job.subjects"> {{ subject.subject.subject }} </span> </p>
              <p><strong>Level: </strong>{{  selected_job.level.charAt(0).toUpperCase() + selected_job.level.slice(1)}}</p>
              <p><strong>Alumni of: </strong>{{ selected_job.institute.name }}</p>
            </div>
          </div>
          <div class="modal-footer border-0 pt-0 mb-0">
            <button type="button" class="btn px-5 py-2 next-btn" v-on:click="applyForJob()">Apply</button>
            <button v-on:click="$parent.applyJobFrom = !$parent.applyJobFrom" class="btn cancel-btn">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";

export default {
  name: "ApplyJobComponent",
  props:['selected_job'],
  data() {
    return {

    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
    postedAt(date) {
      let created = moment(date).tz()
      let today = moment(new Date()).tz()
      return moment.duration(today.diff(created)).humanize()
    },
    async applyForJob() {
      let self = this
      let job = this.selected_job
      let query = {}
      query.id = job.id
      const {data} = await axiosInstance.post(endpoints.applyJobRoute, query);
      if (data.type === 'success') {
        toast.success(data.message)
        self.$parent.applyJobFrom = !self.$parent.applyJobFrom
        await this.$parent.getJobPost()
      }
      else if (data.type === 'error') {
        toast.error(data.message)
        self.$parent.applyJobFrom = !self.$parent.applyJobFrom
      }
    }
  }
}
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}
</style>
