<template>
    <div>
        <div
            class="modal"
            style="display: block; background-color: rgba(0, 0, 0, 0.4)"
            id="postajob-02"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div
                class="modal-dialog modal-lg modal-dialog job_alert_modal modal-dialog-centered"
                role="document"
            >
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Post a Tuition
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            v-on:click="$parent.closeTuitionForm()"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="steps text-center">
                            <ul
                                id="progressbar"
                                class="progressbar-box-02 my-2"
                            >
                                <li class="active" id="general">
                                    <span>1</span
                                    ><strong>Subjects & Levels </strong>
                                </li>
                                <li class="active" id="duration">
                                    <span>2</span><strong>Preferences</strong>
                                </li>
                                <li id="classsessionroomsetting">
                                    <span>3</span><strong>Other Details</strong>
                                </li>
                            </ul>
                        </div>
                        <form class="form-style-01">
                            <div class="form-group">
                                <label class="text-uppercase"
                                    >Alumni/Student of</label
                                >
                              <!-- eslint-disable -->
                                <select
                                    v-model="post.institute_id"
                                    class="form-control"
                                    v-on:focus="errorMessages.institute = []"
                                >
                                    <option value="0" selected disabled>
                                        Select an option if you want the tutor
                                        to be from a specific
                                        university/college.
                                    </option>
                                    <option
                                        :value="institute.id"
                                        v-for="institute in $parent.institutes"
                                    >
                                        {{ institute.name }}
                                    </option>
                                </select>
                                <ul
                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                    v-if="errorMessages.institute.length > 0"
                                >
                                    <li v-for="err in errorMessages.institute">
                                        <small>{{ err }}</small>
                                    </li>
                                </ul>
                            </div>
                            <div class="form-group">
                                <label class="text-uppercase">Gender</label>
                                <div class="d-flex">
                                    <div class="mr-3">
                                        <div class="frequency-labels">
                                            <div class="daily-weekly">
                                                <label class="radiostyle-01">
                                                    <input
                                                        type="radio"
                                                        id="male"
                                                        value="male"
                                                        name="gender"
                                                        v-model="post.gender"
                                                        class="form-check"
                                                        v-on:focus="
                                                            errorMessages.gender =
                                                                []
                                                        "
                                                    />
                                                    <span
                                                        class="radiostyle-01-checkmark"
                                                    ></span>
                                                    <span
                                                        class="weekly-daily-lable-t"
                                                        >Male</span
                                                    >
                                                </label>
                                            </div>
                                            <div class="daily-weekly">
                                                <label class="radiostyle-01">
                                                    <input
                                                        type="radio"
                                                        id="female"
                                                        value="female"
                                                        name="gender"
                                                        v-model="post.gender"
                                                        class="form-check"
                                                        v-on:focus="
                                                            errorMessages.gender =
                                                                []
                                                        "
                                                    />
                                                    <span
                                                        class="radiostyle-01-checkmark"
                                                    ></span>
                                                    <span
                                                        class="weekly-daily-lable-t"
                                                        for="female"
                                                        >Female</span
                                                    >
                                                </label>
                                            </div>
                                            <div class="daily-weekly">
                                                <label class="radiostyle-01">
                                                    <input
                                                        type="radio"
                                                        id="other"
                                                        value="other"
                                                        name="gender"
                                                        v-model="post.gender"
                                                        class="form-check"
                                                        v-on:focus="
                                                            errorMessages.gender =
                                                                []
                                                        "
                                                    />
                                                    <span
                                                        class="radiostyle-01-checkmark"
                                                    ></span>
                                                    <span
                                                        class="weekly-daily-lable-t"
                                                        for="other"
                                                        >Prefer not to
                                                        disclose</span
                                                    >
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ul
                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                    v-if="errorMessages.gender.length > 0"
                                >
                                    <li v-for="err in errorMessages.gender">
                                        <small>{{ err }}</small>
                                    </li>
                                </ul>
                            </div>
                        </form>
                    </div>
                    <div
                        class="modal-footer border-0 pt-0 mb-3 d-flex justify-content-between"
                    >
                        <button
                            type="button"
                            class="btn btn-primary next-btn px-5 py-2"
                            v-on:click="$parent.step--"
                        >
                            Back
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary next-btn d-flex align-items-center justify-content-center px-5 py-2"
                            v-on:click="validateForm()"
                            :disabled="!!submitLoading"
                        >
                            <div
                                v-if="!!submitLoading"
                                class="spinner-border text-primary"
                                style="
                                    color: white !important;
                                    width: 20px !important;
                                    height: 20px !important;
                                "
                                role="status"
                            ></div>
                            <span v-if="!!submitLoading === false">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
    name: "JobPostStep02Component",
    data() {
        return {
            loading: false,
            submitLoading: false,
            post: {
                institute_id: 0,
                gender: "",
            },
            errorMessages: {
                institute: [],
                gender: [],
            },
        };
    },
    created() {
        if (this.$parent.authenticated && this.$parent.selectedJob) {
            this.post = this.$parent.selected_job;
        }
        if (this.$parent.post_id > 0) {
            this.getJobPost();
        }
    },
    mounted() {},
    methods: {
        async getJobPost() {
            let query = {};
            query.id = this.$parent.post_id;
            const { data } = await axiosInstance.get(endpoints.getJobPostRoute, {params: query});
            this.post.institute_id = data.data.institute_id ?? 0;
            this.post.gender = data.data.gender;
        },
        validateForm() {
            this.checkInstitute();
            this.checkGender();
            if (this.checkInstitute() && this.checkGender()) {
                this.addJobPost();
            }
        },
        checkInstitute() {
            this.errorMessages.institute = [];
            if (this.post.institute_id === 0) {
                this.errorMessages.institute.push("Choose Institute...");
                return false;
            } else {
                this.errorMessages.institute = [];
                return true;
            }
        },
        checkGender() {
            this.errorMessages.gender = [];
            if (!this.post.gender) {
                this.errorMessages.gender.push("Choose gender...");
                return false;
            } else {
                this.errorMessages.gender = [];
                return true;
            }
        },

        async addJobPost() {
            let formData = new FormData();
            formData.append("institute_id", this.post.institute_id);
            formData.append("gender", this.post.gender);
            formData.append("id", this.$parent.post_id);
            this.submitLoading = true;
            const { data } = await axiosInstance.post(endpoints.addJobStep2Route, formData);
            this.submitLoading = false;
            if (data.type === "success") {
                this.$parent.step++;
            }
        },
    },
};
</script>

<style scoped></style>
