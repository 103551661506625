<template>

  <div class="rows head p-4">
    <h4>Weekly Schedule</h4>
  </div>

      <div class="card-shape">
        <template v-for="(day, avaKey) in availability" :key="avaKey">

          <template v-if="day.type === 0">

            <div class="row ava-row">

              <div class="col-md-2">
                <label class="my-3 mx-3 font-weight-bold">
                  {{ weekdays[day.available] }}
                </label>
              </div>

              <div class="col-md-8">

                <template v-if="day.duration?.length > 0">
                  <div class="row mt-2" v-for="(dur, durKey) in day.duration" :key="durKey">

                    <div class="col-md-4">

                      <div class="d-inline-flex">
                        <p class="small my-3 mx-1">{{ this.$parent.currency_symbol}}.</p>
                        <input class="form-control"
                               type="number"
                               name="price"
                               v-model="dur.price"
                               placeholder="Price"
                               @input="this.$emit('valuesUpdate', 'price', dur.price, durKey, avaKey)"
                        />
                      </div>
                      <p class="small text-danger" v-if="avaKey in availabilityMessages">{{ availabilityMessages[avaKey][durKey]?.price }}</p>

                    </div>

                    <div class="col-md-3">
                      <VueTimepicker
                          format="HH:mm"
                          placeholder="Start"
                          v-model="dur.start"
                          @close="this.$emit('valuesUpdate','start', dur.start, durKey, avaKey)"
                          input-width="5em"
                          input-class="rounded"
                          close-on-complete
                          hide-clear-button
                      >
                      </VueTimepicker>
                      <p class="small text-danger" v-if="avaKey in availabilityMessages">{{ availabilityMessages[avaKey][durKey]?.start }}</p>
                    </div>

                    <div class="col-md-3">
                      <VueTimepicker
                          format="HH:mm"
                          placeholder="End"
                          v-model="dur.end"
                          @close="this.$emit('valuesUpdate','end', dur.end, durKey, avaKey)"
                          input-width="5em"
                          input-class="rounded"
                          close-on-complete
                          hide-clear-button
                      >
                      </VueTimepicker>
                      <p class="small text-danger" v-if="avaKey in availabilityMessages">{{ availabilityMessages[avaKey][durKey]?.end }}</p>
                    </div>

                    <div class="col-md-1 mt-2">

                      <a class="text-danger" v-on:click="this.$emit('deleteDuration', avaKey, durKey)">
                        <i class="fa fa-times fa-md"></i>
                      </a>

                    </div>

                  </div>
                </template>

              </div>

              <div class="col-md-2">

                <div class="d-inline-flex my-3 gap-10">

                  <a class="text-success" v-on:click="this.$emit('addDuration', avaKey)">
                    <i class="fa fa-plus fa-md"></i>
                  </a>

                  <div>

                    <a class="text-primary" @click="toggleDropdown(day.available, durKey)">
                      <i class="fa fa-copy fa-md"></i>
                    </a>

                    <ul v-if="openDropdown.day === day.available && openDropdown.durKey === durKey" class="dropdown-list">
                      <template v-for="(wday, index) in weekdays" :key="index">
                        <li v-if="index !== day.available">
                          <input type="checkbox" @click="copyToOtherDays(avaKey, index, durKey, $event)">
                          <label>{{ wday }}</label>
                        </li>
                      </template>
                    </ul>

                  </div>

                </div>

              </div>



            </div>
          </template>

        </template>
      </div>

</template>

<script>

import VueTimepicker from 'vue3-timepicker';
import 'vue3-timepicker/dist/VueTimepicker.css';

export default {

  name: "WeeklyWiseScheduleComponent",
  components: {VueTimepicker},
  props: {
    availability: {
      type: Array,
      required: true,
    },

    availabilityMessages: {
      type: Array,
      required: true,
    }
  },

  emits: ['addDuration', 'deleteDuration', 'valuesUpdate'],

  data() {

    return {

      openDropdown: {
        day: null,
        durKey: null
      },

      weekdays: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ],

      weeklySlots: [],

      dataError: null

    }

  },

  methods : {

    toggleDropdown(dayAvailable, durKey) {
      if (this.openDropdown.day === dayAvailable && this.openDropdown.durKey === durKey) {
        // Close the dropdown if it's already open
        this.openDropdown = { day: null, durKey: null };
      } else {
        // Open the new dropdown
        this.openDropdown = { day: dayAvailable, durKey };
      }
    },

    copyToOtherDays(from, to, durationIndex, event) {

      if(event.target.checked) {
        let weekdayDurations = this.weeklySlots[from]["duration"][durationIndex];

        let copyTo = this.weeklySlots[to]["duration"];
        copyTo.push(weekdayDurations);

      } else {
        let weekdayDurations = this.weeklySlots[to]["duration"];
        weekdayDurations.pop();
      }

    }

  },
}

</script>

<style scoped>

  a:hover i {
    opacity: 0.7;
  }

  .dropdown-list {
    list-style-type: none;
    padding: 0;
    margin: 5px 0;
    border: 1px solid #ccc;
    width: 200px;
    position: absolute;
    background-color: #f9f9f9;
    z-index: 10000;
  }

  .dropdown-list li {
    padding: 10px;
    cursor: pointer;
  }

  .dropdown-list li:hover {
    background-color: #f0f0f0;
  }

</style>
<script setup>
</script>