<template>
  <div>
    <section class="search-wrap sec-top-btns">
      <div class="container">

        <div class="row">
          <div class="col-lg-3 pr-lg-5"></div>
          <div class="col-lg-9">

            <section class="search-wrap sec-top-btns">
              <div class="search-con">
                <div class="left-header">
                  <!--                  <h1> Jobs</h1>-->
                  <div class="top-bttns" v-if="user.active_role === 'tutor'">
                    <button v-if="!query.saved_job && !myJobs" v-on:click="showMyJobs()" type="button">My Jobs</button>
                    <button :class="query.saved_job ? 'active' : ''" v-if="myJobs" v-on:click="getSavedJob()" type="button">Saved</button>
                    <button :class="query.applied_job ? 'active' : ''" v-if="myJobs" v-on:click="appliedJobFilter()" type="button">Applied</button>
                    <button v-on:click="modalHandler()"><span class="mr-2 display-inline-block"></span>Job Alerts</button>
                    <div class="job_alert_jobs" v-if="!!query.keyword" v-on:click="jobPostSubscriptionForm = !jobPostSubscriptionForm"> <i class="fa fa-bell p-2"></i>
                    </div>
                  </div>

                  <div class="top-bttns align-items-center" v-if="user.active_role ===  'student' || user.active_role === 'guardian'">
                    <button v-if="query.my_job" v-on:click="allJobFilter()" :disabled="!authenticated" class="active">All Jobs</button>
                    <button v-on:click="showTuitionForm()">Post A Tuition</button>
                  </div>
                </div>
                <form action="" @submit.prevent="getJobPost()" class="d-flex" >
                  <div class="form-group">
                    <input type="text" name="search" v-model="query.keyword" placeholder="Search">
                    <button v-on:click="getJobPost()"><i class="fa fa-search"></i></button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <section class="cl-wrap">
      <div class="container">
        <div class="cl-con">
          <div class="row">
            <FilterSectionComponent></FilterSectionComponent>
            <JobListingComponent :jobs="jobs"></JobListingComponent>
          </div>
        </div>
      </div>
    </section>

    <JobPostStep01Component v-if="step === 1 && showJobForm"></JobPostStep01Component>
    <JobPostStep02Component v-if="step === 2 && showJobForm"></JobPostStep02Component>
    <JobPostStep03Component v-if="step === 3 && showJobForm"></JobPostStep03Component>
    <ApplyJobComponent v-if="applyJobFrom" :selected_job="selected_job"></ApplyJobComponent>
    <JobPostAlertSubscriptionComponent v-if="jobPostSubscriptionForm" :email="email"></JobPostAlertSubscriptionComponent>

    <div v-if="this.isOpen" class="modal bd-example-modal-lg" style="display: block; background-color: rgba(0,0,0, 0.4); overflow: auto;" tabindex="5" role="dialog">
      <div class="modal-dialog job_alert_modal modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Job Alerts</h5>
            <div>
              <button v-on:click="jobPostSubscriptionForm = !jobPostSubscriptionForm" class="add_job_alert_btn">Add job alert</button>
              <button type="button" class="close close_job_alert_modal" data-dismiss="modal" aria-label="Close">
                <span v-on:click="modalHandler()" aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div class="job_alert_upper_body">
              <h5>Job Recommendations</h5>
              <div class="d-flex justify-content-between">
                <div class="job_alert_upper_body_text">Based On Your Job Alerts, Activity, And Profile.</div>
                <label class="switch">
                  <input type="checkbox" id="hide_applied" value="1">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <!-- eslint-disable -->
            <div v-for="alert in jobAlerts" class="job_alert_modal_card">
              <div class="d-flex justify-content-between align-items-center mb-1">
                <div class="job_alert_edit_card_heading">{{ alert.keyword }}</div>
                <img v-on:click="editJobPostAlert(alert.id)" class="job_alert_edit_icon" src="@/assets/new-theme-images/new-edit-icon.png" alt="edit" />
              </div>
              <div class="d-flex justify-content-between align-items-start">
                <div class="job_alert_edit_card_text">Lahore District, Punjab, Pakistan<br/>Frequency: {{ alert.frequency }} Via Email And Notification</div>
                <img v-on:click="deleteJobPostAlert(alert.id)" class="job_alert_edit_icon job_alert_delete_icon" src="@/assets/new-theme-images/trash.png" alt="edit" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button v-on:click="modalHandler()" class="add_job_alert_btn add_job_alert_done_btn">Done</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JobPostStep01Component from "@/components/frontend/post/job/JobPostStep01Component";
import JobPostStep02Component from "@/components/frontend/post/job/JobPostStep02Component";
import JobPostStep03Component from "@/components/frontend/post/job/JobPostStep03Component";
import ApplyJobComponent from "@/components/frontend/post/job/ApplyJobComponent";
import FilterSectionComponent from "@/components/frontend/post/job/FilterSectionComponent";
import JobListingComponent from "@/components/frontend/post/job/JobListingComponent";
import JobPostAlertSubscriptionComponent from "@/components/frontend/post/job/JobPostAlertSubscriptionComponent";
import {GlobalEventBus} from "@/assets/js/eventBus";
import {toast} from "vue3-toastify";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {hideLoader, showLoader} from "@/assets/js/swal";


export default {
  name: "JobPostComponent",
  components: {
    JobPostStep01Component,
    JobPostStep02Component,
    JobPostStep03Component,
    ApplyJobComponent,
    FilterSectionComponent,
    JobListingComponent,
    JobPostAlertSubscriptionComponent,
  },
  data() {
    return {
      selectedJob: 0,
      selected_job: {},
      userDetail: {},
      selected_job_alert: {},
      post_id: 0,
      step: 1,
      email: '',
      myJobs: false,
      jobPostSubscriptionForm: false,
      authenticated: false,
      showJobForm: false,
      applyJobFrom: false,
      jobs: [],
      jobAlerts: [],
      subjects: [],
      levels: [],
      institutes: [],
      user: {},
      roles: [],
      savedItems: [],
      applied_jobs: [],
      isOpen: false,

      query: {
        applied_job: false,
        saved_job: false,
        my_job: false,
        sort_by: 'DESC',
        order_by: '',
        subject_id: 0,
        level: 0,
        hide_applied: 0,
        verified_only: 0,
        keyword: '',

        page: 1,
        limit: 8,
        total: 0,
      },
    }
  },
  created() {
    this.getSubjects()
    this.getCachedJobPost()
    this.getJobPost()
  },
  mounted() {
    let self = this
    self.authenticated = localStorage.getItem('authenticated') === 'true'
    if (self.authenticated){
      self.userDetail = JSON.parse(localStorage.getItem('detail'))
      self.email = self.userDetail.user.email
      self.roles = self.userDetail.roles
      self.user = self.userDetail.user
      self.savedItems = self.userDetail.saved_items
      self.applied_jobs = self.userDetail.applied_jobs
    }
    GlobalEventBus.on('getUserDetail', function (data) {
      self.authenticated = data.authenticated
      if (data.authenticated) {
        self.email = data.user.email
        self.roles = data.roles
        self.user = data.user
        self.savedItems = data.saved_items
        self.applied_jobs = data.applied_jobs
      }
    })
  },
  methods: {
    showLoginForm() {
      GlobalEventBus.emit('showLoginForm')
    },
    resetFilters() {
      this.$parent.query.hide_applied = 0
    },
    filterCheck() {
      return !(
        this.$parent.query.hide_applied === 0      )
    },
    showTuitionForm() {
      let self = this
      if (!this.authenticated) {
        GlobalEventBus.emit('showLoginForm')
      }
      else {
        if (self.user.active_role === 'student' || self.user.active_role === 'guardian') {
          self.step = 1
          self.showJobForm = true
        }
        else {
          toast.success('Only Student or Guardian Can Post Job')
        }
      }
    },
    modalHandler(){
      this.getJobAlert()
      this.isOpen = !this.isOpen
    },
    closeTuitionForm() {
      this.step = 1
      this.showJobForm = false
    },
    async getSubjects() {
      try {
        showLoader();
        const {data} = await axiosInstance.get(endpoints.getFilterContent);
        this.subjects = data.data.subjects
        this.levels = data.data.levels
        this.institutes = data.data.institutes
        hideLoader();
      } catch (e) {
        hideLoader();
        toast.error('Server Error')
      }
    },
    async getJobPost() {
      let query = this.query
      const {data} = await axiosInstance.get(endpoints.getJobPostsRoute, {params: query});
      this.query.total = data.data.total;
      this.jobs = data.data.jobs
    },
    async getCachedJobPost() {
      try {

        showLoader();
        let query = this.query
        const {data} = await axiosInstance.get(endpoints.getCachedJobPostsRoute, {params: query});
        this.query.total = data.data.total;
        this.jobs = data.data.data
        hideLoader();

      } catch (e) {
        hideLoader();
        toast.error('Server Error')
      }
    },
    async myJobFilter() {
      this.query.my_job = true
      await this.getJobPost()
    },
    async allJobFilter() {
      this.query.my_job = false
      this.query.saved_job = false
      this.query.applied_job = false
      await this.getJobPost()
    },
    async applyJob(id) {
      this.selectedJob = id
      let query = {}
      query.id = id
      const {data} = await axiosInstance.get(endpoints.getJobPostRoute, {params: query});
      this.selected_job = data.data
      this.applyJobFrom = true
    },
    async editPost(id) {
      this.selectedJob = id
      let query = {}
      query.id = id
      const {data} = await axiosInstance.get(endpoints.getJobPostRoute, {params: query});
      this.selected_job = data.data
      this.selected_subjects = data.subjects
      this.showJobForm = true
    },
    async deletePost(id) {
      this.selectedJob = id
      let query = {}
      query.id = id
      const {data} = await axiosInstance.get(endpoints.jobPostDeleteRoute, {params: query});
      if (data.type === 'success') {
        toast.success(data.message)
        await this.getJobPost()
      }
    },
    async getSavedJob() {
      this.query.saved_job = !this.query.saved_job
      this.query.applied_job = false
      await this.getJobPost()
    },
    async appliedJobFilter() {
      this.query.applied_job = !this.query.applied_job
      this.query.saved_job = false
      await this.getJobPost()
    },
    async showMyJobs() {
      this.myJobs = !this.myJobs
      // this.query.my_job = this.myJobs
      // await this.getJobPost()
    },
    async getJobAlert() {
      const {data} = await axiosInstance.get(endpoints.getJobAlertRoute);
      this.jobAlerts = data.data
    },
    async editJobPostAlert(id) {
      this.selected_job_alert = this.jobAlerts.find(j => j.id === id)
      this.jobPostSubscriptionForm = !this.jobPostSubscriptionForm
    },
    async deleteJobPostAlert(id) {
      let query = {}
      query.id = id
      await axiosInstance.get(endpoints.deleteJobAlertRoute, {params: query});
      await this.getJobAlert()
    },
  },

}
</script>

<style scoped>

</style>
