import Dashboard from "@/components/tutor/dashboard/DashboardComponent.vue";
import App from "@/components/tutor/app/App.vue";
import CreateCourse from "@/components/tutor/course/steps/CreateCourseStepComponent.vue";
import ProfileSettings from "@/components/tutor/profile/ProfileSettings.vue";
import ChatComponent from "@/components/utilities/chat/ChatComponent.vue";
// import WishlistComponent from "../components/tutor/wishlist/WishlistComponent.vue";
// import LogActivityComponent from "../components/utilities/log-activity/LogActivityComponent.vue";
import BillingComponent from "@/components/tutor/billings/BillingComponent.vue";
// import OverviewComponent from "../components/tutor/billings/OverviewComponent.vue";
import TransactionComponent from "@/components/tutor/transaction/TransactionComponent.vue";
import MakeADepositComponent from "@/components/student/deposit/MakeADepositComponent.vue";
// import Courses from "../components/tutor/course/PurchaseCoursesComponent.vue";
// import SlotComponent from "../components/tutor/slots/SlotComponent.vue";
import OneToOneSessionComponent from "@/components/tutor/slots/OneToOneSessionComponent.vue";
import BookedAppointments from "@/components/tutor/slots/BookedAppointments.vue";
import CoursesComponent from "../components/tutor/course/CoursesComponent.vue";
// import JobPostAlertComponent from "../components/tutor/post/job/alert/JobPostAlertComponent.vue";
import GetVerifiedComponent from "@/components/tutor/verified/GetVerifiedComponent.vue";
// import ScheduleComponent from "../components/tutor/schedule/ScheduleComponent.vue";
import SessionLogComponent from "@/components/tutor/session/SessionLogComponent.vue";
// import QuizComponent from "../components/tutor/quiz/QuizComponent.vue";
// import CreateCourseQuizComponent from "../components/tutor/quiz/CreateCourseQuizComponent.vue";
// import CourseReportComponent from "../components/frontend/course/report/CourseReportComponent.vue";
// import SessionListComponent from "../components/tutor/sessions/SessionListComponent.vue";
// import CourseQuizResultComponent from "../components/tutor/quiz/CourseQuizResultComponent.vue";
import WithdrawRequestComponent from "@/components/tutor/billings/WithdrawRequestComponent.vue";
// import WithdrawListingComponent from "../components/tutor/billings/WithdrawListingComponent.vue";
import RequestsComponent from "@/components/tutor/billings/RequestsComponent.vue";
// import DisputeComponent from "../components/tutor/dispute/DisputeComponent.vue";
// import DisputeDetailComponent from "../components/tutor/dispute/DisputeDetailComponent.vue";
// import RefundDetailComponent from "../components/tutor/refund/RefundDetailComponent.vue";


// const CourseStudentComponent = () =>
//     import("../components/tutor/students/CourseStudentComponent.vue");

export default [
    {
        path: '/tutor',
        component: App,
        children: [
            {
                name: "tutor-dashboard",
                path: "dashboard",
                props: {
                    has_sub: false,
                },
                component: Dashboard,
                meta: { requiresAuth: true },
            },
            {
                name: "tutor-courses",
                path: "/tutor/courses",
                props: {
                    has_sub: false,
                },
                component: CoursesComponent,
                meta: { requiresAuth: true },
            },
            // {
            //     name: "courses-report",
            //     path: "/tutor/course/report",
            //     props: {
            //         has_sub: false,
            //     },
            //     component: CourseReportComponent,
            // },
            // {
            //     name: "purchased-courses",
            //     path: "/tutor/purchase/courses",
            //     props: {
            //         has_sub: false,
            //     },
            //     component: Courses,
            // },
            {
                name: "courseCreate",
                path: "courses/create",
                props: {
                    has_sub: false,
                },
                component: CreateCourse,
                meta: { requiresAuth: true },
            },
            // {
            //     name: "slots",
            //     path: "/tutor/slots",
            //     props: {
            //         has_sub: true,
            //         parent: "appointments",
            //     },
            //     component: SlotComponent,
            // },
            {
                name: "tutor-one-to-one-slots",
                path: "one-to-one-session",
                props: {
                    has_sub: true,
                    parent: "appointments",
                },
                component: OneToOneSessionComponent,
                meta: { requiresAuth: true },
            },
            {
                name: "tutor-booked-slots",
                path: "booked-appointments",
                props: {
                    has_sub: true,
                    parent: "appointments",
                },
                component: BookedAppointments,
                meta: { requiresAuth: true },
            },
            // {
            //     name: "bookings",
            //     path: "/tutor/schedule",
            //     props: {
            //         has_sub: true,
            //         parent: "appointments",
            //     },
            //     component: ScheduleComponent,
            // },
            // {
            //     name: "sessions",
            //     path: "/tutor/sessions",
            //     props: {
            //         has_sub: false,
            //     },
            //     component: SessionListComponent,
            // },
            // {
            //     name: "wishlist",
            //     path: "/tutor/wishlist",
            //     props: {
            //         has_sub: false,
            //     },
            //     component: WishlistComponent,
            // },
            // {
            //     name: "updateCourse",
            //     path: "/tutor/courses/edit/:id",
            //     props: {
            //         has_sub: false,
            //     },
            //     component: CreateCourse,
            // },
            {
                name: "tutor-profile",
                path: "profile",
                props: {
                    has_sub: false,
                },
                component: ProfileSettings,
                meta: { requiresAuth: true },
            },
            // // {
            // //     name: 'transaction',
            // //     path: '/tutor/transaction',
            // //             props: {has_sub: false  },
            // // component: Transaction
            // // },
            // {
            //     name: "activity-logs",
            //     path: "/tutor/log/activity",
            //     props: {
            //         has_sub: true,
            //         parent: "log",
            //     },
            //     component: LogActivityComponent,
            // },
            {
                name: "billing",
                path: "billing",
                props: {
                    has_sub: true,
                    parent: "billing",
                },
                component: BillingComponent,
                meta: { requiresAuth: true },
            },
            {
                name: "tutor-requests",
                path: "billing/requests",
                props: {
                    has_sub: true,
                    parent: "billing",
                },
                component: RequestsComponent,
                meta: { requiresAuth: true },
            },
            {
                name: "tutor-transaction",
                path: "billing/transaction",
                props: {
                    has_sub: true,
                    parent: "billing",
                },
                component: TransactionComponent,
                meta: { requiresAuth: true },
            },
            // {
            //     name: "overview",
            //     path: "/tutor/billing/overview",
            //     props: {
            //         has_sub: true,
            //         parent: "billing",
            //     },
            //     component: OverviewComponent,
            // },
            // {
            //     name: "alert",
            //     path: "/tutor/job/post/alert",
            //     props: {
            //         has_sub: false,
            //     },
            //     component: JobPostAlertComponent,
            // },
            {
                name: "tutor-get-verified",
                path: "get/verified",
                props: {
                    has_sub: false,
                },
                component: GetVerifiedComponent,
                meta: { requiresAuth: true },
            },
            {
                name: "tutor-session-logs",
                path: "log/session",
                props: {
                    has_sub: true,
                    parent: "log",
                },
                component: SessionLogComponent,
                meta: { requiresAuth: true },
            },
            // {
            //     name: "course-quiz",
            //     path: "/tutor/course/quiz",
            //     props: {
            //         has_sub: false,
            //     },
            //     component: QuizComponent,
            // },
            // {
            //     name: "course-quiz-result",
            //     path: "/tutor/course/quiz/results",
            //     props: {
            //         has_sub: false,
            //     },
            //     component: CourseQuizResultComponent,
            // },
            // {
            //     name: "add-course-quiz",
            //     path: "/tutor/course/edit/:course_id/quiz/create",
            //     props: {
            //         has_sub: false,
            //     },
            //     component: CreateCourseQuizComponent,
            // },
            // // {
            // //     name: 'course-quiz-step',
            // //     path: '/tutor/course/:course_id/quiz/create',
            // //             props: {has_sub: false  },
            // // component: CreateCourseQuizComponent,
            // // },
            // {
            //     name: "course-quiz-step",
            //     path: "/tutor/course/:course_id/quiz",
            //     props: {
            //         has_sub: false,
            //     },
            //     component: QuizComponent,
            // },
            // {
            //     name: "course-quiz-quiz",
            //     path: "/tutor/course/:course_id/quiz/:quiz_id",
            //     props: {
            //         has_sub: false,
            //     },
            //     component: CourseQuizResultComponent,
            // },
            // {
            //     name: "edit-course-quiz",
            //     path: "/tutor/course/quiz/edit/:id",
            //     props: {
            //         has_sub: false,
            //     },
            //     component: CreateCourseQuizComponent,
            // },
            // {
            //     name: "edit-course-quiz-step",
            //     path: "/tutor/course/:course_id/quiz/edit/:id",
            //     props: {
            //         has_sub: false,
            //     },
            //     component: CreateCourseQuizComponent,
            // },
            // {
            //     name: "withdraw-listing",
            //     path: "/tutor/billing/withdraw/request",
            //     props: {
            //         has_sub: true,
            //         parent: "billing",
            //     },
            //     component: WithdrawListingComponent,
            // },
            {
                name: "tutor-withdraw-request",
                path: "billing/withdraw/request/create",
                props: {
                    has_sub: false,
                },
                component: WithdrawRequestComponent,
                meta: { requiresAuth: true },
            },
            {
                name: "tutor-chat",
                path: "chat",
                props: {
                    has_sub: false,
                },
                component: ChatComponent,
                meta: { requiresAuth: true },
            },
            // {
            //     name: "course-student",
            //     path: "/tutor/course/:id/students",
            //     props: {
            //         has_sub: false,
            //     },
            //     component: CourseStudentComponent,
            // },
            // {
            //     name: "course-student-list",
            //     path: "/tutor/course/students",
            //     props: {
            //         has_sub: false,
            //     },
            //     component: CourseStudentComponent,
            // },
            // {
            //     name: "dispute",
            //     path: "/tutor/dispute",
            //     props: {
            //         has_sub: false,
            //     },
            //     component: DisputeComponent,
            // },
            // {
            //     name: "dispute-detail",
            //     path: "/tutor/dispute/:id",
            //     props: {
            //         has_sub: false,
            //     },
            //     component: DisputeDetailComponent,
            // },
            // {
            //     name: "refund-detail",
            //     path: "/tutor/refund/:id",
            //     props: {
            //         has_sub: false,
            //     },
            //     component: RefundDetailComponent,
            // },
            {
                name: "tutor-deposit",
                path: "billing/deposit",
                props: {
                    has_sub: true,
                    parent: "billing",
                },
                component: MakeADepositComponent,
                meta: { requiresAuth: true },
            },
        ]
    }
];
