<template>
    <div>
        <div class="row">
            <!--      <div class="col-md-4">-->
            <!--        <div class="form-group">-->
            <!--          <label for="categories">Categories</label>-->
            <!--          <select v-on:change="$parent.getCourses()" v-model="query.category_id" class="form-control" id="categories">-->
            <!--            <option value="">All</option>-->
            <!--            <option v-for="category in categories" :value="category.id">{{ category.name }}</option>-->
            <!--          </select>-->
            <!--        </div>-->
            <!--      </div>-->
            <!--      <div class="col-md-4">-->
            <!--        <div class="form-group">-->
            <!--          <label for="status">Status</label>-->
            <!--          <select v-on:change="$parent.getCourses()" v-model="query.status" class="form-control" id="status">-->
            <!--            <option value="">All</option>-->
            <!--            <option value="approved">Approved</option>-->
            <!--            <option value="draft">Draft</option>-->
            <!--            <option value="pending">Pending</option>-->
            <!--          </select>-->
            <!--        </div>-->
            <!--      </div>-->
            <!--      <div class="col-md-4">-->
            <!--        <div class="form-group">-->
            <!--          <label for="price">Price</label>-->
            <!--          <select v-on:change="$parent.getCourses()" v-model="query.price" class="form-control" id="price">-->
            <!--            <option value="">All</option>-->
            <!--            <option value="1">Free</option>-->
            <!--            <option value="0">Paid</option>-->
            <!--          </select>-->
            <!--        </div>-->
            <!--      </div>-->
            <!--      <div class="col-md-3">-->
            <!--        <button class="btn search_btn ">Search</button>-->
            <!--      </div>-->
        </div>
        <section class="px-0">
            <!-- <div class="mb-2"><span class="h4">Course List</span></div> -->
            <div class="row tabel_overflow py-sm-2 py-0">
                <div class="col-md-12 px-0">
                    <div
                        class="table-wrap table-responsive"
                        style="min-height: 70vh"
                    >
                        <table class="table text-center table-hover">
                            <thead class="thead-primary border-0">
                                <tr>
                                    <th>Title</th>
                                    <th>Category</th>
                                    <!--                <th>Lessons</th>-->
                                    <!--                <th>Sessions</th>-->
                                    <th>Enrolled Students</th>
                                    <!--                <th>Visible</th>-->
                                    <th>Status</th>
                                    <th>Price</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            <!-- eslint-disable -->
                                <tr v-for="course in $parent.coursesView">
                                    <td>
                                        <a
                                            :href="'/course/' + course.uuid"
                                            target="_blank"
                                            >{{ course.title }}</a
                                        >
                                    </td>
                                    <td>{{ course.category.name }}</td>
                                    <!--                <td>{{ course.outlines.length }}</td>-->
                                    <!--                <td>{{ course.sessions }}</td>-->
                                    <td v-if="course.students.length > 0">
                                        <router-link
                                            :to="{
                                                name: 'course-student',
                                                params: { id: course.id },
                                            }"
                                            >{{
                                                course.students.length
                                            }}</router-link
                                        >
                                    </td>
                                    <td v-else>{{ course.students.length }}</td>
                                    <!--                <td>{{ !!course.is_visible }}</td>-->
                                    <td>{{ course.status }}</td>
                                    <td>
                                        {{
                                            !!course.discounted_price
                                                ? course.discounted_price
                                                : course.price
                                        }}
                                        {{ $t("text.pkr") }}
                                    </td>
                                    <td class="table_dropdown">
                                        <div class="dropdown">
                                            <a>
                                                <i
                                                    class="fas fa-ellipsis-v"
                                                ></i>
                                            </a>
                                            <div class="dropdown-content p-2">
                                                <router-link
                                                    :to="
                                                        '/tutor/course/' +
                                                        course.id +
                                                        '/quiz'
                                                    "
                                                >
                                                    Quizzes
                                                </router-link>
                                                <router-link
                                                    v-if="
                                                        course.status !==
                                                            'approved' &&
                                                        course.status !==
                                                            'completed'
                                                    "
                                                    :to="
                                                        '/tutor/courses/edit/' +
                                                        course.id
                                                    "
                                                >
                                                    Edit
                                                </router-link>
                                                <a
                                                    v-if="
                                                        course.students.length <
                                                            1 &&
                                                        course.status !==
                                                            'approved'
                                                    "
                                                    href="javascript:void(0)"
                                                    v-on:click="
                                                        removeCourse(course.id)
                                                    "
                                                    >Delete</a
                                                >
                                                <a
                                                    v-if="
                                                        course.status ===
                                                            'approved' ||
                                                        course.status ===
                                                            'completed'
                                                    "
                                                    target="_blank"
                                                    :href="
                                                        '/course/' +
                                                        course.id +
                                                        '/forum'
                                                    "
                                                    >Visit Forum</a
                                                >
                                            </div>
                                        </div>
                                    </td>
                                    <!--                <td><a href="javascript:void (0)" v-on:click="showConversation(course.id)">-->
                                    <!--                  <i class="fas fa-envelope"></i>-->
                                    <!--                </a></td>-->
                                </tr>
                            </tbody>
                        </table>
                        <div v-if="no_record">
                            <p class="p-2">{{ $t("text.no_record") }}</p>
                        </div>
                    </div>
                    <pagination
                        class="hide-actions"
                        v-if="$parent.query.total > $parent.query.limit"
                        v-show="$parent.query.total > 0"
                        v-model="$parent.query.page"
                        :records="$parent.query.total"
                        :per-page="$parent.query.limit"
                        @paginate="$parent.getCourses"
                    />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import { GlobalEventBus } from "@/assets/js/eventBus";
import axiosInstance from "@/assets/js/axios";

export default {
    name: "CourseListingComponent",
    props: ["categories"],
    components: { },
    data() {
        return {
            no_record: false,
            courses: this.$parent.courses,
            coursesView: this.$parent.coursesView,
            query: {
                page: 1,
                limit: 8,
                keyword: "",
                category_id: "",
                status: "",
                price: "",
                role: "",
                total: 0,
            },
        };
    },
    created() {
        this.$parent.getCourses();
    },
    mounted() {
        let self = this;
        GlobalEventBus.on("filter-courses", function (type) {
            self.query.status = "";
            if (type === "paid") {
                self.query.price = true;
            } else {
                self.query.status = type;
            }
            self.$parent.getCourses();
        });
    },
    methods: {
        // async getCourses(page) {
        //   this.query.page = (page) ? page : this.query.page;
        //   const { data } = await courseRoute.list(this.query);
        //   this.coursesView = data.data.data;
        //   this.sessions = data.sessions;
        //   this.$parent.courses = data.data.data
        //   this.$parent.filter = data.filter
        //   this.$parent.categories = data.categories
        //   this.query.total = data.data.total;
        //   if (this.coursesView.length === 0) {
        //     this.no_record = true
        //   }
        // },

        removeCourse(id) {
            let self = this;
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    axiosInstance
                        .get("/v1/course/delete/" + id)
                        .then(function (response) {
                            self.$root.$emit("updateCourse");
                            if (response.data.data.type === "success") {
                                this.$toasted.success(
                                    response.data.data.message
                                );
                            }
                        })
                        .catch(function (error) {
                            // handle error
                            console.log(error);
                        })
                        .then(function () {
                            // always executed
                            self.$parent.getCourses();
                        });
                }
            });
        },
        showConversation(id) {
            this.this.parent.selectedCourse = id;
            this.$parent.courseDisableReasonModal = true;
        },
    },
};
</script>

<style scoped>
.dropdown-content.p-2 {
    color: black;
    background-color: white;
}
</style>
