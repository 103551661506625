<template>
    <div class="w-100" style="background: #edeff0">
        <div class="d-flex justify-content-center row w-100 m-0">
            <div
                class="tutor-detail-banner-info pb-5 d-flex justify-content-center align-items-center w-100"
            >
                <div class="row" style="width: 80%">
                    <div class="col-8">
                        <div class="d-flex flex-row mt-5">
                            <div
                                class="add_dot_icon"
                                style="
                                    border-radius: 4px;
                                    display: flex;
                                    flex-direction: column;
                                    height: 160px;
                                    margin-right: 32px;
                                    min-width: 160px;
                                    position: relative;
                                    width: 160px;
                                    z-index: 1;
                                "
                            >
                                <div
                                    class="align-items-start justify-content-end mr-4"
                                    style="
                                        background-color: #fff;
                                        clip-path: url(#clipPath);
                                        -webkit-clip-path: url(#clipPath);
                                        display: flex;
                                        height: 100%;
                                        justify-content: center;
                                        overflow: hidden;
                                        position: absolute;
                                        width: 160px;
                                        height: 160px;
                                    "
                                >
                                    <svg
                                        style="position: absolute"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <defs>
                                            <clipPath
                                                id="clipPath"
                                                clipPathUnits="objectBoundingBox"
                                            >
                                                <path
                                                    d="M.858.027C.682-.014.128-.024.044.117-.041.258.009.873.128.933c.12.06.559.067.744.029C1 .89 1 .068.858.027"
                                                ></path>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <img
                                        src="@/assets/new-theme-images/dashboard-user-profile.png"
                                        style="width: 160px !important"
                                    />
                                </div>
                            </div>
                            <div class="d-flex flex-column mt-1">
                                <h5 class="text-dark font-weight-bold">
                                    {{
                                        $parent.tutor?.first_name +
                                        " " +
                                        $parent.tutor?.last_name?.at(0) +
                                        "."
                                    }}
                                    <img
                                        src="https://static.preply.com/groot/country_flags/4x3/pk.svg"
                                        class="mx-2"
                                        width="21"
                                        height="14"
                                    />

                                    <i
                                        v-if="$parent.tutor.verified"
                                        class="fas fa-check-circle"
                                        style="color: #812bb2"
                                    ></i>
                                </h5>
                                <p>
                                    {{ $parent.tutor?.info?.about_short }}
                                </p>
                                <div class="d-flex align-items-center mt-2">
                                    <div class="">
                                        <svg
                                            style="
                                                height: 20px;
                                                margin: 1px 12px 2px 0;
                                                min-width: 16px;
                                                width: 18px;
                                                color: #3c4447;
                                                font-size: 14px;
                                            "
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="13"
                                            height="10"
                                            viewBox="129 34 13 10"
                                        >
                                            <path
                                                fill="rgba(0,0,0,0.4)"
                                                fill-rule="evenodd"
                                                d="M135.399 34 129 37.2l6.399 3.199 5.235-2.618v4.944h1.163V37.2L135.4 34zm0 7.7-4.654-2.327v1.3L135.4 43l4.653-2.327v-1.3l-4.653 2.326z"
                                            ></path>
                                        </svg>
                                    </div>
                                    <span class="text-14" style="color: #3c4447"
                                        >Teaches
                                        <span
                                            v-html="getSubjectsWithUnderline()"
                                        ></span>
                                        Lessons</span
                                    >
                                </div>
                                <div
                                    class="d-flex align-items-center mt-1"
                                    v-if="
                                        $parent?.tutor?.total_lessons_taught > 0
                                    "
                                >
                                    <div class="">
                                        <svg
                                            width="16"
                                            height="16"
                                            style="
                                                height: 20px;
                                                margin: 1px 12px 2px 0;
                                                min-width: 16px;
                                                width: 16px;
                                                color: #3c4447;
                                                font-size: 14px;
                                            "
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 16 16"
                                        >
                                            <g
                                                id="Page-1"
                                                fill="none"
                                                fill-rule="evenodd"
                                                stroke="none"
                                                stroke-width="1"
                                            >
                                                <g
                                                    id="uEA33-aim"
                                                    fill="rgba(0,0,0,0.4)"
                                                    fill-rule="nonzero"
                                                >
                                                    <g id="Oval-4">
                                                        <path
                                                            id="Shape"
                                                            d="M8 16c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8ZM8 1.5C4.4 1.5 1.5 4.4 1.5 8s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5S11.6 1.5 8 1.5Z"
                                                        ></path>
                                                        <circle
                                                            id="Oval-4_1_"
                                                            cx="8"
                                                            cy="8"
                                                            r="3"
                                                        ></circle>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <span
                                        class="text-14"
                                        style="color: #3c4447"
                                    >
                                        {{
                                            $parent?.tutor
                                                ?.total_lessons_taught ?? 0
                                        }}
                                        Lessons Taught</span
                                    >
                                </div>
                            </div>
                        </div>

                        <section
                            v-if="$parent.reviews?.length > 0"
                            class="recent_review_section mt-2 pt-5"
                        >
                            <div class="d-flex w-100">
                                <img
                                    class="w-25-l h-25-l rounded-circle"
                                    src="@/assets/new-theme-images/dashboard-user-profile.png"
                                    style="
                                        border-radius: 12px;
                                        flex: none;
                                        height: 32px;
                                        margin-right: 12px;
                                        overflow: hidden;
                                        width: 32px;
                                    "
                                />
                                <div class="ml-3 w-100">
                                    <p
                                        class="mb-0 d-flex align-items-center justify-content-between"
                                    >
                                        <span class="">{{
                                            $parent.reviews?.at(0)?.user?.name
                                        }}</span>
                                        <span
                                            style="
                                                color: #989c9e;
                                                font-weight: 500;
                                            "
                                        >
                                            Recent review
                                        </span>
                                    </p>

                                    <blockquote class="mt-1 mb-0">
                                        {{ $parent.reviews?.at(0)?.detail }}
                                    </blockquote>
                                    <a
                                        class="view_all_reviews_style"
                                        href="#reviews"
                                    >
                                        View all reviews
                                    </a>
                                </div>
                            </div>
                        </section>

                        <div id="about"></div>
                        <div class="styles_FixedHeaderContainer__81Lu0">
                            <ul class="styles_FixedHeaderList__pW3ca">
                                <a
                                    class="styles_FixedHeaderListItem__MAX__ styles_FixedHeaderListItemActive__n5IF3"
                                    href="#about"
                                >
                                    About
                                </a>
                                <a
                                    class="styles_FixedHeaderListItem__MAX__"
                                    href="#schedule"
                                >
                                    Schedule
                                </a>
                                <a
                                    class="styles_FixedHeaderListItem__MAX__"
                                    href="#reviews"
                                >
                                    Reviews ({{ $parent?.ratings?.total }})
                                </a>
                                <a
                                    class="styles_FixedHeaderListItem__MAX__"
                                    href="#resume"
                                >
                                    Resume
                                </a>
                                <a
                                    class="styles_FixedHeaderListItem__MAX__"
                                    href="#subjects"
                                >
                                    Subjects
                                </a>
                            </ul>
                        </div>

                        <!-- About the Tutor start -->
                        <div class="bg-white p-3 shadow">
                            <span class="font-weight-bold"
                                >About the tutor</span
                            >
                            <div class="mt-3">
                                <!-- <p>{{ $parent.tutor.info.about }}</p> -->
                                <p
                                    v-if="!showDetails"
                                    v-html="
                                        $parent.tutor?.info?.about?.substr(0, 400)
                                    "
                                    class="about-p m-0"
                                ></p>
                                <p
                                    v-else
                                    v-html="$parent.tutor?.info?.about"
                                    class="about-p m-0"
                                ></p>
                                <a
                                    v-on:click="showDetails = !showDetails"
                                    v-if="
                                        $parent.tutor?.info?.about?.length > 400 &&
                                        !showDetails
                                    "
                                    href="javascript:void(0)"
                                    >Show More</a
                                >
                                <a
                                    v-on:click="showDetails = !showDetails"
                                    v-else-if="
                                        $parent.tutor?.info?.about?.length > 400
                                    "
                                    href="javascript:void(0)"
                                    >Hide</a
                                >
                            </div>
                        </div>

                        <!-- Schedule Section Start -->
                        <div class="bg-white p-3 shadow mt-3" id="schedule">
                            <span class="font-weight-bold">Schedule</span>
                            <div class="mt-3">
                                <p class="schedule_banner">
                                    Choose the time for your first lesson. The
                                    timings are displayed in your local
                                    timezone.
                                </p>

                                <AvailabileHours />
                            </div>
                        </div>
                        <!-- Schedule Section End -->

                        <!-- Review Section Start -->
                        <div class="bg-white p-3 shadow mt-3" id="reviews">
                            <span class="font-weight-bold"
                                >What students say
                            </span>
                            <hr />

                            <div class="expertise mt-0">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="">
                                            <div
                                                class="rating-reviews mt-4 pt-0"
                                            >
                                                <div class="rating-section">
                                                    <p
                                                        class="rating-no font-weight-bold"
                                                    >
                                                        {{
                                                            $parent.ratings?.rating?.toFixed(
                                                                1
                                                            ) ?? 0
                                                        }}
                                                    </p>

                                                    <!-- <p class="total-rating">
                                                        Overall Rating
                                                    </p> -->
                                                    <div class="rating-stars">
                                                        <star-rating
                                                            :show-rating="false"
                                                            id="Excellent"
                                                            v-model="
                                                                $parent.ratings
                                                                    .rating
                                                            "
                                                            read-only
                                                            :increment="0.25"
                                                            v-bind:star-size="
                                                                35
                                                            "
                                                        ></star-rating>
                                                    </div>
                                                    <p class="m-0 mt-2">
                                                        {{
                                                            $parent?.ratings
                                                                ?.total
                                                        }}
                                                        Reviews
                                                    </p>
                                                </div>
                                                <div class="progress-container">
                                                    <div
                                                        class="rating-progress"
                                                    >
                                                        <label
                                                            :class="
                                                                $parent.ratings
                                                                    .five > 0
                                                                    ? 'mr-2 text-dark'
                                                                    : 'mr-2 text-gray'
                                                            "
                                                            >5 Star</label
                                                        >
                                                        <div class="progress">
                                                            <div
                                                                class="progress-bar"
                                                                role="progressbar"
                                                                :style="
                                                                    'width:' +
                                                                    ($parent
                                                                        .ratings
                                                                        .five /
                                                                        $parent
                                                                            .ratings
                                                                            .total) *
                                                                        100 +
                                                                    '%'
                                                                "
                                                                aria-valuenow="100"
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            ></div>
                                                        </div>
                                                        <label
                                                            :class="
                                                                $parent.ratings
                                                                    .five > 0
                                                                    ? 'text-dark'
                                                                    : 'text-gray'
                                                            "
                                                            v-if="
                                                                $parent.ratings
                                                                    .five > 0
                                                            "
                                                            >({{
                                                                $parent.ratings
                                                                    .five
                                                            }})</label
                                                        >
                                                        <label v-else
                                                            >(0)</label
                                                        >
                                                    </div>

                                                    <div
                                                        class="rating-progress"
                                                    >
                                                        <label
                                                            :class="
                                                                $parent.ratings
                                                                    .four > 0
                                                                    ? 'mr-2 text-dark'
                                                                    : 'mr-2 text-gray'
                                                            "
                                                            >4 Star</label
                                                        >
                                                        <div class="progress">
                                                            <div
                                                                class="progress-bar"
                                                                role="progressbar"
                                                                :style="
                                                                    'width:' +
                                                                    ($parent
                                                                        .ratings
                                                                        .four /
                                                                        $parent
                                                                            .ratings
                                                                            .total) *
                                                                        100 +
                                                                    '%'
                                                                "
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            ></div>
                                                        </div>
                                                        <label
                                                            :class="
                                                                $parent.ratings
                                                                    .four > 0
                                                                    ? 'text-dark'
                                                                    : 'text-gray'
                                                            "
                                                            v-if="
                                                                $parent.ratings
                                                                    .four > 0
                                                            "
                                                            >({{
                                                                $parent.ratings
                                                                    .four
                                                            }})</label
                                                        >
                                                        <label v-else
                                                            >(0)</label
                                                        >
                                                    </div>

                                                    <div
                                                        class="rating-progress"
                                                    >
                                                        <label
                                                            :class="
                                                                $parent.ratings
                                                                    .three > 0
                                                                    ? 'mr-2 text-dark'
                                                                    : 'mr-2 text-gray'
                                                            "
                                                            >3 Star</label
                                                        >
                                                        <div class="progress">
                                                            <div
                                                                class="progress-bar"
                                                                role="progressbar"
                                                                :style="
                                                                    'width:' +
                                                                    ($parent
                                                                        .ratings
                                                                        .three /
                                                                        $parent
                                                                            .ratings
                                                                            .total) *
                                                                        100 +
                                                                    '%'
                                                                "
                                                                aria-valuenow="50"
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            ></div>
                                                        </div>
                                                        <label
                                                            :class="
                                                                $parent.ratings
                                                                    .three > 0
                                                                    ? 'text-dark'
                                                                    : 'text-gray'
                                                            "
                                                            v-if="
                                                                $parent.ratings
                                                                    .three > 0
                                                            "
                                                            >({{
                                                                $parent.ratings
                                                                    .three
                                                            }})</label
                                                        >
                                                        <label v-else
                                                            >(0)</label
                                                        >
                                                    </div>

                                                    <div
                                                        class="rating-progress"
                                                    >
                                                        <label
                                                            :class="
                                                                $parent.ratings
                                                                    .two > 0
                                                                    ? 'mr-2 text-dark'
                                                                    : 'mr-2 text-gray'
                                                            "
                                                            >2 Star</label
                                                        >
                                                        <div class="progress">
                                                            <div
                                                                class="progress-bar"
                                                                role="progressbar"
                                                                :style="
                                                                    'width:' +
                                                                    ($parent
                                                                        .ratings
                                                                        .two /
                                                                        $parent
                                                                            .ratings
                                                                            .total) *
                                                                        100 +
                                                                    '%'
                                                                "
                                                                aria-valuenow="75"
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            ></div>
                                                        </div>
                                                        <label
                                                            :class="
                                                                $parent.ratings
                                                                    .two > 0
                                                                    ? 'text-dark'
                                                                    : 'text-gray'
                                                            "
                                                            v-if="
                                                                $parent.ratings
                                                                    .two > 0
                                                            "
                                                            >({{
                                                                $parent.ratings
                                                                    .two
                                                            }})</label
                                                        >
                                                        <label v-else
                                                            >(0)</label
                                                        >
                                                    </div>

                                                    <div
                                                        class="rating-progress"
                                                    >
                                                        <label
                                                            :class="
                                                                $parent.ratings
                                                                    .one > 0
                                                                    ? 'mr-2 text-dark'
                                                                    : 'mr-2 text-gray'
                                                            "
                                                            >1 Star</label
                                                        >
                                                        <div class="progress">
                                                            <div
                                                                class="progress-bar"
                                                                role="progressbar"
                                                                :style="
                                                                    'width:' +
                                                                    ($parent
                                                                        .ratings
                                                                        .one /
                                                                        $parent
                                                                            .ratings
                                                                            .total) *
                                                                        100 +
                                                                    '%'
                                                                "
                                                                aria-valuenow="0"
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            ></div>
                                                        </div>
                                                        <label
                                                            :class="
                                                                $parent.ratings
                                                                    .one > 0
                                                                    ? 'text-dark'
                                                                    : 'text-gray'
                                                            "
                                                            v-if="
                                                                $parent.ratings
                                                                    .one > 0
                                                            "
                                                            >({{
                                                                $parent.ratings
                                                                    .one
                                                            }})</label
                                                        >
                                                        <label v-else
                                                            >(0)</label
                                                        >
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <h4 class="mb-3">Featured Reviews</h4> -->
                                            <div
                                                v-for="review in $parent.reviews" :key="review"
                                                class="lesson-detail mx-3 mt-3"
                                            >
                                                <div class="d-flex w-100">
                                                    <img
                                                        class="w-25-l h-25-l rounded-circle"
                                                        src="@/assets/new-theme-images/dashboard-user-profile.png"
                                                        style="
                                                            width: 50px;
                                                            height: 50px;
                                                        "
                                                    />
                                                    <div class="ml-3 w-100">
                                                        <p
                                                            class="mb-0 d-flex align-items-center justify-content-start"
                                                        >
                                                            <span
                                                                class="font-weight-bold"
                                                                >{{
                                                                    review?.user
                                                                        ?.name
                                                                }}</span
                                                            >
                                                            <span
                                                                class="ml-3 font-weight-bold d-flex align-items-center justify-content-center"
                                                            >
                                                                <svg
                                                                    height="14"
                                                                    viewBox="0 0 24 23"
                                                                    width="14"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="#fdc75f"
                                                                    class="mr-1"
                                                                >
                                                                    <path
                                                                        d="M23.97 8.721a.597.597 0 0 0-.507-.413l-7.744-.822-3.172-7.16c-.192-.435-.903-.435-1.095 0l-3.17 7.16-7.745.822a.601.601 0 0 0-.508.413.606.606 0 0 0 .17.635l5.785 5.248-1.616 7.667a.605.605 0 0 0 .586.729.595.595 0 0 0 .3-.081L12 19.003l6.747 3.916c.204.119.46.105.652-.035a.606.606 0 0 0 .234-.613l-1.616-7.668 5.786-5.248a.606.606 0 0 0 .168-.634z"
                                                                    ></path>
                                                                </svg>
                                                                <span
                                                                    class="mt-1"
                                                                    style="
                                                                        color: #fdc75f;
                                                                    "
                                                                >
                                                                    {{
                                                                        review?.rating
                                                                    }}
                                                                </span>
                                                            </span>
                                                        </p>
                                                        <span
                                                            style="color: gray"
                                                            class="mt-2 text-14"
                                                        >
                                                            {{
                                                                moment(
                                                                    review?.created_at
                                                                ).format(
                                                                    "MMMM DD, YYYY"
                                                                )
                                                            }}
                                                        </span>

                                                        <blockquote
                                                            class="mt-1 mb-0"
                                                        >
                                                            {{ review?.detail }}
                                                        </blockquote>
                                                    </div>
                                                </div>
                                            </div>
                                            <nav
                                                aria-label="Page navigation example"
                                            >
                                                <pagination
                                                    v-show="
                                                        $parent.query.total >
                                                        $parent.query.limit
                                                    "
                                                    v-model="$parent.query.page"
                                                    :records="
                                                        $parent.query.total
                                                    "
                                                    :per-page="
                                                        $parent.query.limit
                                                    "
                                                    @paginate="
                                                        $parent.getReviews()
                                                    "
                                                />
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- aslkdjfalsd -->
                        </div>
                        <!-- Review Section End -->

                        <!-- Resume Section Start -->
                        <div class="bg-white p-3 shadow mt-3" id="resume">
                            <div class="d-flex">
                                <span
                                    class="font-weight-bold"
                                    style="width: 150px"
                                    >Resume</span
                                >
                                <div class="tabs d-flex align-items-center">
                                    <p
                                        @click="resumeActiveTab = 'education'"
                                        role="button"
                                        v-if="
                                            $parent.tutor.qualifications
                                                ?.length > 0
                                        "
                                        :class="
                                            resumeActiveTab === 'education'
                                                ? 'resume-active-tab mb-0 text-dark'
                                                : 'mb-0 resume-tab m-0'
                                        "
                                    >
                                        Education
                                    </p>
                                    <p
                                        role="button"
                                        v-if="
                                            $parent.tutor.experiences?.length >
                                            0
                                        "
                                        @click="resumeActiveTab = 'experiences'"
                                        :class="
                                            resumeActiveTab === 'experiences'
                                                ? 'ml-3 resume-active-tab mb-0 text-dark'
                                                : 'ml-3 resume-tab mb-0'
                                        "
                                    >
                                        Work Experience
                                    </p>
                                    <p
                                        role="button"
                                        v-if="
                                            $parent.tutor.certifications
                                                ?.length > 0
                                        "
                                        @click="
                                            resumeActiveTab = 'certifications'
                                        "
                                        :class="
                                            resumeActiveTab === 'certifications'
                                                ? 'ml-3 resume-active-tab mb-0 text-dark'
                                                : 'ml-3 resume-tab mb-0'
                                        "
                                    >
                                        Certifications
                                    </p>
                                </div>
                            </div>
                            <hr />
                            <div
                                class="mt-3 d-flex flex-column"
                                v-if="resumeActiveTab === 'education'"
                            >
                                <div
                                    class="my-3 d-flex flex-row"
                                    v-for="qualification in $parent.tutor
                                        .qualifications" :key="qualification"
                                >
                                    <span class="text-gray" style="width: 150px"
                                        >{{
                                            qualification?.start_year
                                                ?.split("-")
                                                ?.at(0)
                                        }}-{{
                                            qualification?.end_year
                                                ?.split("-")
                                                ?.at(0)
                                        }}
                                    </span>
                                    <div class="tabs flex-column d-flex">
                                        <p
                                            class="m-0 text-capitalize font-weight-bold"
                                        >
                                            {{
                                                qualification?.institution?.name
                                            }}
                                            {{
                                                qualification?.institution?.city
                                            }}
                                        </p>
                                        <p class="m-0 text-capitalize">
                                            {{
                                                qualification?.education?.level
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="mt-3 d-flex flex-column"
                                v-if="resumeActiveTab === 'certifications'"
                            >
                                <div
                                    class="my-3 d-flex flex-row"
                                    v-for="certification in $parent.tutor
                                        .certifications" :key="certification"
                                >
                                    <span class="text-gray" style="width: 150px"
                                        >{{
                                            certification?.issue_date
                                                ?.split("-")
                                                ?.at(0)
                                        }}-{{
                                            certification?.expiration_date
                                                ?.split("-")
                                                ?.at(0)
                                        }}
                                    </span>
                                    <div class="tabs flex-column d-flex">
                                        <p
                                            class="m-0 text-capitalize font-weight-bold"
                                        >
                                            {{ certification?.organization }}
                                        </p>
                                        <p class="m-0 text-capitalize">
                                            {{ certification?.title }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="mt-3 d-flex flex-column"
                                v-if="resumeActiveTab === 'experiences'"
                            >
                                <div
                                    class="my-3 d-flex flex-row"
                                    v-for="experience in $parent.tutor
                                        .experiences" :key="experience"
                                >
                                    <span class="text-gray" style="width: 150px"
                                        >{{
                                            experience?.start_date
                                                ?.split("-")
                                                ?.at(0)
                                        }}-{{
                                            experience?.end_date
                                                ?.split("-")
                                                ?.at(0)
                                        }}
                                    </span>
                                    <div class="tabs flex-column d-flex">
                                        <p
                                            class="m-0 text-capitalize font-weight-bold"
                                        >
                                            {{ experience?.company }}
                                        </p>
                                        <p class="m-0 text-capitalize">
                                            {{ experience?.job_title }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Resume Section End -->

                        <!-- Subjects Section Start -->
                        <div
                            class="bg-white p-3 shadow mt-3 d-flex"
                            id="subjects"
                        >
                            <span class="font-weight-bold">Subjects</span>
                            <div class="ml-5 d-flex align-items-center">
                                <p
                                    v-for="subject in $parent.tutor.subjects" :key="subject"
                                    class="border-bottom text-dark border-dark mr-3 mb-0"
                                >
                                    {{ subject.subject }}
                                </p>
                            </div>
                        </div>
                        <!-- Subjects Section End -->
                    </div>
                    <div class="col-4">
                        <div class="sticky_top_box mt-3">
                            <div class="bg-white shadow pb-3">
                                <img
                                    v-if="!$parent.tutor.intro_video"
                                    src="@/assets/new-theme-images/dashboard-user-profile.png"
                                    class="rounded w-100"
                                    style="height: 300px; object-fit: cover"
                                />
                                <video
                                    :src="
                                        '/storage/' + $parent.tutor.intro_video
                                    "
                                    v-if="$parent.tutor.intro_video"
                                    style="height: 200px; object-fit: cover"
                                    class="rounded w-100"
                                    autoplay="off"
                                    controls
                                ></video>
                                <div class="mx-3">
                                    <div
                                        class="d-flex mt-4 mb-3 align-items-center justify-content-around"
                                    >
                                        <div
                                            class="d-flex align-items-center justify-content-center flex-column"
                                        >
                                            <p
                                                class="m-0 font-weight-bold"
                                                style="font-size: 25px"
                                            >
                                                <svg
                                                    height="14"
                                                    viewBox="0 0 24 23"
                                                    width="14"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="#fdc75f"
                                                    class="mr-1"
                                                >
                                                    <path
                                                        d="M23.97 8.721a.597.597 0 0 0-.507-.413l-7.744-.822-3.172-7.16c-.192-.435-.903-.435-1.095 0l-3.17 7.16-7.745.822a.601.601 0 0 0-.508.413.606.606 0 0 0 .17.635l5.785 5.248-1.616 7.667a.605.605 0 0 0 .586.729.595.595 0 0 0 .3-.081L12 19.003l6.747 3.916c.204.119.46.105.652-.035a.606.606 0 0 0 .234-.613l-1.616-7.668 5.786-5.248a.606.606 0 0 0 .168-.634z"
                                                    ></path></svg>
                                                {{
                                                    $parent.ratings?.rating?.toFixed(
                                                        1
                                                    ) ?? 0
                                                }}
                                            </p>
                                            <p class="m-0">
                                                <span class="font-weight-bold">
                                                    <span>
                                                        {{
                                                            $parent?.ratings
                                                                ?.total
                                                        }}
                                                    </span>
                                                </span>
                                                reviews
                                            </p>
                                        </div>
                                        <div
                                            class="d-flex align-items-center justify-content-center flex-column"
                                        >
                                            <p
                                                class="m-0 font-weight-bold"
                                                style="font-size: 25px"
                                            >
                                                {{ $parent.tutor?.info?.price }}
                                                {{ "Pkr" }}
                                            </p>
                                            <p class="m-0">60-min lesson</p>
                                        </div>
                                    </div>

                                    <button
                                        @click="$parent.gotoMessage()"
                                        class="book_trial_lesson_outline d-flex align-items-center justify-content-center mt-2"
                                    >
                                        <i class="far fa-envelope mr-2"></i>
                                        Send message
                                    </button>
                                    <button
                                        class="book_trial_lesson_outline d-flex align-items-center justify-content-center mt-2"
                                        @click="$parent.saveToMyList"
                                        :disabled="
                                            !!$parent.addToWhishListLoading
                                        "
                                    >
                                        <div
                                            v-if="
                                                !!$parent.addToWhishListLoading
                                            "
                                            class="spinner-border primaryColor"
                                            style="
                                                width: 20px !important;
                                                height: 20px !important;
                                                border-width: 3px;
                                            "
                                            role="status"
                                        ></div>
                                        <span
                                            v-if="
                                                !!$parent.addToWhishListLoading ==
                                                false
                                            "
                                            ><i
                                                v-if="!$parent.addedToWhishList"
                                                class="far fa-heart mr-2"
                                            ></i>
                                            <i
                                                v-if="$parent.addedToWhishList"
                                                class="fa fa-heart mr-2"
                                            ></i
                                            >{{
                                                !$parent.addedToWhishList
                                                    ? "Save to my list"
                                                    : "Saved"
                                            }}</span
                                        >
                                    </button>
                                    <!-- Book Trial Lesson Button End -->
                                    <div
                                        class="d-flex align-items-center justify-content-center mt-3"
                                    >
                                        <svg
                                            class="mt-1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="none"
                                            viewBox="0 0 10 10"
                                        >
                                            <circle
                                                cx="5"
                                                cy="5"
                                                r="4"
                                                stroke="#6FBB3D"
                                                stroke-width="2"
                                            ></circle>
                                        </svg>
                                        <!-- table: user_personal_infos col: response_time -->
                                        <p class="m-0 mt-2 ml-2 text-14">
                                            Usually responds in
                                            {{
                                                $parent.tutor?.info
                                                    ?.response_time
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </div>

        <!-- End -->
    </div>
</template>

<script>
import StarRating from "vue-star-rating";
import moment from "moment-timezone";
import AvailabileHours from "./AvailabileHours.vue";

export default {
    name: "InfoSectionComponent",
    components: {
      StarRating,
      AvailabileHours,
    },
    props: ["user"],
    data() {
        return {
            showDetails: false,
            resumeActiveTab: "education",
        };
    },
    mounted() {},
    created() {},
    methods: {
        moment(...props) {
            return moment(...props);
        },
        convert24Hourto12Hour() {},
        getSubjectsWithUnderline() {
            return this.$parent?.tutor?.subjects
                ?.map((_) => {
                    return `<span class="font-weight-bold underline border-bottom border-dark mx-1" style='padding-bottom: 1px;'>${_.subject}</span>`;
                })
                ?.join(" and ");
        },
    },
};
</script>

<style scoped>
.tutor-detail-banner-info {
    background: #fafafa !important;
    height: auto !important;
}
.center-image img {
    border-radius: 15px;
}
.tutor-detail-container .center-image img {
    height: 80%;
    width: 80%;
}
.tutor-detail-container .center-image {
    display: flex;
    align-items: center;
    justify-content: center;
}
.view_all_reviews_style {
    display: inline-flex;
    align-items: center;
    border: 0;
    padding: 0;
    background: none;
    color: #3bb3bd;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    transition: color 50ms;
}
.styles_FixedHeaderContainer__81Lu0 {
    margin-top: 60px;
    background-color: #fafafa;
    height: 59px;
    padding: 16px;
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    z-index: 3;
}
.sticky_top_box {
    padding-top: 20px;
    height: 59px;
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    z-index: 3;
}
.styles_FixedHeaderList__pW3ca {
    align-items: flex-start;
    display: flex;
    height: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.styles_FixedHeaderListItem__MAX__:first-child {
    margin-left: 0;
}

.styles_FixedHeaderListItemActive__n5IF3 {
    color: #384047;
    font-weight: 500;
    line-height: 20px;
    position: relative;
}
.styles_FixedHeaderListItem__MAX__ {
    color: #384047;
    cursor: pointer;
    font-size: 14px;
    margin-left: 28px;
}
/* .styles_FixedHeaderListItemActive__n5IF3 {
    color: #384047;
    font-weight: 500;
    line-height: 20px;
    position: relative;
} */
/* .styles_FixedHeaderListItemActive__n5IF3:after {
    border: 1px solid #3bb3bd;
    bottom: -5px;
    content: "";
    display: block;
    margin-top: 2px;
    position: absolute;
    width: 100%;
} */

/* About the tutor */
.customBox {
    background: #fff;
    border: 1px solid #edeff0;
    border-radius: 8px;
    box-shadow: 0 0 4px rgb(0 0 0 / 15%);
    padding: 0 32px;
    position: relative;
    z-index: 1;
}
.styles_aboutTutorWrap__fI_sr {
    padding: 32px 0 0;
    position: relative;
}
/* Schedule */
.schedule_banner {
    background: hsla(200, 9%, 94%, 0.5);
    color: #3c4447;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    margin-bottom: 20px;
    padding: 12px;
    position: relative;
}
.rating-no {
    font-size: 50px !important;
    color: black !important;
    margin-bottom: 10px !important;
}
/* Resume */
.resume-active-tab {
    border-bottom: 1px solid rgba(62, 0, 97, 0.79) !important;
    color: black;
}
.resume-tab {
    color: gray;
}
.book_trial_lesson {
    width: 100%;
    border-radius: 12px;
    background: rgba(62, 0, 97, 0.79);
    padding: 10px 0;
    color: white;
    outline: none;
    border: none;
    font-weight: bold;
    height: 42px;
}
.book_trial_lesson_outline {
    width: 100%;
    border-radius: 12px;
    border: 1px solid rgba(62, 0, 97, 0.79);
    background-color: transparent;
    padding: 10px 0;
    color: rgba(62, 0, 97, 0.79);
    outline: none;
    font-weight: bold;
    height: 42px;
}
.text-gray {
    color: #aeb5bc;
}
.text-dark {
    color: black !important;
}
.tutor-detail-container .expertise .rating-progress .progress {
    background-color: rgba(0, 0, 0, 0.1) !important;
}
.primaryColor {
    color: rgb(129, 43, 178) !important;
}
.add_dot_icon::before {
    /* background: transparent
        url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuMTk1IDE1LjkzNGMuNzA2IDEuNjY1IDEuOTQ2IDIuOTI2IDMuODU2IDMuMjggMS43MDguMzE2IDMuNDc1LS4zMzMgNC44NzgtMS4zNDguNjU3LS40NzYgMS4yNjktMS4wNSAxLjYxOC0xLjc4MS4zMTMtLjY1Ni4zOTYtMS4zOTUuNTgtMi4wOThhNy40MDUgNy40MDUgMCAwIDEgMi4yMjQtMy42MzFjLjg1Ni0uNzYzIDEuODgtMS4zMTYgMi43NjUtMi4wNDUuODg1LS43MjkgMS42Ni0xLjcxMyAxLjczMy0yLjg1OC4wNTItLjgyMy0uMjctMS42MzgtLjc0OC0yLjMxMS0xLjE1Ni0xLjYyNS0zLjE4Mi0yLjQ4LTUuMTcyLTIuNTg0LTEuOTktLjEwNC0zLjk1Ny40NjItNS43OTkgMS4yMTMtMS43NzguNzI1LTMuNTM5IDEuNjc1LTQuNzA0IDMuMTk4Qy41NDcgNy40MjYtLjI1IDEyLjUyNiAxLjE5NSAxNS45MzRaIiBmaWxsPSIjNTJENURGIi8+PC9zdmc+)
        0 0 no-repeat; */
    content: "";
    height: 20px;
    left: -10px;
    position: absolute;
    top: 0;
    width: 20px;
}
</style>
