<template>
    <div>
        <div class="container adding-courses-newtheme course-edit-container">
            <div class="course-adding-form box-style-coruse">
                <ul id="progressbar" class="progressbar-box six-steps">
                    <li class="active" id="general">
                        <strong class="color-light-blue">Basic Course Info</strong><span>1</span>
                    </li>
                    <li id="duration">
                        <strong>Proposed Schedule</strong><span>2</span>
                    </li>
                    <!--<li id="classsessionroomsetting"><strong>Class / Session Room Setting</strong><span>3</span></li>-->
                    <li id="pricingrequirements">
                        <strong>Pricing & Requirements </strong><span>3</span>
                    </li>
                    <li id="courseoutline">
                        <strong class="color-light-blue">Course Outline</strong><span>4</span>
                    </li>
                    <li id="mediaseo">
                        <strong>Media & SEO</strong><span>5</span>
                    </li>
                    <!--          <li id="quiz"><strong>Quiz</strong><span>6</span></li>-->
                </ul>
                <!-- fieldsets -->
                <!-- MultiStep Form -->
                <div class="container-fluid" id="grad1">
                    <div class="row">
                        <div class="col-md-12 mx-0">
                            <form @submit.prevent="checkCourseForm()" enctype="multipart/form-data">
                                <fieldset>
                                    <div class="row p-3 pb-0">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="course_title">Course Title (10 Characters)</label>
                                                <input type="text" class="form-control input_height" v-model="course.title"
                                                    id="course_title" aria-describedby="emailHelp"
                                                    placeholder="Enter Course Title" required v-on:focus="
                                                        errorMessages.title = []
                                                    " />
                                                <ul class="text-sm text-danger text-red-600 text-xs italic mt-1" v-if="
                                                    errorMessages.title
                                                        .length > 0
                                                ">
                                                    <li v-for="err in errorMessages.title" :key="err">
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="short_description">Short Description (50 Characters)</label>
                                                <input type="text" class="form-control input_height" v-model="
                                                    course.short_description
                                                " id="short_description" aria-describedby="emailHelp"
                                                    placeholder="Short Description" v-on:focus="
                                                        errorMessages.short_description =
                                                        []
                                                    " />
                                                <ul class="text-sm text-danger text-red-600 text-xs italic mt-1" v-if="
                                                    errorMessages
                                                        .short_description
                                                        .length > 0
                                                ">
                                                    <li v-for="err in errorMessages.short_description" :key="err">
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Description (200 Characters)</label>
                                                <div class="form-group">
<!--                                                    <ckeditor :editor="editor" v-model="-->
<!--                                                        course.description-->
<!--                                                    " :config="editorConfig"></ckeditor>-->
                                                  <textarea v-model="course.description"></textarea>
                                                    <ul class="text-sm text-danger text-red-600 text-xs italic mt-1" v-if="
                                                        course.description
                                                            .length < 200
                                                    ">
                                                        <li v-for="err in errorMessages.description" :key="err">
                                                            <small>{{
                                                                err
                                                            }}</small>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <!--<textarea class="form-control textarea_height" v-model="course.description" id="description" rows="3" placeholder="Description"></textarea>-->
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Category</label>
                                                <select v-model="course.category_id" class="form-control input_height"
                                                    id="category" name="category" v-on:focus="
                                                        errorMessages.category_id =
                                                        []
                                                    ">
                                                    <option value="0">
                                                        Choose Category
                                                    </option>
                                                    <option v-for="category in categories" :value="category.id" :key="category.id">
                                                        {{ category.name }}
                                                    </option>
                                                </select>
                                                <ul class="text-sm text-danger text-red-600 text-xs italic mt-1" v-if="
                                                    errorMessages
                                                        .category_id
                                                        .length > 0
                                                ">
                                                    <li v-for="err in errorMessages.category_id" :key="err">
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Board</label>
                                                <select v-model="course.board_id" class="form-control input_height"
                                                    id="board" name="board" v-on:focus="
                                                        errorMessages.board_id =
                                                        []
                                                    ">
                                                    <option value="0">
                                                        Choose Education Board
                                                    </option>
                                                    <option v-for="board in educationBoards" :value="board.id" :key="board.id">
                                                        {{ board.board }}
                                                    </option>
                                                </select>
                                                <ul class="text-sm text-danger text-red-600 text-xs italic mt-1" v-if="
                                                    errorMessages.board_id
                                                        .length > 0
                                                ">
                                                    <li v-for="err in errorMessages.board_id" :key="err">
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Level</label>
                                                <select class="form-control input_height" id="level" name="level"
                                                    v-model="course.class_id" v-on:focus="
                                                        errorMessages.class_id =
                                                        []
                                                    ">
                                                    <option value="0">
                                                        Choose Education Level
                                                    </option>
                                                    <option v-for="level in educationLevels" :value="level.id" :key="level.id">
                                                        {{ level.level }}
                                                    </option>
                                                </select>
                                                <ul class="text-sm text-danger text-red-600 text-xs italic mt-1" v-if="
                                                    errorMessages.class_id
                                                        .length > 0
                                                ">
                                                    <li v-for="err in errorMessages.class_id" :key="err">
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Course Level</label>
                                                <select v-model="course.level" class="form-control input_height"
                                                    id="course_level" name="course_level" v-on:focus="
                                                        errorMessages.level = []
                                                    ">
                                                    <option value="0">
                                                        Choose Course Level
                                                    </option>
                                                    <option value="beginner">
                                                        Beginner
                                                    </option>
                                                    <option value="advanced">
                                                        Advanced
                                                    </option>
                                                    <option value="intermediate">
                                                        Intermediate
                                                    </option>
                                                </select>
                                                <ul class="text-sm text-danger text-red-600 text-xs italic mt-1" v-if="
                                                    errorMessages.level
                                                        .length > 0
                                                ">
                                                    <li v-for="err in errorMessages.level" :key="err">
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Language </label>
                                                <select v-model="course.language" class="form-control input_height"
                                                    id="language" name="language" v-on:focus="
                                                        errorMessages.language =
                                                        []
                                                    ">
                                                    <option value="0">
                                                        Choose Course Language
                                                    </option>
                                                    <option value="english" selected>
                                                        English
                                                    </option>
                                                </select>
                                                <ul class="text-sm text-danger text-red-600 text-xs italic mt-1" v-if="
                                                    errorMessages.language
                                                        .length > 0
                                                ">
                                                    <li v-for="err in errorMessages.language" :key="err">
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-6"></div>
                                        <div class="col-md-6"></div>
                                    </div>
                                    <div class="row px-3 mb-3">
                                        <div class="col-sm-6"></div>
                                        <div class="col-sm-6 text-right">
                                            <!--<a href="javascript:void(0)" v-if="!!$route.params.id" v-on:click="$parent.steps++" class="next action-button btn btn-primary btn-lg">Next Step</a>-->
                                            <a href="javascript:void(0)" v-on:click="checkCourseForm"
                                                class="next action-button btn btn-primary btn_fontsize btn-lg"
                                                :disabled="!!loading">
                                                <div v-if="!!loading" class="spinner-border text-primary" style="
                                                                    color: white !important;
                                                                    width: 20px !important;
                                                                    height: 20px !important;
                                                                " role="status"></div>
                                                <span v-if="!!loading == false">
                                                    Save and Continue</span>
                                            </a>
                                            <!--                      <a href="javascript:void(0)" v-else v-on:click="checkCourseForm" class="next action-button btn btn-primary btn_fontsize btn-lg">Create Course</a>-->
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import axiosInstance from "@/assets/js/axios";

export default {
    name: "Step1Component",
    data() {
        return {
            loading: false,
            editor: null,
            editorData: "",
            editorConfig: {},
            categories: [],
            educationBoards: [],
            educationLevels: [],
            course: {
                is_top_course: false,
                title: "",
                short_description: "",
                description: "",
                category_id: 0,
                board_id: 0,
                class_id: 0,
                level: 0,
                language: 0,
            },
            errorMessages: {
                is_top_course: [],
                title: [],
                short_description: [],
                description: [],
                category_id: [],
                board_id: [],
                class_id: [],
                level: [],
                language: [],
            },
        };
    },
    created() {
        this.getAllFilterContent();
        if (this.$route.params.id) this.getCourseDetails();
    },
    mounted() { },
    watch: {
        "course.description"(newValue) {
            if (newValue.length >= 200) {
                this.errorMessages.description = [];
            }
        },
    },
    methods: {
        getAllFilterContent() {
            let self = this;
            axiosInstance
                .get("/v1/filter/content")
                .then(function (response) {
                    // handle success
                    self.categories = response.data.data.categories;
                    self.educationBoards = response.data.data.boards;
                    self.educationLevels = response.data.data.educationLevels;
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        },
        checkTitle() {
            this.errorMessages.title = [];
            if (!this.course.title || this.course.title.length < 10) {
                this.errorMessages.title.push(
                    "Course Title Required Minimum 10 Characters."
                );
                return false;
            } else {
                this.errorMessages.title = [];
                return true;
            }
        },
        checkShortPassword() {
            this.errorMessages.short_description = [];
            if (
                !this.course.short_description ||
                this.course.short_description.length < 50
            ) {
                this.errorMessages.short_description.push(
                    "Short Description Required min 50 characters."
                );
                return false;
            } else {
                this.errorMessages.short_description = [];
                return true;
            }
        },
        checkDescription() {
            this.errorMessages.description = [];
            if (
                !this.course.description ||
                this.course.description.length < 200
            ) {
                this.errorMessages.description.push(
                    "Detailed Description Required Minimum 200 Character."
                );
                return false;
            } else {
                this.errorMessages.description = [];
                return true;
            }
        },
        checkCategoryId() {
            this.errorMessages.category_id = [];
            if (!this.course.category_id) {
                this.errorMessages.category_id.push(
                    "Choose Category required."
                );
                return false;
            } else {
                this.errorMessages.category_id = [];
                return true;
            }
        },
        checkLevel() {
            this.errorMessages.class_id = [];
            if (!this.course.class_id) {
                this.errorMessages.class_id.push("Choose Level.");
                return false;
            } else {
                this.errorMessages.class_id = [];
                return true;
            }
        },
        checkCourseLevel() {
            this.errorMessages.level = [];
            if (!this.course.level) {
                this.errorMessages.level.push("Choose Course Level.");
                return false;
            } else {
                this.errorMessages.level = [];
                return true;
            }
        },
        checkLanguage() {
            this.errorMessages.language = [];
            if (!this.course.language) {
                this.errorMessages.language.push("Choose Course Language.");
                return false;
            } else {
                this.errorMessages.language = [];
                return true;
            }
        },

        checkCourseForm() {
            this.checkTitle();
            this.checkShortPassword();
            this.checkDescription();
            this.checkCategoryId();
            // this.checkLevel();
            this.checkCourseLevel();
            this.checkLanguage();

            if (
                this.checkTitle() &&
                this.checkShortPassword() &&
                this.checkDescription() &&
                this.checkCategoryId() &&
                // this.checkLevel() &&
                this.checkCourseLevel() &&
                this.checkLanguage()
            ) {
                if (this.$route.params.id) {
                    this.updateCourse();
                } else {
                    this.createCourse();
                }
            }
        },
        async createCourse() {
            let self = this;
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            let formData = new FormData();
            formData.append("file", self.file);
            _.each(self.course, (value, key) => {
                formData.append(key, value);
            });
            self.loading = true;

            axiosInstance
                .post("/v1/course/create/step-1", formData, config)
                .then(function (response) {
                    self.loading = false;
                    // handle success
                    if (response.data.data.type === "success") {
                        self.$toasted.success(response.data.data.message);
                        self.days = self.requirements = self.outcomes = [];
                        self.image = "";
                        self.course = {};
                        self.course.sessionroom = {};
                        self.$router.push({
                            name: "updateCourse",
                            params: { id: response.data.data.id },
                        });
                        self.$parent.steps++;
                    } else if (response.data.data.type === "validation") {
                        Object.keys(response.data.data.message).forEach(
                            function (key) {
                                self.errorMessages[key] =
                                    response.data.data.message[key];
                            }
                        );
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    self.loading = false;
                    // always executed
                });
        },
        async updateCourse() {
            let self = this;

            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            let formData = new FormData();
            formData.append("file", self.file);
            _.each(self.course, (value, key) => {
                formData.append(key, value);
            });

            if (self.$route.params.id) {
                let id = self.$route.params.id;
                formData.append("id", id);
            }

            self.loading = true;
            axiosInstance
                .post("/v1/course/create/step-1", formData, config)
                .then(function (response) {
                    self.loading = false;
                    // handle success
                    if (response.data.type === "success") {
                        this.$toasted.success(response.data.message);
                        self.getCourseDetails();
                    } else if (response.data.type === "validation") {
                        Object.keys(response.data.message).forEach(function (
                            key
                        ) {
                            self.errorMessages[key] =
                                response.data.message[key];
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .then(function () {
                    self.loading = false;
                    self.$parent.steps++;
                });

            // Swal.fire({
            //   title: 'Are you sure?',
            //   text: "You won't be able to revert this!",
            //   icon: 'warning',
            //   showCancelButton: true,
            //   confirmButtonColor: '#3085d6',
            //   cancelButtonColor: '#d33',
            //   confirmButtonText: 'Yes, Update it!'
            // }).then((result) => {
            //   if (result.isConfirmed) {
            //
            //   }
            //   else {
            //     self.$parent.steps++
            //   }
            // })
        },
        getCourseDetails() {
            let id = this.$route.params.id;
            let self = this;
            axiosInstance
                .get("/v1/course/edit/" + id)
                .then(function (response) {
                    // handle success
                    if (response.data.type === "success") {
                        self.course = response.data.data;
                    } else {
                        this.$toasted.error(response.data.message);
                        self.$router.replace("/tutor/courses");
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        },
    },
};
</script>

<style scoped>
@media screen and (min-width: 1850px) {
    .container {
        max-width: 2150px !important;
    }
}
</style>
