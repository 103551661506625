<template>
    <div id="qualifications">
        <div class="experience-header">
            <h2>Qualification</h2>
            <img
                src="@/assets/new-theme-images/bi_plus-square.png"
                alt="plus"
                @click="openAddExp()"
            />
        </div>
        <div
            v-for="qualification in qualifications" :key="qualification.id"
            class="row experience-item"
        >
            <div class="col-md-6">
                <h5 class="experience-title">
                    {{ qualification?.degree?.level }}
                </h5>
                <p class="experience-desc m-0">
                    <u>{{ qualification.institute?.name }}</u>
                </p>
                <p class="experience-desc m-0">
                    <u
                        >{{ qualification.start_year }} -
                        {{
                            qualification.job_continue
                                ? " Present "
                                : qualification.end_year
                        }}
                        .
                        {{
                            calculateDuration(
                                qualification.start_year,
                                qualification.end_year
                            )
                        }}</u
                    >
                </p>
                <p class="experience-desc m-0">
                    <u>{{ qualification.headline }}</u>
                </p>
            </div>

            <div class="col-md-6 experience-edit">
                <img
                    @click="editQualification(qualification.id)"
                    src="@/assets/new-theme-images/clarity_edit-line.png"
                    alt="edit"
                    class="mr-3"
                />
                <img
                    @click="deleteQualification(qualification.id)"
                    src="@/assets/new-theme-images/trash.png"
                    alt="trash"
                />
            </div>
        </div>

        <div
            class="modal bd-example-modal-lg"
            id="addqualification"
            tabindex="-1"
            role="dialog"
        >
            <form action="" @submit.prevent="addQualification()">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Add Qualification</h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Institute </label>
                                        <select
                                            v-model="qualification.institute_id"
                                            class="form-control"
                                            name="institute_id"
                                            id="institute_id"
                                            v-on:focus="
                                                errorMessages.institute_id = []
                                            "
                                        >
                                            <option value="0" disabled>
                                                Please Select Institute
                                            </option>
                                            <option
                                                v-for="institute in institutes"
                                                :key="institute.id"
                                                :value="institute.id"
                                            >
                                                {{ institute.name }}
                                            </option>
                                        </select>
                                        <ul
                                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                            v-if="
                                                errorMessages.institute_id
                                                    .length > 0
                                            "
                                        >
                                            <li
                                                v-for="err in errorMessages.institute_id" :key="err"
                                            >
                                                <small>{{ err }}</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Degree</label>
                                        <select
                                            v-model="
                                                qualification.education_level_id
                                            "
                                            class="form-control"
                                            name="education_level_id"
                                            id="education_level_id"
                                            v-on:focus="
                                                errorMessages.education_level_id =
                                                    []
                                            "
                                        >
                                            <option value="0" disabled>
                                                Please Select Degree
                                            </option>
                                            <option
                                                v-for="degree in degrees"
                                                :key="degree.id"
                                                :value="degree.id"
                                            >
                                                {{ degree.level }}
                                            </option>
                                        </select>
                                        <ul
                                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                            v-if="
                                                errorMessages.education_level_id
                                                    .length > 0
                                            "
                                        >
                                            <li
                                                v-for="err in errorMessages.education_level_id" :key="err"
                                            >
                                                <small>{{ err }}</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Field Of Study</label>
                                        <select
                                            v-model="qualification.subject_id"
                                            class="form-control"
                                            id="subject_id"
                                            v-on:focus="
                                                errorMessages.subject_id = []
                                            "
                                        >
                                            <option value="0" selected disabled>
                                                Choose Subject
                                            </option>
                                            <option
                                                v-for="subject in subjects" :key="subject.id"
                                                :value="subject.id"
                                            >
                                                {{ subject.subject }}
                                            </option>
                                        </select>
                                        <ul
                                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                            v-if="
                                                errorMessages.subject_id
                                                    .length > 0
                                            "
                                        >
                                            <li
                                                v-for="err in errorMessages.subject_id" :key="err"
                                            >
                                                <small>{{ err }}</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="start_year"
                                            >Start Year</label
                                        >
                                        <input
                                            v-model="qualification.start_year"
                                            type="date"
                                            class="form-control"
                                            id="start_year"
                                            v-on:focus="
                                                errorMessages.start_year = []
                                            "
                                        />
                                        <ul
                                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                            v-if="
                                                errorMessages.start_year
                                                    .length > 0
                                            "
                                        >
                                            <li
                                                v-for="err in errorMessages.start_year" :key="err"
                                            >
                                                <small>{{ err }}</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="end_year">End Year</label>
                                        <input
                                            v-model="qualification.end_year"
                                            type="date"
                                            class="form-control"
                                            id="end_year"
                                            v-on:focus="
                                                errorMessages.end_year = []
                                            "
                                        />
                                        <ul
                                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                            v-if="
                                                errorMessages.end_year.length >
                                                0
                                            "
                                        >
                                            <li
                                                v-for="err in errorMessages.end_year" :key="err"
                                            >
                                                <small>{{ err }}</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="cgpa">CGPA</label>
                                        <input
                                            v-model="qualification.cgpa"
                                            type="text"
                                            class="form-control"
                                            id="cgpa"
                                            aria-describedby="emailHelp"
                                            placeholder="CGPA"
                                            v-on:focus="errorMessages.cgpa = []"
                                        />
                                        <ul
                                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                            v-if="errorMessages.cgpa.length > 0"
                                        >
                                            <li
                                                v-for="err in errorMessages.cgpa" :key="err"
                                            >
                                                <small>{{ err }}</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="marks">Marks in (%)</label>
                                        <input
                                            v-model="qualification.marks"
                                            type="text"
                                            class="form-control"
                                            id="marks"
                                            aria-describedby="emailHelp"
                                            placeholder="Marks in (%)"
                                            v-on:focus="
                                                errorMessages.marks = []
                                            "
                                        />
                                        <ul
                                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                            v-if="
                                                errorMessages.marks.length > 0
                                            "
                                        >
                                            <li
                                                v-for="err in errorMessages.marks" :key="err"
                                            >
                                                <small>{{ err }}</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="mx-auto text-center">
                                <button
                                    class="btn confirm_deposite_btn"
                                    :disabled="loading"
                                >
                                    {{ loading ? "Saving..." : "Save" }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="d-flex align-items-center justify-content-end mt-3">
            <span></span>
            <button
                type="submit"
                class="btn custom-button d-flex align-items-center justify-content-center"
                style="width: 100px"
                v-on:click="$parent.nextTab()"
            >
                <span>Next</span>
            </button>
        </div>
    </div>
</template>

<script>

import Swal from "sweetalert2";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";
import $ from "jquery";
import moment from "moment-timezone";

export default {
    name: "QualificationComponent",
    props: [],
    components: {},
    directives: {},
    data() {
        return {
            subjects: [],
            institutes: [],
            degrees: [],
            grades: [],
            qualifications: [],
            loading: false,
            qualification: {
                institute_id: 0,
                education_level_id: 0,
                subject_id: 0,
                start_year: "",
                end_year: "",
                cgpa: "",
                marks: "",
                grade: 1,
            },
            errorMessages: {
                institute_id: [],
                education_level_id: [],
                subject_id: [],
                start_year: [],
                end_year: [],
                cgpa: [],
                marks: [],
                grade: [],
            },
        };
    },
    computed: {},
    created() {
        this.getFilterContent();
        this.getQualifications();
    },
    methods: {
        async getFilterContent() {
            const filterRes = await axiosInstance.get(endpoints.filterContent);

            let data = null;

            if(filterRes) {
              data = filterRes.data.data;
            }
            this.subjects = data.subjects;
            this.degrees = data.educationLevels;
            this.institutes = data.institutes;
        },
        openAddExp() {
            $("#addqualification").modal("show");
        },
        async getList() {
            const { data } = await axiosInstance.get(endpoints.instituteProRoute);
            this.institutes = data.data;
        },
        async getDegree() {
            const { data } = await axiosInstance.get(endpoints.degreeProRoute);
            this.degrees = data;
        },
        async getGrade() {
            const { data } = await axiosInstance.get(endpoints.gradeProRoute);
            this.grades = data;
        },
        async getQualifications() {
            const qualiRes = await axiosInstance.get(endpoints.qualiPro);
            let data = null;
            if(qualiRes) {
              data = qualiRes.data.data;
            }
            this.qualifications = data.data.data;
        },
        async editQualification(id) {
            const data = await axiosInstance.get(endpoints.qualiPro, {params: id} );
            this.qualification = data.data;
            this.openAddExp();
        },
        async deleteQualification(id) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axiosInstance.delete(endpoints.qualiPro+'/'+id);
                    await this.getQualifications();
                    toast.success("Qualification been deleted.");
                }
            });
        },
        checkInstitute() {
            this.errorMessages.institute_id = [];
            if (this.qualification.institute_id === 0) {
                this.errorMessages.institute_id.push("Choose Institute ..");
                return false;
            } else {
                this.errorMessages.institute_id = [];
                return true;
            }
        },
        checkDegree() {
            this.errorMessages.education_level_id = [];
            if (this.qualification.education_level_id === 0) {
                this.errorMessages.education_level_id.push(
                    "Choose Education Level"
                );
                return false;
            } else {
                this.errorMessages.education_level_id = [];
                return true;
            }
        },
        checkStartDate() {
            this.errorMessages.start_year = [];
            if (!this.qualification.start_year) {
                this.errorMessages.start_year.push("Enter Start Date");
                return false;
            } else {
                this.errorMessages.start_year = [];
                return true;
            }
        },
        checkEndDate() {
            this.errorMessages.end_year = [];
            if (!this.qualification.end_year) {
                this.errorMessages.end_year.push("Enter End Date");
                return false;
            } else {
                this.errorMessages.end_year = [];
                return true;
            }
        },

        async addQualification() {
            let self = this;
            this.checkInstitute();
            this.checkDegree();
            this.checkStartDate();
            this.checkEndDate();
            if (
                this.checkInstitute() &&
                this.checkDegree() &&
                this.checkStartDate() &&
                this.checkEndDate()
            ) {
                this.loading = true;
                if (this.qualification.id) {
                    await axiosInstance
                        .put(endpoints.qualiPro+'/'+this.qualification.id, this.qualification)
                        .then((response) => {
                            if (response.data.type === "validation") {
                                Object.keys(response.data.message).forEach(
                                    function (key) {
                                        self.errorMessages[key] =
                                            response.data.message[key];
                                    }
                                );
                            } else if (response.data.type === "success") {
                                toast.success(response.message);
                                this.loading = false;
                                $("#addqualification").modal("hide");
                                self.qualification.institute_id = 0;
                                self.qualification.education_level_id = 0;
                                self.qualification.subject_id = 0;
                                self.qualification.start_year = "";
                                self.qualification.end_year = "";
                                self.qualification.cgpa = "";
                                self.qualification.marks = "";
                                self.qualification.grade = 1;
                                this.getQualifications();
                            }
                        })
                        .catch(() => {
                            this.loading = false;
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    await axiosInstance
                        .post(endpoints.qualiPro, this.qualification)
                        .then((response) => {
                            if (response.data.type === "validation") {
                                Object.keys(response.data.message).forEach(
                                    function (key) {
                                        self.errorMessages[key] =
                                            response.data.message[key];
                                    }
                                );
                            } else if (response.data.type === "success") {
                                toast.success(response.message);
                                this.loading = false;
                                $("#addqualification").modal("hide");
                                self.qualification.institute_id = 0;
                                self.qualification.education_level_id = 0;
                                self.qualification.subject_id = 0;
                                self.qualification.start_year = "";
                                self.qualification.end_year = "";
                                self.qualification.cgpa = "";
                                self.qualification.marks = "";
                                self.qualification.grade = 1;
                                this.getQualifications();
                            }
                        })
                        .catch(() => {
                            this.loading = false;
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            }
        },
      calculateDuration(start_year, end_year) {
        let start = moment(start_year);
        let end = moment(end_year);
        return moment.duration(end.diff(start)).humanize();
      },
    },
};
</script>
<style scoped>
button {
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom-button {
    background-color: #812bb2;
    font-size: 15px;
    font-weight: 700;
    line-height: 5px;
    font-family: "Roboto", sans-serif;
    color: #fccd05 !important;
    height: 32px !important;
    border-radius: 5px !important;
    margin-top: 15px;
}
</style>
