<template>
  <div>
    <div class="container instructor-newtheme">
      <div class="instructor-page-title">
        <h2>Tutor Verification</h2>
        <!-- <p>Instructor Application Form</p> -->
      </div>
      <div class="course-adding-form box-style-coruse">
        <ul id="progressbar" class="progressbar-box student-dashboard">
          <li id="general"><strong class="color-light-blue">Upload Educational Document</strong><span>1</span></li>
          <li class="active" id="duration"><strong class="color-light-blue">Payment</strong><span>2</span></li>
          <li id="courseoutline"><strong>Under Reviewed</strong><span>3</span></li>
        </ul>
        <div class="container-fluid" id="grad1">
          <div class="row">
            <div class="col-md-12">
              <div style="display: flex; justify-content: space-between;">
              <div>
                <h6 class="font-weight-bold mt-4 mb-0 theme-color">Payment</h6>
                <h6 class="font-weight-bold mt-0 mb-3 theme-color"><small>Pay account verification fee</small></h6>
              </div>
              <div class="font-weight-bold mt-4 mb-0 theme-color">Fees: 600 PKR</div>
                </div>
              <div class="box-shadow-style-01 rounded-8">
                <div class="radiobox-style py-2 px-5 position-relative">
                  <div class="media">
                    <div class="media-body">
                      <input  type="radio" v-model="payment_method" name="payment_method" id="inlineRadio1" value="jazzcash">
                      <label class="form-check-label" for="inlineRadio1"><strong>JazzCash</strong></label>
                    </div>
                    <img src="@/assets/images/payment-02.png" alt="payment" />
                  </div>
                </div>
                <hr>
                <div class="radiobox-style py-2 px-5 position-relative  ">
                  <div class="media">
                    <div class="media-body">
                      <input  type="radio" v-model="payment_method" name="payment_method" id="inlineRadio2" value="foreepay">
                      <label class="form-check-label" for="inlineRadio2"><strong>Foree Pay</strong></label>
                    </div>
                    <img src="@/assets/images/foree_pay.png" alt="payment" />
                  </div>
                </div>
                <hr>
                <div class="radiobox-style py-2 px-5 position-relative  ">
                  <div class="media">
                    <div class="media-body">
                      <input  type="radio" v-model="payment_method" name="payment_method" id="inlineRadio3" value="wallet">
                      <label class="form-check-label" for="inlineRadio3"><strong>Wallet</strong></label>
                    </div>
                    <img src="@/assets/images/wallet.png" alt="payment" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 p-2">
              <button v-on:click="$parent.step--" class="btn btn-success float-left bg-Back-white">Back</button>
              <button v-on:click="payment()" class="btn btn-success float-right next-btn">Pay</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";

export default {
  name: 'Step2Component',
  props: [],
  components: {},
  directives: {},
  data() {
    return {
      subjects: [],
      institutes: [],
      degrees: [],
      grades: [],
      qualifications: [],
      loading: false,
      qualificationForm: false,
      qualification:{},
      payment_method: 'wallet',
    }
  },
  computed: {},
  created() {},
  methods: {
    async getFees() {
      await axiosInstance.get(endpoints.getMetaFeesRoute);

    },
    async payment() {
      let self = this
      let formData = new FormData();
      formData.append('amount', 500)
      formData.append('payment_method', self.payment_method)
      if (self.payment_method === 'wallet') {
        const {data} = await axiosInstance.post(endpoints.paymentRoute, formData);
        if (data.type === 'success') {
          toast.success('Payment Paid Successfully')
          self.$parent.step++
        }
        else {
            toast.error(data.message);
        }
      }
      else if (self.payment_method === 'foreepay') {
        const {data} = await axiosInstance.post(endpoints.paymentRoute, formData);
        console.log(data)
        // this.initiateCheckout(data)
      }
      else if (self.payment_method === 'jazzcash') {
        window.location.href = '/v1/tutor/verification/payment/jazzcash'
      }
      else {
        await axiosInstance.post(endpoints.paymentRoute, formData);
      }

    },
    // initiateCheckout(data) {
    //
    //   let callBack = APP_URL+'/response/foree'
    //   let amount = data.amount
    //   let crn = data.crn
    //   let customer_phone_number = data.customer_phone_number;
    //   let customername = data.consumer_name
    //   let customeremail = data.customer_email_address
    //
    //   let urlParamObj = {
    //     'key': process.env.MIX_FOREE_API_KEY,
    //     'reference_number': crn,
    //     'amount': amount,
    //     'is_generated': 0,
    //     'customer_email_address': customeremail,
    //     'customer_phone_number': customer_phone_number,
    //     'consumer_name': customername,
    //     'callback_url': callBack
    //   }
    //   /*Call back url*/
    //   initiateForeeCheckout(urlParamObj);
    // },

  },
};
</script>

<style scoped>
.theme-color{
  color: #623a6c;
}
</style>
