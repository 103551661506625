<template>
  <div class="container mt-5">
    <h4>Course Creation and Approval Process</h4>
    <p>
      ILMZone offers tutors the opportunity to design and set up their own courses. Courses focus on specific subjects, grades, and topics, and offer tutors a great way to teach multiple students at the same time, thus growing their earnings.
    </p>

    <h4>Course Format</h4>
    <p>
      Tutors determine their own time period (example three weeks) for their course duration. Lessons are scheduled on various days during that period. Each section in the course outline represents a day. Each section in each day has a quiz, activities, and topics to be covered that day.
    </p>
    <p>Seminars focused on a single topic, lasting one or two days, can also be taught.</p>

    <h4>Course Creation</h4>
    <p>The online course creation process is simple. Just follow these steps:</p>
    <p><b>Getting Started</b></p>
    <ul>
      <li>Login to your tutor’s account</li>
      <li>Go to your dashboard</li>
      <li>On your dashboard, click Courses.</li>
      <li>In the Courses Section, go to Create a Course</li>
    </ul>
    <img src="@/assets/images/course-creation-approval-1.png" width="500px" alt="Course Creation Step 1">

    <p><b>Step 1: Enter Preliminary Course information</b></p>
    <p>Start creating your course by entering the following where indicated: Course title</p>
    <ul>
      <li>A short description of your course</li>
      <li>
        A more detailed description about the course. Add details such as background about the topic or subject, what the student will be able to do with the knowledge they gain, and job outlook.
      </li>
      <li>Course Subject</li>
      <li>Course board</li>
      <li>Student's Level</li>
      <li>Course level (from beginner to advanced)</li>
      <li>Language the course will be taught in</li>
    </ul>
    <img src="@/assets/images/course-creation-approval-2.png" width="500px" alt="Course Information">
    <img src="@/assets/images/course-creation-approval-3.png" width="500px" alt="Course Information">

    <p><b>Step 2: Course Duration</b></p>
    <p>In this step, you will add details about the time and duration of the course: Daily class start and end time</p>
    <ul>
      <li>Start and end dates of the course</li>
      <li>Days of the week the class will be held</li>
      <li>Number of weeks the course will last</li>
    </ul>
    <img src="@/assets/images/course-creation-approval-4.png" width="500px" alt="Course Duration">

    <p><b>Step 3: Create Course Outline</b></p>
    <p>This step is the most important and requires careful creation. In this step, you will see the weeks and days you chose to hold your classes.</p>
    <p>
      Change "Add outline" to "Create Your Course Outline," add some ghosted instructions in the topic detail.
    </p>
    <img src="@/assets/images/course-creation-approval-5.png" width="500px" alt="Course Outline Creation">
    <p>
      Click the edit button to the right of the weekly dates. A pop-up will show each day you chose to teach your proposed classes. Enter the Topic you intend to teach each day. Enter structured Details for that class. Repeat for each day of the course.
    </p>
    <img src="@/assets/images/course-creation-approval-6.png" width="500px" alt="Course Outline Edit">

    <p><b>Step 4: Add pricing and prerequisite information</b></p>
    <p>In this section, you will add course pricing:</p>
    <ul>
      <li>Suggested course fees (you can also list it as a free course by checking the box)</li>
      <li>Discount information</li>
    </ul>
    <p>In addition to the pricing information, you will also add any prerequisites that are mandatory to take the course. Some prerequisite examples are:</p>
    <ul>
      <li>A specific degree if any required to take this course.</li>
      <li>Experience that is required or desired to take this course</li>
      <li>Basic understanding of any software tools</li>
    </ul>
    <img src="@/assets/images/course-creation-approval-7.png" width="500px" alt="Course Pricing and Prerequisites">

    <p><b>Step 5: Add Media and (optional) SEO</b></p>
    <p>
      Adding course thumbnail, course introduction video URL, meta keywords, and description helps your course rank in search engines. This helps you gain more visibility and better sales. Some things that you can add in this section are:
    </p>
    <ul>
      <li>Thumbnails that will be seen by students interested in the course</li>
      <li>
        An introductory video (Statistics suggest that courses with well-produced explanatory videos tend to rank higher). This is an opportunity for you to exhibit your expertise more effectively than with only written narrative.
      </li>
      <li>Optional SEO keywords to optimize your course listing for students’ search.</li>
      <li>Optional Meta description</li>
    </ul>
    <img src="@/assets/images/course-creation-approval-8.png" width="500px" alt="Course Media and SEO">
    <p>The next step is an option to include a short quiz in your course to gauge its effectiveness. Create a quiz of at least three questions and click Save.</p>

    <h4>Course Review</h4>
    <p>
      After submitting your course, it will undergo a rigorous review process. ILMZone strives to maintain high-quality content that provides great value to students and tutors. The course review can take up to three working days. The following steps are included in the course review process:
    </p>
    <ul>
      <li>The administration reviews the course content and suggests improvements and/or edits</li>
      <li>
        After your course has been reviewed, if changes are required you will receive a notice to the effect. You should then open your proposed course, see the required changes, and incorporate them into your proposal.
      </li>
      <li>After updating, the course will be returned to administration to be reviewed once again.</li>
      <li>If administration sees that the course has been updated satisfactorily, the course will be approved.</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CourseCreationApproval",
};
</script>

<style scoped>
.container img {
  display: block;
  margin: 20px 0;
}

.container ul {
  list-style-type: disc;
  padding-left: 20px;
}
</style>