<template>
    <div class="tutor-detail-container">
        <InfoSectionComponent :user="this.$route.params.id"></InfoSectionComponent>
    </div>
</template>

<script>
import { GlobalEventBus } from "@/assets/js/eventBus.js";
import moment from "moment-timezone";
import InfoSectionComponent from "@/components/frontend/tutor/InfoSectionComponent.vue";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";


export default {
    name: "TutorDetailComponent",
    components: {InfoSectionComponent},
    props: [],
    data() {
        return {
            addToWhishListLoading: false,
            selected_slot: {},
            chatModal: false,
            reportModal: false,
            modal_id: 0,
            month: moment().format("M"),
            selected_date: "",
            showFeedBackModal: false,
            calendarModal: false,
            authenticated: false,
            got: false,
            userDetail: {},
            tutor: {},
            slots: [],
            booked: [],
            dependents: [],
            schedule: [],
            reviews: [],
            ratings: [],
            addedToWhishList: false,
            currentWeekSlots: [],
            query: {
                id: this.user,
                model_id: this.user,
                model: "Tutor",
                keyword: "",
                sort: "DESC",
                page: 1,
                limit: 10,
                total: 0,
                date_from: "",
                date_to: "",
                time: "",
            },
        };
    },
    async mounted() {},
    created() {
        let self = this;

        // this.getSlots();
        this.getTutorDetail();
        // this.getReviews();

        GlobalEventBus.on("selected_date", function (data) {
            if (self.authenticated) {
                self.selected_date = data;
                self.showCalendar();
            } else {
                GlobalEventBus.$emit("showLoginForm");
            }
        });

        GlobalEventBus.on("getUserDetail", async function (data) {
            if (data.authenticated) {
                self.userDetail = data;
                self.dependents = data.dependents;
                self.authenticated = data.authenticated;
                await self.getCalendarForReschedule();
            }
        });

    },
    methods: {
        async openchatbox(id) {
            document.getElementsByClassName("fa-angle-up")[0].click();
            setTimeout(function() {
                    if(document.getElementById(id)){
                        document.getElementById(id).click();
                    }
                    else{
                        location.reload();
                    }
                }, 500);
        },
        async gotoMessage(){
            this.authenticated = localStorage.getItem("authenticated") === "true";
                if (!this.authenticated) {
                    GlobalEventBus.$emit("showLoginForm");
                } else {
                    let formData = {
                        "receiver_id" : this.tutor.id
                    };
                    const { data } = await axiosInstance.post(endpoints.addToMessageList, formData);
                    if(data && data[0].data.receiver_id === this.tutor.id){
                        window.location = "/student/chat";
                    }
                    else{
                        alert("something wrong");
                    }
                }
        },
        async saveToMyList() {
            if (this.addToWhishListLoading) return;
            // this.authenticated =
            //     localStorage.getItem("authenticated") === "true";
            if (!this.authenticated) {
                GlobalEventBus.$emit("showLoginForm");
                return;
            }

            let formData = new FormData();
            formData.append("model_id", this.tutor.id);
            formData.append("model", "Tutor");
            this.addToWhishListLoading = true;
            const { data } = await axiosInstance.post(endpoints.addToWishlistRoute, formData);
            this.addedToWhishList = data.action !== "remove";
            this.addToWhishListLoading = false;
        },
        async getCalendarForReschedule() {
            if (!!this.slot_id > 0 && this.slots.length > 0) {
                this.selected_slot = this.slots.find(
                    (s) => s.id === parseInt(this.slot_id)
                );
                await this.showCalendar();
            } else {
                if (!!this.slot_id > 0) {
                    // await this.getSlots();
                    await this.getCalendarForReschedule();
                }
            }
        },
        async getTutorDetail() {
            const data = await axiosInstance.get(endpoints.getTutorDetailRoute, {params: {id: this.$route.params.id}});
            console.log("Tutor", data.data.data);
            this.tutor = data.data.data.data;
            this.addedToWhishList = !!data?.data?.addedToWhishList;
            // this.currentWeekSlots = data.data.currentWeekSlots
            this.got = true;
        },
        async getReviews() {
            const { data } = await axiosInstance.get(endpoints.getReviews, {params: {
                ...this.query,
                limit: Number.MAX_VALUE,
              }});

            this.reviews = data.data.data;
            this.ratings = data.ratings;
            this.query.total = data.data.total;
            GlobalEventBus.$emit("CourseRatingReviews", data.ratings);
        },
        async showCalendar() {
            if (!this.authenticated) {
                GlobalEventBus.$emit("showLoginForm");
            } else {
                let query = {};
                query.id = this.user;
                if (!localStorage.getItem("query")) {
                    let q = JSON.parse(localStorage.getItem("query"));
                    if (!q.date_from) {
                        this.selected_date = moment(q.date_from).format();
                    }
                }
                // await this.getSlots()
                this.calendarModal = true;
            }
        },
        closeModal() {
            this.calendarModal = !this.calendarModal;
            this.selected_date = "";
        },
        reportIt() {
            if (!this.authenticated) {
                GlobalEventBus.$emit("showLoginForm");
            } else {
                this.modal_id = this.user;
                this.reportModal = !this.reportModal;
            }
        },
    },
};
</script>

<style scoped>
body,
html {
    overflow-x: hidden;
}
</style>
