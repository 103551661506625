<template>
    <HeaderComponent :toggleHandler="this.toggler"></HeaderComponent>
    <div class="student dashboard-newtheme-main-bg">
        <div class="hide-sidebar-ontoggle">
            <SidebarComponent :toggleHandler="this.toggler"></SidebarComponent>
        </div>
        <button
            v-if="!isToggle"
            id="mob-left-sidebar-toggler"
            v-on:click="isToggle = !isToggle"
        >
            <i class="fa fa-chevron-right"></i>
        </button>
        <div v-if="this.isToggle" class="show-sidebar-ontoggle">
            <SidebarComponent :toggleHandler="this.toggler"></SidebarComponent>
        </div>
        <div class="home_content dashboard-newtheme student_dashboard_main">
            <div class="courses_parent_width student-dashboard student-courses-newtheme">
                <router-view></router-view>
            </div>
        </div>
        <ChatUserListComponent></ChatUserListComponent>
    </div>
    <FooterComponent></FooterComponent>
</template>

<script>
import SidebarComponent from "@/components/student/layout/SidebarComponent";
import HeaderComponent from "@/components/frontend/includes/header/HeaderComponent";
import ChatUserListComponent from "@/components/frontend/includes/chat/ChatUserListComponent";
import FooterComponent from "@/components/frontend/includes/footer/FooterComponent.vue";

export default {
    name: "App",
    components: {
      FooterComponent,
        SidebarComponent,
        HeaderComponent,
        ChatUserListComponent,
    },
    data() {
        return {
            isToggle: false
        };
    },
    methods: {
        toggler() {
            this.isToggle = !this.isToggle;
        },
    },
};
</script>

<style scoped>
nav#frontend-head {
    height: 50px;
}

.box-drop {
    position: absolute;
    width: 100px;
    height: 40px;
    top: 14px;
    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.37);
    border-radius: 5px;
}

#mob-left-sidebar-toggler {
    display: none;
}

@media (max-width: 576px) {
    #mob-left-sidebar-toggler {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 50%;
        left: 0;
        z-index: 999;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border: none;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.7);
        width: 20px;
        height: 60px;
    }
}
</style>
