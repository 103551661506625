<template>
    <div style="height: 100%">
        <LoadingComponent :loading="loading"></LoadingComponent>
        <div v-if="!loading" class="h-100">
            <div
                v-if="profileUnderReview"
                class="d-flex align-items-center justify-content-center h-100"
            >
                <h1 class="fw-bold">Profile Under Review</h1>
            </div>
            <div
                v-if="profileCancel"
                class="d-flex align-items-center justify-content-center h-100"
            >
                <div>
                    <h1>After reviewing your application, We're sorry to inform you that we're unable to accept you.</h1>
                    <p>
                        The information you provided during the registration process does not currently meet our profile criteria. We appreciate your time and interest in teaching with us. You're always welcome to apply again in the future.
                    </p>
                </div>
            </div>
            <div
                class="account_setting_section tutor-dashboard"
                v-if="!profileUnderReview && !profileCancel"
            >
                <!-- <h2 class="dashboard-pageheading">Edit Your Profile</h2> -->
                <div class="row m-0">
                    <div
                        class="col-12 padding-left-desktop"
                        id="tutor-settingTabs"
                    >
                        <div class="">
                            <div
                                class="nav nav-pills d-flex align-items-center justify-content-center flex-row"
                                id="v-pills-tab"
                                role="tablist"
                                aria-orientation="vertical"
                            >
                                <span
                                    v-on:click="showTab = 'personal_info'"
                                    class="nav-link cursor-pointer border-bottom py-3 pl-4"
                                    v-bind:class="{
                                        active: showTab == 'personal_info',
                                    }"
                                    id="paypal"
                                    href="#paypalss"
                                    style="
                                        border-radius: 10px 10px 0px 0px !important;
                                        border: 0 !important;
                                    "
                                >
                                    <span>Personal Information</span>
                                </span>
                                <span
                                    v-on:click="showTab = 'experience'"
                                    class="nav-link cursor-pointer border-bottom py-3 pl-4"
                                    id="experiences_tab"
                                    href="#experiences"
                                    v-bind:class="{
                                        active: showTab == 'experience',
                                    }"
                                    style="
                                        border-radius: 10px 10px 0px 0px !important;
                                        border: 0 !important;
                                    "
                                >
                                    <span>Experience</span>
                                </span>
                                <span
                                    v-on:click="showTab = 'qualification'"
                                    class="nav-link cursor-pointer border-bottom py-3 pl-4"
                                    id="qualification_tab"
                                    href="#qualifications"
                                    v-bind:class="{
                                        active: showTab == 'qualification',
                                    }"
                                    style="
                                        border-radius: 10px 10px 0px 0px !important;
                                        border: 0 !important;
                                    "
                                >
                                    <span>Qualification</span>
                                </span>
                                <span
                                    v-on:click="showTab = 'reference'"
                                    class="nav-link cursor-pointer border-bottom py-3 pl-4"
                                    id="reference_tab"
                                    v-bind:class="{
                                        active: showTab == 'reference',
                                    }"
                                    href="#reference"
                                    style="
                                        border-radius: 10px 10px 0px 0px !important;
                                        border: 0 !important;
                                    "
                                >
                                    <span>Certifications</span>
                                </span>
                                <span
                                    v-on:click="showTab = 'account_details'"
                                    v-bind:class="{
                                        active: showTab === 'account_details',
                                    }"
                                    class="nav-link cursor-pointer border-bottom py-3 pl-4"
                                    id="billingdetails_tab"
                                    data-toggle="pill"
                                    href="#accountDetail"
                                    role="tab"
                                    aria-controls="accountDetail"
                                    aria-selected="false"
                                    style="
                                        border-radius: 10px 10px 0px 0px !important;
                                        border: 0 !important;
                                    "
                                >
                                    <span>Billing Account Details</span>
                                </span>
                                <span
                                    v-on:click="showTab ='reset_password'"
                                    v-bind:class="{
                                        active: showTab == 'reset_password',
                                    }"
                                    class="nav-link cursor-pointer border-bottom py-3 pl-4"
                                    id="offlinePayment"
                                    data-toggle="pill"
                                    href="#rest-password"
                                    role="tab"
                                    aria-controls="rest-password"
                                    aria-selected="false"
                                    style="
                                        border-radius: 10px 10px 0px 0px !important;
                                        border: 0 !important;
                                    "
                                >
                                    <span>Reset Password</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 mb-4">
                    <div class="tabs_2">
                        <PersonalInfo
                            v-if="showTab === 'personal_info'"
                        ></PersonalInfo>

                        <Experience
                            v-if="showTab === 'experience'"
                        ></Experience>

                        <Qualification
                            v-if="showTab === 'qualification'"
                        ></Qualification>

                        <Reference v-if="showTab === 'reference'">

                        </Reference>

                        <BillingAccountDetail
                            v-if="showTab === 'account_details'"
                        ></BillingAccountDetail>

                        <PasswordResetComponent
                            v-if="showTab === 'reset_password'"
                        ></PasswordResetComponent>

                        <DocumentSectionComponent
                            v-if="showTab === 'get_verified'"
                        ></DocumentSectionComponent>

                        <!--                            <wallet-detail/>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Experience from "@/components/tutor/profile/components/ExperienceComponent.vue";
import PersonalInfo from "@/components/tutor/profile/components/PersonalInfo.vue";
import Qualification from "@/components/tutor/profile/components/Qualification.vue";
import Reference from "@/components/tutor/profile/components/Reference.vue";
import BillingAccountDetail from "@/components/tutor/profile/components/BillingAccountDetail.vue";
import PasswordResetComponent from "@/components/tutor/profile/components/PasswordResetComponent";
import DocumentSectionComponent from "@/components/tutor/profile/components/DocumentSectionComponent";
import LoadingComponent from "@/components/utilities/loading/loader.vue";
import moment from "moment-timezone";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";
import {useUserStore} from "@/store/store";


export default {
    name: "UserProfileSetting",
    props: [],
    components: {
        LoadingComponent,
        PasswordResetComponent,
        Experience,
        PersonalInfo,
        Qualification,
        Reference,
        BillingAccountDetail,
        DocumentSectionComponent,
    },
    directives: {},
    data() {
        return {
            loading: true,
            finishBtnLoading: false,
            reject_user_msgs: [],
            profile_status: "",
            profileUnderReview: false,
            profileCancel: false,
            showTab: "personal_info",
        };
    },
    computed: {},
    async created() {
        this.loading = true;
        await this.checkProfileStatus();
        this.loading = false;
    },
    methods: {
        async checkProfileStatus() {
            let self = this;
            let query = {};

            const userStore = useUserStore();

            query.role = userStore.currentUser.role;
            const { data } = await axiosInstance.get(endpoints.InfoProfileStatusRoute, {params: query});
            self.profile_status = data.status;
            if (data.type === "error") {
                userStore.setProfileStatus("incomplete");
                data.data.forEach(function (error) {
                    toast.error(error).goAway(2000);
                });
            } else {
                this.reject_user_msgs = JSON.parse(
                    data?.reject_user_msgs || "[]"
                );
                if (data.status == "changes-requested") {
                    userStore.setProfileStatus("changes-requested");
                } else {
                    if (data.status == "under-review") {
                        self.profileUnderReview = true;
                        toast.success("Your profile is currently under review, we will notify you when the review is complete.")
                            .goAway(2000);
                        userStore.setProfileStatus("under-review");
                    }
                    else if (data.status == "cancel") {
                        self.profileCancel = true;
                        toast.success("Your profile is Cancelled.")
                            .goAway(2000);
                       userStore.setProfileStatus("cancelled");
                    }
                    else {
                      userStore.setProfileStatus("complete");
                    }
                }
            }
        },
        async updatePassword() {
            let formData = new FormData();
            let pro = this.info;
            formData.append("email", pro.email);
            formData.append("current_password", pro.current_password);
            formData.append("password", pro.password);
            formData.append("password_confirmation", pro.confirm_password);
            const { data } = await axiosInstance.post(endpoints.updatePassword, formData);
            if (data.type === "success") {
                toast.success(data.message);
            } else {
                let message = "";
                if (data.message.password) {
                    message = data.message.password[0];
                } else {
                    message = data.message;
                }
                toast.success(message);
            }
        },
        nextTab() {
            if (this.showTab === "personal_info") {
                this.showTab = "personal_info";
            } else if (this.showTab === "experience") {
                this.showTab = "experience";
            } else if (this.showTab === "qualification") {
                this.showTab = "qualification";
            } else if (this.showTab === "reference") {
              this.showTab = "reference";
            } else if (this.showTab === "account_details") {
              this.showTab = "account_details";
            } else if (this.showTab === "reset_password") {
              this.showTab = "reset_password";
            }
        },
        async finishProfileSetup() {
            if (this.profile_status == "complete") {
                this.showTab = "personal_info";
            } else {
                this.finishBtnLoading = true;
                this.reject_user_msgs?.push({
                    message: "Profile Submitted for approval.",
                    from: `Tutor`,
                    created_at: moment().unix(),
                });
                let formData = new FormData();
                formData.append("profile_status", "under-review");
                formData.append("reject_user_msgs", JSON.stringify(this.reject_user_msgs)
                );
                await axiosInstance.post(endpoints.InfoProfileStatusRoute, formData);
                await this.checkProfileStatus();
                this.finishBtnLoading = false;
                this.showTab = "personal_info";
            }
        },
    },
};
</script>
<style scoped>
button {
    display: flex;
    align-items: center;
    justify-content: center;
}
.tabs_1 {
    padding-bottom: 0 !important;
}
.profile-uploaded-img,
.change-img {
    width: 12vw !important;
    height: 12vw !important;
}
.home_content.dashboard-newtheme .tabs_2 .row-upload-img {
    justify-content: left !important;
}
.profile-edit-image {
    right: 5px !important;
    top: 10px !important;
}
.home_content.dashboard-newtheme
    .tabs_2
    form
    .form-group
    .radio-btns
    .form-check.form-check-inline {
    margin-right: 10px !important;
}
</style>
