<template>
  <div class="card rounded shadow ">
    <div class="card-body">
      <div class="testimonial-name-img">
        <div class="testimonial-image">
          <img width="60px" height="60px" class="mr-2 rounded" :src="'/storage'+data.user.image" :alt="data.user.name">
        </div>
        <h4 class="card-title">
          <div class=" name">
            <p>{{ data.user.name }}</p>
            <div class="text-left">
              <!-- eslint-disable -->
              <star-rating :show-rating="false" :read-only="true" v-model="data.rating" :increment="0.25" v-bind:star-size="20"></star-rating>

            </div>
          </div>
        </h4>
      </div>
      <p class="card-text text_card">
        {{data.detail}}
      </p>

    </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'

export default {
  name: "TestimonialCardComponent",
  components: {StarRating},
  props: ['data'],
}
</script>

<style scoped>

</style>
