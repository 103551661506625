<template>
    <div class="col-lg-3 pr-lg-5 job-listing-filter">
      <form class="form-style-01">
        <div class="form-group">
          <label class="text-uppercase">SORT BY</label>
          <select id="sort_by" class="form-control" v-model="$parent.query.order_by" v-on:change="$parent.getJobPost()">
            <option value="">Any Time</option>
            <option value="past_month">Past Month</option>
            <option value="past_week">Past Week</option>
            <option value="past_24_hours">Past 24 Hours</option>
          </select>
        </div>
        <div class="form-group">
          <!-- eslint-disable -->
          <label class="text-uppercase">Subjects</label>
          <select id="subject" class="form-control" v-model="$parent.query.subject_id" v-on:change="$parent.getJobPost()">
            <option value="0" selected >Choose Subject</option>
            <option v-for="subject in $parent.subjects" :value="subject.id">{{ subject.subject }}</option>
          </select>
        </div>
        <div class="form-group">
          <label class="text-uppercase">Levels</label>
          <select id="level" class="form-control" v-model="$parent.query.level" v-on:change="$parent.getJobPost()">
            <option value="0">Choose Course Level</option>
            <option value="beginner">Beginner</option>
            <option value="advanced">Advanced</option>
            <option value="intermediate">Intermediate</option>
          </select>
        </div>
        <div class="form-group reset-container">
          <div class="mb-1 switch-filter">
           <span class="switch-label">Hide jobs applied for</span>
            <label class="switch">
              <input v-on:change="$parent.getJobPost()" type="checkbox" id="hide_applied" value="1" v-model="$parent.query.hide_applied">
              <span class="slider round"></span>
            </label>
          </div>

        </div>

        <hr>
        <div v-if="filterCheck()" class="form-group">
          <button v-on:click="resetFilters()" href="javascript:void (0)" class="reset-btn mt-0">Reset All</button>
        </div>
      </form>
    </div>
</template>

<script>
export default {
  name: "FilterSectionComponent",
  methods: {
    resetFilters() {
        this.$parent.query.sort_by = 'DESC',
        this.$parent.query.subject_id = 0,
        this.$parent.query.level = 0,
        this.$parent.query.hide_applied = 0,
        this.$parent.query.verified_only = 0,
      this.$parent.getJobPost()
    },
    filterCheck() {
      return !(
        this.$parent.query.sort_by === 'DESC' &&
        this.$parent.query.subject_id === 0 &&
        this.$parent.query.level === 0 &&
        this.$parent.query.hide_applied === 0 &&
        this.$parent.query.verified_only === 0
      )
    }
  }
}
</script>

<style scoped>

</style>
