<template>
  <div class="tab-pane fade show active " id="transactions" role="tabpanel" aria-labelledby="home-tab">
    <div class="responsive-scroll" v-if="transactions.data?.length > 0">
      <table class="table tables text-center table-style-01">
        <thead class="thead-primary thead-primarys border-0">
        <tr class="th">
          <th>Transaction Id</th>
          <th>Date</th>
          <th>Status</th>
          <th>Type</th>
          <th>Description</th>
          <th>For</th>
          <th>Transaction Method</th>
          <th>Amount</th>
          <th>Balance</th>
          <th>Invoice</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="transaction in transactions.data" :key="transaction.id">
          <td class="border-0">{{ transaction.trx_id }}</td>
          <td class="border-0">{{ transaction.created_at }}</td>
          <td class="border-0">{{ transaction.status }}</td>
          <td class="border-0">{{ transaction.type }}</td>
          <td class="border-0">{{ transaction.details }}</td>
          <td class="border-0">{{ transaction.for }}</td>
          <td class="border-0">{{ transaction.method }}</td>
          <td class="border-0">{{ transaction.amount }} PKR</td>
          <td class="border-0">{{ transaction.balance }} PKR</td>
          <td class="border-0">
            <a href="javascript:void(0)" v-on:click="showReceipt(transaction.id)">
              <img src="@/assets/new-theme-images/blue-pdf.png" />
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-if="transactions.data.length > 0" class="row mx-4">
      <div class="col-12 col-sm-7">
        <div class="text-xl-right text-lg-right text-md-right pt-2">
          <a v-if="transactions.total > transactions.data.length" href="javascript:void(0)" v-on:click="$parent.getTransactionShowMore()" class="show_more"> Show more</a>
        </div>
      </div>
    </div>
    <div v-if="no_record">
      <p class="p-2">{{"No Record Found"}}</p>
    </div>
    </div>
</template>
<script>

export default {
  name: "AllTransactionComponent",
  props: ['transactions', 'no_record'],
  created() {
  },
  methods: {
    showReceipt(id) {
        console.log(this.transactions.data.find(t => t.id === id));
      this.$parent.selectedTransaction = this.transactions.data.find(t => t.id === id)
      this.$parent.transactionModal = !this.$parent.transactionModal
    }
  }
}
</script>

<style scoped>

</style>
