<template>
    <div class="text-center">
        <div class="col-md-12" v-if="choosedView === 'calendar'">
            <div class="calendar-variants">
                <div class="float-right text-lg font-medium text-gray-600 mb-6">
                    <div
                        class="btn-group pull-right"
                        role="group"
                        aria-label="..."
                    >
                        <a
                            v-on:click="choosedView = 'calendar'"
                            href="javascript:void(0)"
                            class="btn btn-primary pjTsViewTab"
                            :class="choosedView === 'calendar' ? 'active' : ''"
                            title="monthly"
                            data-layout="1"
                            ><i class="fa fa-calendar" aria-hidden="true"></i
                        ></a>
                        <a
                            v-on:click="choosedView = 'week'"
                            href="javascript:void(0)"
                            class="btn btn-primary pjTsViewTab"
                            :class="choosedView === 'week' ? 'active' : ''"
                            title="weekly"
                            data-layout="2"
                            ><i class="fa fa-th" aria-hidden="true"></i
                        ></a>
                    </div>
                </div>
            </div>

            <v-calendar
                class="custom_calendar"
                :mode="mode"
                v-model="dates"
                ref="calendar"
                :attributes="attributes"
                trim-weeks
                is-expanded
                :min-date="dates"
                @update:from-page="monthChange"
            >
              <!-- eslint-disable -->
                <template slot="month-content" slot-scope="props">
                    <span>{{ props }}</span>
                </template>
                <template slot="day-content" slot-scope="props">
                    <div
                        class="day-cell"
                        v-if="props.day.inMonth"
                        style="min-height: 100px; width: 150px"
                    >
                        <div
                            class="bg-success"
                            v-if="checkSlot(props.day) === 'available'"
                            v-tooltip="{
                                content: toolTipContent(props.day),
                                loadingContent: '<i>Loading...</i>',
                                placement: 'top',
                                classes: ['info'],
                                targetClasses: ['it-has-a-tooltip'],
                            }"
                        >
                            <span
                                v-on:click="showDaySlots(props.day)"
                                class="event-day-date"
                                >{{ props.day.day }}</span
                            >
                        </div>
                        <div
                            class="bg-c-yellow"
                            v-else-if="
                                checkSlot(props.day) === 'partially-booked'
                            "
                            v-tooltip="{
                                content: toolTipContent(props.day),
                                loadingContent: '<i>Loading...</i>',
                                placement: 'top',
                                classes: ['info'],
                                targetClasses: ['it-has-a-tooltip'],
                            }"
                        >
                            <span class="event-day-date">{{
                                props.day.day
                            }}</span>
                        </div>
                        <div
                            class="bg-danger"
                            v-else-if="checkSlot(props.day) === 'booked'"
                            v-tooltip="{
                                content: toolTipContent('booked'),
                                loadingContent: '<i>Loading...</i>',
                                placement: 'top',
                                classes: ['info'],
                                targetClasses: ['it-has-a-tooltip'],
                            }"
                        >
                            <span class="event-day-date">{{
                                props.day.day
                            }}</span>
                        </div>
                        <div
                            class="bg-lightgray"
                            v-else-if="checkSlot(props.day) === 'past'"
                        >
                            <span
                                disabled="disabled"
                                class="event-day-date"
                                style="color: #414141"
                                >{{ props.day.day }}</span
                            >
                        </div>
                        <div class="bg-danger" v-else>
                            <span class="event-day-date">{{
                                props.day.day
                            }}</span>
                        </div>
                    </div>
                </template>
            </v-calendar>
            <div class="row p-0 m-0 my-2">
                <span class="cal-badge-available-green"></span>
                <span class="px-2">Available</span>
                <span class="cal-badge-unavailable"></span>
                <span class="px-2">Unavailable</span>
            </div>
        </div>

        <div
            class="row monthly-calendar-variant-top-container"
            v-if="choosedView === 'day'"
        >
            <div class="col-md-12">
                <div class="monthly-calendar-variant-top">
                    <div
                        class="date-text text-lg font-medium text-gray-600 mb-6"
                    >
                        {{ selected_date | DDMMMYYYYNONTIMEZONE }}
                    </div>
                    <div
                        class="calendar-icons float-right text-lg font-medium text-gray-600 mb-6"
                    >
                        <div
                            class="btn-group pull-right"
                            role="group"
                            aria-label="..."
                        >
                            <a
                                v-on:click="choosedView = 'calendar'"
                                href="javascript:void(0)"
                                class="btn btn-primary pjTsViewTab active"
                                title="monthly"
                                data-layout="1"
                                ><i
                                    class="fa fa-calendar"
                                    aria-hidden="true"
                                ></i
                            ></a>
                            <a
                                v-on:click="choosedView = 'week'"
                                href="javascript:void(0)"
                                class="btn btn-primary pjTsViewTab"
                                title="weekly"
                                data-layout="2"
                                ><i class="fa fa-th" aria-hidden="true"></i
                            ></a>
                        </div>
                    </div>
                </div>
                <div class="table">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Start Time</th>
                                <th scope="col">End Time</th>
                                <th scope="col">Availability</th>
                                <th scope="col" class="right-border-none">
                                    Price
                                </th>
                                <th scope="col" class="left-border-none"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(slot, index) in daySlots">
                                <td>{{ slot.start_date_time | hhmmA }}</td>
                                <td>{{ slot.end_date_time | hhmmA }}</td>
                                <td>Available</td>
                                <td class="right-border-none">
                                    {{ slot.price }} {{ $t("text.pkr") }}
                                    <label
                                        style="margin: 0"
                                        class="checkmark-style-02"
                                        :style="
                                            checkSpaceSelected_(slot)
                                                ? 'fill: #00AF40;'
                                                : ''
                                        "
                                        :for="'space-' + index"
                                    >
                                        <input
                                            :disabled="
                                                checkPurchasedSlots(
                                                    selected_date,
                                                    timeFormatA(
                                                        slot.start_date_time
                                                    )
                                                )
                                            "
                                            class="d-none"
                                            type="checkbox"
                                            :checked="checkSpaceSelected_(slot)"
                                            v-on:click="checkSlotTime(slot)"
                                            value="1"
                                            :id="'space-' + index"
                                        />
                                        <span
                                            class="checkmark-icon-style-02"
                                            :style="
                                                checkSpaceSelected_(slot)
                                                    ? 'display:block'
                                                    : 'display:none'
                                            "
                                            :for="'space-' + index"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24.914"
                                                height="24.914"
                                                viewBox="0 0 24.914 24.914"
                                            >
                                                <path
                                                    id="Icon_ionic-md-checkbox-outline"
                                                    data-name="Icon ionic-md-checkbox-outline"
                                                    d="M11.282,14.327,9.344,16.265l6.228,6.228L29.414,8.652,27.476,6.715l-11.9,11.834ZM26.645,26.645H7.268V7.268H21.109V4.5H7.268A2.776,2.776,0,0,0,4.5,7.268V26.645a2.776,2.776,0,0,0,2.768,2.768H26.645a2.776,2.776,0,0,0,2.768-2.768V15.573H26.645Z"
                                                    transform="translate(-4.5 -4.5)"
                                                    opacity="0.3"
                                                />
                                            </svg>
                                        </span>
                                        <span class="checkmark-icon-style-02">
                                            <img
                                                width="25px"
                                                height="25px"
                                                :style="
                                                    checkSpaceSelected_(slot)
                                                        ? 'display:none'
                                                        : ''
                                                "
                                                src="@/assets/images/calendar-checkbox-image.png"
                                            />
                                        </span>
                                    </label>
                                </td>
                                <td class="left-border-none">
                                    <span
                                        v-if="
                                            checkedBooked(
                                                slot.start_date_time,
                                                slot.end_date_time
                                            ).length > 0
                                        "
                                    >
                                        <i
                                            v-for="booked in checkedBooked(
                                                slot.start_date_time,
                                                slot.end_date_time
                                            )"
                                            :title="
                                                'Session Booked with ' +
                                                booked.slot.tutor.name +
                                                ' at ' +
                                                timeFormathhmm(
                                                    booked.slot.start_date_time
                                                ) +
                                                ' - ' +
                                                timeFormathhmm(
                                                    booked.slot.end_date_time
                                                )
                                            "
                                            class="fas fa-exclamation-triangle text-yellow"
                                        ></i>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        v-if="
                            $parent.userDetail.user.active_role ===
                                'guardian' && dependents.length > 0
                        "
                        class="pb-2"
                    >
                        <h4>Choose Dependent</h4>
                        <select
                            class="form-control"
                            name="dependent"
                            id="dependent"
                            v-model="dependent"
                        >
                            <option :value="0">Self</option>
                            <option
                                :value="dependent.id"
                                v-for="dependent in dependents"
                            >
                                {{ dependent.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12" v-if="selectedSlots.length > 0">
                    <div class="float-right">
                        <span>Total: </span
                        ><span>{{ price }} {{ $t("text.pkr") }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="slot_id > 0">
            <div class="col-md-12">
                <h4
                    class="font-weight-bold mt-4 mb-3 text-danger"
                    style="color: #dc3545 !important"
                >
                    Scheduled Slot
                </h4>
                <div class="table">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Start Time</th>
                                <th scope="col">End Time</th>
                                <th scope="col">Availability</th>
                                <th scope="col">Price</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="row">
                                    {{
                                        $parent.selected_slot.start_date_time
                                            | DDMMMYYYY
                                    }}
                                </td>
                                <td>
                                    {{
                                        $parent.selected_slot.start_date_time
                                            | hhmmA
                                    }}
                                </td>
                                <td>
                                    {{
                                        $parent.selected_slot.end_date_time
                                            | hhmmA
                                    }}
                                </td>
                                <td>Available</td>
                                <td>
                                    {{ $parent.selected_slot.price }}
                                    {{ $t("text.pkr") }}
                                </td>
                                <td>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div
            class="row second-monthly-calendar-container-parent"
            v-else-if="choosedView === 'week'"
        >
            <div class="col-md-12 second-monthly-calendar-container">
                <div class="calendar-variants new-monthly-calendar">
                    <div
                        class="float-right text-lg font-medium text-gray-600 mb-5"
                    >
                        <div
                            class="btn-group float-right"
                            role="group"
                            aria-label="..."
                        >
                            <a
                                v-on:click="choosedView = 'calendar'"
                                href="javascript:void(0)"
                                class="btn btn-primary pjTsViewTab active"
                                title="monthly"
                                data-layout="1"
                                ><i
                                    class="fa fa-calendar"
                                    aria-hidden="true"
                                ></i
                            ></a>
                            <a
                                v-on:click="choosedView = 'week'"
                                href="javascript:void(0)"
                                class="btn btn-primary pjTsViewTab"
                                title="weekly"
                                data-layout="2"
                                ><i class="fa fa-th" aria-hidden="true"></i
                            ></a>
                        </div>
                    </div>
                </div>

                <div
                    class="float-left text-lg font-medium text-gray-600 second-monthly-calendar"
                >
                    <a
                        href="javascript:void (0)"
                        v-on:click="decrementDateByWeek()"
                    >
                        <i class="fas fa-less-than"></i>
                    </a>
                    <!--          <input type="date" name="start_date" v-model="start_date">-->
                    <div class="start-end-date">
                        <div>{{ start_date }}</div>
                        to
                        <!--          <input type="date" name="end_date" v-model="end_date">-->
                        <div>{{ end_date }}</div>
                    </div>
                    <a
                        href="javascript:void (0)"
                        v-on:click="incrementDateByWeek()"
                    >
                        <i class="fas fa-greater-than"></i>
                    </a>
                </div>
            </div>
            <div class="col-md-12">
                <div class="">
                    <div class="pjTsWeeklyViewBody">
                        <div class="pjTsWeeklyViewBodyInner">
                            <div class="pj-calendar-head pj-calendar-head2">
                                <div class="pj-calendar-day-header">
                                    <p>&nbsp;</p>
                                </div>

                                <div
                                    v-for="week in weekdays"
                                    class="pj-calendar-day-header"
                                >
                                    <p>{{ week | ddd }}</p>
                                </div>
                            </div>

                            <div class="pj-calendar-body">
                                <form>
                                    <div class="pj-calendar-column">
                                        <div
                                            v-for="hour in hours"
                                            class="pj-calendar-cell"
                                        >
                                            <p class="pjTsWeeklyTime oye">
                                                {{ hour }}
                                            </p>
                                        </div>
                                    </div>

                                    <div
                                        v-for="(day, i) in weekdays"
                                        class="pj-calendar-column border"
                                    >
                                        <div
                                            v-for="(hour, index) in hours"
                                            class="pj-calendar-cell border"
                                        >
                                            <div v-if="checkDaySlot(day, hour)">
                                                <label
                                                    style="margin: 0"
                                                    class="checkmark-style-02"
                                                    :style="
                                                        checkSpaceSelected(
                                                            day,
                                                            hour
                                                        )
                                                            ? 'fill: #00AF40;'
                                                            : ''
                                                    "
                                                    :for="
                                                        'space-' +
                                                        i +
                                                        '-' +
                                                        index
                                                    "
                                                >
                                                    <input
                                                        :disabled="
                                                            checkPurchasedSlots(
                                                                day,
                                                                hour
                                                            )
                                                        "
                                                        type="checkbox"
                                                        :checked="
                                                            checkSpaceSelected(
                                                                day,
                                                                hour
                                                            )
                                                        "
                                                        v-on:click="
                                                            addSelectedSlots(
                                                                day,
                                                                hour
                                                            )
                                                        "
                                                        :value="day"
                                                        :id="
                                                            'space-' +
                                                            i +
                                                            '-' +
                                                            index
                                                        "
                                                    />
                                                    <span
                                                        class="checkmark-icon-style-02"
                                                        :style="
                                                            checkSpaceSelected(
                                                                day,
                                                                hour
                                                            )
                                                                ? ''
                                                                : 'display:none'
                                                        "
                                                        :for="
                                                            'space-' +
                                                            i +
                                                            '-' +
                                                            index
                                                        "
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24.914"
                                                            height="24.914"
                                                            viewBox="0 0 24.914 24.914"
                                                        >
                                                            <path
                                                                id="Icon_ionic-md-checkbox-outline"
                                                                data-name="Icon ionic-md-checkbox-outline"
                                                                d="M11.282,14.327,9.344,16.265l6.228,6.228L29.414,8.652,27.476,6.715l-11.9,11.834ZM26.645,26.645H7.268V7.268H21.109V4.5H7.268A2.776,2.776,0,0,0,4.5,7.268V26.645a2.776,2.776,0,0,0,2.768,2.768H26.645a2.776,2.776,0,0,0,2.768-2.768V15.573H26.645Z"
                                                                transform="translate(-4.5 -4.5)"
                                                                opacity="0.3"
                                                            />
                                                        </svg>
                                                    </span>
                                                    <span
                                                        class="checkmark-icon-style-02"
                                                    >
                                                        <img
                                                            v-on:click="
                                                                addSelectedSlots(
                                                                    day,
                                                                    hour
                                                                )
                                                            "
                                                            width="25px"
                                                            height="25px"
                                                            :style="
                                                                checkSpaceSelected(
                                                                    day,
                                                                    hour
                                                                )
                                                                    ? 'display:none'
                                                                    : ''
                                                            "
                                                            src="@/assets/images/calendar-checkbox-image.png"
                                                        />
                                                    </span>
                                                </label>
                                            </div>
                                            <p v-else>-</p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="selected_space.length > 0">
            <div class="col-md-12">
                <h4 class="font-weight-bold mt-4 mb-3">
                    Selected Slots Details
                </h4>
                <div class="table">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Start Time</th>
                                <th scope="col">End Time</th>
                                <th scope="col">Availability</th>
                                <th scope="col">Price</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(slot, index) in selected_space">
                                <td scope="row">
                                    {{ slot.slot.start_date_time | DDMMMYYYY }}
                                </td>
                                <td>{{ slot.slot.start_date_time | hhmmA }}</td>
                                <td>{{ slot.slot.end_date_time | hhmmA }}</td>
                                <td>Available</td>
                                <td>{{ slot.price }} {{ $t("text.pkr") }}</td>
                                <td>
                                    <!--                <a href="javascript:void (0)" v-on:click="checkSlotTime(slot.slot)"><i class="fa fa-trash"></i></a>-->
                                    <a
                                        href="javascript:void (0)"
                                        v-on:click="
                                            removeSlotFromCart(slot.slot)
                                        "
                                        ><i class="fa fa-trash"></i
                                    ></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="col-md-12">
                        <div v-if="slot_id > 0" class="float-right">
                            <table id="checkout-table">
                                <tr>
                                    <td></td>
                                    <td><b>Scheduled Slot Price</b></td>
                                    <td><b>:</b></td>
                                    <td>
                                        {{ $parent.selected_slot.price }}
                                        {{ $t("text.pkr") }}
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><b>Selected Slot Price</b></td>
                                    <td><b>:</b></td>
                                    <td>
                                        {{ parseInt(total_price) }}
                                        {{ $t("text.pkr") }}
                                    </td>
                                </tr>
                                <tr
                                    v-if="
                                        price - $parent.selected_slot.price > 0
                                    "
                                >
                                    <td></td>
                                    <td><b>Payable Amount </b></td>
                                    <td><b>:</b></td>
                                    <td>
                                        {{
                                            price - $parent.selected_slot.price
                                        }}
                                        {{ $t("text.pkr") }}
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td></td>
                                    <td><b>Remaining Amount </b></td>
                                    <td><b>:</b></td>
                                    <td>
                                        {{
                                            total_price -
                                            $parent.selected_slot.price
                                        }}
                                        {{ $t("text.pkr") }}
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <a
                                            v-if="selected_space.length > 0"
                                            href="javascript:void(0)"
                                            v-on:click="checkout()"
                                            class="btn btn-primary calendar-checkout-btn"
                                        >
                                            Checkout
                                        </a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div v-else class="float-right">
                            <div class="calendar-checkout-total pb-3">
                                Total: {{ total_price }} {{ $t("text.pkr") }}
                            </div>
                        </div>
                    </div>
                    <div v-if="slot_id === 0" class="float-right">
                        <a
                            v-if="selected_space.length > 0"
                            href="javascript:void(0)"
                            v-on:click="checkout()"
                            class="btn btn-primary calendar-checkout-btn"
                        >
                            Checkout
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="calendarDetailModal"
            class="modal bd-example-modal-lg"
            id="addreview"
            style="
                display: block;
                background-color: rgba(0, 0, 0, 0.4);
                overflow: auto;
            "
            tabindex="-1"
            role="dialog"
        >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Tutor Slots {{ selectedDate | DDMMMYYYY }}
                        </h5>
                        <button
                            v-on:click="
                                calendarDetailModal = !calendarDetailModal
                            "
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Tutor</th>
                                        <th>Price</th>
                                        <th>Time</th>
                                        <!--              <th>Total Seats</th>-->
                                        <th>Available</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="space in spaces">
                                        <th scope="row">{{ space.id }}</th>
                                        <td>{{ space.tutor.name }}</td>
                                        <td>
                                            {{ space.price }}
                                            {{ $t("text.pkr") }}
                                        </td>
                                        <td>
                                            {{
                                                space.start_date_time | hhmmA
                                            }}
                                            - {{ space.end_date_time | hhmmA }}
                                        </td>
                                        <td>
                                            {{
                                                space.number_of_students >
                                                space.booked_seats
                                            }}
                                        </td>
                                        <td>
                                            <input
                                                :id="'slot-' + space.id"
                                                v-on:click="
                                                    addSelectedSlots(space)
                                                "
                                                type="checkbox"
                                                class="form-check"
                                                value="1"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import { GlobalEventBus } from "@/assets/js/eventBus";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

const moment = require("moment");

export default {
    props: ["tutor_id", "dependents", "slot_id", "dependent_id"],
    components: {
    },
    data() {
        return {
            slots: [],
            schedule: [],
            purchasedSlots: [],
            alreadyBooked: [],
            paymentModalView: "detail",
            payment_method: "foreepay",
            choosedView: "calendar",
            days: moment.weekdays(),
            hours: [],
            selected_space: [],
            daySlots: [],
            spaces: [],
            visibility: true,
            calendarDetailModal: false,
            paymentModal: false,
            showCalendar: true,
            selectedDate: new Date(),
            selected_date: "",
            weekdays: "",
            start_date: "",
            end_date: "",
            startOfWeek: "",
            endOfWeek: "",
            dependent: this.dependent_id,
            selectedSlots: [],
            price: 0.0,
            total_price: 0.0,
            mode: "date",
            dates: new Date(),
            attributes: [
                {
                    key: "today",
                    highlight: true,
                    dates: new Date(),
                },
            ],
            query: {},
        };
    },
    watch: {
        selected_space() {
            if (
                this.$parent.type === "reschedule" &&
                this.selected_space.length > 1
            ) {
                this.selected_space.shift();
            }
        },
    },
    created() {
        let self = this;
        if (self.$parent.selected_date !== "") {
            this.startOfWeek = moment(self.$parent.selected_date).startOf(
                "week"
            );
            this.endOfWeek = moment(self.$parent.selected_date).endOf("week");
            self.dates = self.$parent.selected_date;
        } else {
            this.startOfWeek = moment().startOf("week");
            this.endOfWeek = moment().endOf("week");
        }
        
        this.hoursListing();
        this.weekListing(this.startOfWeek, this.endOfWeek);
    },
    mounted() {
        let self = this;
        this.getTotalAmount();
        if (localStorage.getItem("detail")) {
            let detail = JSON.parse(localStorage.getItem("detail"));
            this.purchasedSlots = detail.bookedSlots;
        }
        if (localStorage.getItem("cart")) {
            let cart = JSON.parse(localStorage.getItem("cart"));
            self.selected_space = cart.slots ?? [];
            self.total_price = cart.total ?? 0.0;
        }
    },
    methods: {
        async getSlot(month) {
            let query = {};
            query.id = this.tutor_id;
            query.month = month;
            const { data } = await axiosInstance.get(endpoints.getSlotRoute, {params: query});
            this.slots = data.data;
            this.booked = data.booked;
            this.schedule = data.schedule;
            this.calendarModal = true;
        },
        checkSpaceSelected(date, time) {
            let index = this.schedule.findIndex(
                (slot) =>
                    moment(slot.start_date_time).format(
                        "YYYY-MM-DD hh:mm A"
                    ) ===
                    moment(
                        moment(date).format("YYYY-MM-DD") + " " + time
                    ).format("YYYY-MM-DD hh:mm A")
            );
            if (index > -1) {
                return (
                    this.selected_space.findIndex(
                        (space) =>
                            parseInt(space.model_id) ===
                            parseInt(this.schedule[index].id)
                    ) > -1
                );
            }
        },
        checkSpaceSelected_(slot) {
            let index = this.slots.findIndex((s) => s.id === slot.id);
            if (index > -1) {
                return (
                    this.selected_space.findIndex(
                        (space) => space.model_id === this.slots[index].id
                    ) > -1
                );
            }
        },
        async getUserDetail() {
            const { data } = await axiosInstance.get(endpoints.getUserDetails);
            this.purchasedSlots = data.bookedSlots;
            this.getTotalAmount();
        },
        checkPurchasedSlots(date, time) {
            let index = this.slots.findIndex(
                (slot) =>
                    moment(slot.start_date_time).format(
                        "YYYY-MM-DD hh:mm A"
                    ) ===
                    moment(
                        moment(date).format("YYYY-MM-DD") + " " + time
                    ).format("YYYY-MM-DD hh:mm A")
            );
            if (index > -1) {
                return (
                    this.purchasedSlots.findIndex(
                        (space) => space.slot_id === this.slots[index].id
                    ) > -1
                );
            }
        },
        checkDaySlot(date, time) {
            if (
                moment(
                    moment(date).format("YYYY-MM-DD") + " " + time
                ).format() > moment().format()
            )
                return (
                    this.schedule.findIndex(
                        (slot) =>
                            moment(slot.start_date_time).format(
                                "YYYY-MM-DD H"
                            ) ===
                            moment(
                                moment(date).format("YYYY-MM-DD") + " " + time
                            ).format("YYYY-MM-DD H")
                    ) > -1
                );
        },
        checkSlot(data) {
            let self = this;
            let date = moment(data.date).format("YYYY-MM-DD");
            if (!(date >= moment().format("YYYY-MM-DD"))) {
                return "past";
            }
            let filtered = self.slots?.filter(
                (slot) =>
                    moment(slot.start_date_time).format("YYYY-MM-DD") === date
            );
            if (filtered.length === 0) {
                return "off";
            } else {
                let booked = filtered.filter(
                    (slot) => slot.status === "booked"
                );
                let enable = filtered.filter(
                    (slot) => slot.status === "enable"
                );

                if (enable.length > 0) {
                    return "available";
                } else if (booked.length >= enable.length) {
                    return "booked";
                }
            }
        },

        timeFormatA(value) {
            let date = moment(value).format("YYYY-MM-DD");
            let time = moment(value).format("hh:mm");
            if (value) {
                return moment(date + "T" + time).format("hh:mm A");
            }
        },
        checkSlotTime(slot) {
            let index = this.schedule.findIndex((s) => s.id === slot.id);
            if (
                this.selected_space.findIndex(
                    (space) => space.model_id === this.schedule[index].id
                ) < 0 &&
                this.checkedBooked(
                    this.schedule[index].start_date_time,
                    this.schedule[index].end_date_time
                ).length > 0
            ) {
                let s = this.checkedBooked(
                    this.schedule[index].start_date_time,
                    this.schedule[index].end_date_time
                );
                Swal.fire({
                    title: "Are you sure?",
                    text:
                        "Session is already booked with " +
                        s[0].slot.tutor.name +
                        " for the specified time. You must use another time or find another resource.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Add it!",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.updateBookingSlots(slot.id);
                    }
                });
            } else {
                if (
                    this.selected_space.findIndex(
                        (space) => space.model_id === slot.id
                    ) < 0
                )
                    this.updateBookingSlots(slot.id);
                else this.removeFromCart(slot.id);
            }
        },
        removeSlotFromCart(slot) {
            if (
                this.selected_space.findIndex(
                    (space) => space.model_id === slot.id
                ) < 0
            )
                this.updateBookingSlots(slot.id);
            else this.removeFromCart(slot.id);
        },
        addSelectedSlots_(slot) {
            let index = this.slots.findIndex((s) => s.id === slot.id);
            if (this.$parent.userDetail.user.active_role === "guardian") {
                if (index > -1) {
                    let i = this.selected_space.findIndex(
                        (space) =>
                            space.model_id === this.slots[index].id &&
                            space.dependent_id === this.dependent
                    );
                    if (i < 0) {
                        this.selected_space.push({
                            day: slot.day,
                            dependent_id: this.dependent,
                            end_date_time: slot.end_date_time,
                            id: slot.id,
                            notes: slot.notes,
                            number_of_students: slot.number_of_students,
                            price: slot.price,
                            start_date_time: slot.start_date_time,
                            status: slot.status,
                            tutor_id: slot.tutor_id,
                        });
                    } else {
                        this.selected_space.splice(i, 1);
                    }
                }
            } else {
                if (index > -1) {
                    let i = this.selected_space.findIndex(
                        (space) => space.model_id === this.slots[index].id
                    );
                    if (i < 0) {
                        this.selected_space.push(this.slots[index]);
                    } else {
                        this.selected_space.splice(i, 1);
                    }
                }
            }
            this.getTotalAmount();
            this.updateBookingSlots();
        },
        addSelectedSlots(date, time) {
            let index = this.slots.findIndex(
                (slot) =>
                    moment(slot.start_date_time).format(
                        "YYYY-MM-DD hh:mm A"
                    ) ===
                    moment(
                        moment(date).format("YYYY-MM-DD") + " " + time
                    ).format("YYYY-MM-DD hh:mm A")
            );
            if (index > -1) {
                let slot = this.slots.find(
                    (slot) =>
                        moment(slot.start_date_time).format(
                            "YYYY-MM-DD hh:mm A"
                        ) ===
                        moment(
                            moment(date).format("YYYY-MM-DD") + " " + time
                        ).format("YYYY-MM-DD hh:mm A")
                );
                let i = this.selected_space.findIndex(
                    (space) => space.model_id === this.slots[index].id
                );
                if (i < 0) {
                    this.selected_space.push(this.slots[index]);
                    this.getTotalAmount();
                    this.updateBookingSlots(slot.id);
                } else {
                    this.selected_space.splice(i, 1);
                    this.getTotalAmount();
                    this.removeFromCart(slot.id);
                }
            }
        },
        getTotalAmount() {
            let price = 0.0;
            if (this.selected_space.length > 0) {
                this.selected_space.forEach(function (x) {
                    price += x.price;
                });
            }
            this.total_price = this.price = price.toFixed(2);
        },
        async updateBookingSlots(slot_id) {
            let query = {};
            query.id = slot_id;
            if (this.$parent.type === "reschedule") {
                query.slot_id = this.slot_id;
                query.type = this.$parent.type;
            }
            query.dependent_id = this.dependent;
            query.model = "Slot";
            query.payment = "Full";
            const { data } = await axiosInstance.get(endpoints.addToCartRoute, {params: query});
            GlobalEventBus.emit("refreshcart");
            this.selected_space = data.slots;
            this.total_price = data.total;
        },
        async removeFromCart(slot_id) {
            let id = this.selected_space.find(
                (space) => space.model_id === slot_id
            ).id;
            let query = {};
            query.id = id;
            query.model = "Slot";
            const { data } = await axiosInstance.get(endpoints.removeFromCartRoute, {params: query});
            GlobalEventBus.emit("refreshcart");
            this.selected_space = data.slots;
            this.total_price = data.total;
        },
        async bookedSlots() {
            this.paymentModal = true;
        },
        showDaySlots(date) {
            let self = this;
            let s = [];
            self.slots.forEach(function (slot) {
                if (
                    moment(slot.start_date_time).format("YYYY-MM-DD") ===
                    moment(date.date).format("YYYY-MM-DD")
                ) {
                    s.push(slot);
                }
            });
            if (s.length > 0) {
                self.daySlots = s;
                self.choosedView = "day";
                self.selected_date = date.date;
            }
        },
        toolTipContent(date) {
            let self = this;
            let s = [];
            if (date === "booked") {
                return `<div class="tooltip-parent">
                  <div class="pj-calendar-tooltip">
                    <ul>
                      <li>Booked</li>
                    </ul>
                  </div>
                </div>`;
            }

            self.slots.forEach(function (slot) {
                if (
                    moment(slot.start_date_time).format("YYYY-MM-DD") ===
                    moment(date.date).format("YYYY-MM-DD")
                ) {
                    s.push(slot);
                }
            });
            if (s.length > 0) {
                let ele;
                for (let i = 0; i < s.length; i++) {
                    ele +=
                        `<li v-for="slo in s">
                  <span class="tooltip-available">` +
                        self.timeFormathhmm(s[i].start_date_time) +
                        ` - ` +
                        self.timeFormathhmm(s[i].end_date_time) +
                        `</span>
                  <strong class="tooltip-available">available</strong>
                  <strong class="tooltip-available"> ` +
                        s[i].price +
                        ` PKR  </strong>
                </li>`;
                }
                return (
                    `<div class="tooltip-parent">
                  <div class="pj-calendar-tooltip">
                    <ul>` +
                    ele +
                    `</ul>
                  </div>
                  <div class="tooltipster-arrow-top tooltipster-arrow" style="">
                  <span style="border-color:rgb(255, 255, 255);"></span>
                </div>
              </div>`
                );
            }
        },
        incrementDateByWeek() {
            this.startOfWeek = this.startOfWeek.startOf("week").add(1, "week");
            this.endOfWeek = this.endOfWeek.endOf("week").add(1, "week");
            this.weekListing(this.startOfWeek, this.endOfWeek);
        },
        decrementDateByWeek() {
            this.startOfWeek = this.startOfWeek.startOf("week").add(-1, "week");
            this.endOfWeek = this.endOfWeek.endOf("week").add(-1, "week");
            this.weekListing(this.startOfWeek, this.endOfWeek);
        },
        hoursListing() {
            const items = [];
            new Array(24).fill().forEach((acc, index) => {
                items.push(moment({ hour: index }).format("h:mm A"));
                // items.push(moment({ hour: index, minute: 30 }).format('h:mm A'));
            });
            this.hours = items;
        },
        weekListing(startOfWeek, endOfWeek) {
            let days = [];
            let day = startOfWeek;

            while (day <= endOfWeek) {
                days.push(day.toDate());
                day = day.clone().add(1, "d");
            }
            this.weekdays = days;
            this.start_date = moment(startOfWeek).format("DD MMM YYYY");
            this.end_date = moment(endOfWeek).format("DD MMM YYYY");
        },
        async payment() {
            let self = this;
            let payment_method = this.payment_method;
            if (payment_method === "jazzcash") {
                window.location = "/jazz-cash-pay";
            } else if (payment_method === "wallet") {
                let resource = {
                    payment_method: payment_method,
                    total: self.price,
                };
                const { data } = await axiosInstance.post(endpoints.payment, resource);
                this.$toasted.error(data.message);
                if (data.type === "success") {
                    window.location = "/transaction";
                }
            } else {
                let resource = {
                    payment_method: payment_method,
                    total: self.price,
                };
                const { data } = await axiosInstance.post(endpoints.payment, resource);
                this.initiateCheckout(data);
            }
        },
        async checkout() {
            if (this.slot_id > 0) {
                window.location =
                    window.location.origin + "/checkout?s_id=" + this.slot_id;
            } else {
                window.location = window.location.origin + "/checkout";
            }
        },
        initiateCheckout(data) {
          console.log(data)
            // let amount = this.price;
            // let crn = data.crn;
            // let customer_phone_number = data.customer_phone_number;
            // let customername = data.consumer_name;
            // let customeremail = data.customer_email_address;
            //
            // // let UrlParamObj = {
            // //     key: process.env.MIX_FOREE_API_KEY,
            // //     reference_number: crn,
            // //     amount: parseInt(amount),
            // //     is_generated: 0,
            // //     customer_email_address: customeremail,
            // //     customer_phone_number: customer_phone_number,
            // //     consumer_name: customername,
            // //     callback_url: process.env.VUE_APP_API_BASE_URL + "/slots/response/foree",
            // // };
            // // initiateForeeCheckout(UrlParamObj);
        },
        checkedBooked(start, end) {
            let format = "YYYY-MM-DD HH:mm:ss";
            return this.booked.filter(function (book) {
                let start_time = moment(book.slot.start_date_time).format(
                        format
                    ),
                    end_time = moment(book.slot.end_date_time).format(format),
                    beforeTime = moment(start).format(format),
                    afterTime = moment(end).format(format);
                if (
                    start_time === beforeTime ||
                    start_time === afterTime ||
                    end_time === beforeTime ||
                    end_time === afterTime
                ) {
                    return true;
                } else return moment(start_time, format).isBetween(beforeTime, afterTime) || moment(end_time, format).isBetween(beforeTime, afterTime);
            });
        },
        monthChange(data) {
            this.getSlot(data.month);
        },
    },
};
</script>

<style>
.blocks .calendar-blocks .kalendar-day.building-blocks .event-card {
    height: 20px !important;
}

.tooltip {
    display: block !important;
    z-index: 10000;
}

.tooltip .tooltip-inner {
    background: transparent;
    /*color: white;*/
    border-radius: 16px;
    padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    z-index: 1;
}

.tooltip[x-placement^="top"] {
    margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
    margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
    margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}

.tooltip[x-placement^="left"] {
    margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}

.tooltip.popover .popover-inner {
    background: #f9f9f9;
    /*color: black;*/
    padding: 24px;
    border-radius: 5px;
    /*box-shadow: 0 5px 30px rgba(black, .1);*/
}

.tooltip.popover .popover-arrow {
    border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
}
.tooltip.tooltip-loading .tooltip-inner {
    color: #77aaff;
}

.custom_calendar .vc-day {
    border-right: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    padding: 3px;
    width: 100%;
    color: white;
}
.custom_calendar .on-top {
    border-top: 1px solid #aaa;
}
.custom_calendar .on-left {
    border-left: 1px solid #aaa;
}
.custom_calendar .event {
    word-wrap: break-word;
    word-break: break-all;
}
.custom_calendar .event-badge {
    white-space: normal;
    border-radius: 5px;
    margin-top: 2px;
    text-align: left;
}
.event-badge {
    color: #fff;
    width: 75%;
}

.event-name {
    color: white !important;
    padding: 2px;
    display: inline-block;
}
::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #ff0000;
}

.modal-dialog form input {
    height: 15px !important;
}
.day-cell {
    min-height: 60px !important;
}
#checkout-table tr td {
    border: none;
    padding: 0 5px 0 0;
    text-align: initial;
}
i.fas.fa-exclamation-triangle.text-yellow {
    color: #fccd05;
}
.right-border-none {
    border-right: none !important;
}
.left-border-none {
    border-left: none !important;
}
</style>
