<template>

    <div class="card shadow wishlist-course" >
      <div class="position-relative overflow-hidden wishlist-img-container">
        <img class="card-img-top" :src="'/storage'+data.thumbnail" :alt="data.title">
      </div>
      <div class="card-body">
        <div class="wishlist_card-time">5 h 30min</div>
        <h5 class="card-title m-0 text-truncate">{{ data.title }}</h5>
        <small>{{data.tutor.name}}</small>
        <p class="wishlist-para">{{data.short_description}}</p>
        <div class="mt-sm-3 mt-0 slick-bottom-info hide-slick-bottom-info">
          <a :href="'/course/'+data.uuid" class="view-btn">View</a>
          <span class="wishlist-price">{{ data.discount_flag ? data.discounted_price : data.price }} {{ $t('text.pkr') }} </span>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "CourseCardComponent",
  props: ['data'],
  created() {
  }
}
</script>

<style scoped>

</style>
