<template>
    <div style="height: 100%">
        <div class="sessionLog_parent_width_table">
            <div class="p-4-sm p-2 d-flex flex-sm-column flex-column-reverse">
                <div class="sessionLog_table_parent">
                    <div class="sessionLog_table_overflow">
                        <div class="table-responsive session_log_table">
                            <table class="table text-center">
                                <thead class="thead-light">
                                    <tr>
                                        <th>Sr.</th>
                                        <th>Session ID</th>
                                        <th>Course / Tutor</th>
                                        <th>Date</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- eslint-disable -->
                                    <tr v-for="log in logs">
                                        <td class="border-0" scope="row">
                                            {{ log.id }}
                                        </td>
                                        <td class="border-0 main_color">
                                            {{ log.session_key }}
                                        </td>
                                        <td class="border-0">
                                            {{
                                                log?.model === "Course"
                                                    ? log?.course?.title +
                                                      " (Course)"
                                                    : log?.slot?.tutor?.name +
                                                      " (Tutor)"
                                            }}
                                        </td>
                                        <td class="border-0">{{ log.date }}</td>
                                        <td class="border-0">
                                            {{ log.start_time }}
                                        </td>
                                        <td class="border-0">
                                            {{ log.end_time }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-if="no_record">
                                <p class="p-2">No Record Found...</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-6"></div>
                    <div class="col-12 col-sm-6">
                        <div class="float-right session-table-options">
                            <p class="d-flex">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";
import {hideLoader, showLoader} from "@/assets/js/swal";

export default {
    name: "SessionLogComponent",
    props: [],
    components: { },
    directives: {},
    data() {
        return {
            no_record: false,
            logs: [],
            query: {
                keyword: "",
                sort: "DESC",
                page: 1,
                limit: 10,
                total: 0,
            },
        };
    },
    computed: {},
    async created() {
        await this.getSessionLogs();
    },
    methods: {
        async getSessionLogs() {
            try {

              showLoader();

              const { data } = await axiosInstance.get(endpoints.getSessionLogs, {params: this.query});
              this.logs = data.data.logs.data;
              this.query.total = data.data.logs.total;
              if (this.logs.length === 0) {
                this.no_record = true;
              }

              hideLoader();

            } catch (e) {
              hideLoader();
              toast.error('Server Error');
            }
        },
    },
};
</script>
<style scoped></style>
