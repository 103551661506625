import { createRouter, createWebHistory } from 'vue-router';
import VerifyEmail from "@/components/frontend/VerifyEmail.vue";
import { useUserStore } from '@/store/store';
import tutorRoutes from "@/router/tutorRoutes";
import studentRoutes from "@/router/studentRoutes";
import ResetPassword from '@/components/frontend/auth/ResetPassword.vue';
import CourseListingComponent from "@/components/frontend/course/CourseListingComponent.vue";
import HomePage from "@/components/HomePage.vue";
import App from "@/components/frontend/app/App.vue";
import TutorListingComponent from "@/components/frontend/tutor/TutorListingComponent.vue";
import JobPostComponent from "@/components/frontend/post/job/JobPostComponent.vue";
import StudentFAQs from "@/components/frontend/faq/StudentFAQs.vue";
import StudentSignUpProcess from "@/components/frontend/policy/StudentSignUpProcess.vue";
import TutorFAQs from "@/components/frontend/faq/TutorFAQs.vue";
import TutorSignUpProcess from "@/components/frontend/policy/TutorSignUpProcess.vue";
import CourseCreationProcess from "@/components/frontend/policy/CourseCreationProcess.vue";
import CancellationRescheduling from "@/components/frontend/policy/CancellationRescheduling.vue";
import RefundDispute from "@/components/frontend/policy/RefundDispute.vue";
import Top5HelpfulTips from "@/components/frontend/blogs/Top5HelpfulTips.vue";
import ETutoring from "@/components/frontend/blogs/ETutoring.vue";
import AboutUs from "@/components/frontend/AboutUs.vue";
import ContactUs from "@/components/frontend/ContactUs.vue";
import HowToRefund from "@/components/frontend/policy/HowToRefund.vue";
import TermsAndConditions from "@/components/frontend/TermsAndConditions.vue";
import PrivacyPolicy from "@/components/frontend/PrivacyPolicy.vue";
import TutorDetailComponent from "@/components/frontend/tutor/TutorDetailComponent.vue";

const routes = [
  {
    path: '/',
    component: App,
    children: [
      {
        path: '',
        name: 'home',
        component: HomePage
      },
      {
        path: 'courses',
        name: 'courses',
        component: CourseListingComponent
      },
      {
        path: 'tutors',
        name: 'tutors',
        component: TutorListingComponent
      },
      {
        path: 'post/job',
        name: 'post-job',
        component: JobPostComponent
      },
      {
        path: 'student/faq',
        name: 'student-faq',
        component: StudentFAQs
      },
      {
        path: 'policy/student/sign-up-process',
        name: 'student-signup-process',
        component: StudentSignUpProcess
      },
      {
        path: 'tutor/faq',
        name: 'tutor-faq',
        component: TutorFAQs
      },
      {
        path: 'policy/tutor/sign-up-process',
        name: 'tutor-signup-process',
        component: TutorSignUpProcess
      },
      {
        path: 'policy/tutor/course-creation-process',
        name: 'tutor-course-creation-process',
        component: CourseCreationProcess
      },
      {
        path: 'policy/cancellation-rescheduling',
        name: 'policy-cancellation-rescheduling',
        component: CancellationRescheduling
      },
      {
        path: 'policy/refund',
        name: 'policy-refund',
        component: RefundDispute
      },
      {
        path: 'blogs/top-5-helpful-tips',
        name: 'blogs-top-5-helpful-tips',
        component: Top5HelpfulTips
      },
      {
        path: 'blogs/e-tutoring',
        name: 'blogs-e-tutoring',
        component: ETutoring
      },
      {
        path: 'about-us',
        name: 'about-us',
        component: AboutUs
      },
      {
        path: 'contact-us',
        name: 'contact-us',
        component: ContactUs
      },
      {
        path: 'policy/how-to-refund',
        name: 'how-to-refund',
        component: HowToRefund
      },
      {
        path: 'terms-conditions',
        name: 'terms-conditions',
        component: TermsAndConditions
      },
      {
        path: 'privacy',
        name: 'privacy',
        component: PrivacyPolicy
      },
      {
        path: 'verification/email/:email/:hash',
        component: VerifyEmail,
        props: true,
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
      },
      {
        path: 'profile/tutor/:id',
        name: 'tutor-public-profile',
        component: TutorDetailComponent,
      }
    ]
  },

  ...tutorRoutes,
  ...studentRoutes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {

  const userStore = useUserStore();

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !userStore.isEmailVerified) {
    next('/');
  } else {
    next();
  }
});

export default router;