import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10000, // Optional timeout
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
});

// Optional: Add a request interceptor
axiosInstance.interceptors.request.use(
    config => {
      // Do something before the request is sent, such as adding a loader
      return config;
    },
    error => {
      // Do something with request error
      return Promise.reject(error);
    }
);

// Optional: Add a response interceptor
axiosInstance.interceptors.response.use(
    response => {
      // Do something with response data
      return response;
    },
    error => {
      // Do something with response error, like logging out user if 401 is received
      return Promise.reject(error);
    }
);

/**
 * Function to set the authorization token
 * @param {string|null} token - The token to set in the headers. If null, the token is removed.
 */
export function setAuthToken(token = null) {
  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
}

export default axiosInstance;