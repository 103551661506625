<template>
  <div class="tab-pane fade show active" id="wallet-detail-tab" role="tabpanel" aria-labelledby="walletDetail">
    <div class="row">
      <div class="col-md-12 ">
        <h5>Wallet Balance: {{ wallet }} PKR</h5>
      </div>
      <hr>
      <div class="col-md-12">
        <div class="form-group">
          <label for="amount">Topup Amount</label>
          <input type="number" min="0" class="form-control" id="amount" v-model="amount" aria-describedby="emailHelp" placeholder="">
        </div>
        <div class="form-group mb-2 float-right">
          <button v-on:click="createTopUp" type="submit" class="btn btn-success"> Submit </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";
import {useUserStore} from "@/store/store";

export default {
  name: "WalletComponent",
  data() {
    return {
      wallet: 0.00,
      amount: 0.00,
    }
  },
  created() {
    let userStore = useUserStore();
    this.wallet = userStore.walletInfo.balance;
  },
  methods: {
    async createTopUp() {
      if (this.amount) {
        let amount = this.amount
        let formData = new FormData()
        formData.append('amount', amount)
        formData.append('credentials', 'credentials')
        formData.append('payment_method', this.$parent.payment_method)
        console.log("___________________________________this.$parent.payment_method");
        console.log(this.$parent.payment_method);
        if (this.$parent.payment_method === 'jazzcash') {
          window.location = '/v1/topup/jazzcash?amount='+amount+'&type=TopUp'
        }
        else if (this.$parent.payment_method === 'foree') {
          formData.append('payment_method', 'foreepay')
          const {data} = await axiosInstance.post(endpoints.paymentV, formData);
          console.log("data----------------------------");
          console.log(data);
          if(data.is_generated === 1){
            console.log(data);
            window.location.href("/checkout/foree?data="+JSON.stringify(data));
          }
          else {
            toast.error("Foree transaction not generated")
          }
        }
        else {
          const { data } = await axiosInstance.post(endpoints.payment, formData);
          console.log(data)
          if (data.type === 'success')
            toast.success(data.message)
        }
        this.amount = 0
        await this.$parent.getTopUp()
      }
    },
    // initiateCheckout(data) {
    //   // let callBack = window.location.origin + '/response/foree?type=TopUp'
    //   // let amount = data.amount
    //   // let crn = data.crn
    //   // let customer_phone_number = data.customer_phone_number;
    //   // let customername = data.consumer_name
    //   // let customeremail = data.customer_email_address
    //   //
    //   // let urlParamObj = {
    //   //   'key': process.env.MIX_FOREE_API_KEY,
    //   //   'reference_number': crn,
    //   //   'amount': amount,
    //   //   'is_generated': 0,
    //   //   'customer_email_address': customeremail,
    //   //   'customer_phone_number': customer_phone_number,
    //   //   'consumer_name': customername,
    //   //   'callback_url': callBack
    //   // }
    //   /*Call back url*/
    //   // initiateForeeCheckout(urlParamObj);
    // },

    async checkStatus(crn) {
      let formData = new FormData()
      formData.append('crn', crn)
      await axiosInstance.post(endpoints.foreeBillStatus, formData);
      await this.$parent.getTopUp()
    }
  },
}
</script>

<style scoped>

</style>
