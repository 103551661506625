<template>
  <div class="hero-course-wrap bg-offwhite newtheme-cart-component">
    <div class="container">
      <div class="hero-course-con py-4">
        <h2 class="font-weight-bold color_black mb-2 mt-4">FAQ- Tutor support</h2>
      </div>
    </div>
  </div>

  <div class="container mt-5">
    <h4>How do I sign up as tutor?</h4>
    <p>Signing up as an online tutor at ILMZone is easy. All you need is an email address, your valid educational credentials, and a passion to teach and earn. Simply follow these steps:</p>
    <ol>
      <li>Go to <a href="https://www.ilmzone.com">www.ilmzone.com</a></li>
      <li>Click Sign Up at the top right of the page</li>
      <li>Select Tutor</li>
      <li>Click “Continue with Email”</li>
      <li>Provide your Full Name, Email, Username, and Password</li>
      <li>Click Sign Up</li>
      <li>You will receive a verification email. Click the verification link to verify your email</li>
      <li>You will next be returned to ILMZone.com. Log in with your Username (or Email address) and Password, and proceed to complete your PROFILE</li>
    </ol>
    <p>Here are some best practices that will help you get more students, and earn more:</p>
    <ul>
      <li>Optimize your profile by entering all relevant information correctly</li>
      <li>Add a professional profile picture</li>
      <li>Write a good profile description</li>
      <li>Get verified and earn an ILMZone Verification Badge</li>
      <li>Use keywords relative to your specialization in your profile</li>
    </ul>
    <p>In just a few minutes you will have your very own tutor profile.</p>

    <h4>What are the requirements to register as a tutor?</h4>
    <p>ILMZone is an online marketplace where students can choose tutors based on their educational background, skill level, experience, and other important factors. There are no mandatory requirements for registering as a tutor. However, having the following credentials will improve your chances of landing more tutoring jobs:</p>
    <ul>
      <li>A Valid Bachelor’s or Master’s degree</li>
      <li>Proven Experience</li>
      <li>Licenses and certifications</li>
    </ul>
    <p>To become a Verified Tutor on ILMZone, it’s important that all credentials you provide are valid and verifiable. To read more about Tutor and Document verification, <a href="#">click here</a>.</p>

    <h4>How do I get clients?</h4>
    <p>There are two different and effective ways to get students on ILMZone:</p>
    <ol>
      <li>Applying regularly for relevant jobs that appear on our Tutoring Jobs Board.</li>
      <li>Be discovered by students through your profile.</li>
    </ol>
    <p>For best results, you can filter jobs by subject, grade, and board. If a student finds your profile interesting, they can contact you through your profile.</p>
    <p>To be discovered by students on ILMZone, you should follow these best practices:</p>
    <ul>
      <li>Optimize your profile by entering all relevant information correctly.</li>
      <li>Add a professional profile picture.</li>
      <li>Write a good profile description.</li>
      <li>Earn a Verification Badge.</li>
      <li>Use keywords relative to your specialization in your profile.</li>
      <li>Earn the ILMZone Badge which indicates you’ve had your degrees verified.</li>
    </ul>
    <p>Setting up your Availability Schedule is super easy and convenient. Your Availability Schedule is visible to you as well as to potential students. To set up your Availability Schedule you need to ensure that:</p>
    <ul>
      <li>You are logged in to your ILMzone Tutor Account.</li>
      <li>Go to your dashboard.</li>
      <li>Click Schedule -> Appointments -> Add Appointments</li>
    </ul>

    <h4>What if the tutor cancels a lesson at the last moment?</h4>
    <p>We understand that tutors can sometimes experience their own emergencies. ILMZone allows tutors to cancel a lesson any time before the scheduled time. When a tutor cancels a lesson, the full lesson fee is credited to the student’s wallet, and tutors are not paid.</p>
    <p>In case of cancellation, it’s best practice to inform the student at least 24 hours before the scheduled lesson time.</p>
    <p>For more information, please visit our <a href="#">Cancelation Policy</a>.</p>

    <h4>How will I get paid for one-on-one lessons?</h4>
    <p>We make funds available in the tutor's wallet on the fifth day following the lesson provided there are no pending refund or dispute requests for that lesson.</p>
    <p>For example:</p>
    <ul>
      <li>If a lesson ends on Monday 12:00PM PKT, the deadline for submitting a Refund Request is Friday 00:00 AM PKT.</li>
      <li>If the lesson ends on Monday, you have until Friday to submit a Refund Request.</li>
      <li>If the lesson ends on Tuesday, you have until Saturday to submit a Refund Request.</li>
      <li>If the lesson ends on Wednesday, you have until Sunday to submit a Refund Request.</li>
      <li>If the lesson ends on Thursday, you have until Monday to submit a Refund Request.</li>
      <li>If the lesson ends on Friday, you have until Tuesday to submit a Refund Request.</li>
      <li>If the lesson ends on Saturday, you have until Wednesday to submit a Refund Request.</li>
    </ul>
    <p>For Lessons:</p>
    <p>We make funds available in tutors’ wallet on the fifth day following the lesson.</p>

    <h4>How Do I get paid for courses I’ve taught?</h4>
    <p>Course payments are credited to the tutors’ wallet one week after each course month is completed, or at the course completion.</p>
    <p>Students are able to enroll in courses that last longer than 4 weeks in one of two ways:</p>
    <ul>
      <li>on a month-by-month payment plan, or</li>
      <li>enroll for the entire term of the course.</li>
    </ul>
    <p>Monthly course fees are calculated as follows:</p>
    <p><b>(Full Course Fee ÷ Total Number of Course Weeks) X (Number of Course Weeks in the Month)</b></p>
    <p>EXAMPLE:</p>
    <p>If the full Course fee for a 10 week Course is 4000 PKR, the monthly fee will be:</p>
    <p>4000 PKR ÷ 10 weeks x number of monthly weeks</p>
    <p>Month-1 (4 weeks): Fee = 1600 PKR</p>
    <p>Month-2 (4 weeks): Fee = 1600 PKR</p>
    <p>Month-3 (2 weeks): Fee = 800 PKR</p>
    <p>Total fee 10 week course: 4000 PKR</p>
    <img src="@/assets/images/payment_schedule.png" width="100%">
    <p>Your monthly payment = 75 % of (Monthly fee multiplied by number of enrolled students)</p>

    <h4>How Do I withdraw funds from my wallet?</h4>
    <p>In order to withdraw funds from your wallet, a Withdrawal Request must be submitted:</p>
    <ul>
      <li>Log in to your Tutor account</li>
      <li>Go to Dashboard > Billing > Withdraw Funds.</li>
      <li>Click Create Withdrawal Request.</li>
      <li>Enter Withdrawal Amount, select Bank Account, then Click Submit Request.</li>
    </ul>
    <p>We run payroll Bi-Monthly. Checks are disbursed on the 1st and 15th of each month for all approved/verified Withdrawal Requests.</p>
    <p><b>Meta description:</b> FAQs to address tutor’s concerns about the processes of ILMZone</p>
  </div>
</template>

<script>
export default {
  name: "TutorFAQs"
};
</script>

<style scoped>
</style>
