<template>
    <div style="height: 100%">
        <LoadingComponent :loading="loading"></LoadingComponent>
        <div
            class="container container-coursedetail transaction-table student-trans-sec transaction-newtheme"
            v-if="!loading"
        >
            <div class="new-transectionpage">
                <div class="row mx-1 pt-4 transaction-form">
                    <div class="col-sm-4 col-md-4 col-lg-6">
                        <!--            <div class="text-bold float-left"><b>Balance:</b> {{walletBalance}}</div>-->
                        <h5 class="v-align-center">
                            Wallet Balance: {{ walletBalance }} PKR
                        </h5>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-2">
                        <div class="form-group">
                            <label for="date_from">From</label>
                            <input
                                type="date"
                                class="form-control"
                                id="date_from"
                                v-model="query.date_from"
                                aria-describedby="emailHelp"
                            />
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-2">
                        <div class="form-group">
                            <label for="date_to">To</label>
                            <input
                                type="date"
                                class="form-control"
                                id="date_to"
                                v-model="query.date_to"
                                aria-describedby="emailHelp"
                            />
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-2">
                        <button
                            v-on:click="getTransaction"
                            class="add_appointment_btn btn"
                        >
                            Search
                        </button>
                    </div>
                </div>
            </div>
            <AllTransactionComponent
                :transactions="transactions"
                :no_record="no_record"
            ></AllTransactionComponent>
        </div>
        <TransactionDetailComponent
            v-if="transactionModal"
            :selectedTransaction="selectedTransaction"
        ></TransactionDetailComponent>
    </div>
</template>

<script>
import AllTransactionComponent from "@/components/transaction/AllTransactionComponent";
import TransactionDetailComponent from "@/components/frontend/payment/TransactionDetailComponent";
import { GlobalEventBus } from "@/assets/js/eventBus";
import LoadingComponent from "@/components/utilities/loading/loader.vue";
import endpoints from "@/assets/js/api";
import axiosInstance from "@/assets/js/axios";

export default {
    name: "TransactionComponent",
    components: {
        AllTransactionComponent,
        TransactionDetailComponent,
        LoadingComponent,
    },
    data() {
        return {
            no_record: false,
            transactionModal: false,
            authenticated: false,
            transactions: [],
            invoices: [],
            query: {
                status: "",
                keyword: "",
                rating: 0,
                sort: "DESC",
                page: 1,
                limit: 6,
                total: 0,
                date_from: "",
                date_to: "",
            },
            selectedTransaction: {},
            walletBalance: 0,
            loading: true,
        };
    },
    async created() {
        await this.getTransaction();
        let self = this;
        this.wallet = this.$store.state.wallet
        GlobalEventBus.on("getUserDetail", function (data) {
            self.walletBalance = data.wallet;
        });
        this.loading = false;
    },
    mounted() {},
    methods: {
        async getTransaction() {
            const { data } = await axiosInstance.get(endpoints.getTransaction, self.query);
            if (data.type === "success") {
                this.transactions = data.transactions;
                this.invoices = data.invoices;
                if (data.transactions.data.length === 0) {
                    this.no_record = true;
                }
            }
        },
        async getTransactionShowMore() {
            let self = this;
            self.query.page++;
            const { data } = await axiosInstance.get(endpoints.getTransaction, self.query);
            if (data.type === "success") {
                this.transactions.data.push(...data.transactions.data);
            }
        },
    },
};
</script>

<style scoped></style>
