<template>
    <div>
        <div
            class="container container-coursedetail transaction-table student-trans-sec transaction-newtheme"
        >
            <div class="new-transectionpage">
                <div class="row mx-1 pt-4 transaction-form">
                    <div class="col-sm-4 col-md-4 col-lg-6">
                        <!--            <div class="text-bold float-left"><b>Balance:</b> {{walletBalance}}</div>-->
                        <h5 class="v-align-center">
                            Wallet Balance: {{ walletBalance }} PKR
                        </h5>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-2">
                        <div class="form-group">
                            <label for="status">Status</label>
                            <!-- <input
                                type="text"
                                class="form-control"
                                id="status"
                                v-model="query.status"
                                aria-describedby="emailHelp"
                            /> -->

                            <select
                                class="form-control"
                                id="status"
                                v-model="query.status"
                                aria-describedby="emailHelp"
                            >
                                <option value="all">All</option>
                                <option v-for="transaction_status in transaction_statuses" :key="transaction_status.id" :value="transaction_status" >{{transaction_status}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-2">
                        <div class="form-group">
                            <label for="date_from">From</label>
                            <input
                                type="date"
                                class="form-control"
                                id="date_from"
                                v-model="query.date_from"
                                aria-describedby="emailHelp"
                            />
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-2">
                        <div class="form-group">
                            <label for="date_to">To</label>
                            <input
                                type="date"
                                class="form-control"
                                id="date_to"
                                v-model="query.date_to"
                                aria-describedby="emailHelp"
                            />
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-2">
                        <button
                            v-on:click="getTransaction"
                            class="add_appointment_btn btn"
                        >
                            Search
                        </button>
                        <div>
                            <span>Total Amount:</span>
                            <span>{{ transactions_amount }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <AllTransactionComponent
                :transactions="transactions"
                v-if="transactions.data?.length > 0"
            ></AllTransactionComponent>
        </div>
        <TransactionDetailComponent
            v-if="transactionModal"
            :selectedTransaction="selectedTransaction"
        ></TransactionDetailComponent>
    </div>
</template>

<script>
import AllTransactionComponent from "@/components/transaction/AllTransactionComponent";
import TransactionDetailComponent from "@/components/frontend/payment/TransactionDetailComponent";
import {useUserStore} from "@/store/store";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
    name: "TransactionComponent",
    components: {
        AllTransactionComponent,
        TransactionDetailComponent,
    },
    data() {
        return {
            transactionModal: false,
            authenticated: false,
            transactions: [],
            invoices: [],
            pendings: [],
            transaction_statuses: [],
            query: {
                status: "all",
                keyword: "",
                rating: 0,
                sort: "DESC",
                page: 1,
                limit: 6,
                total: 0,
                // date_from: moment().subtract(3, 'months').format('yyyy-MM-DD'),
                // date_to: moment().format('yyyy-MM-DD'),
                date_from: "",
                date_to: ""
            },
            transactions_amount: 0,
            selectedTransaction: {},
            walletBalance: 0,
        };
    },
    created() {
        this.getTransaction();
    },
    mounted() {
      let userStore = useUserStore();
      this.wallet = userStore.walletInfo.balance;

    },
    methods: {
        async getTransaction() {
            let self = this;
            const transData = await axiosInstance.get(endpoints.getTutorTransaction, {params: self.query});
            console.log(transData.data.data.type);
            if (transData.data.data.type === "success") {
                this.transaction_statuses = transData.data.data.transaction_statuses;
                this.transactions_amount = transData.data.data.total_amount;
                this.transactions = transData.data.data.transactions;
                this.invoices = transData.data.data.invoices;
                this.pendings = transData.data.data.pending;
            }
        },
        async getTransactionShowMore() {
            let self = this;
            self.query.page++;
          const { data } = await axiosInstance.get(endpoints.getTutorTransaction, {params: self.query});
            if (data.type === "success") {
                this.transactions.data.push(...data.transactions.data);
            }
        },
    },
};
</script>

<style scoped></style>
