import Dashboard from '@/components/student/dashboard/DashboardComponent.vue';
import App from "@/components/student/app/App.vue";
import BillingComponent from "@/components/student/billings/BillingComponent.vue";
import TransactionComponent from "@/components/transaction/TransactionComponent.vue";
import MakeADepositComponent from "@/components/student/deposit/MakeADepositComponent.vue";
import LogActivityComponent from "@/components/utilities/log-activity/LogActivityComponent.vue";
import BecomeATutorComponent from "@/components/student/becomeATutor/BecomeATutorComponent.vue";
import RefundRequestComponent from "@/components/student/billings/RefundRequestComponent.vue";
import DisputeComponent from "@/components/student/dispute/DisputeComponent.vue";
import DisputeDetailComponent from "@/components/student/dispute/DisputeDetailComponent.vue";
import RequestsComponent from "@/components/student/billings/RequestsComponent.vue";
import RefundDetailComponent from "@/components/student/refund/RefundDetailComponent.vue";
import ProfileSettings from '@/components/student/profile/ProfileSettingsComponent.vue';
import Courses from '@/components/student/course/CoursesComponent.vue';
import Wishlist from '@/components/student/wishlist/WishlistComponent.vue';
import Schedule from '@/components/student/schedule/ScheduleComponent.vue';
import StudentSessionLog from '@/components/student/session/SessionLogComponent.vue';
import ChatComponent from '@/components/utilities/chat/ChatComponent.vue';

export default [
    {
        path: '/student',
        name: 'student-default',
        component: App,
        props: {
            has_sub: true
        },
        children: [
            {
                path: 'dashboard',
                name: 'student-dashboard',
                props: {
                    has_sub: false
                },
                component: Dashboard,
                meta: {
                    requiresAuth: true
                },
            },
            {
                name: 'student-profile-settings',
                path: 'profile',
                props: {
                    has_sub: false
                },
                component: ProfileSettings,
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'student-courses',
                path: 'courses',
                props: {
                    has_sub: false
                },
                component: Courses,
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'wishlist',
                path: 'wishlist',
                props: {
                    has_sub: false
                },
                component: Wishlist,
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'schedule',
                path: 'schedule',
                props: {
                    has_sub: false
                },
                component: Schedule,
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'student-chat',
                path: 'chat',
                props: {
                    has_sub: false
                },
                component: ChatComponent,
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'student-billing',
                path: 'billing',
                props: {
                    parent: "billing",
                    has_sub: true
                },
                component: BillingComponent,
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'student-session-log',
                path: 'log/session',
                props: {
                    has_sub: true,
                    parent: 'log'
                },
                component: StudentSessionLog,
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'activity-logs',
                path: 'log/activity',
                props: {
                    has_sub: true,
                    parent: 'log'
                },
                component: LogActivityComponent,
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'transaction',
                path: 'billing/transaction',
                props: {
                    parent: "billing",
                    has_sub: true
                },
                component: TransactionComponent,
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'student-deposit',
                path: 'billing/deposit',
                props: {
                    parent: "billing",
                    has_sub: true,
                },
                component: MakeADepositComponent,
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'becomeTutor',
                path: 'tutor/registration',
                props: {
                    has_sub: false
                },
                component: BecomeATutorComponent,
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'refund-request',
                path: 'refund',
                props: {
                    has_sub: false
                },
                component: RefundRequestComponent,
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'dispute',
                path: 'dispute',
                props: {
                    has_sub: false
                },
                component: DisputeComponent,
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'dispute-detail',
                path: 'dispute/:id',
                props: {
                    has_sub: false
                },
                component: DisputeDetailComponent,
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'requests',
                path: '/billing/requests',
                props: {
                    has_sub: false
                },
                component: RequestsComponent,
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'refund-detail',
                path: '/refund/:id',
                props: {
                    has_sub: false
                },
                component: RefundDetailComponent,
                meta: {
                    requiresAuth: true
                }
            },
        ]
    }
];
