<template>
  <div>
    <div class="modal" id="SignInModal" style="display: block; background-color: rgba(0,0,0, 0.4);" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modals-newtheme" role="document">
        <div class="modal-content">
          <div class="modal-body p-6">
            <button v-on:click="$parent.resetModal = !$parent.resetModal" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
              <div class="col-md-12 col-12">
                <div class="text-center logo_cover">
                  <a  href="#">
                    <picture>
                      <img src="@/assets/images/logo.svg" alt="Flowers" width="191">
                    </picture>
                  </a>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row ">
                <div class="col-md-12 col-12">
                  <div>
                    <form @submit.prevent="validateForm" action="/login" method="post">
                      <h2 class="login">Forgot Password</h2>
                      <div class="mt-2">
                        <label class="font_size"></label>
                        <input type="email" v-model="credentials.email" name="email" required placeholder="Enter Your Email" class="form-control" />
                      </div>

                      <div class="row">

                        <div class="col-md-12 col-12">
                          <button type="submit" class="btn btn-primary width_login w-100">Reset Password</button>
                        </div>

                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {toast} from "vue3-toastify";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
  name: "PasswordResetEmailComponent",
  data() {
    return {
      credentials: {
        email: '',
        password: '',
      }
    }
  },
  methods: {

    validateForm() {
      if (!this.credentials.email) {
        toast.error('Enter Email...')
      }
      else {
        this.sendPasswordResetLink()
      }
    },
    async sendPasswordResetLink() {

      let email = this.credentials.email
      let formData = new FormData

      formData.append('email', email)
      try {

        const {data} = await axiosInstance.post(endpoints.passwordResetLinkRoute, formData);

        if(data.status === true) {

          toast.success('Password reset link has been sent to your email.');

          setTimeout(() => {
            location.reload()
          }, 3000);

        }

      } catch (e) {
        console.log(e)

        if(e.response && e.response.data && e.response.data.errors && e.response.data.errors.email) {
          toast.error('We can\'t find a user with that email address.')
        } else {
          toast.error('Error sending link')
        }
      }
    }
  }
}
</script>

<style scoped>
.dont_account .sign a{
  color: #4e97d2b5;
  font-weight: 300;
}
.width_login{
  margin-top:20px;
  width:100%;
}

.forgot a{
  color: #4e97d2b5;
  font-weight: 300;
}
.font_size{
  font-size:16px;
}
.login{
  color:black;
}
.logo_cover{
  padding: 22px;
}
#SignInModal .modals-newtheme {
  color: #dc3545!important;
}
</style>
