import Swal from 'sweetalert2';

export function showLoader() {
    Swal.fire({
        title: 'Loading...',
        html: '<div class="custom-swal-loader"></div>',
        allowOutsideClick: false,
        showConfirmButton: false,
        customClass: {
            popup: 'custom-swal-popup'
        }
    });
}

export function hideLoader() {
    Swal.close();
}

export default Swal;