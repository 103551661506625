<template>
  <div>
    <div class="hero-course-wrap bg-offwhite newtheme-cart-component">
      <div class="container">
        <div class="hero-course-con py-4">
          <h2 class="font-weight-bold color_black mb-2 mt-4">How to Refund</h2>
        </div>
      </div>
    </div>

    <div class="container mt-5">
      <h4>How to Refund</h4>
      <p>
        We want you to be satisfied, so all eligible courses purchased on Ilmzone can be refunded within 30 days. For whatever reason, if you are unhappy with a course, you can request a refund, provided the request meets the guidelines in our refund policy.
      </p>
      <p>
        Most refunds are returned via the original payment method. Please note that certain restrictions may apply and some purchases may only be eligible for credit refunds. Learn more about our refund policy.
      </p>
      <h2>How to Request a Refund from Your Purchase History</h2>
      <p>
        If you’re accessing Ilmzone on a web browser, you can quickly request a refund from your Purchase History page by following the steps below.
      </p>
      <ol>
        <li>Login as a Student account and go to the dashboard.</li>
        <li>Navigate to your Refund page.</li>
        <li>Click the button "Create a Refund Request" and select the transaction you want to refund.</li>
      </ol>

      <img src="../../../assets/new-theme-images/asdf.png" width="500" alt="refund-image" />

      <p>
        Once you submit the request, it will be sent to the Admin who will review it. After a complete review, the Admin can approve or reject the refund request.
      </p>
      <p>
        The refund will be processed to the bank account mentioned in your profile.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "RefundPage",
};
</script>

<style scoped>
.hero-course-wrap {
  background-color: #f7f7f7;
}

.container {
  margin-top: 20px;
}

.hero-course-con h2 {
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

img {
  display: block;
  margin: 20px auto;
}
</style>
