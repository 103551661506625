<template>
  <div>
    <div class="hero-course-wrap bg-offwhite newtheme-cart-component">
      <div class="container">
        <div class="hero-course-con py-4">
          <h2 class="font-weight-bold color_black mb-2 mt-4">Cancellation and Rescheduling</h2>
        </div>
      </div>
    </div>

    <div class="container mt-5">
      <h4>ILMZone Cancelation and Reschedule Policy</h4>
      <p>
        Canceling and rescheduling lessons can sometimes be troublesome. Therefore, by registering with ILMZone you agree to the following policy:
      </p>

      <h4>Tutor Cancelation Policy</h4>
      <ol>
        <li>
          Tutors registered on ILMZone can cancel a scheduled lesson at any time. There is no penalty. However, we request Tutors inform Customer Care at least 24 hours before the scheduled lesson time.
        </li>
        <li>
          When a Tutor cancels a lesson, the entire lesson fee is credited to the student's Wallet. The Tutor will not be paid for the booked Lesson.
        </li>
      </ol>

      <h4>Student Cancelation</h4>
      <ol>
        <li>Students must cancel at least 24 hours before the Lesson’s start time.</li>
        <li>Cancellations within 24 hours of the Lesson will result in forfeiture of the Lesson fees. No refund is given.</li>
        <li>If Students fail to attend a Lesson as scheduled and do not cancel per policy, the total Lesson fee is forfeited. No refund is given.</li>
        <li>In case of emergency, students can contact customer support at any time to cancel a lesson, reschedule, or request a refund.</li>
        <li>Refunds for cancellation within 24 hours of a Lesson due to an emergency are at ILMZone’s discretion.</li>
        <li>Students cannot cancel or reschedule lessons more than three times per month.</li>
      </ol>

      <h4>Tutor Rescheduling</h4>
      <p>Tutors cannot reschedule one-on-one Lessons. Tutors can only reschedule course classes.</p>
      <p><b>Meta description:</b> ILMZone canceling and rescheduling policy outlined to aware students and tutors.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CancellationAndRescheduling",
};
</script>

<style scoped>
.hero-course-wrap {
  background-color: #f7f7f7; /* Example background color */
}

.container {
  margin-top: 20px;
}

.hero-course-con h2 {
  font-weight: bold;
  color: #333; /* Example text color */
  margin-bottom: 20px;
}

ol {
  list-style-type: decimal;
  padding-left: 20px;
}

ol li {
  margin-bottom: 10px;
}
</style>
