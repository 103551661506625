<template>
    <div>
        <div class="container pt-2 pb-2">
            <div class="course-adding-form box-style-coruse">
                <ul id="progressbar" class="progressbar-box six-steps">
                    <li class="active" id="general">
                        <strong class="color-light-blue">Basic Course Info</strong
                        ><span>1</span>
                    </li>
                    <li class="active" id="duration">
                        <strong class="color-light-blue">Proposed Schedule</strong
                        ><span>2</span>
                    </li>
                    <li id="pricingrequirements">
                        <strong>Pricing & Requirements </strong><span>3</span>
                    </li>
                    <li id="courseoutline">
                        <strong class="color-light-blue">Course Outline</strong
                        ><span>4</span>
                    </li>
                    <li id="mediaseo">
                        <strong>Media & SEO</strong><span>5</span>
                    </li>
                    <!--          <li id="quiz"><strong>Quiz</strong><span>6</span></li>-->
                </ul>
                <div class="container-fluid" id="grad1">
                    <div class="row">
                        <div class="col-md-12 mx-0">
                            <form id="form-box" class="form-box">
                                <fieldset>
                                    <div>
                                        <div class="row p-3">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="count_week"
                                                        >Number of Weeks</label
                                                    >
                                                    <select
                                                        class="form-control input_height"
                                                        v-on:change="
                                                            getNumberOfWeeks
                                                        "
                                                        v-model="
                                                            course.count_weeks
                                                        "
                                                    >
                                                        <option
                                                            value="1"
                                                            selected
                                                        >
                                                            1
                                                        </option>
                                                        <option value="2">
                                                            2
                                                        </option>
                                                        <option value="3">
                                                            3
                                                        </option>
                                                        <option value="4">
                                                            4
                                                        </option>
                                                    </select>
                                                    <!-- <input
                                                        type="number"
                                                        class="form-control input_height"
                                                        name="count_week"
                                                        min="1"
                                                        max="50"
                                                        id="count_week"
                                                        v-model="
                                                            course.count_weeks
                                                        "
                                                        v-on:change="
                                                            getNumberOfWeeks
                                                        "
                                                        v-on:focus="
                                                            errorMessages.count_weeks =
                                                                []
                                                        "
                                                    /> -->
                                                    <ul
                                                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                        v-if="
                                                            errorMessages
                                                                .count_weeks
                                                                .length > 0
                                                        "
                                                    >
                                                      <!-- eslint-disable -->
                                                        <li
                                                            v-for="err in errorMessages.count_weeks"
                                                        >
                                                            <small>{{
                                                                err
                                                            }}</small>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label
                                                    class="block text-gray-600 text-sm font-bold mb-2"
                                                    for="start_date"
                                                    >Proposed Start Date</label
                                                >
                                                <div class="flex w-full">
                                                    <input
                                                        v-on:change="
                                                            startDayDateCheck()
                                                        "
                                                        :min="minDate"
                                                        v-on:click="
                                                            startDayDateCheck()
                                                        "
                                                        type="date"
                                                        id="start_date"
                                                        class="form-control input_height"
                                                        :class="{
                                                            'border-red-600':
                                                                errorMessages.start_date,
                                                        }"
                                                        v-model="
                                                            course.start_date
                                                        "
                                                        v-on:focus="
                                                            errorMessages.start_date =
                                                                []
                                                        "
                                                    />
                                                    <ul
                                                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                        v-if="
                                                            errorMessages
                                                                .start_date
                                                                .length > 0
                                                        "
                                                    >
                                                      <!-- eslint-disable -->
                                                        <li
                                                            v-for="err in errorMessages.start_date"
                                                        >
                                                            <small>{{
                                                                err
                                                            }}</small>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label
                                                    class="block text-gray-600 text-sm font-bold mb-2"
                                                    for="end_date"
                                                    >Proposed End Date</label
                                                >
                                                <div class="flex w-full">
                                                    <input
                                                        type="date"
                                                        disabled
                                                        id="end_date"
                                                        class="form-control input_height"
                                                        :class="{
                                                            'border-red-600':
                                                                errorMessage,
                                                        }"
                                                        v-model="
                                                            course.end_date
                                                        "
                                                        v-on:focus="
                                                            errorMessages.end_date =
                                                                []
                                                        "
                                                    />
                                                    <ul
                                                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                        v-if="
                                                            errorMessages
                                                                .end_date
                                                                .length > 0
                                                        "
                                                    >
                                                      <!-- eslint-disable -->
                                                        <li
                                                            v-for="err in errorMessages.end_date"
                                                        >
                                                            <small>{{
                                                                err
                                                            }}</small>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <ul
                                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                    v-if="
                                                        errorMessages
                                                            .count_weeks
                                                            .length > 0
                                                    "
                                                >
                                                  <!-- eslint-disable -->
                                                    <li
                                                        v-for="err in errorMessages.count_weeks"
                                                    >
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                                <!--                        <p class="text-blue-500 text-xs font-bold mt-1 mb-0" v-else>-->
                                                <!--                          We got it. Thanks!-->
                                                <!--                        </p>-->
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="start_time"
                                                        >Proposed Times</label
                                                    >
                                                    <input
                                                        type="time"
                                                        class="form-control input_height"
                                                        id="start_time"
                                                        v-on:change="
                                                            checkTimeDifference
                                                        "
                                                        v-model="
                                                            course.start_time
                                                        "
                                                        aria-describedby="emailHelp"
                                                        placeholder="04:00 PM"
                                                        v-on:focus="
                                                            errorMessages.start_time =
                                                                []
                                                        "
                                                    />
                                                    <ul
                                                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                        v-if="
                                                            errorMessages
                                                                .start_time
                                                                .length > 0
                                                        "
                                                    >
                                                      <!-- eslint-disable -->
                                                        <li
                                                            v-for="err in errorMessages.start_time"
                                                        >
                                                            <small>{{
                                                                err
                                                            }}</small>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="end_time"
                                                        >Proposed End Times</label
                                                    >
                                                    <input
                                                        type="time"
                                                        class="form-control input_height"
                                                        id="end_time"
                                                        v-on:change="
                                                            checkTimeDifference
                                                        "
                                                        v-model="
                                                            course.end_time
                                                        "
                                                        aria-describedby="emailHelp"
                                                        placeholder="04:50 PM"
                                                        v-on:focus="
                                                            errorMessages.end_time =
                                                                []
                                                        "
                                                    />
                                                    <ul
                                                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                        v-if="
                                                            errorMessages
                                                                .end_time
                                                                .length > 0
                                                        "
                                                    >
                                                      <!-- eslint-disable -->
                                                        <li
                                                            v-for="err in errorMessages.end_time"
                                                        >
                                                            <small>{{
                                                                err
                                                            }}</small>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-8 mt-0">
                                                <div class="daysselection-box">
                                                    <!--                      <label class="mb-0">Set Available Days</label>-->
                                                    <label class="mb-0"
                                                        >Proposed Days</label
                                                    >
                                                    <ul
                                                        class="list-unstyled availible_days align-items-center justify-content-lg-between"
                                                    >
                                                      <!-- eslint-disable -->
                                                        <li v-for="day in days">
                                                            <label
                                                                class="checkmark-style-02"
                                                                :style="
                                                                    available_days &&
                                                                    available_days.length >
                                                                        0 &&
                                                                    available_days.indexOf(
                                                                        day
                                                                            .toLowerCase()
                                                                            .trim()
                                                                    ) > -1
                                                                        ? 'fill: #00AF40;'
                                                                        : ''
                                                                "
                                                                :for="
                                                                    day
                                                                        .toLowerCase()
                                                                        .trim()
                                                                "
                                                                >{{
                                                                    day.substring(
                                                                        0,
                                                                        3
                                                                    )
                                                                }}
                                                                <input
                                                                    type="checkbox"
                                                                    :checked="
                                                                        checkAvailableDay(
                                                                            day
                                                                        )
                                                                    "
                                                                    v-on:click="
                                                                        availableDays(
                                                                            day
                                                                                .toLowerCase()
                                                                                .trim()
                                                                        )
                                                                    "
                                                                    :value="
                                                                        day
                                                                            .toLowerCase()
                                                                            .trim()
                                                                    "
                                                                    :id="
                                                                        day
                                                                            .toLowerCase()
                                                                            .trim()
                                                                    "
                                                                />
                                                                <span
                                                                    class="checkmark-icon-style-02 daysSlots"
                                                                    :style="
                                                                        available_days &&
                                                                        available_days.length >
                                                                            0 &&
                                                                        available_days.indexOf(
                                                                            day
                                                                                .toLowerCase()
                                                                                .trim()
                                                                        ) > -1
                                                                            ? ''
                                                                            : 'display:none'
                                                                    "
                                                                    :for="
                                                                        day
                                                                            .toLowerCase()
                                                                            .trim()
                                                                    "
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="24.914"
                                                                        height="24.914"
                                                                        viewBox="0 0 24.914 24.914"
                                                                    >
                                                                        <path
                                                                            id="Icon_ionic-md-checkbox-outline"
                                                                            data-name="Icon ionic-md-checkbox-outline"
                                                                            d="M11.282,14.327,9.344,16.265l6.228,6.228L29.414,8.652,27.476,6.715l-11.9,11.834ZM26.645,26.645H7.268V7.268H21.109V4.5H7.268A2.776,2.776,0,0,0,4.5,7.268V26.645a2.776,2.776,0,0,0,2.768,2.768H26.645a2.776,2.776,0,0,0,2.768-2.768V15.573H26.645Z"
                                                                            transform="translate(-4.5 -4.5)"
                                                                            opacity="0.3"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    class="checkmark-icon-style-02 daysSlots"
                                                                >
                                                                    <img
                                                                        width="25px"
                                                                        height="25px"
                                                                        :style="
                                                                            available_days &&
                                                                            available_days.length >
                                                                                0 &&
                                                                            available_days.indexOf(
                                                                                day
                                                                                    .toLowerCase()
                                                                                    .trim()
                                                                            ) >
                                                                                -1
                                                                                ? 'display:none'
                                                                                : ''
                                                                        "
                                                                        src="@/assets/new-theme-images/checkbox.png"
                                                                    />
                                                                </span>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                    <ul
                                                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                        v-if="
                                                            errorMessages
                                                                .available_days
                                                                .length > 0
                                                        "
                                                    >
                                                      <!-- eslint-disable -->
                                                        <li
                                                            v-for="err in errorMessages.available_days"
                                                        >
                                                            <small>{{
                                                                err
                                                            }}</small>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <!--                      <div v-if="!!course.start_date && !!course.end_date" class="col-md-12">-->
                                            <!--                        <h5 class="mb-1 invalid-date">{{ course.start_date | DDMMMM }} - {{ course.end_date | DDMMMM }}</h5>-->
                                            <!--                      </div>-->
                                        </div>
                                        <div class="row px-3 mb-3">
                                            <div class="col-sm-6 col-6">
                                                <a
                                                    v-on:click="$parent.steps--"
                                                    href="javascript:void(0)"
                                                    class="btn btn_fontsize btn-outline-primary btn-lg back-btn"
                                                    >Back</a
                                                >
                                            </div>
                                            <div
                                                class="col-sm-6 text-right col-6"
                                            >
                                                <a
                                                    v-on:click="
                                                        checkCourseForm()
                                                    "
                                                    href="javascript:void(0)"
                                                    :disabled="!!loading"
                                                    class="next action-button btn btn-primary btn_fontsize btn-lg"
                                                >
                                                    <div
                                                        v-if="!!loading"
                                                        class="spinner-border text-primary"
                                                        style="
                                                            color: white !important;
                                                            width: 20px !important;
                                                            height: 20px !important;
                                                        "
                                                        role="status"
                                                    ></div>
                                                    <span
                                                        v-if="
                                                            !!loading == false
                                                        "
                                                    >
                                                        Save and Continue</span
                                                    >
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from "@/assets/js/axios";

const moment = require("moment");
import _ from "lodash";
import {toast} from "vue3-toastify";

export default {
    name: "Step2Component",
    data() {
        return {
            loading: false,
            success: false,
            minDate: moment().format("YYYY-MM-DD"),
            date: moment().format("YYYY-MM-DD"),
            course: {
                course_sessions: 0,
                count_weeks: 1,
                day_count: 0,
                // start_date: moment().format('YYYY-MM-DD'),
                // end_date: moment().format('YYYY-MM-DD'),
                start_date: "",
                end_date: "",
                start_at: "",
                end_at: "",
            },
            start_date: "",
            end_date: "",
            // start_date: moment().format('YYYY-MM-DD'),
            // end_date: moment().format('YYYY-MM-DD'),
            available_days: [],
            days: [],
            attributesStart: [
                {
                    key: "today",
                    highlight: true,
                    dates: moment().format("YYYY-MM-DD"),
                },
            ],
            attributesEnd: [
                {
                    key: "today",
                    highlight: true,
                    dates: moment().format("YYYY-MM-DD"),
                },
            ],
            course_minimum_duration: 1,
            course_maximum_duration: 2,
            errorMessages: {
                start_date: [],
                end_date: [],
                start_time: [],
                end_time: [],
                count_weeks: [],
                available_days: [],
            },
        };
    },
    watch: {
        "course.start_date": function (newValue, oldValue) {
            // this.available_days = []
            if (this.course.start_date) {
                this.available_days.push(
                    moment(newValue).format("dddd").toLowerCase()
                );
                this.available_days = [...new Set(this.available_days)];
                console.log(moment(oldValue).format("dddd").toLowerCase());
                if (oldValue) {
                    let index = this.available_days.indexOf(
                        moment(oldValue).format("dddd").toLowerCase()
                    );
                    if (index > -1) {
                        this.available_days.splice(index, 1);
                    }
                }
            }
        },
    },
    computed: {
        errorMessage() {
            if (!this.date) return "Date is required.";
            return "";
        },
        inputState() {
            if (!this.date) {
                return {
                    type: "is-danger",
                    message: "Date required.",
                };
            }
            return {
                type: "is-primary",
                message: "",
            };
        },
    },
    created() {
        this.days = moment.weekdays();
        if (this.$route.params.id) this.getCourseDetails();
    },
    methods: {
        checkCourseForm() {
            this.course.days = JSON.stringify(this.available_days);
            this.startDayDateCheck();
            this.checkCourseStartDate();
            this.checkCourseStartTime();
            this.checkCourseEndTime();
            this.checkTimeDifference();
            this.checkWeekDays();
            this.checkAvailableDays();
            this.startDayDateCheck();
            // else if (!this.course.start_time) {
            //   this.$toasted.error('Choose Course Start Time.');
            // }
            // else if (!this.course.end_time) {
            //   this.$toasted.error('Choose Course End Time.');
            // }
            // else if (!this.course.days) {
            //   this.$toasted.error('Course Days Required');
            // }
            if (
                this.checkCourseStartDate() &&
                this.checkCourseStartTime() &&
                this.checkCourseEndTime() &&
                this.checkTimeDifference() &&
                this.checkWeekDays() &&
                this.checkAvailableDays() &&
                this.startDayDateCheck()
            ) {
                if (this.$route.params.id) {
                    this.updateCourse();
                }
            }
        },
        checkCourseEndTime() {
            this.errorMessages.end_time = [];
            if (!this.course.end_time) {
                this.errorMessages.end_time.push("Choose End Time...!");
                return false;
            } else {
                this.errorMessages.end_time = [];
                return true;
            }
        },
        checkCourseStartTime() {
            this.errorMessages.start_time = [];
            if (!this.course.start_time) {
                this.errorMessages.start_time.push("Choose Start Time...!");
                return false;
            } else {
                this.errorMessages.start_time = [];
                return true;
            }
        },
        checkCourseStartDate() {
            this.errorMessages.start_date = [];
            if (!this.course.start_date) {
                this.errorMessages.start_date.push("Choose Start Date...!");
                return false;
            } else {
                this.errorMessages.start_date = [];
                return true;
            }
        },
        checkWeekDays() {
            this.errorMessages.count_weeks = [];
            if (!this.course.count_weeks || this.course.days === 0) {
                this.errorMessages.count_weeks.push("Enter weeks count...!");
                return false;
            } else {
                this.errorMessages.count_weeks = [];
                return true;
            }
        },
        checkAvailableDays() {
            this.errorMessages.available_days = [];
            if (!this.available_days || this.available_days.length === 0) {
                this.errorMessages.available_days.push(
                    "Choose available days...!"
                );
                return false;
            } else {
                this.errorMessages.available_days = [];
                return true;
            }
        },
        checkTimeDifference() {
            this.errorMessages.start_time = [];
            let a = moment(
                this.course.start_date + "T" + this.course.start_time
            );
            let b = moment(this.course.end_date + "T" + this.course.end_time);
            let duration = moment.duration(b.diff(a));
            let hours = duration.hours();
            let response = true;
            if (
                hours > this.course_maximum_duration ||
                hours < this.course_minimum_duration
            ) {
                this.errorMessages.start_time.push(
                    "Course has maximum " +
                        this.course_maximum_duration +
                        " and minimum " +
                        this.course_minimum_duration +
                        " hours."
                );
                response = false;
            }
            duration = moment.duration(moment().diff(a));
            if (moment() > a) {
                this.errorMessages.start_time.push(
                    "start date time should greater than now."
                );
                response = false;
            }
            return response;
        },
        onDayClickStart(day) {
            (this.attributesStart[0].dates =
                this.start_date =
                this.course.start_date =
                    moment(day.id).format("YYYY-MM-DD")),
                this.getNumberOfWeeks();
            this.startDayDateCheck();
        },
        onDayClickEnd(day) {
            this.attributesEnd[0].dates =
                this.end_date =
                this.course.end_date =
                    moment(day.id).format("YYYY-MM-DD");
            this.getNumberOfWeeks();
        },
        sortSelectedDays() {
            this.available_days.sort(function (a, b) {
                return moment().day(a).weekday() - moment().day(b).weekday();
            });
        },
        startDayDateCheck() {
            let self = this;
            let success = false;
            if (this.available_days.length < 1) {
                return;
            }
            this.sortSelectedDays();
            let previousWeek = [];
            let currentWeek = [];
            let nextWeek = [];
            let x = 0;
            let d = moment(this.course.start_date).day();
            let difference = d - moment().day(this.available_days[0]).weekday();
            if (difference !== 0) {
                while (x < 7) {
                    previousWeek.push({
                        date: moment(
                            moment(this.course.start_date)
                                .startOf("week")
                                .add(-1, "weeks")
                        )
                            .add(x, "day")
                            .format(),
                        day: moment(
                            moment(this.course.start_date)
                                .startOf("week")
                                .add(-1, "weeks")
                        )
                            .add(x, "day")
                            .format("dddd")
                            .toLowerCase(),
                    });
                    currentWeek.push({
                        date: moment(this.course.start_date)
                            .startOf("week")
                            .add(x, "day")
                            .format(),
                        day: moment(this.course.start_date)
                            .startOf("week")
                            .add(x, "day")
                            .format("dddd")
                            .toLowerCase(),
                    });
                    nextWeek.push({
                        date: moment(
                            moment(this.course.start_date)
                                .startOf("week")
                                .add(1, "weeks")
                        )
                            .add(x, "day")
                            .format(),
                        day: moment(
                            moment(this.course.start_date)
                                .startOf("week")
                                .add(1, "weeks")
                        )
                            .add(x, "day")
                            .format("dddd")
                            .toLowerCase(),
                    });
                    x++;
                }
                let pre = previousWeek.findIndex(
                    (previous) =>
                        this.available_days[0] === previous.day &&
                        previous.date >= moment().format()
                );
                let cur = currentWeek.findIndex(
                    (current) =>
                        this.available_days[0] === current.day &&
                        current.date >= moment().format()
                );
                let nex = nextWeek.findIndex(
                    (next) =>
                        this.available_days[0] === next.day &&
                        next.date >= moment().format()
                );
                this.errorMessages.start_date = [];
                if (cur > -1) {
                    self.errorMessages.start_date.push(
                        "Based on your days' selection, the start date should be either " +
                            moment(currentWeek[cur].date).format("YYYY-MM-DD") +
                            " or " +
                            moment(nextWeek[nex].date).format("YYYY-MM-DD")
                    );
                    success = false;
                } else if (pre > -1) {
                    self.errorMessages.start_date.push(
                        "Based on your days' selection, the start date must be " +
                            moment(previousWeek[pre].date).format(
                                "YYYY-MM-DD"
                            ) +
                            " or " +
                            moment(nextWeek[nex].date).format("YYYY-MM-DD")
                    );
                    success = false;
                } else if (nex > -1) {
                    self.errorMessages.start_date.push(
                        "Based on your days' selection, the start date must be " +
                            moment(nextWeek[nex].date).format("YYYY-MM-DD")
                    );
                    success = false;
                }
            } else {
                this.errorMessages.start_date = [];
                success = true;
            }

            this.getNumberOfWeeks();
            return success;
        },
        getNumberOfWeeks() {
            let self = this;
            this.sortSelectedDays();
            if (self.course.start_date) {
                this.attributesEnd[0].dates = self.course.end_date = moment(
                    self.course.start_date
                )
                    .add(self.course.count_weeks, "weeks")
                    .format("YYYY-MM-DD");
                this.attributesEnd[0].dates = self.course.end_date = moment(
                    self.course.end_date
                )
                    .subtract(1, "days")
                    .format("YYYY-MM-DD");
                if (self.available_days.length > 0)
                    while (
                        moment(self.course.end_date)
                            .format("dddd")
                            .toUpperCase() !==
                        this.available_days[
                            this.available_days.length - 1
                        ].toUpperCase()
                    ) {
                        this.attributesEnd[0].dates = self.course.end_date =
                            moment(self.course.end_date)
                                .subtract(1, "days")
                                .format("YYYY-MM-DD");
                    }
                let a = moment(self.start_date);
                let b = moment(self.course.end_date);
                self.course.day_count = moment.duration(b.diff(a)).asDays();
                self.course.course_sessions =
                    self.available_days.length * self.course.count_weeks;
            }
        },
        getCourseDetails() {
            let id = this.$route.params.id;
            let self = this;
            axiosInstance
                .get("/v1/course/edit/" + id)
                .then(function (response) {
                    // handle success
                    if (response.data.type === "success") {
                        self.course = response.data.data;
                        if (
                            !!self.course.start_at &&
                            !!self.course.start_time
                        ) {
                            self.course.start_at = moment(
                                self.course.start_at
                            ).format();
                            self.course.end_at = moment(
                                self.course.end_at
                            ).format();

                            self.attributesStart[0].dates =
                                self.start_date =
                                self.course.start_date =
                                    moment(self.course.start_at).format(
                                        "YYYY-MM-DD"
                                    );
                            self.attributesEnd[0].dates =
                                self.end_date =
                                self.course.end_date =
                                    moment(self.course.end_at).format(
                                        "YYYY-MM-DD"
                                    );

                            self.course.start_time = moment(
                                self.course.start_at
                            ).format("HH:mm:ss");
                            self.course.end_time = moment(
                                self.course.end_at
                            ).format("HH:mm:ss");
                        } else {
                            self.course.count_weeks = 1;
                            // self.course.start_date = moment().format('YYYY-MM-DD')
                            // self.course.end_date = moment().add(1, 'week').format('YYYY-MM-DD')
                        }

                        self.course.sessionroom =
                            response.data.data.session_room;
                        self.course_minimum_duration = parseInt(
                            response.data.meta["course_minimum_duration"]
                        );
                        self.course_maximum_duration = parseInt(
                            response.data.meta["course_maximum_duration"]
                        );

                        if (response.data.data.days) {
                            self.available_days = response.data.days;
                        }
                    } else {
                        toast.error(response.data.message);
                        self.$router.replace("/tutor/courses");
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .then(function () {});
        },
        updateCourse() {
            let self = this;
            self.loading = true;
            let a = moment(
                this.course.start_date + "T" + this.course.start_time
            );
            let b = moment(this.course.end_date + "T" + this.course.end_time);
            let duration = moment.duration(b.diff(a));
            let hours = duration.hours();
            self.course.course_sessions =
                self.available_days.length * self.course.count_weeks;

            if (hours > 2) {
                this.checkTimeDifference();
                return;
            }

            self.course.start_at = moment(
                self.course.start_date + "T" + self.course.start_time
            ).format();
            self.course.end_at = moment(
                self.course.end_date + "T" + self.course.end_time
            ).format();
            this.course.sessionroom = JSON.stringify(this.course.sessionroom);

            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            let formData = new FormData();
            formData.append("file", self.file);
            _.each(self.course, (value, key) => {
                formData.append(key, value);
            });
            if (self.$route.params.id) {
                let id = self.$route.params.id;
                formData.append("id", id);
            }
            axiosInstance
                .post("/v1/course/create/step-2", formData, config)
                .then(function (response) {
                    self.loading = false;

                    // handle success
                    if (response.data.data.type === "success") {
                        self.$parent.steps++;
                    }
                })
                .catch(function (error) {
                    self.loading = false;
                    // handle error
                    console.log(error);
                })
                .then(function () {});
        },
        availableDays(day) {
            let self = this;
            if (self.available_days.findIndex((d) => d === day) < 0) {
                self.available_days.push(day);
                if (
                    self.available_days.length > 0 &&
                    self.course.count_weeks > 0
                )
                    self.getNumberOfWeeks();
            } else {
                let ind = self.available_days.indexOf(day);
                if (ind > -1) {
                    self.available_days.splice(ind, 1);
                    if (
                        self.available_days.length > 0 &&
                        self.course.count_weeks > 0
                    )
                        self.getNumberOfWeeks();
                }
            }
            self.course.course_sessions =
                self.available_days.length * self.course.count_weeks;
            this.startDayDateCheck();
            if (this.available_days.length > 0) {
                self.errorMessages.available_days = [];
            }
        },
        checkAvailableDay(day) {
            return (
                this.available_days.findIndex(
                    (d) => d === day.toLowerCase().trim()
                ) > -1
            );
        },
    },
};
</script>

<style scoped>
.availible_days {
    display: flex;
    flex-wrap: wrap;
    width: 560px;
    margin-top: 26px;
}

.listStyle {
    padding: 12px !important;
    padding-top: 0 !important;
}

@media only screen and (max-width: 800px) {
    .availible_days {
        display: flex;
        flex-wrap: wrap;
        /* overflow: visible; */
        /* overflow-wrap: break-word; */
        /* white-space: nowrap; */
        /* gap: 0 30px; */
        width: 441px;
    }

    .listStyle {
        padding: 10px !important;
        padding-top: 0 !important;
    }
}

@media only screen and (max-width: 800px) {
    .availible_days {
        display: flex;
        flex-wrap: wrap;
        /* overflow: visible; */
        /* overflow-wrap: break-word; */
        /* white-space: nowrap; */
        /* gap: 0 30px; */
        width: 441px;
    }

    .listStyle {
        padding: 10px !important;
        padding-top: 0 !important;
    }
}

@media only screen and (max-width: 576px) {
    .container {
        max-width: 100% !important;
    }

    .availible_days {
        display: block;
        width: 200px;
    }
}

@media screen and (min-width: 1400px) {
    .container {
        max-width: 1450px !important;
    }
}

@media screen and (min-width: 1850px) {
    .container {
        max-width: 2150px !important;
    }
}
</style>
