<template>
    <div class="course-box" v-if="tutor">
        <div style="width: 80px; height: 80px">
            <a :href="'/profile/tutor/' + tutor.uid">
                <img
                    style="
                        width: 50px;
                        height: 50px;
                        border-radius: 999px;
                        border: 1px solid;
                    "
                    src="@/assets/images/profile.png"
                    :alt="tutor.name"
                />
            </a>
            <div class="course-box-instructor-level">Top Rated</div>
        </div>
        <div class="course-box-content py-2">
            <strong class="course-box-title" :title="tutor?.name">
                <a :href="'/profile/tutor/' + tutor.uid">
                    {{ tutor.name }}
                    <i
                        v-if="tutor?.verified"
                        class="fas fa-check-circle"
                        style="color: #812bb2"
                    ></i>
                </a>
                <!-- <i class="fa fa-check-circle" aria-hidden="true"></i> -->
                <span class="tutor-field"> {{ tutor?.info?.about_short }}</span>
            </strong>
            <p
                class="course-box-author-name"
                v-if="tutor.qualifications?.length > 0"
            >
                {{ tutor?.qualifications[0]?.education?.level }} from
                {{ tutor?.qualifications[0]?.institution?.name }}
                {{ tutor.qualifications[0]?.institution?.city }}
            </p>
            <div class="per-session-content mobile">
                <div class="course-box-prices">
                    {{ tutor.info?.price }} {{ "Pkr" }}
                </div>
                <p class="mb-1 per-hour">/hr</p>
            </div>
            <div class="tutor-subject-details desktop">
                <span class="subject-t">Subject:</span>
                <span class="subjects-content text-14">{{
                    tutor.subjects.length? choosedSubjects(tutor) : ''
                }}</span>
            </div>

            <div class="course-outline desktop">
                <span class="subject-t">Levels:</span>
                {{ tutor.grades.length? choosedGrades(tutor) : ''}}
            </div>
            <a :href="'/profile/tutor/' + tutor.uid">
                <p
                    class="course-box-text desktop"
                    title=""
                    v-if="tutor.info?.about?.length > 350"
                    v-html="
                        tutor.info?.about?.substring(0, 350) +
                        `<a href='/profile/tutor/${tutor.uid}'>....View More</a>`
                    "
                ></p>
                <p
                    class="course-box-text desktop"
                    title=""
                    v-else
                    v-html="tutor.info?.about"
                ></p>
            </a>
        </div>

        <div class="course-box-price py-2">
            <div>
                <div class="tutor-subject-details mobile">
                    <span class="subject-t">Subject:</span>
                    <span class="subjects-content text-14">{{
                        tutor.subjects.length? choosedSubjects(tutor) : ''
                    }}</span>
                </div>

                <div class="course-outline mobile">
                    <span class="subject-t">Levels:</span>
                    {{ tutor.grades.length? choosedGrades(tutor) : '' }}
                </div>
                <p
                    class="course-box-text mobile"
                    title=""
                    v-html="tutor.info?.about?.substring(0, 75)"
                ></p>
                <div class="per-session-content desktop">
                    <div class="course-box-prices">
                        {{ tutor.info?.price }} {{ "Pkr" }}
                    </div>
                    <p class="mb-1 per-hour">/hr</p>
                </div>
                <div class="course-box-detail">
                    <ul>
                        <li>
                            <img
                                width="18"
                                height="18"
                                src="@/assets/new-theme-images/tutor-calendar.png"
                            />
                            {{ latestActivity(tutor) }}
                        </li>
                        <li v-if="!!tutor.sessions_sum_duration">
                            <img
                                width="20"
                                height="20"
                                src="@/assets/new-theme-images/tutor-time.png"
                            />
                            {{ tutor.sessions_sum_duration }} hours tutoring
                        </li>
                        <li>
                            <img
                                width="20"
                                height="20"
                                src="@/assets/new-theme-images/tutor-education.png"
                            />
                            Avg Response Time: {{ tutor?.info?.response_time }}
                        </li>
                        <li>
                            <img
                                width="20"
                                height="20"
                                src="@/assets/new-theme-images/tutor-location.png"
                            />
                            {{ tutor?.info?.city?.title }}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="book-join-btn text-white">
                <router-link
                    :to="'/profile/tutor/' + tutor.id + '#schedule'"
                    class="btn btn-primary text-white"
                >
                    Hire Me
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";

export default {
    name: "TutorTabComponent",
    props: ["tutor"],
    data() {
        return {};
    },
    created() {
    },
    mounted() {},
    methods: {
        latestActivity(tutor) {
            let created = moment(tutor.updated_at);
            let today = moment(new Date());
            return "Active " + moment.duration(today.diff(created)).humanize();
        },
        choosedGrades(tutor) {
            let sub = "";
            tutor.grades.forEach(function (grade, index) {
                if (tutor.grades.length - 1 > index) {
                    sub += grade.grade?.name + "," + " ";
                } else {
                    sub += grade.grade?.name;
                }
            });
            return sub;
        },
        choosedSubjects(tutor) {
            let sub = "";
            tutor.subjects.forEach(function (subject, index) {
                if (tutor.subjects.length - 1 > index) {
                    sub += subject.subject + "," + " ";
                } else {
                    sub += subject.subject;
                }
            });
            return sub;
        },
    },
};
</script>

<style scoped></style>
