<template>
    <div>
        <step-1 v-if="steps === 1"></step-1>
        <step-2 v-if="steps === 2"></step-2>
        <!--    <step-3 v-if="steps === 3"></step-3>-->
        <step-4 v-if="steps === 3"></step-4>
        <step-5 v-if="steps === 4"></step-5>
        <step-6 v-if="steps === 5"></step-6>
        <step-7 v-if="steps === 6"></step-7>
        <step-8 v-if="steps === 7"></step-8>

        <div v-if="threads.length > 0" class="row py-lg-5">
            <div
                class="container adding-courses-newtheme course-edit-container"
            >
                <div class="col-md-12 p-0">
                    <!-- DIRECT CHAT -->
                    <div class="card direct-chat direct-chat-warning">
                        <div class="card-header">
                            <div class="card-title px-3 pt-3">
                                Threads Step#{{ steps }}
                            </div>
                            <div class="card-tools"></div>
                        </div>
                        <div class="card-body">
                            <div class="direct-chat-messages">
                                <!-- Message. Default to the left -->
                                <div
                                    class="direct-chat-msg"
                                    v-for="thread in threads" :key="thread.id"
                                    :class="
                                        thread.user.roles.findIndex(
                                            (role) => role.name === 'admin'
                                        ) > -1
                                            ? 'right'
                                            : 'left'
                                    "
                                >
                                    <div class="direct-chat-infos clearfix">
                                        <span
                                            class="direct-chat-name"
                                            :class="
                                                thread.user.roles.findIndex(
                                                    (role) =>
                                                        role.name === 'admin'
                                                ) > -1
                                                    ? 'float-right'
                                                    : 'float-left'
                                            "
                                            >{{ thread.user.name }}</span
                                        >
                                        <span
                                            class="direct-chat-timestamp"
                                            :class="
                                                thread.user.roles.findIndex(
                                                    (role) =>
                                                        role.name === 'admin'
                                                ) > -1
                                                    ? 'float-left'
                                                    : 'float-right'
                                            "
                                            >{{
                                                thread.created_at
                                            }}</span
                                        >
                                    </div>
                                    <img
                                        class="direct-chat-img"
                                        width="20px"
                                        :src="'/storage' + thread.user.image"
                                        alt="message user image"
                                    />
                                    <div class="direct-chat-text">
                                        {{ thread.message }}
                                        <img
                                            v-on:click="showMedia(thread.media)"
                                            width="50px"
                                            :src="'/storage' + thread.media"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <form
                                @submit.prevent=""
                                class="d-flex align-items-center"
                            >
                                <input
                                    type="text"
                                    name="message"
                                    class="width-90 px-3"
                                    placeholder="Type Message ..."
                                    v-model="message"
                                />
                                <label
                                    for="FileInput"
                                    class="filelabel mx-3 my-0"
                                >
                                    <i class="fa fa-paperclip"> </i>
                                    <input
                                        ref="file"
                                        v-on:change="handleFileUpload"
                                        class="FileUpload1 d-none"
                                        id="FileInput"
                                        name="booking_attachment"
                                        type="file"
                                    />
                                </label>
                                <button
                                    type="button"
                                    v-on:click="courseThreadDiscussion()"
                                    class="btn btn-warning"
                                    :disabled="!!sendingMessageLoading"
                                >
                                    <div
                                        v-if="!!sendingMessageLoading"
                                        class="spinner-border text-primary"
                                        style="
                                            color: white !important;
                                            width: 20px !important;
                                            height: 20px !important;
                                        "
                                        role="status"
                                    ></div>
                                    <span
                                        v-if="!!sendingMessageLoading == false"
                                        >Send</span
                                    >
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="modal courseStatusChange"
            v-if="showMediaImage"
            style="
                display: block;
                background-color: rgba(0, 0, 0, 0.4);
                overflow-x: scroll;
            "
            id="exampleModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div
                class="modal-dialog job_alert_modal modal-dialog-centered modal-xl"
            >
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="title">Media</h3>
                        <button
                            v-on:click="showMediaImage = !showMediaImage"
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12">
                            <img
                                :src="'/storage' + media"
                                alt=""
                                style="width: 100%"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Step1Component from "@/components/tutor/course/steps/Step1Component";
import Step2Component from "@/components/tutor/course/steps/Step2Component";
// import Step3Component from "@/components/tutor/course/steps/Step3Component";
import Step4Component from "@/components/tutor/course/steps/Step4Component";
import Step5Component from "@/components/tutor/course/steps/Step5Component";
import Step6Component from "@/components/tutor/course/steps/Step6Component";
import Step7Component from "@/components/tutor/course/steps/Step7Component";
import Step8Component from "@/components/tutor/course/steps/Step8Component";
import moment from "moment-timezone";
import _ from "lodash";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
    name: "CreateCourseStepComponent",
    components: {
        "step-1": Step1Component,
        "step-2": Step2Component,
        // "step-3": Step3Component,
        "step-4": Step5Component,
        "step-5": Step4Component,
        "step-6": Step6Component,
        "step-7": Step7Component,
        "step-8": Step8Component,
    },
    data() {
        return {
            sendingMessageLoading: false,
            showMediaImage: false,
            media: "",
            steps: 1,
            stepStatus: "comment",
            message: "",
            course: {
                sessionroom: {},
                category_id: 0,
                board_id: 0,
                class_id: 0,
                level: 0,
                language: 0,
                start_date: moment().format("YYYY-MM-DD"),
                end_date: moment().format("YYYY-MM-DD"),
                count_weeks: 5,
                day_count: 0,
                start_time: [],
                end_time: [],
                outline: [],
            },
            threads: [],
            outlines: [],
            outline: [],
            course_outlines: [],
            categories: [],
            educationBoards: [],
            educationLevels: [],
            languages: [],
            days: {
                type: Array(),
                default: [],
            },
            available_days: [],
            requirement: {
                requirement: "",
            },
            outcome: {
                outcome: "",
            },
            outcomes: [],
            requirements: [],
            errors: {},
            image: "",
            discount: 0.0,
            selected_week: 0,
            selected_outline: [],
            showModal: false,
            outlineState: "",
        };
    },
    created() {
        this.getCourseThreads();
    },
    watch: {
        steps: function () {
            this.getCourseThreads();
        },
    },
    mounted() {},
    methods: {
        async changeCourseStatus(status) {
            let self = this;
            let id = this.$route.params.id;
            let formData = new FormData();
            formData.append("course_id", id);
            formData.append("status", status);
            formData.append("step", self.steps);
            const { data } = await axiosInstance.post(endpoints.changeCourseStatusRoute, formData);
            if (data.data.status === "approved") {
                await self.getCourseDetails();
            }
            await this.getCourseThreads();
        },
        async getCourseThreads() {
            let self = this;
            let id = this.$route.params.id;
            let formData = new FormData();
            formData.append("course_id", id);
            formData.append("step", self.steps);
            formData.append("sort", "DESC");
            const { data } = await axiosInstance.post(endpoints.getCourseThreadsRoute, formData);
            this.threads = data.threads;
            this.stepStatus = data.status;
        },
        async courseThreadDiscussion() {
            let self = this;
            let id = this.$route.params.id;
            let message = this.message;
            let formData = new FormData();
            formData.append("course_id", id);
            formData.append("media", self.file);
            formData.append("message", message);
            formData.append("step", self.steps);
            this.sendingMessageLoading = true;
            await axiosInstance.post(endpoints.updateCourseStatus, formData);
            // this.$parent.selectedCourse = 0
            this.message = "";
            // this.$parent.courseDisableReasonModal = !this.$parent.courseDisableReasonModal
            await this.getCourseThreads();
            this.sendingMessageLoading = false;
        },
        getCategories() {
            let self = this;
            axiosInstance
                .get("/v1/categories")
                .then(function (response) {
                    // handle success
                    self.categories = response.data.data;
                    // console.log(response.data.data);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        },
        getEducationBoards() {
            let self = this;
            axiosInstance
                .get("/webapi/getEducationBoards")
                .then(function (response) {
                    // handle success
                    self.educationBoards = response.data.data;
                    // console.log(response.data.data);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        },
        getEducationLevels() {
            let self = this;
            axiosInstance
                .get("/v1/levels")
                .then(function (response) {
                    // handle success
                    // console.log(response.data.data);
                    self.educationLevels = response.data.data;
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {});
        },

        // availableDays (day, i) {
        //   let self = this
        //   let index = i
        //   if(!self.available_days[index]) {
        //     self.available_days[index] = []
        //   }
        //   if (document.querySelector('#'+day+'-'+index).checked) {
        //     self.available_days[index].push(day)
        //   }
        //   else {
        //     let ind = self.available_days[index].indexOf(day)
        //     if (ind > -1) {
        //       self.available_days[index].splice(ind, 1);
        //     }
        //   }
        // },
        availableDays(day) {
            let self = this;
            if (document.querySelector("#" + day).checked) {
                self.available_days.push(day);
            } else {
                let ind = self.available_days.indexOf(day);
                if (ind > -1) {
                    self.available_days.splice(ind, 1);
                }
            }
        },
        addNewRequirements() {
            this.requirements.push({...this.requirement});
        },
        addNewOutCome() {
            this.outcomes.push({...this.outcome});
        },
        removeoOutComes(index) {
          this.outcomes.splice(index, 1);
        },
        removeApartment(index) {
          this.requirements.splice(index, 1);
        },
        checkCourseForm() {
            this.course.days = JSON.stringify(this.available_days);
            this.course.requirements = JSON.stringify(this.requirements);
            this.course.outcomes = JSON.stringify(this.outcomes);
            this.course.image = this.image;
            this.errors = [];

            if (
                this.course.description &&
                this.course.description.lenght < 100
            ) {
                this.errors.push(
                    "Enter Minimum 100 characters for Description"
                );
                return;
            }

            if (
                this.course.title &&
                this.course.short_description &&
                this.course.description &&
                this.course.category_id &&
                // this.course.language &&
                this.course.start_time &&
                this.course.end_time &&
                this.course.sessionroom.title &&
                this.course.description &&
                this.course.price &&
                // this.course.meta_keywords &&
                this.course.days
                // &&
                // this.course.requirements &&
                // this.course.outcomes &&
                // this.course.image &&
                // this.course.meta_description
            ) {
                if (this.$route.params.id) {
                    this.updateCourse();
                } else {
                    this.createCourse();
                }
            }

            if (!this.course.title) {
                this.errors.push("Course Title required.");
                this.scrollToTop();
            }
            if (!this.course.short_description) {
                this.errors.push("Short Description Required.");
                this.scrollToTop();
            }
            if (!this.course.description) {
                this.errors.push("Detailed Description Required.");
                this.scrollToTop();
            }
            if (!this.course.category_id) {
                this.errors.push("Choose Category required.");
                this.scrollToTop();
            }
            // if (!this.course.board_id) {
            //   this.errors.push('Choose Board.');
            //   this.scrollToTop()
            // }
            // if (!this.course.class_id) {
            //   this.errors.push('Choose Education Level.');
            //   this.scrollToTop()
            // }
            // if (!this.course.level) {
            //   this.errors.push('Choose Level.');
            //   this.scrollToTop()
            // }
            // if (!this.course.language) {
            //   this.errors.push('Choose Language.');
            //   this.scrollToTop()
            // }
            // if (!this.course.is_top_course) {
            //   this.errors.push('Age required.');
            // }
            if (!this.course.start_time) {
                this.errors.push("Choose Course Start Time.");
                this.scrollToTop();
            }
            if (!this.course.end_time) {
                this.errors.push("Choose Course End Time.");
                this.scrollToTop();
            }
            // if (!this.course.sessionroom.title) {
            //   this.errors.push('Meeting Title required.');
            //   this.scrollToTop()
            // }
            if (!this.course.days) {
                this.errors.push("Course Days Required");
                this.scrollToTop();
            }
            // if (!this.course.requirements) {
            //   this.errors.push('Course Requirements Required');
            //   this.scrollToTop()
            // }
            if (!this.course.outcomes) {
                this.errors.push("Course Outcomes Required");
                this.scrollToTop();
            }
            // if (!this.course.image) {
            //   this.errors.push('Image Required');
            // }
            if (!this.course.description) {
                this.errors.push("Description Required.");
                this.scrollToTop();
            }
            if (!this.course.price) {
                this.errors.push("Course Price Required.");
                this.scrollToTop();
            }
            // if (!this.course.discounted_price) {
            //   this.errors.push('Discounted Price Required.');
            // }
            // if (!this.course.is_free_course) {
            //   this.errors.push('Age required.');
            // }
            // if (!this.course.discount_flag) {
            //   this.errors.push('Age required.');
            // }
            // if (!this.course.meta_keywords) {
            //   this.errors.push('Meta Keywords Required.');
            //   this.scrollToTop()
            // }
            // if (!this.course.meta_description) {
            //   this.errors.push('Meta Description Required.');
            //   this.scrollToTop()
            // }
        },
        scrollToTop() {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        },
        createCourse() {
            let self = this;
            this.course.sessionroom = JSON.stringify(this.course.sessionroom);
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            let formData = new FormData();
            formData.append("file", self.file);
            _.each(self.course, (value, key) => {
                formData.append(key, value);
            });
            formData.append(
                "sessions",
                self.available_days.length * self.course.count_weeks
            );

            axiosInstance
                .post("/ v1/course/store", formData, config)
                .then(function (response) {
                    // handle success
                    if (response.data.type === "success") {
                        self.$toasted.success(response.data.message);
                        self.days = self.requirements = self.outcomes = [];
                        self.image = "";
                        self.course = {};
                        self.course.sessionroom = {};
                        self.$router.push({
                            name: "updateCourse",
                            params: { id: response.data.id },
                        });
                        self.getCourseDetails();
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        },
        updateCourse() {
            let self = this;
            let id = this.$route.params.id;
            this.course.sessionroom = JSON.stringify(this.course.sessionroom);

            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            let formData = new FormData();
            formData.append("file", self.file);
            _.each(self.course, (value, key) => {
                formData.append(key, value);
            });

            axiosInstance
                .post("/v1/course/update/" + id, formData, config)
                .then(function (response) {
                    // handle success
                    if (response.data.type === "success") {
                        self.$toasted.success(response.data.message);
                        self.days = self.requirements = self.outcomes = [];
                        self.image = "";
                        self.course = {};
                        self.course.sessionroom = {};
                        self.$router.push({ name: "courses" });
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        },
        handleFileUpload() {
            this.file = this.$refs.file.files[0];
        },
        getCourseDetails() {
            let id = this.$route.params.id;
            let self = this;
            axiosInstance
                .get("/v1/course/detail/" + id)
                .then(function (response) {
                    // handle success
                    if (response.data.type === "success") {
                        self.outline = response.data.data.outlines;
                        self.course = response.data.data;
                        self.available_days = JSON.parse(
                            response.data.data.days
                        );
                        self.requirements = JSON.parse(
                            response.data.data.requirements
                        );
                        self.outcomes = JSON.parse(response.data.data.outcomes);
                        self.course.sessionroom =
                            response.data.data.session_room;
                    } else {
                        self.$toasted.error(response.data.message);
                        self.$router.replace("/tutor/courses");
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                    self.breakCourseOutlines();
                });
        },
        getNumberOfWeeks() {
            let self = this;
            self.course.end_date = moment(self.course.start_date)
                .add(self.course.count_weeks, "weeks")
                .format("YYYY-MM-DD");
            let a = moment(self.course.start_date);
            let b = moment(self.course.end_date);
            self.course.day_count = moment.duration(b.diff(a)).asDays();
        },
        calculatePricePercentage() {
            if (this.course.price && this.course.discounted_price) {
                this.discount = (
                    (this.course.discounted_price / this.course.price) *
                    100
                ).toFixed(2);
            } else {
                this.discount = 0.0;
            }
        },
        showOutlineModal(id) {
            this.showModal = true;
            this.selected_week = id;
            this.outline = "";
        },
        close() {
            this.showModal = false;
            this.selected_week = 0;
            this.outlineState = "";
        },
        validateCourseOultineForm() {
            let self = this;
            let url = "";
            let outline_id = 0;
            let outline = this.outline;
            let week = this.selected_week;
            let id = this.$route.params.id;
            if (this.outlineState === "update") {
                url = "/webapi/updateCourseOutline";
                outline_id = self.selected_outline.id;
            } else {
                url = "/v1/course/outline/add";
            }
            if (this.outline) {
                axiosInstance
                    .post(url, {
                        id: outline_id,
                        course_id: id,
                        outline: outline,
                        week: week,
                    })
                    .then(function (response) {
                        // handle success
                        self.outline = [];
                        self.outline = response.data.outlines;
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                    .then(function () {
                        self.close();
                        self.breakCourseOutlines();
                        self.outline = "";
                    });
            }
        },
        breakCourseOutlines() {
            let self = this;
            self.outlines = [];
            self.outline.forEach(function (outline) {
                if (!self.outlines[outline.week]) {
                    self.outlines[outline.week] = [];
                }
                self.outlines[outline.week].push(outline);
            });
        },
        editOutline(outline) {
            this.outlineState = "update";
            this.outline = outline.outline;
            this.selected_week = outline.week;
            this.selected_outline = outline;
            this.showModal = true;
        },
        deleteOutline(outline) {
            let self = this;
            let id = this.$route.params.id;

            let url = "/v1/course/outline/delete";
            if (outline) {
                axiosInstance
                    .post(url, {
                        id: outline.id,
                        course_id: id,
                        outline: outline.outline,
                        week: outline.week,
                    })
                    .then(function (response) {
                        // handle success
                        self.outline = [];
                        self.outline = response.data.outlines;
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                    .then(function () {
                        self.close();
                        self.breakCourseOutlines();
                        self.outline = "";
                    });
            }
        },
        showMedia(path) {
            this.media = path;
            this.showMediaImage = true;
        },
    },
};
</script>

<style scoped>
label.filelabel {
    padding: 2px;
    font-size: 20px !important;
}

.direct-chat-text {
    border-radius: 0.3rem;
    background-color: #d2d6de;
    border: 1px solid #d2d6de;
    color: #444;
    margin: 5px 0 0 50px;
    padding: 5px 10px;
    position: relative;
}
.right .direct-chat-text {
    background-color: #f39c12;
    border-color: #f39c12;
    color: #1f2d3d;
    border-radius: 0.3rem;
    margin: 5px 0 0 50px;
    padding: 5px 10px;
    position: relative;
}
.direct-chat-msg {
    margin-bottom: 10px;
}
.direct-chat-infos {
    display: block;
    font-size: 0.875rem;
    margin-bottom: 2px;
}
.direct-chat-img {
    border-radius: 50%;
    float: left;
    height: 40px;
    width: 40px;
}
.direct-chat-text::after,
.direct-chat-text::before {
    border-right-color: #4b545c;
}
.direct-chat-text::before {
    border-width: 6px;
    margin-top: -6px;
}
.right .direct-chat-img {
    float: right;
}

.direct-chat-primary .right > .direct-chat-text {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
}
.right .direct-chat-text {
    margin-left: 0;
    margin-right: 50px;
}
.direct-chat-text {
    border-radius: 0.3rem;
    background-color: #d2d6de;
    border: 1px solid #d2d6de;
    color: #444;
    margin: 5px 0 0 50px;
    padding: 5px 10px;
    position: relative;
}

.direct-chat-warning .right > .direct-chat-text::after,
.direct-chat-warning .right > .direct-chat-text::before {
    border-left-color: #f39c12;
}
.right > .direct-chat-text::after,
.right > .direct-chat-text::before {
    border-right-color: transparent;
}
.direct-chat-warning .right > .direct-chat-text::after,
.direct-chat-warning .right > .direct-chat-text::before {
    border-left-color: #ffc107;
}
.direct-chat-text::after,
.direct-chat-text::before {
    border-right-color: #4b545c;
}
.right .direct-chat-text::after,
.right .direct-chat-text::before {
    border-left-color: #d2d6de;
    border-right-color: transparent;
    left: 100%;
    right: auto;
}
.direct-chat-text::before {
    border-width: 6px;
    margin-top: -6px;
}
.direct-chat-text::after,
.direct-chat-text::before {
    border: solid transparent;
    border-right-color: #d2d6de;
    content: " ";
    height: 0;
    pointer-events: none;
    position: absolute;
    right: 100%;
    top: 15px;
    width: 0;
}
.direct-chat-primary .right > .direct-chat-text::after,
.direct-chat-primary .right > .direct-chat-text::before {
    border-left-color: #007bff;
}

.right .direct-chat-text::after,
.right .direct-chat-text::before {
    border-left-color: #d2d6de;
    border-right-color: transparent;
    left: 100%;
    right: auto;
}
.direct-chat-text::after {
    border-width: 5px;
    margin-top: -5px;
}
.direct-chat-text::after,
.direct-chat-text::before {
    border: solid transparent;
    border-right-color: #d2d6de;
    content: " ";
    height: 0;
    pointer-events: none;
    position: absolute;
    right: 100%;
    top: 15px;
    width: 0;
}

.direct-chat-primary .right > .direct-chat-text::after,
.direct-chat-primary .right > .direct-chat-text::before {
    border-left-color: #007bff;
}
.right .direct-chat-text::after,
.right .direct-chat-text::before {
    border-left-color: #d2d6de;
    border-right-color: transparent;
    left: 100%;
    right: auto;
}
.direct-chat-text::before {
    border-width: 6px;
    margin-top: -6px;
}
.direct-chat-text::after,
.direct-chat-text::before {
    border: solid transparent;
    border-right-color: #d2d6de;
    content: " ";
    height: 0;
    pointer-events: none;
    position: absolute;
    right: 100%;
    top: 15px;
    width: 0;
}
.width-90 {
    width: 94%;
}
.container.adding-courses-newtheme {
    max-width: 98% !important;
}
</style>
