<template>
  <div class="container instructor-newtheme">
    <div class="instructor-page-title">
      <h2>Tutor Verification</h2>
      <!-- <p>Instructor Application Form</p> -->
    </div>

    <div class="course-adding-form box-style-coruse">
      <ul id="progressbar" class="progressbar-box student-dashboard">
        <li class="active" id="general"><strong class="color-light-blue">Upload Educational Document</strong><span>1</span></li>
        <li id="duration"><strong class="color-light-blue">Payment</strong><span>2</span></li>
        <li id="courseoutline"><strong>Under Reviewed</strong><span>3</span></li>
      </ul>
      <div class="container-fluid" id="grad1">
        <div class="row">
          <div class="col-md-12">
            <label for="">CNIC Upload</label>
            <vue-dropzone ref="myVueDropzoneCnic" v-on:vdropzone-sending="sendingEventCNIC" id="dropzone-cnic" :options="dropzoneOptions" @vdropzone-removed-file="removeFile"></vue-dropzone>
            <ul class="text-sm text-danger text-red-600 text-xs italic mt-1" v-if="errorMessages.cnic.length > 0">
              <li v-for="err in errorMessages.cnic" :key="err">
                <small>{{ err }}</small>
              </li>
            </ul>
            <label class="mt-4" for="">Degree Upload</label>
            <vue-dropzone ref="myVueDropzone" v-on:vdropzone-sending="sendingEvent" id="dropzone" :options="dropzoneOptions" @vdropzone-removed-file="removeFile"></vue-dropzone>
            <ul class="text-sm text-danger text-red-600 text-xs italic mt-1" v-if="errorMessages.docs.length > 0">
              <li v-for="err in errorMessages.docs" :key="err">
                <small>{{ err }}</small>
              </li>
            </ul>
            <div class="col-md-12 p-2">
              <button v-on:click="validateStep()" class="btn btn-success float-right next-btn">Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useDropzone } from "vue3-dropzone";
// import 'vue3-dropzone/dist/vue3Dropzone.min.css'
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
  name: "Step1Component",
  components: {
    vueDropzone: useDropzone
  },
  data() {
    return {
      cnic: 0,
      docs: 0,
      dropzoneOptions: {
        removeLink : true,
        addRemoveLinks: true,
        url: '/v1/tutor/upload/document',
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').content },
        destroyDropzone: false,
      },
      errorMessages: {
        cnic: [],
        docs: [],
      },
    }
  },
  created() {},
  mounted() {
    this.getDocuments()
  },
  methods: {
    sendingEventCNIC (file, xhr, formData) {
      this.cnic++
      this.errorMessages.cnic = []
      formData.append('doc_type', 'CNIC');
    },
    sendingEvent (file, xhr, formData) {
      this.docs++
      this.errorMessages.docs = []
      formData.append('doc_type', 'Degree');
    },
    async getDocuments() {
      let self = this
      const {data} = await axiosInstance.get(endpoints.getDocumentsRoute);
      data.data.forEach(function (doc) {
        let file = { id: doc.id, size: doc.size, name: doc.name, type: "image/png" };
        let url = window.location.origin+'/storage'+doc.path;
        if (doc.doc_type === 'Degree') {
          self.$refs.myVueDropzone.manuallyAddFile(file, url);
          self.cnic++
        }
        else {
          self.$refs.myVueDropzoneCnic.manuallyAddFile(file, url);
          self.docs++
        }
      })
    },
    async removeFile(file) {
      let formData = new FormData();
      formData.append('doc_id', file.id)
      await axiosInstance.post(endpoints.deleteDocumentsRoute, formData);
    },
    validateStep() {
      if (this.cnic > 0 && this.docs > 0) {
        this.$parent.step++
      }
      else {
        if (this.cnic === 0) {
          this.errorMessages.cnic.push('Add Cnic...')
        }
        if (this.docs === 0) {
          this.errorMessages.docs.push('Add Document...')
        }
      }
    }
  },

}
</script>

<style scoped>

</style>
