<template>
  <div>
    <div class="card-header">
      <div class="d-flex justify-content-between">
        <h3 class="card-title">Refund Requests</h3>
        <div class="row">
          <form @submit.prevent="getRefundRequest()" >
            <input type="text" v-model="query.keyword" placeholder="Search.." name="search">
            <button type="submit" class="btn btn-secondary"><i class="fa fa-search"></i></button>
          </form>
          <button class="btn btn-success ml-2" style="height: 40px;" v-on:click="getRefundRequest()" type="button"><i class="fa fa-refresh"></i></button>
        </div>
      </div>
    </div>

    <div class="table-wrap">
      <table class="table text-center" >
        <thead class="thead-primary border-0">
        <tr>
          <th></th>
          <th>Id</th>
          <th>User</th>
          <th>Transaction Id</th>
          <th>Slot Time</th>
          <th>Price</th>
          <th>status</th>
          <th>Created At</th>
          <th></th>
        </tr>
        </thead>

        <tbody >
        <tr v-for="req in reqs" :key="req.id">
          <td></td>
          <td>{{req.id}}</td>
          <td>{{req.user.name}}</td>
          <td><a href="javascript:void (0)" v-on:click="$parent.getTransactionDetail(req.trx_id)">{{req.trx_id}}</a></td>
          <td><span v-if="!!req.transaction">{{req.transaction.slots.find(s => s.slot.id === req.model_id).slot.start_date_time}} - {{req.transaction.slots.find(s => s.slot.id === req.model_id).slot.end_date_time}}</span></td>
          <td><span v-if="!!req.transaction">{{req.transaction.slots.find(s => s.slot.id === req.model_id).slot.price}} PKR</span></td>
          <td>{{req.status }}</td>
          <td>{{req.created_at }}</td>
          <td>
            <router-link :to="'/tutor/refund/'+req.id">Details</router-link>
            <!--                <a href="javascript:void(0)" v-on:click="showDetails(req)">Details</a>-->
          </td>
        </tr>
        </tbody>
      </table>

      <div v-if="no_record">
        <p class="p-2">{{"No Record Found"}}</p>
      </div>

      <div class="row">
        <pagination v-show="query.total > query.limit" v-model="query.page" :records="query.total" :per-page="query.limit" @paginate="getRefundRequest()"/>
      </div>
    </div>

    <RefundRequestDetailComponent v-if="refundModal" :refundDetail="transaction"></RefundRequestDetailComponent>
  </div>
</template>

<script>
import RefundRequestDetailComponent from "@/components/tutor/billings/RefundRequestDetailComponent";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
  name: "RefundRequestComponent",
  components: {
    RefundRequestDetailComponent,
  },
  data() {
    return {
      no_record: false,
      refundModal: false,
      transaction: {},
      reqs: [],
      balance: 0.00,
      query: {
        status: '',
        keyword: '',
        sort: 'DESC',
        page: 1,
        limit: 6,
        total: 0,
      },
    }
  },
  mounted() {
  },
  created() {
    this.getRefundRequest()
  },
  methods: {
    async getRefundRequest() {
      const refundReqData = await axiosInstance.get(endpoints.getTutorRefundRequestRoute, {params: this.query});
      this.reqs = refundReqData.data.data.data.data
      this.query.total = refundReqData.data.data.data.total
      this.no_record = this.reqs.length === 0
    },
    showDetails(req) {
      this.transaction = req
      this.refundModal = !this.refundModal
    },
  }
}
</script>

<style scoped>
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
</style>
