<template>
    <div>
        <div id="smallSideBar" class="sidebar sidebar-small newtheme-sidebar">
            <div class="sidebar-cross">
                <span
                    class="sidebar-cross-icon cross_btn"
                    @click="toggleHandler"
                >
                    x
                </span>
            </div>
            <div class="menu-sticky">
                <ul class="nav_lis">
                    <li @click="toggleHandler">
                        <router-link to="/tutor/dashboard">
                            <span
                                ><img src="@/assets/new-theme-images/dashboard.png"
                            /></span>
                            <span class="sidebar-links">Overview</span>
                        </router-link>
                    </li>

                    <li @click="toggleHandler">
                        <router-link to="/tutor/courses">
                            <span
                                ><img src="@/assets/new-theme-images/course.png"
                            /></span>
                            <span class="sidebar-links">Courses</span>
                        </router-link>
                        <span class="tooltip">Courses</span>
                    </li>

                    <li
                        :class="{ submenu_open: activeMenu === 'availability' }"
                        class="accordion has-submenu logs"
                    >
                        <a
                            @click="openAppointments"
                            href="javascript:void(0)"
                            :class="{
                                'router-link-active':
                                    activeMenu === 'availability',
                            }"
                        >
                            <span
                                ><img src="@/assets/new-theme-images/activity.png"
                            /></span>
                            <span class="sidebar-links courses"
                                >Availability</span
                            >
                        </a>
                        <div class="submenu-route">
                            <router-link to="/tutor/one-to-one-session">
                                <span class="sidebar-links">One to One Sessions</span>
                            </router-link>
                            <router-link to="/tutor/schedule">
                                <span class="sidebar-links">Schedule</span>
                            </router-link>
                        </div>
                    </li>

                    <li
                      :class="{ submenu_open: activeMenu === 'appointments' }"
                      class="accordion has-submenu logs"
                  >
                    <a
                        @click="bookedAppointments"
                        href="javascript:void(0)"
                        :class="{
                                'router-link-active':
                                    activeMenu === 'appointments',
                            }"
                    >
                            <span
                            ><img src="@/assets/new-theme-images/activity.png"
                            /></span>
                      <span class="sidebar-links courses"
                      >Appointments</span
                      >
                    </a>
                    <div class="submenu-route">
                      <router-link to="/tutor/one-to-one-session">
                        <span class="sidebar-links">One to One Sessions</span>
                      </router-link>
                      <router-link to="/tutor/schedule">
                        <span class="sidebar-links">Schedule</span>
                      </router-link>
                    </div>
                  </li>

                    <li @click="toggleHandler">
                        <router-link to="/tutor/chat">
                            <span
                                ><i
                                    class="fa fa-comments"
                                    aria-hidden="true"
                                ></i
                            ></span>
                            <span class="sidebar-links pl-0">Messages</span>
                        </router-link>
                    </li>

                    <li v-if="!user_verified" @click="toggleHandler">
                        <router-link to="/tutor/get/verified">
                            <span>
                              <i class="fa fa-comments" aria-hidden="true"></i>
                            </span>
                            <span class="sidebar-links pl-0">Get verified</span>
                        </router-link>
                    </li>

                    <li
                        :class="{ submenu_open: activeMenu === 'log' }"
                        class="accordion has-submenu logs"
                    >
                        <a
                            @click="openLogs"
                            href="javascript:void(0)"
                            :class="{
                                'router-link-active': activeMenu === 'log',
                            }"
                        >
                            <span>
                              <img src="@/assets/new-theme-images/activity.png"/>
                            </span>

                            <span class="sidebar-links courses">Logs</span>
                        </a>
                        <div class="submenu-route">

                            <router-link to="/tutor/log/session">
                                <span class="sidebar-links">Session Logs</span>
                            </router-link>

                            <router-link to="/tutor/log/activity">
                                <span class="sidebar-links">Activity Logs</span>
                            </router-link>

                        </div>
                    </li>

                    <li
                        @click="toggleHandler"
                        :class="{ submenu_open: activeMenu === 'billing' }"
                        class="accordion has-submenu"
                    >

                        <router-link to="/tutor/billing">
                            <span
                                ><img src="@/assets/new-theme-images/billings.png"
                            /></span>
                            <span class="sidebar-links courses">Billing</span>
                        </router-link>

                        <div class="submenu-route">

                            <router-link to="/tutor/billing/deposit">
                                <span class="sidebar-links"
                                    >Make a Deposit</span
                                >
                            </router-link>

                            <router-link to="/tutor/billing/overview">
                                <span class="sidebar-links"
                                    >Earning Overview</span
                                >
                            </router-link>

                            <router-link to="/tutor/billing/transaction">
                                <span class="sidebar-links"
                                    >Transaction History</span
                                >
                            </router-link>

                            <router-link to="/tutor/billing/requests">
                                <span class="sidebar-links courses"
                                    >Requests</span
                                >
                            </router-link>

                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

import {useUserStore} from "@/store/store";

export default {
    name: "SidebarComponent",
    props: {
      toggleHandler: { type: Function },
    },
    data() {

      const userStore = useUserStore()
      return {
          userDetail: userStore.currentUser,
          user_verified: userStore.isUserVerified,
          activeMenu: "",
          homeMenus: false,
      };

    },
    watch: {
        $route() {
            if (this.$router.currentRoute._value.matched[0].props.default.has_sub) {
                this.activeMenu =
                    this.$router.currentRoute._value.matched[0].props.default.parent;
            } else {
                this.activeMenu = "";
            }
        },
    },
    mounted() {
        setTimeout(() => {
            if (this.$router.currentRoute._value.matched[0].props.default.has_sub) {
                this.activeMenu = this.$router.currentRoute._value.matched[0].props.default.parent;
            } else {
                this.activeMenu = "";
            }
        }, 500);
    },
    methods: {
        openLogs() {
            this.$router.push("/tutor/log/session");
        },
        openAppointments() {
            this.$router.push("/tutor/one-to-one-session");
        },
      bookedAppointments() {
        this.$router.push("/tutor/booked-appointments");
      },
        toogleHome() {
            this.homeMenus = !this.homeMenus;
        },
    },
};
</script>

<style lang="scss" scoped>
.submenu-route {
    background: #7a67c7;
    border-radius: 4px 0px 0px 42px;
    position: absolute;
    width: 100%;
    top: 33px;
    left: 4px;
    padding: 23px 15px;
}
.submenu-route:hover {
    background: #7a67c7;
}
.submenu-route::before {
    content: "";
    width: 10px;
    height: 10px;
    background: #7a67c7;
}
.home_menus {
    display: none;
}
@media (max-width: 1231px) {
    .home_menus {
        display: block;
    }
}
li.accordion.has-submenu .submenu-route {
    display: none;
}
#smallSideBar.newtheme-sidebar .nav_lis li.accordion.has-submenu.submenu_open {
    height: 192px;
}
#smallSideBar.newtheme-sidebar
    .nav_lis
    li.accordion.has-submenu.submenu_open.logs {
    height: 152px;
}
#smallSideBar.newtheme-sidebar
    .nav_lis
    li.accordion.has-submenu.submenu_open.home_menus {
    height: 195px;
}
li.accordion.has-submenu.submenu_open .submenu-route {
    display: block;
}
//#smallSideBar.newtheme-sidebar .nav_lis {
//  margin-top: 25px;
//}

span.sidebar-links {
    line-height: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
