<template>
  <div>
    <HeaderComponent></HeaderComponent>
    <router-view></router-view>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from "@/components/frontend/includes/header/HeaderComponent";
import FooterComponent from "@/components/frontend/includes/footer/FooterComponent.vue";
export default {
  name: "App",
  components: {
    FooterComponent,
    HeaderComponent,
  },
};
</script>

<style scoped>
nav#frontend-head {
  height: 50px;
}

.box-drop {
  position: absolute;
  width: 100px;
  height: 40px;
  top: 14px;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.37);
  border-radius: 5px;
}

#mob-left-sidebar-toggler {
  display: none;
}

@media (max-width: 576px) {
  #mob-left-sidebar-toggler {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 0;
    z-index: 999;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: none;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.7);
    width: 20px;
    height: 60px;
  }
}
</style>
