<template>
    <div>
        <div class="container pt-4 pb-4">
            <div class="course-adding-form box-style-coruse">
                <ul id="progressbar" class="progressbar-box six-steps">
                    <li class="active" id="general">
                        <strong class="color-light-blue">Basic Course Info</strong
                        ><span>1</span>
                    </li>
                    <li class="active" id="duration">
                        <strong class="color-light-blue">Proposed Schedule</strong
                        ><span>2</span>
                    </li>
                    <li class="active" id="pricingrequirements">
                        <strong class="color-light-blue"
                            >Pricing & Requirements </strong
                        ><span>3</span>
                    </li>
                    <li id="courseoutline">
                        <strong class="color-light-blue">Course Outline</strong
                        ><span>4</span>
                    </li>
                    <li id="mediaseo">
                        <strong>Media & SEO</strong><span>5</span>
                    </li>
                    <!--          <li id="quiz"><strong>Quiz</strong><span>6</span></li>-->
                </ul>
                <div class="container-fluid" id="grad1">
                    <div class="row">
                        <div class="col-md-12 mx-0">
                            <form
                                @submit.prevent="checkCourseForm()"
                                id="form-box"
                                class="form-box"
                            >
                                <fieldset>
                                    <div class="row p-3">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="price"
                                                    >Proposed Course Price ({{
                                                        $t("text.pkr")
                                                    }})</label
                                                >
                                                <input
                                                    type="number"
                                                    class="form-control input_height"
                                                    v-model="course.price"
                                                    v-on:change="
                                                        calculatePriceRupee
                                                    "
                                                    id="price"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Course Price"
                                                />
                                                <ul
                                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                    v-if="
                                                        errorMessages.price
                                                            .length > 0
                                                    "
                                                >
                                                  <!-- eslint-disable -->
                                                    <li
                                                        v-for="err in errorMessages.price"
                                                    >
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                                <div class="form-check">
                                                    <input
                                                        type="checkbox"
                                                        v-model="
                                                            course.is_free_course
                                                        "
                                                        class="form-check-input"
                                                        id="exampleCheck1"
                                                    />
                                                    <label
                                                        class="form-check-label mt-2"
                                                        for="exampleCheck1"
                                                        >Check If This Is A Free
                                                        Course</label
                                                    >
                                                    <ul
                                                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                        v-if="
                                                            errorMessages
                                                                .is_free_course
                                                                .length > 0
                                                        "
                                                    >
                                                      <!-- eslint-disable -->
                                                        <li
                                                            v-for="err in errorMessages.is_free_course"
                                                        >
                                                            <small>{{
                                                                err
                                                            }}</small>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="discounted_price"
                                                    >Discount ({{
                                                        $t("text.pkr")
                                                    }})</label
                                                >
                                                <input
                                                    type="number"
                                                    v-on:change="
                                                        calculatePricePercentage()
                                                    "
                                                    min="0"
                                                    step="0.01"
                                                    :max="course.price"
                                                    class="form-control input_height"
                                                    v-model="course.discount"
                                                    id="discounted_price"
                                                    aria-describedby="discounted_price"
                                                    placeholder="Discounted Price in Rs"
                                                />
                                                <ul
                                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                    v-if="
                                                        errorMessages.discount
                                                            .length > 0
                                                    "
                                                >
                                                  <!-- eslint-disable -->
                                                    <li
                                                        v-for="err in errorMessages.discount"
                                                    >
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label
                                                    for="discounted_percentage"
                                                    >Discounted Percentage
                                                    %</label
                                                >
                                                <input
                                                    type="number"
                                                    v-on:change="
                                                        calculateDiscountRupee()
                                                    "
                                                    min="0"
                                                    step="0.01"
                                                    max="100"
                                                    class="form-control input_height"
                                                    v-model="
                                                        course.discounted_percentage
                                                    "
                                                    id="discounted_percentage"
                                                    aria-describedby="discounted_percentage"
                                                    placeholder="Discounted Price in %"
                                                />
                                                <ul
                                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                    v-if="
                                                        errorMessages
                                                            .discounted_percentage
                                                            .length > 0
                                                    "
                                                >
                                                  <!-- eslint-disable -->
                                                    <li
                                                        v-for="err in errorMessages.discounted_percentage"
                                                    >
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <h2
                                                class="mt-0 mb-2 prerequisite-t"
                                            >
                                            Prerequisites (Requirements) Optional
                                            </h2>
                                        </div>
                                      <!-- eslint-disable -->
                                        <div class="col-md-12">
                                            <div
                                                class="form-group"
                                                v-for="(
                                                    requirement, index
                                                ) in requirements"
                                            >
                                                <div class="row m-0 p-0 mb-3">
                                                    <input
                                                        type="text"
                                                        class="form-control input_height prerequisite-input"
                                                        id="requirement"
                                                        v-model="
                                                            requirement.requirement
                                                        "
                                                        name="requirement[]"
                                                        placeholder="Provide Prerequisite"
                                                        aria-label="Recipient's username"
                                                        aria-describedby="basic-addon2"
                                                    />
                                                    <div class="prereq_btn">
                                                        <button
                                                            class="btn btn-success input_height"
                                                            v-on:click="
                                                                addNewRequirements
                                                            "
                                                            type="button"
                                                        >
                                                            <img
                                                                src="/new-theme-images/yellow-plus.png"
                                                                class="new-plus"
                                                                style="
                                                                    width: 15px;
                                                                "
                                                            />
                                                        </button>
                                                        <button
                                                            class="btn btn-danger input_height"
                                                            v-if="index > 0"
                                                            v-on:click="
                                                                removeApartment(
                                                                    index
                                                                )
                                                            "
                                                            type="button"
                                                        >
                                                            <img
                                                                src="/new-theme-images/yellow-minus.png"
                                                                class="new-plus"
                                                                style="
                                                                    width: 15px;
                                                                "
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul
                                                class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                v-if="
                                                    errorMessages.requirements
                                                        .length > 0 &&
                                                    !requirements[0].requirement
                                                "
                                            >
                                              <!-- eslint-disable -->
                                                <li
                                                    v-for="err in errorMessages.requirements"
                                                >
                                                    <small>{{ err }}</small>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-12">
                                            <h2
                                                class="mt-0 mb-2 prerequisite-t"
                                            >
                                            OutCome - What your students will learn
                                            </h2>
                                        </div>
                                        <div class="col-md-12">
                                          <!-- eslint-disable -->
                                            <div
                                                class="form-group"
                                                v-for="(
                                                    outcome, index
                                                ) in outcomes"
                                            >
                                                <div class="row m-0 p-0 mb-3">
                                                    <div
                                                        class="col-md-12 m-0 p-0"
                                                    >
<!--                                                        <ckeditor-->
<!--                                                            :editor="editor"-->
<!--                                                            v-model="-->
<!--                                                                outcome.outcome-->
<!--                                                            "-->
<!--                                                            :config="-->
<!--                                                                editorConfig-->
<!--                                                            "-->
<!--                                                        ></ckeditor>-->
                                                      <textarea v-model="outcome.outcome"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                          <!-- eslint-disable -->
                                            <ul
                                                class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                v-if="
                                                    errorMessages.outcomes
                                                        .length > 0 &&
                                                    !outcomes[0].outcome
                                                "
                                            >
                                                <li
                                                    v-for="err in errorMessages.outcomes"
                                                >
                                                    <small>{{ err }}</small>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="row px-3 mt-0 mb-0">
                                        <div class="col-sm-6 col-6">
                                            <a
                                                v-on:click="$parent.steps--"
                                                href="javascript:void(0)"
                                                class="btn btn-outline-primary btn_fontsize btn-lg back-btn"
                                                >Back</a
                                            >
                                        </div>
                                        <div class="col-sm-6 col-6 text-right">
                                            <button
                                                type="submit"
                                                class="next action-button btn btn-primary btn_fontsize btn-lg"
                                                style="height: 36.8px"
                                                :disabled="!!loading"
                                            >
                                                <div
                                                    v-if="!!loading"
                                                    class="spinner-border text-primary"
                                                    style="
                                                        color: white !important;
                                                        width: 20px !important;
                                                        height: 20px !important;
                                                    "
                                                    role="status"
                                                ></div>
                                                <span v-if="!!loading == false">
                                                    Save and Continue</span
                                                >
                                            </button>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import axiosInstance from "@/assets/js/axios";

export default {
    name: "Step5Component",
    data() {
        return {
            loading: false,
            editor: null,
            editorData: "",
            editorConfig: {},
            course: {
                price: 0.0,
                discounted_percentage: 0.0,
                discount: 0.0,
                discounted_price: 0.0,
                outcomes: [],
                requirements: [],
                is_free_course: false,
            },
            requirement: {
                requirement: "",
            },
            outcome: {
                outcome: "",
            },
            outcomes: [],
            requirements: [],
            discount: 0.0,
            errorMessages: {
                is_free_course: [],
                price: [],
                discount: [],
                discounted_percentage: [],
                requirements: [],
                outcomes: [],
            },
        };
    },
    created() {
        this.addNewRequirements();
        this.addNewOutCome();
        this.getCourseDetails();
    },
    methods: {
        checkRequirement() {
            this.errorMessages.requirements = [];
            if (!this.requirements[0].requirement) {
                this.errorMessages.requirements.push("Enter Requirements...");
                return false;
            } else {
                this.errorMessages.requirements = [];
                return true;
            }
        },
        checkOutcomes() {
            this.errorMessages.outcomes = [];
            if (!this.outcomes[0].outcome) {
                this.errorMessages.outcomes.push("Enter OutComes...");
                return false;
            } else {
                this.errorMessages.outcomes = [];
                return true;
            }
        },
        checkPrice() {
            this.errorMessages.price = [];
            if (!this.course.price) {
                this.errorMessages.price.push("Enter Price...");
                return false;
            } else {
                this.errorMessages.price = [];
                return true;
            }
        },
        checkCourseForm() {
            // this.checkRequirement()
            this.checkOutcomes();
            this.checkPrice();
            if (
                // this.checkRequirement() &&
                this.checkOutcomes() &&
                this.checkPrice()
            ) {
                this.course.requirements = JSON.stringify(this.requirements);
                this.course.outcomes = JSON.stringify(this.outcomes);
                this.updateCourse();
            }
        },

        addNewRequirements() {
            this.requirements.push({...this.requirement});
        },
        addNewOutCome() {
            this.outcomes.push({...this.outcome});
        },
        removeOutComes(index) {
          this.outcomes.splice(index, 1);
        },
        removeApartment(index) {
          this.requirements.splice(index, 1);
        },
        getCourseDetails() {
            let id = this.$route.params.id;
            let self = this;
            axiosInstance
                .get("/v1/course/edit/" + id)
                .then(function (response) {
                    // handle success
                    if (response.data.type === "success") {
                        self.course = response.data.data;
                        if (self.course.discounted_price > 0)
                            self.course.discount =
                                self.course.price -
                                self.course.discounted_price;
                        else self.course.discount = 0.0;
                        if (
                            self.requirements !== null &&
                            JSON.parse(response.data.data.requirements) !== null
                        ) {
                            self.requirements = JSON.parse(
                                response.data.data.requirements
                            );
                        }
                        if (
                            self.outcomes !== null &&
                            JSON.parse(response.data.data.outcomes) !== null
                        ) {
                            self.outcomes = JSON.parse(
                                response.data.data.outcomes
                            );
                        }
                    } else {
                        this.$toasted.error(response.data.message);
                        self.$router.replace("/tutor/courses");
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        },
        calculatePricePercentage() {
            if (this.course.price && this.course.discount) {
                this.course.discounted_price =
                    this.course.price - this.course.discount;
                this.course.discounted_percentage = (
                    (this.course.discount / this.course.price) *
                    100
                ).toFixed(2);
            } else {
                this.course.discounted_percentage = 0.0;
            }
            this.comparePriceDiscounted();
        },
        calculateDiscountRupee() {
            if (
                this.course.price > 0.0 &&
                this.course.discounted_percentage > 0.0
            ) {
                this.course.discount = (
                    (this.course.discounted_percentage / 100) *
                    this.course.price
                ).toFixed(2);
                this.course.discounted_price =
                    this.course.price - this.course.discount;
            } else {
                this.course.discounted_percentage = 0.0;
            }
            this.comparePriceDiscounted();
        },
        calculatePriceRupee() {
            this.course.discount = "";
            this.course.discounted_percentage = "";
        },
        comparePriceDiscounted() {
            if (
                parseFloat(this.course.discount) > 0 &&
                parseFloat(this.course.discounted_price) >=
                    parseFloat(this.course.price)
            ) {
                this.errorMessages.discounted_price.push(
                    "Discount is greater than amount"
                );
                return true;
            }
            return false;
        },
        updateCourse() {
            let self = this;
            if (
                parseFloat(this.course.discounted_price) >=
                parseFloat(this.course.price)
            ) {
                this.comparePriceDiscounted();
                return;
            }

            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            let formData = new FormData();
            formData.append("file", self.file);
            _.each(self.course, (value, key) => {
                formData.append(key, value);
            });
            if (self.$route.params.id) {
                let id = self.$route.params.id;
                formData.append("id", id);
            }
            self.loading = true;
            axiosInstance
                .post("/v1/course/create/step-5", formData, config)
                .then(function (response) {
                    self.loading = false;
                    // handle success
                    if (response.data.type === "success") {
                        this.$toasted.error(response.data.message);
                    }
                })
                .catch(function (error) {
                    self.loading = false;
                    console.log(error);
                })
                .then(function () {
                    self.$parent.steps++;
                });
        },
    },
};
</script>

<style scoped>
@media screen and (min-width: 1400px) {
    .container {
        max-width: 1450px !important;
    }
}
@media screen and (min-width: 1850px) {
    .container {
        max-width: 2150px !important;
    }
}
</style>
