<template>
  <div>
    <div class="container pt-4 pb-4 add-qualification">
      <div class="course-adding-form box-style-coruse">
        <ul id="progressbar" class="progressbar-box">
          <li class="active" id="general"><strong class="color-light-blue">Teaching </strong><span>1</span></li>
          <li class="active" id="duration"><strong class="color-light-blue">What are your Qualifications?</strong><span>2</span></li>
          <li id="courseoutline"><strong>Course Outline</strong><span>3</span></li>
        </ul> <!-- fieldsets -->
        <!-- MultiStep Form -->
        <div class="container-fluid" id="grad1">
          <div class="row">
            <div class="col-md-12 mx-0">
                <!-- progressbar -->
                <fieldset>
                  <div class="p-3">
                    <div class="tab-pane" id="qualifications" role="tabpanel" aria-labelledby="qualifications">
                      <div class="row mb-3">
                        <div class="col-md-7">
                          <h5>Qualification</h5>
                          <small>Please Add Your Top Five Educational Degree/Deploma</small>
                        </div>
                        <div class="col-md-5 edit_icon">
                          <div class="float-right">
                            <button class="btn btn-primary inline-block" v-on:click="qualificationForm = !qualificationForm">Add Qualification</button>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3" v-for="qualify in qualifications" :key="qualify.id">
                        <div class="col-md-6">
                          <h5>{{ qualify.degree.level }}</h5>
                          <p class="mb-0"> {{ qualify.institute.name }}</p>
                          <small> '<i>{{ changeDate(qualify.start_year ) }}</i>' to '<i>{{ (qualify.job_continue) ? 'Till now' : changeDate(qualify.end_year) }}</i>'</small>

                        </div>
                        <div class="col-md-6 text-right qualification-actions">
                          <button class="btn btn-primary inline-block" @click="editQualification(qualify.id)"><i class="fas fa-edit"></i> </button>
                          <button class="btn btn-danger inline-block" @click="deleteQualification(qualify.id)"><i class="fas fa-trash"></i> </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row px-3  mb-3">
                    <div class="col-sm-6">
                      <a v-on:click="$parent.steps--" href="javascript:void(0)" class="back btn btn_fontsize  btn-outline-primary btn-lg">Back</a>
                    </div>
                    <div class="col-sm-6 text-right">
                      <a href="javascript:void(0)" v-on:click="checkEducation()" class="next action-button btn btn-primary btn_fontsize btn-lg">Continue</a>
                      <!--                      <a href="javascript:void(0)" v-else v-on:click="checkCourseForm" class="next action-button btn btn-primary btn_fontsize btn-lg">Create Course</a>-->
                    </div>
                  </div>
                </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div> <!-- Home Content End -->
    <div v-if="qualificationForm" class="modal bd-example-modal-lg" style="display: block; background-color: rgba(0,0,0, 0.4); overflow: auto;" id="addqualification"  tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Qualification</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="qualificationForm = !qualificationForm">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form action="" @submit.prevent="addQualification()">

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Institute </label>
                    <select  v-model="qualification.institute_id" class="form-control" name="institute_id" id="institute_id">
                      <option value="Please Select" disabled>Please Select Institute</option>
                      <option v-for="institute in institutes" :key="institute.id" :value="institute.id">
                        {{institute.name}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">

                  <div class="form-group">
                    <label for="education_level_id">Degree</label>
                    <select v-model="qualification.education_level_id" class="form-control" name="education_level_id" id="education_level_id">
                      <option value="Please Select" disabled>Please Select Degree</option>
                      <option v-for="degree in degrees" :key="degree.id" :value="degree.id">
                        {{degree.level}}
                      </option>

                    </select>
                  </div>
                </div>
                <div class="col-md-6">

                  <div class="form-group">
                    <!-- eslint-disable -->
                    <label for="subject_id">Field Of Study</label>
                    <select v-model="qualification.subject_id" class="form-control" id="subject_id">
                      <option :value="subject.id" v-for="subject in subjects">{{ subject.subject }}</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-6">

                  <div class="form-group">
                    <label for="start_year">Start Year</label>
                    <input v-model="qualification.start_year" type="date" class="form-control" id="start_year" />
                  </div>
                </div>

                <div class="col-md-6">

                  <div class="form-group">
                    <label for="end_year">End Year</label>
                    <input v-model="qualification.end_year" type="date" class="form-control" id="end_year" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="cgpa">CGPA</label>
                    <input v-model="qualification.cgpa" type="text" class="form-control" id="cgpa" aria-describedby="emailHelp" placeholder="CGPA">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="marks">Marks in (%)</label>
                    <input v-model="qualification.marks" type="text" class="form-control" id="marks" aria-describedby="emailHelp" placeholder="Marks in (%)">
                  </div>
                </div>
              </div>

              <div class="mx-auto text-center mt-5">
                <button class="btn btn-primary inline-block save-btn-modal" type="submit">Save</button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Swal from "sweetalert2";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import moment from "moment-timezone";
import {toast} from "vue3-toastify";

export default {
  name: 'Step2Component',
  props: [],
  components: {  },
  directives: { },
  data() {
    return {
      subjects: [],
      institutes: [],
      degrees: [],
      grades: [],
      qualifications: [],
      loading: false,
      qualificationForm: false,
      qualification:{ },
    }
  },
  computed: {
  },
  created() {

    this.getFilterContent();
    this.getQualifications();
  },
  methods: {
    async getFilterContent() {
      const {data} = await axiosInstance.get(endpoints.filterContent);
      this.cities = data.cities
      this.institutes = data.institutes
      this.degrees = data.educationLevels
      this.grades = data.grades
      this.subjects = data.subjects
    },
    openAddExp() {
      this.qualificationForm = true
    },
    changeDate(date) {
      return moment(date).tz().format('DD MMM, YYYY');
    },
    async getList() {
      const { data } = await axiosInstance.get(endpoints.institutePro);
      this.institutes = data.data;
    },
    async getDegree(){
      const { data } = await axiosInstance.get(endpoints.degreePro);
      this.degrees = data;
    },
    async getGrade(){
      const { data } = await axiosInstance.get(endpoints.gradePro);
      this.grades = data;
    },
    async getQualifications() {
      const { data } = await axiosInstance.get(endpoints.qualiPro);
      this.qualifications = data.data;
    },
    async editQualification(id) {
      const data = await axiosInstance.get(endpoints.degreePro, id);
      this.qualification = data.data;
      this.openAddExp();

    },
    async deleteQualification(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
            await axiosInstance.post(endpoints.qualiPro, id);
            await this.getQualifications();
            toast.success('Your file has been deleted.')
        }
      })
    },
    async addQualification(){
      this.loading = true;
      if(this.qualification.id) {
        await axiosInstance.post(endpoints.qualiPro, {id: this.qualification.id, qua: this.qualification})
          .then(response => {
            toast.success(response.message)
            this.loading = false;
            this.qualificationForm = false
            this.getQualifications();
            this.qualification = {};
          })
          .catch(error => {
            console.log(error)
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        await axiosInstance.post(endpoints.qualiPro, this.qualification)
          .then(response => {
            toast.success(response.message)
            this.loading = false;
            this.qualificationForm = false
            this.getQualifications();
          })
          .catch(error => {
            console.log(error)
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    checkEducation() {
      if (this.qualifications.length > 0) {
        this.$parent.steps++
      }
      else {
        toast.success('Qualification Missing...')
      }
    }
  },
};
</script>

<style scoped>
.availible_days {
  display: flex;
    flex-wrap: wrap;
    width: 710px;
    margin-top: 26px;
}
.listStyle{
	padding: 12px !important;
    padding-top: 0 !important;
}
@media only screen and (max-width: 800px) {
	.availible_days {
	display: flex;
    flex-wrap: wrap;
    /* overflow: visible; */
    /* overflow-wrap: break-word; */
    /* white-space: nowrap; */
    /* gap: 0 30px; */
    width: 441px;
}
.listStyle{
	padding: 10px !important;
    padding-top: 0 !important;
}
}

@media only screen and (max-width: 800px) {
	.availible_days {
	display: flex;
    flex-wrap: wrap;
    /* overflow: visible; */
    /* overflow-wrap: break-word; */
    /* white-space: nowrap; */
    /* gap: 0 30px; */
    width: 441px;
}
.listStyle{
	padding: 10px !important;
    padding-top: 0 !important;
}
}
@media only screen and (max-width: 576px){
.container {
    max-width: 100% !important;


}
.availible_days {
	display: block;
  width: 200px;

}
}
/* @media screen and (min-width: 1400px) {
  .container {
    max-width:1450px !important;

  }
} */
@media screen and (min-width: 1850px) {
  .container {
    max-width:2150px  !important;

  }
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}

.progressbar-box li {
  width: 33%;
}
</style>
