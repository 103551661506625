<template>
  <div>
    <div class="modal" id="ResetPasswordModal" style="display: block; background-color: rgba(0,0,0, 0.4);" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modals-newtheme" role="document">
        <div class="modal-content">
          <div class="modal-body p-6">
            <div class="row">
              <div class="col-md-12 col-12">
                <div class="text-center logo_cover">
                  <a href="#">
                    <picture>
                      <img src="@/assets/images/logo.svg" alt="Flowers" width="191">
                    </picture>
                  </a>
                </div>
              </div>
            </div>
            <div class="container">

              <div class="row">
                <div class="col-md-12 col-12">
                  <div v-if="showForm">
                    <form @submit.prevent="submit">
                      <h2 class="login m-auto">Reset Password</h2>
                      <input type="hidden" v-model="token" />
                      <input type="hidden" v-model="email" />
                      <div class="mt-2">
                        <label class="font_size"></label>
                        <div class="password-container">

                          <input :type="passwordFieldType"
                                 v-model="password"
                                 required
                                 placeholder="Enter New Password"
                                 class="form-control"
                                 @focusin="toggleDropdown"
                                 @focusout="toggleDropdown"/>
                          <i
                              :class="passwordFieldType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'"
                              class="toggle-password"
                              @click="togglePasswordVisibility"
                          ></i>
                        </div>
                        <ul
                          class="text-sm text-danger text-red-600 text-xs italic mt-1"
                          v-if="
                                errors
                                    .password
                                    .length >
                                0
                            "
                      >
                        <!-- eslint-disable -->
                        <li
                            v-for="err in errors.password"
                        >
                          <small>{{
                              err
                            }}</small>
                        </li>
                      </ul>
                      </div>
                      <ul class="password-requirements reset text-small" v-if="activeDropdown">
                        <li class="spc-tiny-n">Please Include:</li>
                        <li class="lower-letter-check"><i class="wc-check hide"></i><span class="text-disabled">— </span> A lower case letter</li>
                        <li class="upper-letter-check"><i class="wc-check hide"></i><span class="text-disabled">— </span> An upper case letter</li>
                        <li class="number-check"><i class="wc-check hide"></i><span class="text-disabled">— </span> A number</li>
                        <li class="symbol-check"><i class="wc-check hide"></i><span class="text-disabled">— </span> A symbol: !@#$%^&amp;*</li>
                        <li class="length-check"><i class="wc-check hide"></i><span class="text-disabled">— </span> At least 6 characters</li>
                      </ul>
                      <div class="mt-2">
                        <label class="font_size"></label>
                        <div class="password-container">
                          <input
                              :type="confirmPasswordFieldType"
                              v-model="password_confirmation"
                              required
                              placeholder="Confirm New Password"
                              class="form-control"
                          />
                          <i
                              :class="confirmPasswordFieldType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'"
                              class="toggle-password"
                              @click="toggleConfirmPasswordVisibility"
                          ></i>
                        </div>
                        <div v-if="errors.password_confirmation" class="text-danger">{{ errors.password_confirmation[0] }}</div>
                      </div>

                      <div class="row">
                        <div class="col-md-12 col-12 m-auto">
                          <button type="submit" width="100%" class="btn btn-primary width_login">Reset Password</button>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <button class="btn btn-primary width_login" v-if="showResendButton" @click="sendPasswordResetLink">Resend Password Reset Email</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import { toast } from "vue3-toastify";

export default {
  data() {
    return {
      token: this.$route.query.token || '',
      email: this.$route.query.email || '',
      password: '',
      password_confirmation: '',
      errors: {
        password : []
      },
      activeDropdown: false,
      passwordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      showResendButton: false,
      showForm: true
    };
  },
  methods: {

    togglePasswordVisibility() {
      this.passwordFieldType =
          this.passwordFieldType === 'password' ? 'text' : 'password';
    },

    toggleConfirmPasswordVisibility() {
      this.confirmPasswordFieldType =
          this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
    },

    toggleDropdown() {
      this.activeDropdown = !this.activeDropdown;
    },
    async submit() {

      if(this.password !== this.password_confirmation) {
        toast.error('Password & confirm password doesn\'t match');
        return;
      }

      try {

        await axiosInstance.post(endpoints.passwordResetRoute, {
          token: this.token,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
        });

        toast.success('Your password has been reset. Please login to continue.');

        setTimeout(() => {
          this.$router.push('/');
        }, 4000);

      } catch (error) {

        this.errors.password = [];
        let res = error.response.data;

        if(res.status === false && res.type === 'validation') {

          let dataObj = this;
          dataObj.errors.password = [];

          Object.keys(res.errors).forEach(function (key) {
            dataObj.errors[key] = res.errors[key];
          });

        } else if(res.status === false && res.type === 'expired') {

          toast.error(res.message)
          this.showResendButton = true
          this.showForm = false

        } else {
          this.errors.password[0] = res.message;
        }

      }
    },

    async sendPasswordResetLink() {

      let email = this.email
      let formData = new FormData

      formData.append('email', email)
      try {

        const {data} = await axiosInstance.post(endpoints.passwordResetLinkRoute, formData);

        if(data.status === true) {

          toast.success('Password reset link has been sent to your email.');

          setTimeout(() => {
            this.$router.push('/');
          }, 4000);

        }

      } catch (e) {
        console.log(e)

        if(e.response && e.response.data && e.response.data.message) {
          toast.error(e.response.data.message)
        } else {
          toast.error('Error sending link')
        }
      }
    }
  },
};
</script>

<style scoped>
.select {
  background: #4e97d2;
  color: white;
}
.select:focus {
  outline: 1px solid #4e97d2;
}
.set {
  display: flex;
}
.dont_account {
  font-size: 17px;
}
.dateOfB {
  font-size: 17px;
  margin-top: 20px;
}
.dont_account .sign a {
  color: #4e97d2b5;
  font-weight: 300;
}
.width_login {
  margin-top: 20px;
  width: 100%;
}
.forgot {
  margin-top: 14px;
  font-size: 17px;
}
.forgot a {
  color: #4e97d2b5;
  font-weight: 300;
}
.margin_top {
  margin-top: 30px;
}
.font_size {
  font-size: 16px;
}
.login {
  color: black;
}
.logo_cover {
  padding: 22px;
}
.modal-header {
  border: none;
}
#ResetPasswordModal .modals-newtheme .dont_account {
  color: #dc3545 !important;
}
.text-danger {
  color: #dc3545;
  font-size: 0.875rem;
}

.password-container {
  position: relative;
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
