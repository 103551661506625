<template>
  <div>
    <h2 class="sched-heading">Upcoming Schedule</h2>
    <div v-if="slots.length > 0">
      <div v-for="(slot, index) in slots" :key="index" class="upcoming-schedule">
        <div class="col-md-3 col-6">
          <h6>Date</h6>
          <p>{{ slot.start_date_time }}</p>
        </div>
        <div class="col-md-3 col-6">
          <h6>Time</h6>
          <p>{{ slot.start_date_time }} - {{ slot.end_date_time }}</p>
        </div>
        <div v-if="slot.model === 'Slot'" class="col-md-3 col-6">
          <h6>Instructor</h6>
          <p>{{ slot.data.slot.tutor.name }}</p>
        </div>
        <div v-if="slot.model === 'Session'" class="col-md-3 col-6">
          <h6>Course Title</h6>
          <p>{{ slot.data.course.title }}</p>
        </div>

        <div class="col-md-3 col-6">
          <h6>Type</h6>
          <p v-if="slot.model === 'Slot'">Single Appointment</p>
          <p v-if="slot.model === 'Session'">Course Session</p>
        </div>
      </div>
    </div>

    <div v-if="slots.length < 1">
      <p>No Record Found...!</p>
    </div>

  </div>
</template>

<script>

import moment from 'moment-timezone';
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
  name: "ScheduleComponent",
  data() {
    return {
      slots: [],
    }
  },
  created() {
    this.getAppointments()
  },
  methods: {
    async getAppointments() {
      try {
        let query = {}
        query.month = moment().format('M')
        const { data } = await axiosInstance.get(endpoints.getBookedSlotsRoute, {params: query});
        this.slots = data.data.slots;
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
