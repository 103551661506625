<template>
  <div>
    <div class="container_home_section student-dashboard newtheme-student-dashboard">
      <div class="row">
        <div class="banner">
          <div class="welcome-text">
            <h1> Hi <span>{{ user.name }}!</span> Welcome Back!</h1>
            <p>Add inspirational educational quotes</p>
          </div>
          <div class="dashboard-banner-image">
            <img src="@/assets/new-theme-images/welcome.png" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <div class="container upcoming_scheduling">
      <div class="row">
        <div class="col-lg-7 p-0">
          <CardGadgetComponent></CardGadgetComponent>
          <ScheduleComponent></ScheduleComponent>
        </div>
        <div class="col-lg-5 schedule-chart">
          <div class="schedule-board">
            <ThisWeekScheduleComponent role="student"></ThisWeekScheduleComponent>
          </div>
          <OnGoingCoursesComponent></OnGoingCoursesComponent>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardGadgetComponent from "@/components/student/dashboard/CardGadgetComponent";
import ScheduleComponent from "@/components/student/dashboard/ScheduleComponent";
import OnGoingCoursesComponent from "@/components/student/dashboard/OnGoingCoursesComponent";
import ThisWeekScheduleComponent from "@/components/utilities/week-schedule/ThisWeekScheduleComponent";
import {useUserStore} from "@/store/store";

export default {
  name: "SidebarComponent",
  components: {
    CardGadgetComponent,
    ScheduleComponent,
    OnGoingCoursesComponent,
    ThisWeekScheduleComponent,
  },
  data() {

    const userStore = useUserStore();

    return {
      user: userStore.currentUser,
      role: userStore.currentUser.role,
      authenticated: userStore.isAuthenticated,
      purchasedCourses: [],
      enrolled_courses: 0,
      in_progress_courses: 0,
      completed_courses: 0,
    }
  },
}
</script>

<style scoped>

</style>
