<template>
    <div style="height: 100%">
        <LoadingComponent :loading="loading"></LoadingComponent>

        <div class="sessionLog_parent_width_table" v-if="!loading">
            <div class="p-4-sm p-2 d-flex flex-sm-column flex-column-reverse">
                <div class="sessionLog_table_parent">
                    <div class="sessionLog_table_overflow">
                        <div class="table-responsive session_log_table">
                            <table class="table text-center">
                                <thead class="thead-light">
                                    <tr>
                                        <th>Sr.</th>
                                        <th>Session ID</th>
                                        <th>Course / Tutor</th>
                                        <th>Date</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <!--                  <th>Action</th>-->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="log in logs" :key="log.id">
                                        <td class="border-0" scope="row">
                                            {{ log.id }}
                                        </td>
                                        <td class="border-0 main_color">
                                            {{ log.session_key }}
                                        </td>
                                        <td class="border-0">
                                            {{
                                                log.model === "Course"
                                                    ? log.course.title +
                                                      " (Course)"
                                                    : log.user.name + " (Tutor)"
                                            }}
                                        </td>
                                        <td class="border-0">{{ log.date }}</td>
                                        <td class="border-0">
                                            {{ log.start_time }}
                                        </td>
                                        <td class="border-0">
                                            {{ log.end_time }}
                                        </td>
                                        <!--                  <td class="border-0"><div class="view_button" data-toggle="modal" data-target="#sessionLog_popup">View</div></td>-->
                                    </tr>
                                </tbody>
                            </table>

                            <div v-if="no_record">
                                <p class="p-2">{{ "No Record Found" }}</p>
                            </div>
                        </div>
                        <div class="col-12 text-center">
                            <div
                                class="text-xl-right text-lg-right text-md-right pt-2 log_btn"
                            >
                                <pagination
                                    v-show="query.total > 0"
                                    v-model="query.page"
                                    :records="query.total"
                                    :per-page="query.limit"
                                    @paginate="getSessionLogs"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <!--        <div class="row">-->
                <!--          <div class="col-12 col-sm-6"></div>-->
                <!--          <div class="col-12 col-sm-6">-->
                <!--            <div class="float-right session-table-options">-->
                <!--              <p class="d-flex">-->
                <!--                <a href="javascript:void (0)"><download-csv :data= "logs"><img src="/new-theme-images/export-icon.png" /> Download CSV</download-csv></a>-->
                <!--                <a href="" download><img src="/new-theme-images/blue-pdf.png" />Print PDF</a>-->
                <!--              </p>-->
                <!--            </div>-->
                <!--          </div>-->
                <!--        </div>-->
            </div>
        </div>
    </div>
</template>
<script>
// import JsonCSV from "vue-json-csv";
import LoadingComponent from "@/components/utilities/loading/loader.vue";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
// Vue.component("downloadCsv", JsonCSV);

export default {
    name: "SessionLogComponent",
    props: [],
    components: { LoadingComponent },
    directives: {},
    data() {
        return {
            loading: true,
            no_record: false,
            logs: [],
            query: {
                keyword: "",
                sort: "DESC",
                page: 1,
                limit: 10,
                total: 0,
            },
        };
    },
    computed: {},
    async created() {
        await this.getSessionLogs();
        this.loading = false;
    },
    methods: {
        async getSessionLogs() {
            let self = this;
            const logsData = await axiosInstance.get(endpoints.getSessionLogsRoute, {params: self.query});
            this.logs = logsData.data.data.logs.data;
            this.query.total = logsData.data.data.logs.total;
            this.no_record = this.logs.length === 0;
        },
    },
};
</script>
<style scoped></style>
