<template>
  <div class="tab-pane fade tutor-modal-fix" id="get_verified" role="tabpanel" aria-labelledby="documentDetail">
    <GetVerifiedComponent></GetVerifiedComponent>
  </div>
</template>

<script>
import GetVerifiedComponent from "@/components/tutor/verified/GetVerifiedComponent";

export default {
  name: "DocumentSectionComponent",
  components: {GetVerifiedComponent}
}
</script>

<style scoped>

</style>
