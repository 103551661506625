<template>
  <div class="courses_summary">
    <div class="tabel_overflow top_purchase_under_progress tutor_dashboard">
      <div class="rows head">
        <h4>This Week's Schedule</h4>
        <p class="mb-0">
          <router-link :to="'/' + role + '/schedule'">
            <a href="javascript:void (0)">My Calendar</a>
          </router-link>
        </p>
      </div>
      <table class="table tables text-center" >
        <thead>
        <tr class="th">
          <th class="text-left">Days</th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(day, index) in weekDays" :key="index">
          <td style="text-align: start; width: 100px;">{{ formatDate(day) }}</td>
          <td class="row">
            <div >
              <div v-for="(slot, index) in filterSlots(day)" :key="index" class="thrd calendar-appointment-span m-1">
                <div v-if="index < 3">
                  <p style="color: rgb(3, 105, 161); font-size: 12px; line-height: 16px; font-family: Inter, sans-serif; margin-bottom: 0px; text-align: center;">
                    <span class="name" v-if="slot.model === 'Slot'">{{ slot.student.slice(0, 15) }}</span>
                    <span class="name" v-if="slot.model === 'Session'">{{ slot.title.slice(0, 15) }}</span>
                    <br>
                    <span class="time" v-if="slot.model === 'Slot'">{{slot.start_time}} - {{slot.end_time}}</span>
                    <span class="time" v-if="slot.model === 'Session'">{{slot.date + ' '+ slot.start_time}} - {{slot.date + ' '+ slot.end_time}}</span>
                  </p>
                </div>
              </div>
            </div>
          </td>

          <td v-if="filterSlots(day).length > 0" class="view-more">
            <router-link :to="'/'+role+'/schedule'">View All</router-link>
          </td>

        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
  name: "ThisWeekScheduleComponent",
  props: ['role'],
  data() {
    return {
      slots: [],
      weekDays: [],
      sessions: [],
    }
  },
  created() {
    this.getWeeklySchedule()
    this.getWeekDays()
  },
  methods: {
    formatDate(date) {
      return moment(date).format('MM-DD-YYYY');
    },
    filterSlots(day) {
      let s = []
      this.slots.forEach(function (slot) {
        if(moment(slot.start_date_time).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD')) {
          s.push({
            'model': 'Slot',
            'start_time': slot.start_date_time,
            'end_time': slot.end_date_time,
            'student': slot.booked.user.name
          })
        }
      })
      this.sessions.forEach(function (session) {
        if(moment(session.date).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD')) {
          s.push({
            'model': 'Session',
            'date': session.date,
            'start_time': session.start_time,
            'end_time': session.end_time,
            'title': session.course.title
          })
        }
      })
      return s;
    },
    async getWeeklySchedule() {

      try {
        const {data} = await axiosInstance.get(endpoints.getWeeklyScheduleRoute, {params: {'role': this.role}});
        this.slots = data.data.slots
        this.sessions = data.data.sessions
      } catch (e) {
        console.log(e)
      }

    },
    getWeekDays() {
      let startOfWeek = moment().startOf('week');
      let endOfWeek = moment().endOf('week');

      let days = [];
      let day = startOfWeek;
      while (day <= endOfWeek) {
        days.push(day.toDate());
        day = day.clone().add(1, 'd');
      }
      this.weekDays = days
    },
  }
}
</script>

<style scoped>
.slot-capsule {
  background: rgb(129 43 178);
  border-radius: 50px;
  width: auto;
  height: 25px;
  padding: 0 5px;
  color: #fccd05;
  margin: 2px;
}
a {
  color: #fccd05 !important;
}
.calendar-appointment-span {
  background: #812bb2;
  border-radius: 5px;
  width: 110px;
}
.calendar-session-span {
  background: #fccd05;
  border-radius: 5px;
  width: 120px;
  margin-bottom: 5px;
}

span.time,
span.name
{
  color: #fccd05 !important;
  font-weight: 500;
}
h5 {
  color: #812bb2 !important;
}
.view-more {
  width: 80px;
}
.view-more a {
  color: #812bb2 !important;
}
</style>