<template>
  <div class="deposite_student_parent">
    <div class="live_courses_container newtheme-livecourses ">
      <div class="row">
        <div class="col-md-3 mb-md-0 mb-2">
          <div class="tabs_1 mb-2">
            <!-- card side bar -->
            <div class="nav flex-column-sm flex-row nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <h5 class="px-2">Credit/Debit Card</h5>

              <!-- jazzcash -->
              <div v-if="isJazcash" class="col-sm-12 col-6 px-0">
                <a class="nav-link border-bottom py-3" :class="{'active':payment_method === 'jazzcash'}" v-on:click="payment_method = 'jazzcash'" id="jazzcash" data-toggle="pill" href="#jazzcash-tab" role="tab" aria-controls="jazzcash-tab" aria-selected="true">
                  <div style="margin-top: 5px;"><input style="position: initial;" type="radio" name="method" id="jazzcash-checkbox" :checked="payment_method === 'jazzcash'"></div>
                  <span>JazzCash</span>
                  <img src="@/assets/images/payment-02.png" class="active-img w-25" />
                  <img src="@/assets/images/payment-02.png" class="inactive-img w-25" />
                </a>
              </div>

              <!-- foreepay -->
              <div v-if="isForeePay" class="col-sm-12 col-6 px-0">
                <a class="nav-link border-bottom py-3" :class="{'active':payment_method === 'foree'}" v-on:click="payment_method = 'foree'" id="foree" data-toggle="pill" href="#foree-tab" role="tab" aria-controls="foree-tab" aria-selected="false">
                  <div style="margin-top: 5px;"><input style="position: initial;" type="radio" name="method" id="foree-checkbox" :checked="payment_method === 'foree'"></div>
                  <span>Foree Pay</span>
                  <img src="@/assets/images/foree_pay.png" class="inactive-img w-25" />
                  <img src="@/assets/images/foree_pay.png" class="active-img w-25" />
                </a>
              </div>

              <h5 class="px-2">TCS</h5>

              <!-- foree topup -->
              <div v-if="isForeePayTopup" class="col-sm-12 col-6 px-0">
                <a class="nav-link border-bottom py-3" :class="{'active':payment_method === 'foree_invoice'}" v-on:click="payment_method = 'foree_invoice'" id="foree_invoice" data-toggle="pill" href="#wallet-detail-tab" role="tab" aria-controls="wallet-detail-tab" aria-selected="false">
                  <div style="margin-top: 5px;"><input style="position: initial;" type="radio" name="method" id="topup-checkbox" :checked="payment_method === 'foree_invoice'"></div>
                  <span>Foree TopUp</span>
                  <img src="@/assets/images/foree_pay.png" class="inactive-img w-25" />
                  <img src="@/assets/images/foree_pay.png" class="active-img w-25" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-9">
          <div class="tabs_2">
            <div class="tab-content" id="v-pills-tabContent">
              <!-- First Tab -->
              <div class="tab-pane fade" id="paypals-tab" role="tabpanel" aria-labelledby="paypal">
                <form action="">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlSelect1">Deposite Currency </label>
                        <select class="form-control" id="exampleFormControlSelect1">
                          <option>USD - US Dollar</option>
                          <option>RS</option>
                          <option>UK</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Deposite Amount</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="$0.00">
                      </div>
                    </div>
                  </div>

                  <div class="deposite_detail">

                    <div class="row">

                      <div class="col-8 text-xl-right text-lg-right text-md-right">
                        <p>Processing Fee</p>
                      </div>
                      <div class="col-4 text-xl-right text-lg-right text-md-right">
                        <p>$0.00</p>
                      </div>

                      <div class="col-8 text-xl-right text-lg-right text-md-right">
                        <p>Total</p>
                      </div>
                      <div class="col-4 text-xl-right text-lg-right text-md-right">
                        <p>$0.00</p>
                      </div>

                    </div>
                  </div>

                  <div class="mx-auto text-center my-3">
                    <button class="btn confirm_deposite_btn">Confirm And Deposit</button>
                  </div>

                </form>
              </div>
              <!-- Second Tab -->
              <div class="tab-pane fade" id="deposits-tab" role="tabpanel" aria-labelledby="deposite">

                <form action="">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="account-holder-name">Account Holder Name</label>
                        <input type="text" class="form-control" id="account-holder-name" aria-describedby="emailHelp" placeholder="Name">
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="bank-name">Bank Name </label>
                        <select class="form-control" id="bank-name">
                          <option>Meezan Bank</option>
                          <option>200</option>
                          <option>199</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="bank-account-address">Bank Account Number</label>
                        <input type="text" class="form-control" id="bank-account-address" aria-describedby="emailHelp" placeholder="Account Number">
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="iban">IBAN</label>
                        <input type="text" class="form-control" id="iban" aria-describedby="emailHelp" placeholder="IBAN">
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="bank-address">Bank Address</label>
                        <input type="text" class="form-control" id="bank-address" aria-describedby="emailHelp" placeholder="Address">
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="branch-code">Branch Code</label>
                        <input type="text" class="form-control" id="branch-code" aria-describedby="emailHelp" placeholder="Branch Code">
                      </div>
                    </div>
                  </div>
                  <div class="mx-auto text-center my-3">
                    <button class="btn confirm_deposite_btn">Save</button>
                  </div>
                </form>
              </div>
              <WalletComponent></WalletComponent>
            </div>
          </div>
        </div>
      </div>
      <div v-if="topups.length > 0" class="col-md-12 p-0 mt-3">
        <section class="mt-1px-0">
          <div class="mb-2">
            <h5 class="h4">TopUp Requests</h5> <p>For making payment of requested amount against generated CRN, got to your nearest TCS center and deposit it.</p>
          </div>
          <div class="row tabel_overflow py-1">
            <div class="col-md-12">
              <div class="table-wrap table-responsive">
                <table class="table text-center table-hover">
                  <thead class="thead-primary border-0">
                  <tr>
                    <th>Amount</th>
                    <th>Bill Created At</th>
                    <th>CRN Number</th>
                    <th>Status</th>
                    <!--                    <th>Update</th>-->
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="top in topups" :key="top.id">
                    <td>{{ top.amount }} PKR</td>
                    <td>{{ top.created_at }}</td>
                    <td>{{ '13673357951' + top.crn }}</td>
                    <td>{{ top.status }}</td>
                    <!--                    <td v-if="top.status === 'Unpaid'"><button v-on:click="checkStatus(top.crn)" class="btn btn-success"> Check Status </button></td>-->
                    <!--                    <td v-else><button disabled class="btn btn-success"> Status Updated </button></td>-->
                  </tr>
                  </tbody>
                </table>
              </div>
              <pagination v-show="query.total > query.limit" v-model="query.page" :records="query.total" :per-page="query.limit" @paginate="getTopUp"/>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import WalletComponent from "@/components/tutor/profile/components/WalletComponent";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
  name: "MakeADepositComponent",
  components: {
    WalletComponent,
  },
  data() {
    return {
      topups: [],
      tab: 'paypal',
      payment_method: 'jazzcash',
      isJazcash: false,
      isForeePay: false,
      isForeePayTopup: false,
      query: {
        page: 1,
        limit: 8,
        keyword: '',
        category_id: '',
        status: '',
        price: '',
        role: '',
        total: 0,
        sort: 'DESC',
      },
    }
  },
  created() {
    this.getTopUp()
    // this.getJazzCashStatus(),
    // this.getForeepayStatus(),
    // this.getForeepayTopupStatus()
  },
  methods: {
    async getTopUp() {
      const topUpData = await axiosInstance.get(endpoints.getTopUpRoute, {params: this.query});

      this.topups = topUpData.data.data
      this.query.total = topUpData.data.data.data.total
      this.wallet = topUpData.data.data.wallet

      this.isJazcash = topUpData.data.data.is_jazzcash;
      this.isForeePay = topUpData.data.data.is_foreepay;
      this.isForeePayTopup = topUpData.data.data.is_foreepaytopup;
    },
    // async getJazzCashStatus() {
    //   const jazzcash = await getJazzCashStatus.list();
    //   this.isJazcash = jazzcash;
    // },
    // async getForeepayStatus() {
    //   const foreepay = await getForeepayStatus.list();
    //   console.log("getForeepayStatus ----------------------------");
    //   this.isForeePay = foreepay;
    // },
    // async getForeepayTopupStatus() {
    //   const foreepaytopup = await getForeepayTopupStatus.list();
    //   this.isForeePayTopup = foreepaytopup;
    // },
  }
}
</script>

<style scoped>

</style>
