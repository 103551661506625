<template>
  <div class="tutor-modal-fix" id="accountDetail" role="tabpanel" aria-labelledby="accountDetail">
  <div class="experience-header">
    <h2>Billing Details</h2>
    <img src="@/assets/new-theme-images/bi_plus-square.png" alt="plus" @click="openForm()"/>
  </div>

  <div v-for="billing in billings" class="row experience-item" :key="billing.id">
    <div class="col-md-9">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="account_titles">
            <p>Account Holder Name</p>
            <p>Bank Name</p>
            <p>Account Number</p>
            <p>IBAN</p>
            <p>Bank Address</p>
            <p>Branch Code</p>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="accountTitles_right">
            <p>{{ billing.account_holder_name }}</p>
            <h5>{{ billing.bank_name }}</h5>
            <p><u> ******{{ billing?.account_number?.charAt(billing.account_number.length - 3)  }}</u></p>
            <p>{{ billing.iban }}</p>
            <p>{{ billing.bank_address }}</p>
            <p>{{ billing.bank_branch_code }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 experience-edit">
      <img @click="deleteBillingInfo(billing.id)" src="@/assets/new-theme-images/trash.png" alt="trash" class="cursor-pointer"/>
    </div>
  </div>


    <div class="modal bd-example-modal-lg" v-if="billingForm" style="display: block; overflow-y: scroll;" id="addqualification"  tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Billing</h5>
            <button v-on:click="billingForm = !billingForm" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="checkForm" method="post">

            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Account Holder Name</label>
                    <input type="text" class="form-control" v-model="info.account_holder_name" id="name" aria-describedby="emailHelp" placeholder="Account Holder Name" v-on:focus="errorMessages.account_holder_name = []">
                    <ul class="text-sm text-danger text-red-600 text-xs italic mt-1" v-if="errorMessages.account_holder_name.length > 0">
                      <li v-for="err in errorMessages.account_holder_name" :key="err">
                        <small>{{ err }}</small>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="bank_name">Bank Name</label>
                    <select v-model="info.bank_name" class="form-control" id="bank_name" v-on:focus="errorMessages.bank_name = []">
                      <option value="MZB">Meezan Bank</option>
                      <option value="HBL">HBL</option>
                      <option value="MCB">MCB</option>
                    </select>
                    <ul class="text-sm text-danger text-red-600 text-xs italic mt-1" v-if="errorMessages.bank_name.length > 0">
                      <li v-for="err in errorMessages.bank_name" :key="err">
                        <small>{{ err }}</small>
                      </li>
                    </ul>
                  </div>

                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="account_number">Bank Account Number</label>
                    <input type="number" min="16" class="form-control" id="account_number" v-model="info.account_number" aria-describedby="emailHelp" placeholder="Bank Account Number" v-on:focus="errorMessages.account_number = []">
                    <ul class="text-sm text-danger text-red-600 text-xs italic mt-1" v-if="errorMessages.account_number.length > 0">
                      <li v-for="err in errorMessages.account_number" :key="err">
                        <small>{{ err }}</small>
                      </li>
                    </ul>
                  </div>

                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="iban">IBAN</label>
                    <input type="text" class="form-control" v-model="info.iban" id="iban" aria-describedby="emailHelp" placeholder="IBAN" v-on:focus="errorMessages.iban = []">
                    <ul class="text-sm text-danger text-red-600 text-xs italic mt-1" v-if="errorMessages.iban.length > 0">
                      <li v-for="err in errorMessages.iban" :key="err">
                        <small>{{ err }}</small>
                      </li>
                    </ul>
                  </div>

                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="bank_address">Bank Address</label>
                    <input type="text" class="form-control" v-model="info.bank_address" id="bank_address" aria-describedby="bank_address" placeholder="Bank Address" v-on:focus="errorMessages.bank_address = []">
                    <ul class="text-sm text-danger text-red-600 text-xs italic mt-1" v-if="errorMessages.bank_address.length > 0">
                      <li v-for="err in errorMessages.bank_address" :key="err">
                        <small>{{ err }}</small>
                      </li>
                    </ul>
                  </div>

                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="branch_code">Branch Code</label>
                    <input type="text" class="form-control" v-model="info.bank_branch_code" id="branch_code" aria-describedby="emailHelp" placeholder="Branch Code" v-on:focus="errorMessages.bank_branch_code = []">
                    <ul class="text-sm text-danger text-red-600 text-xs italic mt-1" v-if="errorMessages.bank_branch_code.length > 0">
                      <li v-for="err in errorMessages.bank_branch_code" :key="err">
                        <small>{{ err }}</small>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
              <div class="mx-auto text-center mt-2">
                <button class="btn confirm_deposite_btn">Save</button>
              </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import Swal from 'sweetalert2'
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";

export default {
  name: 'BillingAccountDetail',
  props: [],
  components: { },
  directives: { },
  data() {
    return {
      billings: [],
      billingForm: false,
      info: {
        account_holder_name: '',
        bank_name: '',
        account_number: '',
        iban: '',
        bank_address: '',
        bank_branch_code: '',
      },
      errorMessages: {
        account_holder_name: [],
        bank_name: [],
        account_number: [],
        iban: [],
        bank_address: [],
        bank_branch_code: [],
      },
    };
  },
  computed: {
  },
  created() {
    this.getUserBillingDetail()
  },
  methods: {
    openForm() {
      this.billingForm = !this.billingForm
    },
    checkForm() {
      this.checkAccountHolderName()
      this.checkBankName()
      this.checkAccountNumber()
      this.checkIban()
      this.checkBankAddress()
      this.checkBankBranchCode()
      if (
        this.checkAccountHolderName() &&
        this.checkBankName() &&
        this.checkAccountNumber() &&
        this.checkIban() &&
        this.checkBankAddress() &&
        this.checkBankBranchCode()
      ) {
        this.updateUserBillingDetail()
      }
    },
    checkAccountHolderName() {
      this.errorMessages.account_holder_name = []
      if (!this.info.account_holder_name) {
        this.errorMessages.account_holder_name.push('Enter account holder name...')
        return false
      }
      else {
        this.errorMessages.account_holder_name = []
        return true
      }
    },
    checkBankName() {
      this.errorMessages.bank_name = []
      if (!this.info.bank_name) {
        this.errorMessages.bank_name.push('Choose bank...')
        return false
      }
      else {
        this.errorMessages.bank_name = []
        return true
      }
    },
    checkAccountNumber() {
      this.errorMessages.account_number = []
      if (!this.info.account_number || this.info.account_number.length < 16) {
        this.errorMessages.account_number.push('Enter valid account number 16 digits...')
        return false
      }
      else {
        this.errorMessages.account_number = []
        return true
      }
    },
    checkIban() {
      this.errorMessages.iban = []
      if (!this.info.iban || this.info.iban.length < 16 || this.info.iban.length < 24) {
        this.errorMessages.iban.push('Enter Valid IBN of 16 Characters...')
        return false
      }
      else {
        this.errorMessages.iban = []
        return true
      }
    },
    checkBankAddress() {
      this.errorMessages.bank_address = []
      if (!this.info.bank_address) {
        this.errorMessages.bank_address.push('Enter bank address...')
        return false
      }
      else {
        this.errorMessages.bank_address = []
        return true
      }
    },
    checkBankBranchCode() {
      this.errorMessages.bank_branch_code = []
      if (!this.info.bank_branch_code) {
        this.errorMessages.bank_branch_code.push('Enter branch code...')
        return false
      }
      else {
        this.errorMessages.bank_branch_code = []
        return true
      }
    },
    async updateUserBillingDetail() {
      let self = this
      let info = this.info
      let formData = new FormData();
      formData.append('account_holder_name', info.account_holder_name)
      formData.append('bank_name', info.bank_name)
      formData.append('account_number', info.account_number)
      formData.append('iban', info.iban)
      formData.append('bank_address', info.bank_address)
      formData.append('bank_branch_code', info.bank_branch_code)
      const {data} = await axiosInstance.post(endpoints.userBillingDetailRoute, formData)
      console.log(data)
      if (data.type === 'validation') {
        Object.keys(data.message).forEach(function(key) {
          self.errorMessages[key] = data.message[key]
        })
      }
      else if (data.type === 'success') {
        toast.success(data.message)
        self.info.account_holder_name = ''
        self.info.bank_name = ''
        self.info.account_number = ''
        self.info.iban = ''
        self.info.bank_address = ''
        self.info.bank_branch_code = ''
        this.openForm()
        this.getUserBillingDetail()
      }
    },
    async getUserBillingDetail() {
      const billingDetRes = await axiosInstance.get(endpoints.userBillingDetailRoute)
      this.billings = billingDetRes.data.data.data
    },
    async deleteBillingInfo(id) {
      let query = {}
      query.id = id
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const {data} = await axiosInstance.get(endpoints.userBillingDeleteRoute, {params: query} )
          this.billings = data.data
        }
        await this.getUserBillingDetail()

      })
    }
  },
};
</script>
<style scoped>
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}
</style>
