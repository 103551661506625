<template>
    <div style="height: 100%">
        <div class="loader" v-if="loading">
            <div
                class="spinner-border text-primary"
                style="
                    color: white !important;
                    width: 50px !important;
                    height: 50px !important;
                "
                role="status"
            ></div>
            <h3 class="pt-3 text-white">Generating Slots</h3>
        </div>
        <!-- first step -->
        <div v-if="step === 0">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="start_date_">Start Date</label>
                        <input
                            type="date"
                            class="form-control"
                            id="start_date_"
                            :min="minDate"
                            :max="maxDate"
                            v-model="start_date_input"
                        />
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="No. of weeks">No. of weeks</label>
                        <select
                            id="no_of_weeks"
                            class="form-control w-100"
                            v-model="no_of_weeks"
                        >
                            <option v-for="_ in [1, 2, 3, 4]" :value="_" :key="_">
                                {{ _ }}
                            </option>
                        </select>
                        <!-- <input
                            type="number"
                            min="0"
                            class="form-control"
                            id="no_of_weeks"
                            v-model="no_of_weeks"
                        /> -->
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="price_"
                            >Price Per Session (Pkr)</label
                        >
                        <input
                            type="text"
                            class="form-control"
                            id="price_"
                            v-model="price"
                            placeholder="1000 Rs."
                        />
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="duration">Duration (in minutes)</label>
                        <input
                            type="number"
                            disabled
                            class="form-control"
                            placeholder="05 Minutes"
                            id="duration"
                            :value="60"
                            required
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- second step -->
        <div v-if="step === 1" class="w-100">
            <div class="d-flex align-items-center justify-content-between">
                <h5>Week {{ current_week + 1 }}/{{ no_of_weeks }}</h5>
                <h5>Timezone ({{ $parent.timezone }})</h5>
            </div>
            <div
                v-for="slot in slots" :key="slot"
                class="d-flex align-items-center justify-content-between"
            >
                <div v-if="current_week === slot.id" class="w-100">
                    <div
                        v-for="week in slot.week" :key="week"
                        class="d-flex align-items-center justify-content-between w-100 border-bottom py-1"
                    >
                      <template v-if="
                            moment(week.date).unix() >=
                            moment(start_date).unix()
                        ">
                        <!-- Week Day Label -->
                        <label class="d-flex align-items-center flex-row">
                          <input
                              type="checkbox"
                              v-model="week.enable"
                              @input="
                                    (e) => {
                                        if (week.timeSlots.length === 0) {
                                            week.timeSlots = [$parent.generateLatestTimeSlot(week),];
                                        }
                                    }
                                "
                          />
                          <span class="ml-2 text-uppercase"
                          >{{ week?.key?.slice(0, 3) }} ({{
                              moment(week.date).format("DD/MM/YYYY")
                            }})</span
                          >
                        </label>

                        <div class="d-flex flex-column align-items-center">
                          <template v-if="week.enable">
                            <div
                                v-for="timeSlot in week.timeSlots" :key="timeSlot"
                                class="d-flex align-items-center flex-row py-1 gap-10"
                            >
                              <!-- TimeSlots List -->

                              <select
                                  style="width: 110px"
                                  class="form-control"
                                  id="timezone"
                                  v-model="timeSlot.from"
                                  @input="
                                        (event) => {
                                            timeSlot.to = $parent.addOneHour(
                                                event.target.value
                                            );
                                        }
                                    "
                              >
                                <option
                                    :value="item"
                                    v-for="item in $parent.generateDefaultTimeSlots(
                                            week.date,
                                            week.timeSlots?.filter(
                                                (_) => _.id !== timeSlot.id
                                            )
                                        )"
                                    :key="item"
                                >
                                  {{ item }}
                                </option>
                              </select>

                              <!-- Delete TimeSlot -->
                              <button
                                  class="btn px-2"
                                  @click="
                                        () => {
                                            week.timeSlots =
                                                week.timeSlots.filter(
                                                    (_) => _?.id !== timeSlot.id
                                                );
                                            if (week.timeSlots?.length === 0) {
                                                week.enable = false;
                                            }
                                        }
                                    "
                                  type="button"
                              >
                                <i class="fa fa-trash"></i>
                              </button>
                            </div>
                          </template>

                          <div v-if="!week.enable">
                            <h6>Unavailable</h6>
                          </div>
                        </div>
                        <!-- Add New TimeSlot -->
                        <button
                            class="btn"
                            type="button"
                            @click="
                                () => {
                                    week.timeSlots.push($parent.generateLatestTimeSlot(week));
                                    week.enable = true;
                                }
                            "
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </template>

                    </div>

                </div>
            </div>
        </div>

        <div class="pt-3 d-flex align-items-center justify-content-between">
            <button
                v-if="step !== 0"
                class="btn save_btn btn-primary"
                @click="
                    () => {
                        if (current_week === 0) {
                            step -= 1;
                        } else {
                            current_week -= 1;
                        }
                    }
                "
            >
                <span>Back</span>
            </button>
            <div v-else></div>
            <button
                v-if="step !== 0"
                class="btn save_btn btn-primary"
                @click="copyToAllOtherWeeks()"
            >
                <span>Copy to All Next Weeks</span>
            </button>
            <div v-else></div>
            <button
                class="btn save_btn btn-primary"
                @click="createRecurringSlots()"
            >
                <span v-if="step === 1 && current_week + 1 === no_of_weeks"
                    >Finish</span
                >
                <span v-else>Next</span>
            </button>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
import {toast} from "vue3-toastify";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import _ from "lodash";

export default {
    name: "RecuringSlotComponent",
    props: [],
    data() {
        return {
            loading: false,
            step: 0,
            no_of_weeks: 1,
            start_date: null,
            end_date: null,
            start_date_input: null,
            start_time: "",
            price: "1000",
            slots: [],
            slotsAdded: 0,
            current_week: 0,
            defaultTimeSlots: [],
            minDate: moment().format("YYYY-MM-DD"),
            maxDate: moment().add(90, "days").format("YYYY-MM-DD"),
        };
    },
    computed: {},
    watch: {
        no_of_weeks() {
            this.generateSlots();
        },
        start_date_input() {
            this.start_date = moment(this.start_date_input)
                .hour(0)
                .minute(0)
                .second(0)
                .toDate();
            this.generateSlots();
        },
    },
    created() {
        this.$parent.generateDefaultTimeSlots();
    },
    mounted() {},
    methods: {
        logValue(...props) {
            console.log(...props);
        },
        moment(...props) {
            return moment(...props);
        },
        async createRecurringSlots() {
            let self = this;
            if (self.step === 0) {
                let error = false;
                if (self.no_of_weeks === 0) {
                    toast.error("No. of weeks are requried!");
                    error = true;
                }
                if (!self.start_date) {
                    toast.error("Start Date is required!");
                    error = true;
                }
                if (
                    moment(this.start_date).second(1).unix() <=
                    moment().hour(0).minute(0).second(0).unix()
                ) {
                    toast.error("Start Date must be greater than now!");
                    error = true;
                }
                if (!self.price) {
                    toast.error("Price is required!");
                    error = true;
                }

                if (!error) {
                    self.step = 1;
                }
                return;
            }

            if (self.current_week + 1 === self.no_of_weeks) {
                let slotsToCreate = [];
                this.slots.map((_) => {
                    slotsToCreate = [
                        ...slotsToCreate,
                        ..._?.week?.filter((_) => _.enable),
                    ];
                });
                await Promise.all(
                    slotsToCreate.map(async (slot) => {
                        await Promise.all(
                            slot?.timeSlots?.map(async (timeSlot) => {
                                const originalSlot = {
                                    overlapping: false,
                                    available_days: [
                                        moment(slot.date)
                                            .format("dddd")
                                            .toLowerCase()
                                            .trim(),
                                    ],
                                    slot_type: "single",
                                    start_date: moment(slot.date).format(
                                        "YYYY-MM-DD"
                                    ),
                                    end_date: moment(slot.date).format(
                                        "YYYY-MM-DD"
                                    ),
                                    start_time: timeSlot.from,
                                    end_time: timeSlot.to,
                                    duration: "60",
                                    number_of_students: 1,
                                    break_per_slot: 0,
                                    price: this.price,
                                    notes: "",
                                };
                                console.log(this.price);
                                let formData = new FormData();

                                _.each(originalSlot, (value, key) => {
                                    if (key === "available_days") {
                                        formData.append(
                                            key,
                                            JSON.stringify(value)
                                        );
                                    } else {
                                        formData.append(key, value);
                                    }
                                });

                                formData.append("price", this.price);

                                let { data } = await axiosInstance.post(endpoints.slotsProStore, formData);
                                if (data.type === "success") {
                                    this.slotsAdded += 1;
                                }

                            })
                        );
                    })
                );
                this.loading = false;
                this.$parent.getSlots();
                this.$parent.slotModal = false;
                toast.success(this.slotsAdded +` ${this.slotsAdded > 1 ? "Slots" : "Slot"} Created!`);
            } else {
                self.current_week += 1;
            }
        },
        async copyToAllOtherWeeks() {

            let currentSlotWeek = this.slots[this.current_week]?.week;
            let currentSlotId = this.slots[this.current_week]?.id;

            this.slots?.map((_) => {

                let week = _?.week;
                let id = _?.id;

                if (id > currentSlotId) {
                    week.map((weekday) => {
                        let currentSlotWeekDay = currentSlotWeek
                            ?.filter((_) => _.key === weekday.key)
                            ?.at(0);
                        if (currentSlotWeekDay) {
                            weekday.timeSlots = currentSlotWeekDay?.timeSlots;
                            weekday.enable = currentSlotWeekDay?.enable;
                        }
                    });
                }

            });

            await this.createRecurringSlots();
        },

        generateSlots() {
            this.slots = [];
            if (this.no_of_weeks > 0) {
                let day_to_add = 0;
                let currentDate = this.start_date;
                let currentWeekday = moment(currentDate)
                    .format("dddd")
                    ?.toLowerCase();
                if (currentDate) {
                    while (currentWeekday !== "monday") {
                        currentDate = moment(currentDate)
                            .subtract("day", 1)
                            .toDate();
                        currentWeekday = moment(currentDate)
                            .format("dddd")
                            ?.toLowerCase();
                    }
                }

                Array.from({ length: this.no_of_weeks }).map((_, i) => {
                    this.slots.push({
                        id: i,
                        week: [
                            {
                                key: "monday",
                                enable: false,
                                date: moment(currentDate).add("day", day_to_add).toDate(),
                                timeSlots: [],
                            },
                            {
                                key: "tuesday",
                                enable: false,
                                date: moment(currentDate).add("day", day_to_add + 1).toDate(),
                                timeSlots: [],
                            },
                            {
                                key: "wednesday",
                                enable: false,
                                date: moment(currentDate).add("day", day_to_add + 2).toDate(),
                                timeSlots: [],
                            },
                            {
                                key: "thursday",
                                enable: false,
                                date: moment(currentDate).add("day", day_to_add + 3).toDate(),
                                timeSlots: [],
                            },
                            {
                                key: "friday",
                                enable: false,
                                date: moment(currentDate).add("day", day_to_add + 4).toDate(),
                                timeSlots: [],
                            },
                            {
                                key: "satureday",
                                enable: false,
                                date: moment(currentDate).add("day", day_to_add + 5).toDate(),
                                timeSlots: [],
                            },
                            {
                                key: "sunday",
                                enable: false,
                                date: moment(currentDate).add("day", day_to_add + 6).toDate(),
                                timeSlots: [],
                            },
                        ],
                    });
                    this.end_date = moment(currentDate).add("day", day_to_add + 6).toDate();
                    day_to_add += 7;

                });
            }
        },
    },
};
</script>

<style scoped>

.loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    top: 0px;
    left: 0px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    flex-direction: column;
}

</style>
