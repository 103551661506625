import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/css/all.min.css'
import '@/assets/css/navBar.css'
import '@/assets/css/munib-style.css'
import '@/assets/css/new-theme.css'
import '@/assets/css/student.css'
import '@/assets/css/style.css'
import '@/assets/css/calendar.css'
import '@/assets/css/swal-custom.css'
import 'vue3-toastify/dist/index.css'
import '@/assets/fonts/NitroFont.css'

import 'jquery/dist/jquery.slim.js'
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

import Vue3Toastify from 'vue3-toastify';
import Swal from '@/assets/js/swal';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import GoogleSignInPlugin from "vue3-google-signin"
import PortalVue from "portal-vue";
import { createPinia } from 'pinia';

const app = createApp(App)

app.use(GoogleSignInPlugin, {
    clientId: '815900191658-u05u4fgc5piguia1jr979pjd7jisd5ic.apps.googleusercontent.com',
});

app.use(Vue3Toastify, {
    autoClose: 3000
});

app.config.globalProperties.$swal = Swal;
const pinia = createPinia()

app.use(router)
app.use(pinia)
app.use(PortalVue);

app.mount('#app')
