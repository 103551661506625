<template>
    <div class="col-md-12 m-2">
        <div
            class="modal"
            id="addreview"
            style="
                display: block;
                background-color: rgba(0, 0, 0, 0.4);
                overflow: auto;
            "
            tabindex="-1"
            role="dialog"
        >
            <form action="" @submit.prevent="validateForm()">
                <div
                    class="modal-dialog modal-md modal-dialog-centered"
                    role="document"
                >
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Add Review</h5>
                            <button
                                v-on:click="
                                    $parent.showFeedBackModal =
                                        !$parent.showFeedBackModal
                                "
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group">
                                <star-rating
                                    :show-rating="false"
                                    id="Excellent"
                                    v-model="review.rating"
                                    :increment="0.5"
                                    v-bind:star-size="35"
                                ></star-rating>
                            </div>

                            <div class="form-group">
                                <label>Details</label>
                                <textarea
                                    v-model="review.detail"
                                    class="form-control"
                                    id="detail"
                                    aria-describedby="detail"
                                ></textarea>
                            </div>
                            <div class="mx-auto text-right mt-2">
                                <button
                                    class="btn btn-primary save-btn"
                                    type="submit"
                                    :disabled="!!loading"
                                >
                                    <div
                                        v-if="!!loading"
                                        class="spinner-border text-primary"
                                        style="
                                            color: white !important;
                                            width: 20px !important;
                                            height: 20px !important;
                                        "
                                        role="status"
                                    ></div>

                                    <span v-if="!!loading === false">Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import StarRating from "vue-star-rating";
import {toast} from "vue3-toastify";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
    name: "ReviewFormComponent",
    props: ["model_id", "model", "slot_id"],
    data() {
        return {
            loading: false,
            review: {
                rating: 0,
                title: "",
                detail: "",
            },
        };
    },
    components: {
        StarRating,
    },
    mounted() {},
    created() {},
    methods: {
        validateForm() {
            if (!this.review.rating) {
                toast.error("Please Select Rating...");
            }
            if (!this.review.detail) {
                toast.error("Enter Detail...");
            } else {
                this.addReview();
            }
        },
        async addReview() {
            let self = this;
            let rating = this.review.rating;
            let detail = this.review.detail;
            let model_id = this.model_id;
            let model = this.model;
            let formdata = new FormData();

            formdata.append("rating", rating);
            formdata.append("title", detail);
            formdata.append("detail", detail);
            formdata.append("model", model);
            formdata.append("model_id", model_id);
            if (self.model === "Slot") {
                formdata.append("slot_id", self.slot_id);
                formdata.append("model", "Tutor");
                this.loading = true;
                const { data } = await axiosInstance.post(endpoints.tutorReviewRoute, formdata);
                this.loading = false;
                if (data.type === "success") {
                    self.$parent.showFeedBackModal = false;
                    toast.success(data.message);
                } else {
                    toast.error(data.message);
                }
            } else {
                this.loading = true;
                const { data } = await axiosInstance.post(endpoints.reviewRoute, formdata);
                this.loading = false;
                if (data.type === "success") {
                    self.$parent.showFeedBackModal = false;
                    toast.success(data.message);
                } else {
                    toast.error(data.message);
                }
            }
        },
    },
};

</script>

<style scoped>
.vue-star-rating {
    justify-content: flex-start;
}
</style>
