<template>
    <div style="height: 100%">
        <LoadingComponent :loading="loading"></LoadingComponent>

        <div
            class="live_courses_container newtheme-livecourses"
            v-if="!loading"
        >
            <div class="row mx-0 mt-3 mb-3">
                <div class="col-12 col-sm-6">
                    <h4 class="heading-live">Live Courses</h4>
                </div>
                <div class="col-12 col-sm-6">
                    <div class="text-right">
                        <router-link to="/tutor/courses/create">
                            <button class="btn add_course_btn">
                                Add Course
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="row mt-2 mx-0">
                <div
                    class="live_course_box-container dashboard-card-green mt-md-0 mt-2"
                >
                    <a
                        href="javascript:void (0)"
                        v-on:click="filterCourses('approved')"
                    >
                        <div class="text-center live_course_box">
                            <h2>{{ filter.approved }}</h2>
                            <a href="javascript:void (0)">Approved Courses</a>
                        </div>
                    </a>
                </div>
                <div
                    class="live_course_box-container dashboard-card-yellow mt-md-0 mt-2"
                >
                    <a
                        href="javascript:void (0)"
                        v-on:click="filterCourses('under-review')"
                    >
                        <div class="text-center live_course_box">
                            <h2>{{ filter["under-review"] }}</h2>
                            <a href="javascript:void (0)">Pending Courses</a>
                        </div>
                    </a>
                </div>
                <div
                    class="live_course_box-container dashboard-card-pink mt-md-0 mt-2"
                >
                    <a
                        href="javascript:void (0)"
                        v-on:click="filterCourses('draft')"
                    >
                        <div class="text-center live_course_box">
                            <h2>{{ filter.draft }}</h2>
                            <a
                                href="javascript:void (0)"
                                v-on:click="filterCourses('draft')"
                                >Draft Courses</a
                            >
                        </div>
                    </a>
                </div>
                <div
                    class="live_course_box-container dashboard-card-green mt-md-0 mt-2"
                >
                    <a
                        href="javascript:void (0)"
                        v-on:click="filterCourses('free')"
                    >
                        <div class="text-center live_course_box">
                            <h2>{{ filter.free }}</h2>
                            <a
                                href="javascript:void (0)"
                                v-on:click="filterCourses('free')"
                                >Free Courses</a
                            >
                        </div>
                    </a>
                </div>
                <div
                    class="live_course_box-container dashboard-card-blue mt-md-0 mt-2"
                >
                    <a
                        href="javascript:void (0)"
                        v-on:click="filterCourses('paid')"
                    >
                        <div class="text-center live_course_box">
                            <h2>{{ filter.paid }}</h2>
                            <a
                                href="javascript:void (0)"
                                v-on:click="filterCourses('paid')"
                                >Paid Courses</a
                            >
                        </div>
                    </a>
                </div>
            </div>

            <div class="course-list-heading">Course List</div>
            <div class="table_parent px-5 pb-4 pt-2">
                <section class="px-0">
                    <div class="row tabel_overflow py-sm-2 py-0">
                        <div class="col-md-12 px-0">
                            <CourseListingComponent></CourseListingComponent>
                        </div>
                    </div>
                </section>
            </div>

            <div
                class="modal courseStatusChange"
                v-if="courseDisableReasonModal"
                style="
                    display: block;
                    background-color: rgba(0, 0, 0, 0.4);
                    overflow-x: scroll;
                "
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="title">Course Disable Reason</h3>
                            <button
                                v-on:click="
                                    courseDisableReasonModal =
                                        !courseDisableReasonModal
                                "
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="col-md-12">
                                <ConversationComponent></ConversationComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CourseListingComponent from "@/components/tutor/course/CourseListingComponent";
import ConversationComponent from "@/components/tutor/course/conversation/ConversationComponent";
import LoadingComponent from "@/components/utilities/loading/loader.vue";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
    name: "CoursesComponent",
    components: {
        CourseListingComponent,
        ConversationComponent,
        LoadingComponent,
    },
    data() {
        return {
            loading: true,
            selectedCourse: 0,
            courseDisableReasonModal: false,
            courses: [],
            filter: [],
            allCourses: [],
            categories: [],
            showComponent: false,
            query: {
                page: 1,
                limit: 8,
                keyword: "",
                category_id: "",
                status: "",
                price: "",
                role: "",
                total: 0,
            },
            no_record: false,
            coursesView: [],
        };
    },
    mounted() {},
    created() {
        let self = this;
        self.getCourses();
    },
    methods: {
        async getCourses() {
            let self = this;
            const coursesRes = await axiosInstance.get(endpoints.getCoursesRoute, {params: self.query});
            this.loading = false;

            let data = null;

            if(coursesRes) {
              data = coursesRes.data.data
            }

            this.sessions = data.sessions;
            self.courses = self.allCourses = data.data.data;
            self.filter = data.filter;
            self.categories = data.categories;
            self.showComponent = true;

            this.coursesView = data.data.data;
            this.query.total = data.data.total;
            if (this.coursesView.length === 0) {
                this.no_record = true;
            }
        },
        filterCourses(type) {
            let self = this;
            if (type === "paid") {
                self.query.price = true;
                self.query.status = "";
            } else {
                self.query.status = type;
            }
            self.getCourses();
        },
    },
};
</script>

<style scoped></style>
