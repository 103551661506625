<template>
    <div>
        <div
            class="container billing-newtheme"
            style="overflow: hidden"
        >
            <div class="billing_parent_student student_billing">
                <div class="billing_container">
                    <div class="row billing-cards">
                        <div class="col-lg-4 mt-lg-0 mt-4 m-0 box first_box">
                            <div
                                class="row student_billing_box avaiable_bill_card_container"
                            >
                                <div class="d-inline-block col-12 box_text">
                                    <span class="float-left avaiable_bill_card">
                                        <img
                                            src="@/assets/new-theme-images/fluent_vault-24-regular.svg"
                                        />
                                    </span>
                                    <span class="d-inline-block">
                                        <p class="p-0">Available Balance</p>
                                        <h2>
                                            {{ wallet }}
                                        </h2>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            class="col-lg-4 mt-lg-0 mt-4 m-0 box billing_second_box second_box"
                        >
                            <div class="row tutor_billing_box">
                                <div class="d-inline-block col-12 box_text">
                                    <span class="float-left">
                                        <img
                                            src="@/assets/new-theme-images/carbon_money.svg"
                                        />
                                    </span>
                                    <span class="d-inline-block">
                                        <p class="p-0">Amount Spent</p>
                                        <h2>
                                            {{ transaction.toFixed(2) }}
                                        </h2>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            class="col-lg-4 mt-lg-0 mt-4 m-0 billing_second_box box third_box"
                        >
                        </div>
                        <div class="billing_balance_cards">
                            <div class="mt-lg-0 mt-4 m-0 box second_box">
                                <div class="row tutor_billing_box">
                                    <div
                                        class="d-inline-block billing_box_wrapper col-12 box_text"
                                    >
                                        <span
                                            class="float-left billing_card_icon"
                                        >
                                            <img
                                                src="@/assets/new-theme-images/carbon_money.svg"
                                            />
                                        </span>
                                        <span class="d-inline-block">
                                            <p class="p-0">Amount Spent</p>
                                            <h2>{{ transaction }}</h2>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="row parent_links mt-2 justify-content-center billing_transactions"
                    >
                        <div
                            class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-lg-0 mt-4 m-0 link first_link"
                        >
                            <router-link to="/student/billing/deposit">
                                <a href="">
                                    <div class="text-center py-3">
                                        <div class="billing-img">
                                            <img
                                                src="@/assets/new-theme-images/history.png"
                                                alt="img-fluid"
                                            />
                                        </div>
                                        <h6 class="mt-3">Make a Deposit</h6>
                                    </div>
                                </a>
                            </router-link>
                        </div>
                        <div
                            class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-lg-0 mt-4 m-0 link third_link"
                        >
                            <router-link to="/student/billing/transaction">
                                <a href="">
                                    <div class="text-center py-3">
                                        <div class="billing-img">
                                            <img
                                                src="@/assets/new-theme-images/deposit.png"
                                                alt="img-fluid"
                                            />
                                        </div>
                                        <h6 class="mt-3">
                                            Transaction History
                                        </h6>
                                    </div>
                                </a>
                            </router-link>
                        </div>
                        <div
                            class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-lg-0 mt-4 m-0 link third_link"
                        >
                            <router-link to="/student/billing/requests">
                                <a href="javascript:void(0)">
                                    <div class="text-center py-3">
                                        <div class="billing-img">
                                            <img
                                                src="@/assets/new-theme-images/atm.png"
                                                alt="img-fluid"
                                            />
                                        </div>
                                        <h6 class="mt-3">Requests</h6>
                                    </div>
                                </a>
                            </router-link>
                        </div>
                    </div>
                    <div class="billing_transactions_show">
                        <div class="billing_make_deposit">
                            <router-link to="/student/billing/deposit">
                                Make A Deposit
                            </router-link>
                        </div>
                        <div
                            class="billing_make_transaction billing_make_deposit"
                        >
                            <router-link to="/student/billing/transaction">
                                Transaction History
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";
import {hideLoader, showLoader} from "@/assets/js/swal";

export default {
    name: "BillingComponent",
    props: [],
    components: {},
    directives: {},
    data() {
        return {
            logs: [],
            wallet: 0.0,
            transaction: 0.0,
        };
    },
    computed: {},
    async created() {
        await this.getUserDetails();
    },
    methods: {
        async getUserDetails() {
            try {

              showLoader()

              const { data } = await axiosInstance.get(endpoints.getUserDetails);
              this.wallet = parseFloat(data.data.wallet);
              this.transaction = parseFloat(data.data.transaction);

              hideLoader();

            } catch (e) {

              hideLoader();

              toast.error('Server Error');
            }
        },
    },
};
</script>

<style scoped></style>
