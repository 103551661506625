<template>
    <div
        class=""
        id="rest-password"
        aria-labelledby="offlinePayment"
    >
        <div class="row mb-3">
            <div class="col-md-9">
                <h4>Reset Password</h4>
            </div>
        </div>

        <form
            @submit.prevent="updatePassword()"
            action=""
            enctype="multipart/form-data"
            method="post"
        >
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="current_password">Current Password</label>
                        <input
                            required
                            type="password"
                            class="form-control"
                            id="current_password"
                            v-model="info.current_password"
                            aria-describedby="emailHelp"
                            placeholder="Current Password"
                            v-on:focus="errorMessages.current_password = []"
                        />
                        <ul
                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                            v-if="errorMessages.current_password.length > 0"
                        >
                            <li v-for="err in errorMessages.current_password" :key="err">
                                <small>{{ err }}</small>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="password">New Password</label>
                        <input
                            required
                            type="password"
                            class="form-control"
                            id="password"
                            v-model="info.password"
                            aria-describedby="emailHelp"
                            placeholder="New Password"
                            v-on:focus="errorMessages.password = []"
                        />
                        <ul
                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                            v-if="errorMessages.password.length > 0"
                        >
                            <li v-for="err in errorMessages.password" :key="err">
                                <small>{{ err }}</small>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="confirm_password"
                            >Confirm New Password</label
                        >
                        <input
                            required
                            type="password"
                            class="form-control"
                            id="confirm_password"
                            v-model="info.confirm_password"
                            aria-describedby="emailHelp"
                            placeholder="Re-type Password"
                            v-on:focus="errorMessages.confirm_password = []"
                        />
                        <ul
                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                            v-if="errorMessages.confirm_password.length > 0"
                        >
                            <li v-for="err in errorMessages.confirm_password" :key="err">
                                <small>{{ err }}</small>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="mx-auto text-center mt-2">
                <button
                    type="submit"
                    class="btn confirm_deposite_btn"
                    :disabled="!!loading"
                >
                    <div
                        v-if="!!loading"
                        class="spinner-border text-primary"
                        style="
                            color: white !important;
                            width: 20px !important;
                            height: 20px !important;
                        "
                        role="status"
                    ></div>

                    <span v-if="!!loading == false">Save</span>
                </button>
            </div>
        </form>
    </div>
</template>

<script>

import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";

export default {
    name: "PasswordResetComponent",
    data() {
        return {
            loading: false,
            info: {
                email: "",
                current_password: "",
                password: "",
                confirm_password: "",
            },
            errorMessages: {
                email: "",
                current_password: "",
                password: "",
                confirm_password: "",
            },
        };
    },
    created() {
        this.getProfileInfo();
    },
    mounted() {},
    methods: {
        checkOldPassword() {
            this.errorMessages.password = [];
            if (!this.info.username) {
                this.errorMessages.username.push("Enter User Name ...");
                return false;
            } else {
                this.errorMessages.username = [];
                return true;
            }
        },
        checkNewPassword() {
            this.errorMessages.password = [];
            if (!this.info.password || this.info.password.length < 8) {
                this.errorMessages.password.push(
                    "Enter Password min 8 characters ..."
                );
                return false;
            } else {
                this.errorMessages.password = [];
                return true;
            }
        },
        checkConfirmPassword() {
            this.errorMessages.confirm_password = [];
            if (
                !this.info.confirm_password ||
                this.info.password !== this.info.confirm_password
            ) {
                if (!this.info.confirm_password) {
                    this.errorMessages.confirm_password.push(
                        "Enter Confirm Password min 8 characters"
                    );
                    return false;
                }
                if (this.info.password !== this.info.confirm_password) {
                    this.errorMessages.confirm_password.push(
                        "Confirm Password and password should be same ..."
                    );
                    return false;
                }
            } else {
                this.errorMessages.confirm_password = [];
                return true;
            }
        },

        async updatePassword() {
            let self = this;
            this.checkNewPassword();
            this.checkConfirmPassword();
            if (this.checkNewPassword() && this.checkConfirmPassword()) {
                let formData = new FormData();
                let pro = this.info;
                formData.append("email", pro.email);
                formData.append("current_password", pro.current_password);
                formData.append("password", pro.password);
                formData.append("password_confirmation", pro.confirm_password);
                this.loading = true;
                const { data } = await axiosInstance.post(endpoints.updatePassword, formData);
                this.loading = false;
                if (data.type === "success") {
                    toast.success(data.message);
                    this.info.current_password = "";
                    this.info.password = "";
                    this.info.confirm_password = "";
                } else if (data.type === "validation") {
                    Object.keys(data.message).forEach(function (key) {
                        self.errorMessages[key] = data.message[key];
                    });
                } else {
                    self.errorMessages.current_password.push(data.message);
                }
            }
        },
        async getProfileInfo() {
            const infoRes = await axiosInstance.get(endpoints.infoPro);

            let data = null;

            if(infoRes) {
              data = infoRes.data.data
            }

            this.info = data.info;
            this.info.first_name = data.user.first_name;
            this.info.last_name = data.user.last_name;
            this.info.middle_name = data.user.middle_name;
            this.info.email = data.user.email;
            this.info.image = data.user.image;
        },
    },
};
</script>

<style scoped>
button {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
