<template>
  <div>
    <div class="hero-course-wrap bg-offwhite newtheme-cart-component">
      <div class="container">
        <div class="hero-course-con py-4">
          <h2 class="font-weight-bold color_black mb-2 mt-4">Contact Us</h2>
        </div>
      </div>
    </div>

    <section id="contact">
      <div class="contact-wrapper">
        <!-- Contact Form -->
        <form id="contact-form" class="form-horizontal" role="form" @submit.prevent="handleSubmit">
          <div class="form-group">
            <div class="col-sm-12">
              <input type="text" class="form-control" id="name" placeholder="NAME" v-model="form.name" required />
            </div>
          </div>

          <div class="form-group">
            <div class="col-sm-12">
              <input type="email" class="form-control" id="email" placeholder="EMAIL" v-model="form.email" required />
            </div>
          </div>

          <textarea class="form-control" rows="10" placeholder="MESSAGE" v-model="form.message" required></textarea>

          <button class="btn btn-primary send-button" id="submit" type="submit">
            <div class="alt-send-button">
              <span class="send-text">SEND</span>
            </div>
          </button>
        </form>

        <!-- Direct Contact Information -->
        <div class="direct-contact-container">
          <ul class="contact-list">
            <li class="list-item">
              <i class="fas fa-map-marker fa-2x">
                <span class="contact-text place">B-272 lane no 6, LalaRukh WahCantt, Punjab</span>
              </i>
            </li>

            <li class="list-item">
              <i class="fas fa-phone fa-2x">
                <span class="contact-text phone">
                  <a href="tel:+923445643618" title="Give me a call">(+92) 344-5643618</a>
                </span>
              </i>
            </li>

            <li class="list-item">
              <i class="fas fa-envelope fa-2x">
                <span class="contact-text gmail">
                  <a href="mailto:ilmzone.pk@gmail.com" title="Send me an email">ilmzone.pk@gmail.com</a>
                </span>
              </i>
            </li>
          </ul>

          <hr />

<!--          <ul class="social-media-list">-->
<!--            <li><a href="#" target="_blank" class="contact-icon"><i class="fas fa-github" aria-hidden="true"></i></a></li>-->
<!--            <li><a href="#" target="_blank" class="contact-icon"><i class="fas fa-codepen" aria-hidden="true"></i></a></li>-->
<!--            <li><a href="#" target="_blank" class="contact-icon"><i class="fas fa-twitter" aria-hidden="true"></i></a></li>-->
<!--            <li><a href="#" target="_blank" class="contact-icon"><i class="fas fa-instagram" aria-hidden="true"></i></a></li>-->
<!--          </ul>-->

          <hr />

          <div class="copyright">&copy; ALL OF THE RIGHTS RESERVED</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
  data() {
    return {
      form: {
        name: "",
        email: "",
        message: ""
      }
    };
  },
  methods: {
    handleSubmit() {
      // Handle form submission logic here
      console.log("Form submitted with:", this.form);
      // Reset form fields if needed
      this.form.name = "";
      this.form.email = "";
      this.form.message = "";
    }
  }
};
</script>

<style scoped>
.form-group .col-sm-12 {
  padding-left: 0;
  padding-right: 0;
}
#contact {
  width: 100%;
  height: 100%;
}

.section-header {
  text-align: center;
  margin: 0 auto;
  padding: 40px 0;
  font: 300 60px 'Oswald', sans-serif;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 6px;
}

.contact-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  max-width: 840px;
}

/* Left contact page */
.form-horizontal {
  /*float: left;*/
  max-width: 400px;
  font-family: 'Lato';
  font-weight: 400;
  width: 100%;
}

.form-control,
textarea {
  max-width: 400px;
  background-color: #fff;
  color: #000;
  letter-spacing: 1px;
}

.send-button {
  margin-top: 15px;
  height: 34px;
  width: 400px;
  overflow: hidden;
  transition: all .2s ease-in-out;
  background: #532d5f !important;
}

.alt-send-button {
  max-width: 400px;
  width: 100%;
  transition: all .2s ease-in-out;
}

.send-text {
  display: block;
  font: 700 12px 'Lato', sans-serif;
  letter-spacing: 2px;
}

.alt-send-button:hover {
  transform: translate3d(0px, -29px, 0px);
}

/* Begin Right Contact Page */
.direct-contact-container {
  max-width: 400px;
}

/* Location, Phone, Email Section */
.contact-list {
  list-style-type: none;
}

.list-item {
  line-height: 4;
  color: #aaa;
  display: flex;
  margin-bottom: 20px;
}

.list-item i {
  display: flex;
}

.contact-text {
  font: 300 18px 'Lato', sans-serif;
  letter-spacing: 1.9px;
  color: #000;
}

.place {
  margin-left: 20px;
}

.phone {
  margin-left: 15px;
}

.gmail {
  margin-left: 15px;
}

.contact-text a {
  color: #fff;
  text-decoration: none;
  transition-duration: 0.2s;
}

.contact-text a:hover {
  color: #532d5f;
  text-decoration: none;
}


/* Social Media Icons */
.social-media-list {
  position: relative;
  font-size: 22px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.social-media-list li a {
  color: #fff;
}

.social-media-list li {
  position: relative;
  display: inline-block;
  height: 60px;
  width: 60px;
  margin: 10px 3px;
  line-height: 60px;
  border-radius: 50%;
  color: #fff;
  background-color: rgb(27,27,27);
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.social-media-list li:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  opacity: 0;
  box-shadow: 0 0 0 1px #fff;
  transition: all .2s ease-in-out;
}

.social-media-list li:hover {
  background-color: #532d5f;
}

.social-media-list li:hover:after {
  opacity: 1;
  transform: scale(1.12);
  transition-timing-function: cubic-bezier(0.37,0.74,0.15,1.65);
}

.social-media-list li:hover a {
  color: #532d5f;
}

.copyright {
  font: 200 14px 'Oswald', sans-serif;
  color: #555;
  letter-spacing: 1px;
  text-align: center;
}

hr {
  border-color: rgba(255,255,255,.6);
}

/* Begin Media Queries*/
@media screen and (max-width: 850px) {
  .contact-wrapper {
    display: flex;
    flex-direction: column;
  }
  .direct-contact-container, .form-horizontal {
    margin: 0 auto;
  }

  .direct-contact-container {
    margin-top: 60px;
    max-width: 300px;
  }
  .social-media-list li {
    height: 60px;
    width: 60px;
    line-height: 60px;
  }
  .social-media-list li:after {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }

  .contact-list {
    padding: 0;
    margin: 0;
  }
}

@media screen and (max-width: 569px) {

  .direct-contact-container, .form-wrapper {
    float: none;
    margin: 0 auto;
  }
  .form-control, textarea {

    margin: 0 auto;
  }


  .name, .email, textarea {
    width: 280px;
  }

  .direct-contact-container {
    margin-top: 60px;
    max-width: 280px;
  }
  .social-media-list {
    left: 0;
  }
  .social-media-list li {
    height: 55px;
    width: 55px;
    line-height: 55px;
    font-size: 2rem;
  }
  .social-media-list li:after {
    width: 55px;
    height: 55px;
    line-height: 55px;
  }

}

@media screen and (max-width: 410px) {
  .send-button {
    width: 99%;
  }
}


</style>