<template>
    <div style="height: 100%">
        <LoadingComponent :loading="loading"></LoadingComponent>
        <div class="live_courses_container" v-if="!loading">
            <div class="table_parent">
                <section class="mt-4 px-3">
                    <div class="mb-sm-2 mb-1 log_heading">
                        <!--            <span class="h4">Activity Logs</span>-->
                    </div>
                    <div
                        class="row tabel_overflow mx-0 activity-log-table student_log"
                    >
                        <div
                            class="col-md-12 pt-sm-4 pt-0 pr-sm-4 pb-0 pl-sm-4 pl-0 pr-0 d-flex flex-sm-column flex-column-reverse"
                        >
                            <div>
                                <div class="table-wrap table-responsive">
                                    <table
                                        class="table text-center mr-sm-0 mr-3 table-hover log_activity_table"
                                    >
                                        <thead class="thead-primary border-0">
                                            <tr class="th">
                                                <th>Activity</th>
                                                <!--                      <th>URL</th>-->
                                                <!--                      <th>Method</th>-->
                                                <th>IP</th>
                                                <!--                      <th>Agent</th>-->
                                                <th>Date</th>
                                                <!--            <th>Balance</th>-->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <!-- eslint-disable -->
                                            <tr v-for="log in logs">
                                                <td class="border-0">
                                                    {{ log.subject }}
                                                </td>
                                                <!--                      <td class="border-0" >{{ log.url }}</td>-->
                                                <!--                      <td class="border-0" >{{ log.method }}</td>-->
                                                <td class="border-0">
                                                    {{ log.ip }}
                                                </td>
                                                <!--                      <td class="border-0" >{{ log.agent }}</td>-->
                                                <td class="border-0">
                                                  <!-- eslint-disable -->
                                                    {{
                                                        log.created_at
                                                            | DDMMMhhmmA
                                                    }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="no_record">
                                        <p class="p-2">
                                            {{ $t("text.no_record") }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-12 text-center">
                                    <div
                                        class="text-xl-right text-lg-right text-md-right pt-2 log_btn"
                                    >
                                        <pagination
                                            v-show="query.total > 0"
                                            v-model="query.page"
                                            :records="query.total"
                                            :per-page="query.limit"
                                            @paginate="getActivityLogs"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12">
                                <div
                                    class="float-right session-table-options hey"
                                >
                                    <p>
                                        <!--                    <a href="javascript:void (0)" class="d-inline-block"><download-csv :data= "logs"><img src="/new-theme-images/export-icon.png" /> Download CSV</download-csv></a>-->
                                        <!--                    <a href="" download class="d-inline-block"><img src="/new-theme-images/blue-pdf.png" /> Print PDF</a>-->
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="text-center">
                    <!--                <a href="#" class="clear-filter">Load more</a>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingComponent from "@/components/utilities/loading/loader.vue";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
    name: "LogActivityComponent",
    components: { LoadingComponent },
    data() {
        return {
            loading: true,
            no_record: false,
            logs: [],
            query: {
                keyword: "",
                sort: "DESC",
                page: 1,
                limit: 10,
                total: 0,
            },
        };
    },
    async created() {
        await this.getActivityLogs();
        this.loading = false;
    },
    methods: {
        async getActivityLogs(page) {
            this.query.page = page ? page : this.query.page;
            const { data } = await axiosInstance().get(endpoints.getActivityLogsRoute, this.query);
            this.logs = data.data.data;
            this.query.total = data.data.total;
            if (this.logs.length === 0) {
                this.no_record = true;
            }
        },
        async getActivityLogsLoadMore() {
            let self = this;
            this.query.page++;
            const { data } = await axiosInstance().get(endpoints.getActivityLogsRoute, this.query);
            data.data.data.forEach(function (log) {
                self.logs.push(log);
            });
        },
    },
};
</script>
<style scoped></style>
