<template>
  <div>
    <Step1Component v-if="steps === 1"></Step1Component>
    <Step2Component v-if="steps === 2"></Step2Component>
    <Step3Component v-if="steps === 3"></Step3Component>
  </div>
</template>

<script>
import Step1Component from "./steps/Step1Component";
import Step2Component from "./steps/Step2Component";
import Step3Component from "./steps/Step3Component";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";

export default {
  name: "BecomeATutorComponent",
  components: {
    Step1Component,
    Step2Component,
    Step3Component,
  },
  data() {
    return {
      steps: 0,
    }
  },
  created() {
    this.checkApplied()

  },
  mounted() {
  },
  methods: {
    async checkApplied() {
      const {data} = await axiosInstance.get(endpoints.checkRoute);
      if (data.result) {
        toast.success('You have Already Applied')
      }
      else {
        this.steps = 0
      }
    },
  }
}
</script>

<style scoped>
.progressbar-box li {
  width: 33%;
}
</style>
