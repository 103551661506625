<template>
  <div>
    <div v-if="step === 0">
      <h5 style="text-align: center">You're Verified Successfully</h5>
    </div>
    <div v-if="step === 1">
      <Step1Component></Step1Component>
    </div>
    <div v-if="step === 2">
      <Step2Component></Step2Component>
    </div>
    <div v-if="step === 3">
      <Step3Component></Step3Component>
    </div>
  </div>
</template>

<script>

import Step1Component from "@/components/tutor/verified/steps/Step1Component";
import Step2Component from "@/components/tutor/verified/steps/Step2Component";
import Step3Component from "@/components/tutor/verified/steps/Step3Component";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
export default {
  name: "GetVerifiedComponent",
  components: {
    Step1Component,
    Step2Component,
    Step3Component,
  },
  data() {
    return {
      step: 1,

    }
  },
  created() {
    this.getVerification()
  },
  methods: {
    async getVerification() {
      let self = this
      const {data} = await axiosInstance.get(endpoints.getVerificationRoute);
      console.log(data.data, data.type)
      if (data.type === 'success') {
        if (data.data.status === 'confirmed') {
          self.step = 0
        }
        else if (data.data.payment_status === 'paid') {
          self.step = 3
        }
        else if (data.data.payment_status === 'pending') {
          self.step = 2
        }
        else if (data.data.status === 'pending') {
          self.step = 3
        }
        else {
          self.step = 1
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
