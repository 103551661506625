<template>
    <div>
        <div class="container pt-4 pb-4">
            <div class="course-adding-form box-style-coruse">
                <ul id="progressbar" class="progressbar-box six-steps">
                    <li class="active" id="general">
                        <strong class="color-light-blue">Basic Course Info</strong
                        ><span>1</span>
                    </li>
                    <li class="active" id="duration">
                        <strong class="color-light-blue">Proposed Schedule</strong
                        ><span>2</span>
                    </li>
                    <li class="active" id="pricingrequirements">
                        <strong>Pricing & Requirements </strong><span>3</span>
                    </li>
                    <li class="active" id="courseoutline">
                        <strong class="color-light-blue">Course Outline</strong
                        ><span>4</span>
                    </li>
                    <li class="active" id="mediaseo">
                        <strong class="color-light-blue">Media & SEO</strong
                        ><span>5</span>
                    </li>
                    <!--          <li id="quiz"><strong>Quiz</strong><span>6</span></li>-->
                </ul>
                <div class="container-fluid" id="grad1">
                    <div class="row">
                        <div class="col-md-12 mx-0">
                            <form id="form-box" class="form-box">
                                <fieldset>
                                    <div class="row p-3">
                                        <div class="col-md-12">
                                            <h4 class="mb-1 mt-1 media-seo-t">
                                                Upload Media
                                            </h4>
                                        </div>
                                        <div
                                            class="col-md-6 thumbnail-label-media"
                                        >
                                            <div class="form-group">
                                                <label for="file"
                                                    >Upload Course Thumbnail</label
                                                >
                                                <div
                                                    class="input-group mb-3 position-relative"
                                                >
                                                    <div
                                                        class="input-group-append m-0 thumbnail_btn"
                                                    >
                                                        <label
                                                            class="btn btn-outline-secondary"
                                                            for="file"
                                                            type="button"
                                                        >
                                                            <input
                                                                v-on:change="
                                                                    handleFileUpload()
                                                                "
                                                                id="file"
                                                                ref="file"
                                                                type="file"
                                                                class="form-control input_height prerequisite-input invisible"
                                                                placeholder="Upload Your Thumbnail"
                                                                aria-label="Upload Your Thumbnail"
                                                                aria-describedby="basic-addon2"
                                                            />
                                                            Upload Thumbnail
                                                            <img
                                                                src="/new-theme-images/upload-yellow.png"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul
                                                class="text-sm text-danger text-red-600 text-xs italic mt-5"
                                                v-if="
                                                    errorMessages.thumbnail
                                                        .length > 0
                                                "
                                            >
                                              <!-- eslint-disable -->
                                                <li
                                                    v-for="err in errorMessages.thumbnail"
                                                >
                                                    <small>{{ err }}</small>
                                                </li>
                                            </ul>
                                        </div>
                                        <div
                                            class="col-md-6 thumbnail-label-media"
                                        >
                                            <div class="form-group">
                                                <label for="url"
                                                    >Please upload your introductory video (recommended mp4, 2 min max) with course outline.</label
                                                >
                                                <div
                                                    class="input-group mb-3 position-relative"
                                                >
                                                    <div
                                                        class="input-group-append m-0 thumbnail_btn"
                                                    >
                                                        <label
                                                            class="btn btn-outline-secondary"
                                                            type="button"
                                                        >
                                                            <input
                                                                type="file"
                                                                class="form-control input_height prerequisite-input invisible"
                                                                id="url"
                                                                ref="vid"
                                                                v-on:change="
                                                                    handleVideoUpload()
                                                                "
                                                                placeholder="Upload Intro Video"
                                                                aria-label="Upload Intro Video"
                                                                aria-describedby="basic-addon2"
                                                            />
                                                            Upload Intro Video
                                                            <img
                                                                src="/new-theme-images/upload-yellow.png"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                          <!-- eslint-disable -->
                                            <ul
                                                class="text-sm text-danger text-red-600 text-xs italic mt-5"
                                                v-if="
                                                    errorMessages.video.length >
                                                    0
                                                "
                                            >
                                                <li
                                                    v-for="err in errorMessages.video"
                                                >
                                                    <small>{{ err }}</small>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-md-6"></div>
                                        <div class="col-md-12 mt-5 mt-sm-0">
                                            <h4 class="mb-1 mt-5 media-seo-t">
                                                SEO
                                            </h4>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="meta_keywords"
                                                    >Meta Keywords</label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control input_height"
                                                    v-model="
                                                        course.meta_keywords
                                                    "
                                                    id="meta_keywords"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Meta Keywords"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="meta_description"
                                                    >Meta Description</label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control input_height"
                                                    id="meta_description"
                                                    v-model="
                                                        course.meta_description
                                                    "
                                                    aria-describedby="emailHelp"
                                                    placeholder="Meta Description"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row px-3 mb-3">
                                        <div class="col-sm-6 col-6">
                                            <a
                                                v-on:click="$parent.steps--"
                                                href="javascript:void(0)"
                                                class="btn btn_fontsize btn-outline-primary btn-lg back-btn"
                                                >Back</a
                                            >
                                        </div>
                                        <div class="col-sm-6 col-6 text-right">
                                            <a
                                                v-on:click="checkCourseForm"
                                                href="javascript:void(0)"
                                                class="next action-button btn btn-primary btn_fontsize btn-lg"
                                                :disabled="!!loading"
                                                ><div
                                                    v-if="!!loading"
                                                    class="spinner-border text-primary"
                                                    style="
                                                        color: white !important;
                                                        width: 20px !important;
                                                        height: 20px !important;
                                                    "
                                                    role="status"
                                                ></div>
                                                <span v-if="!!loading == false">
                                                    Submit</span
                                                ></a
                                            >
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Home Content End -->
    </div>
</template>

<script>
import _ from "lodash";
import axiosInstance from "@/assets/js/axios";
export default {
    name: "Step6Component",
    data() {
        return {
            loading: false,
            course: {
                course_preview_provider: 0,
                url: "",
                meta_keywords: "",
                meta_description: "",
                thumbnail: "",
            },
            file: "",
            vid: "",
            errorMessages: {
                video: [],
                thumbnail: [],
            },
        };
    },
    created() {
        this.getCourseDetails();
    },
    methods: {
        handleFileUpload() {
            this.file = this.$refs.file.files[0];
        },
        handleVideoUpload() {
            this.vid = this.$refs.vid.files[0];
            this.videoFileSizeCheck();
        },
        videoFileSizeCheck() {
            if (this.vid.size) {
                if (this.vid.size / 1048576 >= 5) {
                    this.errorMessages.video.push(
                        "Video Size not greater than 5 mb!"
                    );
                    return true;
                } else {
                    return false;
                }
            }
        },
        checkCourseForm() {
            this.checkVideo();
            this.checkThumbnail();
            if (this.videoFileSizeCheck()) {
                console.log('check');
            } else {
                if (this.checkVideo() && this.checkThumbnail()) {
                    this.updateCourse();
                }
            }
        },
        checkVideo() {
            this.errorMessages.video = [];
            if (!this.course.video && !this.vid) {
                this.errorMessages.video.push(
                    "Introductory video is mandatory."
                );
                return false;
            } else {
                this.errorMessages.video = [];
                return true;
            }
        },
        checkThumbnail() {
            this.errorMessages.thumbnail = [];
            if (!this.course.thumbnail && !this.file) {
                this.errorMessages.thumbnail.push("Thumbnail is mandatory.");
                return false;
            } else {
                this.errorMessages.thumbnail = [];
                return true;
            }
        },

        getCourseDetails() {
            let id = this.$route.params.id;
            let self = this;
            axiosInstance
                .get("/v1/course/edit/" + id)
                .then(function (response) {
                    // handle success
                    if (response.data.type === "success") {
                        self.course = response.data.data;
                        self.course.meta_keywords =
                            response.data.data.meta_keywords === "null"
                                ? ""
                                : response.data.data.meta_keywords;
                        self.course.meta_description =
                            response.data.data.meta_description === "null"
                                ? ""
                                : response.data.data.meta_description;
                    } else {
                        self.$toasted.error(response.data.message);
                    }
                    console.log(self.course);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        },
        updateCourse() {
            let self = this;
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            let formData = new FormData();
            formData.append("file", self.file);
            formData.append("vid", self.vid);
            _.each(self.course, (value, key) => {
                if (value) formData.append(key, value);
            });
            if (self.$route.params.id) {
                let id = self.$route.params.id;
                formData.append("id", id);
            }

            self.loading = true;
            axiosInstance
                .post("/v1/course/create/step-6", formData, config)
                .then(function (response) {
                    self.loading = false;
                    // handle success
                    if (response.data.data.type === "validation") {
                        Object.keys(response.data.data.message).forEach(
                            function (key) {
                                self.errorMessages[key] =
                                    response.data.data.message[key];
                            }
                        );
                        Object.keys(response.data.data.message).forEach(
                            function (key) {
                                response.data.data.message[key].forEach(
                                    function (err) {
                                        if (key === "file") {
                                            self.errorMessages.thumbnail.push(
                                                err
                                            );
                                        }
                                        if (key === "vid") {
                                            self.errorMessages.video.push(err);
                                        }
                                    }
                                );
                            }
                        );
                    } else if (response.data.data.type === "success") {
                        // Swal.fire({
                        //   title: 'Quiz',
                        //   text: "Want to Add Quiz",
                        //   icon: 'warning',
                        //   showCancelButton: true,
                        //   confirmButtonColor: '#3085d6',
                        //   cancelButtonColor: '#d33',
                        //   confirmButtonText: 'Yes, Add Quiz!'
                        // }).then((result) => {
                        //   if (result.isConfirmed) {
                        //     self.$router.push({ name: 'course-quiz-step', params: { course_id: self.$route.params.id } })
                        //   }
                        //   else {
                        self.$router.push({ name: "courses" });
                        // }
                        // })
                    }
                })
                .catch(function (error) {
                    self.loading = false;
                    // handle error
                    console.log(error);
                })
                .then(function () {});
        },
    },
};
</script>

<style scoped>
@media screen and (min-width: 1400px) {
    .container {
        max-width: 1450px !important;
    }
}
@media screen and (min-width: 1850px) {
    .container {
        max-width: 2150px !important;
    }
}
h5,
small {
    color: #dc3545 !important;
}
</style>
