<template>
  <div>
    <div class="hero-course-wrap bg-offwhite newtheme-cart-component">
      <div class="container">
        <div class="hero-course-con py-4">
          <h2 class="font-weight-bold color_black mb-2 mt-4">Refunds and Disputes</h2>
        </div>
      </div>
    </div>

    <div class="container mt-5">
      <h4>Refunds and Disputes</h4>
      <p>
        ILMZone believes in value, trust, and excellence. Our Refund and Dispute Policy allows for fairness, transparency, and prompt service. By registering with ILMZone as a student or tutor, you automatically agree to the ILMZone Refund and Dispute Policy.
      </p>

      <h4>Refunds</h4>
      <p>A student can request a refund from the tutor if the transaction fulfills the following conditions.</p>
      <ul>
        <li>Refunds are only available for one-on-one lessons.</li>
        <li>Refunds can only be claimed if a tutor did not attend or complete the lesson.</li>
        <li>A Request for Refund must be submitted WITHIN FOUR DAYS (Review Period) of the scheduled lesson. No exceptions.</li>
        <li>Keep in mind that Refund Requests made after the submission deadline will be rejected.</li>
        <li>
          For example, if a lesson ends on Monday 12:00 PM PKT, the deadline for submitting a Refund Request is Friday 00:00 AM PKT.
        </li>
        <img src="@/assets/images/dispute-1.png" width="500px" alt="Dispute Process Image 1">
        <li>If the lesson ends on Monday, you have until Friday to submit a Refund Request.</li>
        <li>If the lesson ends on Tuesday, you have until Saturday to submit a Refund Request.</li>
        <li>If the lesson ends on Wednesday, you have until Sunday to submit a Refund Request.</li>
        <li>If the lesson ends on Thursday, you have until Monday to submit a Refund Request.</li>
        <li>If the lesson ends on Friday, you have until Tuesday to submit a Refund Request.</li>
        <li>If the lesson ends on Saturday, you have until Wednesday to submit a Refund Request.</li>
        <li>If the lesson ends on Sunday, you have until Thursday to submit a Refund Request.</li>
      </ul>

      <h4>How To Request a Refund</h4>
      <p>To submit a Refund Request, follow these steps:</p>
      <ol>
        <li>Log in to your student account.</li>
        <li>Go to your Dashboard.</li>
        <li>Go to Schedule and click on the lesson for which you want to request a refund.</li>
        <img src="@/assets/images/dispute-2.png" width="500px" alt="Dispute Process Image 2">
        <li>A pop-up will appear with that lesson’s details. You will see options for Refund and Dispute creation.</li>
        <img src="@/assets/images/dispute-3.png" width="500px" alt="Dispute Process Image 3">
        <li>Click Refund and complete the details on the subsequent form. Details should include the reason for the Refund Request.</li>
        <img src="@/assets/images/dispute-4.png" width="500px" alt="Dispute Process Image 4">
        <li>Submit the Refund Request.</li>
      </ol>
      <p>
        Once the Refund Request is submitted, the tutor is notified and has access to the request. They have the option to accept or reject the Refund Request. If the Refund Request is accepted, the case will be closed and the lesson fees will be credited to the Student’s Wallet. In case of rejection, the student has the option to file a dispute.
      </p>

      <h4>How to Submit a Dispute</h4>
      <p>A student has the option of submitting a Dispute against a lesson. Note that in dispute resolution, the decision of the Dispute Resolution Specialist is final.</p>
      <p>To file a Dispute, please follow these steps:</p>
      <ol>
        <li>Log in to your student account.</li>
        <li>Go to your Dashboard and click Schedule.</li>
        <li>Click the lesson for which you want to file a Dispute.</li>
        <img src="@/assets/images/dispute-5.png" width="500px" alt="Dispute Process Image 5">
        <li>
          Click the Dispute button and complete the details in the subsequent form. Ensure that you enter all details as this statement will be used by the Dispute Resolution Specialist to evaluate the Dispute.
        </li>
        <img src="@/assets/images/dispute-6.png" width="500px" alt="Dispute Process Image 6">
        <li>Submit the Dispute.</li>
      </ol>

      <h4>Dispute Resolution</h4>
      <p>Once the Dispute is submitted it’s sent to a Dispute Resolution Specialist who takes the following steps:</p>
      <ul>
        <li>The Dispute Resolution Specialist reviews and evaluates the Dispute.</li>
        <li>
          If the Dispute is opened, the Dispute Specialist initiates a Dispute Discussion Thread where the Student, Tutor, and Dispute Specialist share information.
        </li>
        <li>The specialist will evaluate the details of the Dispute and make a decision.</li>
        <li>If the Dispute is honored, the lesson fees will be credited to the Student’s Wallet.</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "RefundsAndDisputes",
};
</script>

<style scoped>
.hero-course-wrap {
  background-color: #f7f7f7; /* Example background color */
}

.container {
  margin-top: 20px;
}

.hero-course-con h2 {
  font-weight: bold;
  color: #333; /* Example text color */
  margin-bottom: 20px;
}

ul,
ol {
  padding-left: 20px;
}

ul li,
ol li {
  margin-bottom: 10px;
}

img {
  margin: 10px 0;
}
</style>
