<template>
  <div>
    <div class="hero-course-wrap bg-offwhite newtheme-cart-component">
      <div class="container">
        <div class="hero-course-con py-4">
          <h2 class="font-weight-bold color_black mb-2 mt-4">Tutor Registration</h2>
        </div>
      </div>
    </div>

    <div class="container mt-5">
      <p>
        ILMZone is an online educational marketplace that connects those who thirst for your expertise. Registering on ILMZone is easy and takes only a few minutes. Simply follow these steps:
      </p>
      <p><b>Step 1:</b> Go to ILMZone.com homepage. At the top-right corner click on Sign Up. Select Tutor in the popup.</p>
      <img src="@/assets/images/tutor-signup-1.png" width="500px" alt="Step 1 - Tutor Sign Up">
      <p><b>Step 2:</b> If you click Continue with Email, you will enter your Name, a Username, Email, and create and confirm Password.</p>
      <img src="@/assets/images/tutor-signup-2.png" width="500px" alt="Step 2 - Tutor Sign Up">
      <p>Clicking the sign up button will complete your registration process.</p>
      <p>You can also sign up with Google.</p>
      <p>After successful registration, you will be asked to complete your profile by providing:</p>
      <ul>
        <li>Your name</li>
        <li>CNIC</li>
        <li>Gender</li>
        <li>Date of birth</li>
        <li>Your current address</li>
        <li>Zoom and Skype IDs</li>
        <img src="@/assets/images/tutor-signup-3.png" width="500px" alt="Profile Completion - Tutor Sign Up">
        <li>Enter Your Education</li>
        <li>Enter Your Certifications</li>
        <img src="@/assets/images/tutor-signup-4.png" width="500px" alt="Education and Certifications - Tutor Sign Up">
        <li>Enter Experience</li>
        <img src="@/assets/images/tutor-signup-5.png" width="500px" alt="Experience - Tutor Sign Up">
        <ul>
          <li>Any relevant experience that underscores your expertise in the chosen field.</li>
        </ul>
        <li>Entering Billing Information</li>
        <img src="@/assets/images/tutor-signup-6.png" width="500px" alt="Billing Information - Tutor Sign Up">
        <ul>
          <li>Your bank account information</li>
        </ul>
      </ul>

      <h5>Document Review process</h5>
      <p>
        ILMZone also offers credential verification. We have all your degrees verified by their accreditation bodies. As a result, verified tutor accounts are more likely to land their dream jobs.
      </p>
      <p>You can have your profile verified in three simple steps:</p>
      <p><b>Step 1:</b> Upload Your Documents.</p>
      <p>
        Upload scanned copies of valid degrees. Documents include your School, college, university degrees, courses from verifiable and recognized educational institutions.
      </p>
      <img src="@/assets/images/tutor-signup-7.png" width="500px" alt="Upload Documents - Tutor Sign Up">
      <p><b>Step 2:</b> Pay the verification fee</p>
      <p>ILMZone verification fee is 1200 PKR</p>
      <img src="@/assets/images/tutor-signup-8.png" width="500px" alt="Verification Fee - Tutor Sign Up">
      <p><b>Step 3:</b> Pass Verification and Get the Badge</p>
      <p>
        After the verification process, we will notify you of your verification via email. If your credentials are successfully verified, the Verification Badge ☑️ will be added to your profile.
      </p>
      <p><b>Meta description:</b> Online sign-up process to set up tutor’s account and update profile</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TutorSignUp",
};
</script>

<style scoped>
.hero-course-wrap {
  background-color: #f7f7f7;
}

.hero-course-con h2 {
  font-size: 24px;
}

.container img {
  display: block;
  margin: 20px 0;
}

.container ul {
  list-style-type: disc;
  padding-left: 20px;
}
</style>
