<template>
    <div v-if="!depends">
        <div
            v-if="
                showDependentDropdown &&
                roles.findIndex((role) => role.name === 'guardian') > -1 &&
                page === 'detail'
            "
            class="pb-2"
        >
            <label class="choose-dependent-label">Choose Dependent</label>
            <select
                class="form-control"
                name="dependent"
                id="dependent"
                v-model="dependent"
            >
                <option :value="0">Choose Dependent</option>
                <!-- eslint-disable -->
                <option :value="dependent.id" v-for="dependent in dependents">
                    {{ dependent.name }}
                </option>
            </select>
        </div>
        <div v-if="page === 'listing'" class="course-listing-cart-btn">
            <button
                v-on:click="joinSession(course.id, 'Course')"
                :disabled="checkCourseStatus(course)"
                v-if="!coursePurchased(course.id)"
                class="btn btn-sm addCart btn-outline-primary"
            >
                Join
            </button>

            <button
                v-on:click="buyNow(course)"
                :disabled="checkCourseAdded(course)"
                v-if="coursePurchased(course.id)"
                :id="'buynow-' + course.id"
                class="btn btn-sm addCart buyNowListingCart btn-outline-primary"
            >
                Buy Now
            </button>
            <!-- <button
                v-on:click="addToCart(course, 'Course')"
                :disabled="checkCourseAdded(course)"
                v-if="coursePurchased(course.id)"
                :id="'addtocart-' + course.id"
                class="btn btn-sm addCart CourseListingCart btn-outline-primary"
            >
                <span
                    v-if="
                        added.findIndex(
                            (c) =>
                                c.course.id === course.id &&
                                c.model === 'Course'
                        ) > -1
                    "
                    >Added</span
                >
                <span v-else>Add To Cart</span>
            </button> -->
        </div>
        <div v-else-if="page === 'detail'">

            <div class="buy-now-btn-container">
                <button
                    v-on:click="buyNow(course)"
                    v-if="coursePurchased(course.id)"
                    class="btn btn-outline-info btn-lg btn-block font_set buy-now-btn"
                >
                    Buy Now
                </button>
            </div>
            <button
                v-on:click="joinSession(course.id, 'Course')"
                :disabled="checkCourseStatus(course)"
                v-if="!coursePurchased(course.id)"
                class="btn font_set btn-outline-info btn-lg btn-block"
            >
                Join
            </button>
        </div>
    </div>
</template>

<script>
import { GlobalEventBus } from "@/assets/js/eventBus";
import moment from "moment-timezone";
// import "vue-slider-component/theme/antd.css";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";


export default {
    name: "AddToCartButtonComponent",
    props: [
        "course",
        "sessions",
        "ownCourses",
        "purchasedCourses",
        "role",
        "page",
        "dependents",
        "depends",
        "added",
        "showDependentDropdown",
    ],
    data() {
        return {
            query: {},
            dependent: 0,
            user: {},
            courses: [],
            roles: [],
            authenticated: false,
        };
    },
    created() {
        // this.getCart()
    },
    mounted() {
        let self = this;

        GlobalEventBus.on("getUserDetail", function (details) {
            self.authenticated = details.authenticated;
            self.user = details.user;
            self.roles = details.roles;
        });
        self.authenticated = localStorage.getItem("authenticated") === "true";
    },
    methods: {
        async getCart() {
            const { data } = await axiosInstance.get(endpoints.getCart);
          // eslint-disable-next-line vue/no-mutating-props
            this.added = data.courses;
        },
        async buyNow(course) {
            if (this.authenticated) {
                if (this.role === "student") {
                    window.location = "/course/checkout/" + course.id;
                }
            } else {
                GlobalEventBus.$emit("showLoginForm");
            }
        },
        async addToCart(course, model) {
            let self = this;
            let dependents = [];
            if (self.role === "guardian" && self.page === "listing") {
                window.location = "/course/" + course.uuid;
                return;
            }
            if (self.role === "guardian" && self.added.length > 0) {
                dependents = JSON.parse(
                    self.added.find((c) => c.course.id === course.id).dependents
                );
                dependents.find(
                    (d) => parseInt(d) === parseInt(self.dependent)
                );
            }
            if (this.dependents.length > 0 && this.role === "guardian") {
                this.query.dependent_id = self.dependent;
            }
            this.query.id = course.id;
            this.query.model = model;
            // get time zone here by tallal
            this.query.timezone = '';
            const { data } = await axiosInstance.get(endpoints.addToCartRoute, this.query);

            if (data.type === "success") {
                this.courses = data.courses;
                GlobalEventBus.emit("refreshcart");
                if (this.role !== "guardian") {
                    document.querySelector(
                        "#addtocart-" + course.id
                    ).disabled = true;
                }
                toast.success(data.message).goAway(1500);
            } else {
                toast.error(data.message).goAway(1500);
            }
        },
        coursePurchased(id) {
            if (this.depends) {
                return false;
            }
            if (this.role === "tutor") {
                let index = this.ownCourses.findIndex((p) => p === id);
                return index <= -1;
            } else {
                let index = this.purchasedCourses.findIndex((p) => p === id);
                return index <= -1;
            }
        },
        checkCourseAdded(course) {
            let self = this;
            if (this.added.length > 0 && this.role !== "guardian") {
                let index = this.added.findIndex(
                    (c) => c.course.id === course.id
                );
                return index > -1;
            } else if (this.added.length > 0 && this.role === "guardian") {
                let index = this.added.findIndex(
                    (c) => c.course.id === course.id
                );
                if (index > -1) {
                    let c = this.added[index];
                    if (c.dependent_count > 0) {
                        let dep = JSON.parse(c.dependents);
                        console.log(
                            self.dependent,
                            dep,
                            dep.findIndex(
                                (d) => parseInt(d) === parseInt(self.dependent)
                            ) > -1
                        );
                        return (
                            dep.findIndex(
                                (d) => parseInt(d) === parseInt(self.dependent)
                            ) > -1
                        );
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        },
        dateFormat(date) {
            return moment(date).tz().format("DD MMMM");
        },
        timeFormat(value) {
            if (value) {
                return moment(value).tz().format("hh:mm A");
            }
        },
        checkCourseStatus(course) {
            let today = moment().tz().format("YYYY-MM-DD");
            let time = moment().tz().format("hh:mm A");

            let start_time = this.timeFormat(
                course.start_date + "T" + course.start_time + "Z"
            );
            let end_time = this.timeFormat(
                course.end_date + "T" + course.end_time + "Z"
            );
            if (
                !!this.sessions[course.id] &&
                !!this.sessions[course.id][today]
            ) {
                return !(time >= start_time && time <= end_time);
            } else {
                return true;
            }
        },
        joinSession(id, model) {
            this.joinZoomPersonalSession(id, model);
            // document.getElementById('meeting-' + id).submit();
        },
        async joinZoomPersonalSession(session_id, model) {
            let formData = new FormData();
            formData.append("session_id", session_id);
            formData.append("model", model);
            const { data } = await axiosInstance.post(endpoints.zoomSessionInitiateRoute, formData);
            if (data.type === "success") {
                if (data.medium === "Zoom") window.open(data.contact, "_blank");
            } else {
                toast.success(data.message);
            }
        },
    },
};
</script>

<style scoped>
@media (min-width: 577px) {
    .addCart {
        width: 101px !important;
    }
}

.addCart {
    margin-right: 7px;
    height: 40px;
}
</style>
