<template>
    <div style="height: 100%">
        <div>
            <div class="fw-bold mt-3 ml-1">
                <h4>Wishlist</h4>
            </div>
            <div
                class="nav nav-pills d-flex align-items-center justify-content-center flex-row mb-3"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
            >
                <a
                    v-on:click="showTab = 'courses'"
                    class="nav-link border-bottom py-3 pl-4"
                    v-bind:class="{
                        active: showTab === 'courses',
                    }"
                    id="paypal"
                    data-toggle="pill"
                    href="#paypalss"
                    role="tab"
                    aria-controls="paypalss"
                    aria-selected="true"
                    style="
                        border-radius: 10px 10px 0px 0px !important;
                        border: 0 !important;
                    "
                >
                    <span>Courses</span>
                </a>
                <a
                    v-on:click="showTab = 'tutors'"
                    class="nav-link border-bottom py-3 pl-4"
                    v-bind:class="{
                        active: showTab === 'tutors',
                    }"
                    id="paypal"
                    data-toggle="pill"
                    href="#paypalss"
                    role="tab"
                    aria-controls="paypalss"
                    aria-selected="true"
                    style="
                        border-radius: 10px 10px 0px 0px !important;
                        border: 0 !important;
                    "
                >
                    <span>Tutors</span>
                </a>
            </div>
            <div class="" v-if="showTab === 'courses'">
                <div class="row cards_parent">
                    <div
                        class="col-6 col-md-6 col-lg-4 col-xl-3"
                        v-for="course in courses"
                        :key="course.course.id"
                    >
                        <CourseCardComponent
                            :data="course.course"
                        ></CourseCardComponent>
                        <form
                            action="/initiate-bbb"
                            method="post"
                            :id="'meeting' + course.course.id"
                            class="d-none"
                        >
                            <input
                                type="text"
                                name="course_id"
                                id="course_id"
                                v-model="course.course.id"
                            />
                        </form>
                    </div>
                    <div v-if="no_record">
                        <p class="p-2">No Record Found...</p>
                    </div>
                </div>
                <!-- Cards Parent Row -->
                <div class="text-center">
                    <pagination
                        v-show="query.total > 0"
                        v-model="query.page"
                        :records="query.total"
                        :per-page="query.limit"
                        @paginate="getWishlist"
                    />
                </div>
            </div>
            <div class="" v-if="showTab === 'tutors'">
                <div class="row cards_parent">
                    <!-- eslint-disable -->
                    <div
                        class="col-6 col-md-6 col-lg-4 col-xl-3"
                        v-for="item in tutors"
                        :key="item?.tutor?.id"
                        v-if="item?.tutor"
                    >
                        <!-- Start -->
                        <div class="card shadow wishlist-course">
                            <div class="">
                                <img
                                    class="w-100"
                                    :src="'/storage' + item?.tutor?.image"
                                    :alt="item?.tutor.name"
                                />
                            </div>
                            <div class="card-body">
                                <h5 class="card-title m-0 text-truncate">
                                    {{ item?.tutor?.name }}
                                </h5>
                                <small>{{
                                    item?.tutor?.info?.about_short
                                }}</small>
                                <div
                                    class="mt-sm-3 mt-0 slick-bottom-info hide-slick-bottom-info"
                                >
                                    <a
                                        :href="
                                            '/profile/tutor/' +
                                            item?.tutor?.uid
                                        "
                                        class="view-btn"
                                        >View</a
                                    >
                                    <span class="wishlist-price"
                                        >{{ item?.tutor?.info?.price }}
                                        {{ $t("text.pkr") }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- End -->
                    </div>
                </div>
                <!-- Cards Parent Row -->
                <div class="text-center">
                    <pagination
                        v-show="query.total > 0"
                        v-model="query.page"
                        :records="query.total"
                        :per-page="query.limit"
                        @paginate="getWishlist"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment-timezone";
import CourseCardComponent from "@/components/frontend/home/card/CourseCardComponent";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";
import {hideLoader, showLoader} from "@/assets/js/swal";


export default {
    name: "WishlistComponent",
    props: [],
    components: {
        CourseCardComponent,
    },
    directives: {},
    data() {
        return {
            showTab: "courses",
            course_id: 0,
            no_record: false,
            categories: [],
            educationBoards: [],
            educationLevels: [],
            courses: [],
            tutors: [],
            sessions: [],
            base_url: "",
            query: {
                category_id: "",
                status: "",
                price: "",
                role: "",
                board_id: "",
                education_level: "",
                level: "",
                language: "",
                keyword: "",
                rating: 0,
                sort: "DESC",
                page: 1,
                limit: 8,
                total: 0,
            },
        };
    },
    watch: {
        showTab(newValue) {
            if (newValue === "courses") {
                this.getWishlist();
            } else {
                this.getTutorWishlist();
            }
        },
    },
    computed: {},
    async created() {
        await this.getFilterContent();
        await this.getWishlist();
    },
    methods: {
        async getWishlist(page) {
            try {

              showLoader();

              this.query.page = page ? page : this.query.page;
              const { data } = await axiosInstance.get(endpoints.getWishlistCourses, {params: this.query});
              this.courses = data.data.courses.data;
              this.sessions = data.data.sessions;
              this.no_record = this.courses.length === 0;

              hideLoader();

            } catch (e) {

              hideLoader();

              toast.error('Server Error')
            }
        },
        async getTutorWishlist(page) {
            try {
              showLoader()
              this.query.page = page ? page : this.query.page;
              const { data } = await axiosInstance.get(endpoints.getWishlistTutors, {params: this.query});
              this.tutors = data.data.tutors.data;
              this.no_record = this.tutors.length === 0;
              hideLoader()
            } catch (e) {
              hideLoader()
              toast.error('Server Error');
            }
        },
        queryCheck() {
            return !!(
                this.query.category_id ||
                this.query.status ||
                this.query.price ||
                this.query.role ||
                this.query.board_id ||
                this.query.education_level ||
                this.query.level ||
                this.query.language ||
                this.query.keyword ||
                this.query.rating
            );
        },
        clearFilter() {
            this.query.category_id = "";
            this.query.status = "";
            this.query.price = "";
            this.query.role = "";
            this.query.board_id = "";
            this.query.education_level = "";
            this.query.level = "";
            this.query.language = "";
            this.query.keyword = "";
            this.query.rating = "";
        },
        async getFilterContent() {
            try {
              showLoader()
              const { data } = await axiosInstance.get(endpoints.filterContent);
              this.categories = data.data.categories;
              this.educationBoards = data.data.boards;
              this.educationLevels = data.data.educationLevels;
              hideLoader()
            } catch (e) {
              hideLoader()
              toast.error('Server Error')
            }
        },
        async setRating(rating, id) {
            let formData = new FormData();
            formData.append("course_id", id);
            formData.append("rating", rating);
            await axiosInstance.post(endpoints.courseRatingRoute, formData);
        },
        courseProgress(course) {
            let start = moment(course.start_date + " " + course.start_time);
            let today = moment();

            let duration = moment.duration(today.diff(start));
            let hours = duration.hours();
            let days = duration.days();
            let weeks = duration.weeks();
            let months = duration.months();
            if (months < 0) {
                return "Course Will Start in " + Math.abs(months) + " Months";
            } else if (weeks < 0) {
                return "Course Will Start in " + Math.abs(weeks) + " Weeks";
            } else if (days < 0) {
                return "Course Will Start in " + Math.abs(days) + " Days";
            } else if (hours < 0) {
                return "Course Will Start in " + Math.abs(hours) + " Hours";
            } else {
                return this.calculateCourseDays(
                    course,
                    hours,
                    days,
                    weeks,
                    months
                );
            }
        },

        calculateCourseDays(course, hours, days, weeks, months) {
            if (months > 0) {
                return "Course Started " + Math.abs(months) + " Month ago.";
            } else if (weeks > 0) {
                return "Course Started " + Math.abs(weeks) + " Week ago.";
            } else if (days > 0) {
                return "Course Started " + Math.abs(days) + " Day ago.";
            } else if (hours > 0) {
                return "Course Started " + Math.abs(hours) + " Hour ago.";
            }
        },
        checkCourseStatus(course) {
            let today = moment().tz().format("YYYY-MM-DD");
            let time = moment().tz().format("HH:mm:ss");
            console.log(
                course.id,
                course.start_time,
                course.start_date,
                this.sessions[course.id],
                this.sessions[course.id][today],
                !(time >= course.start_time && time <= course.end_time)
            );
            if (this.sessions[course.id][today]) {
                return !(time >= course.start_time && time <= course.end_time);
            } else {
                return true;
            }
        },
        joinSession(id) {
            document.getElementById("meeting" + id).submit();
        },
    },
};
</script>
<style scoped></style>
