<template>
    <div class="row margin_top_progress">
        <div class="col-12 p-0">
            <div class="top_purchase_under_progress">
                <div class="rows head">
                    <h4>My Courses</h4>
                    <p class="mb-0">
                        <router-link to="/student/courses">
                            <a href="javascript:void (0)">View All Courses</a>
                        </router-link>
                    </p>
                </div>
                <div class="overflow_scroll_top_purchase">

                  <div
                      class="rows"
                      v-for="(course, index) in courses"
                      :key="index"
                  >
                    <div v-if="index < 3">
                        <div class="top-purchases-left-col">
                          <div class="marketing_circle_icon">
                            <img
                                width="50px"
                                :src="'/storage' + course.course.thumbnail"
                                :alt="course.course.title"
                            />
                          </div>
                          <div class="social-media">
                            <a :href="'/course/' + course.course.uuid"
                            ><h6 class="m-0">
                              {{ course.course.title }}
                            </h6></a
                            >
                          </div>
                        </div>
                        <h4>{{ sessions[course.course_id].progress }}%</h4>
                      </div>

                  </div>

                  <div v-if="no_record">
                      <p class="p-2">No Record Found...!!</p>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
    name: "OnGoingCoursesComponent",
    data() {
        return {
            no_record: false,
            courses: [],
            sessions: [],
            query: {
                category_id: "",
                status: "approved",
                priceRange: [0, 50000],
                price: "",
                role: "",
                board_id: "",
                education_level: "",
                level: "",
                language: "",
                keyword: "",
                rating: 0,
                sort: "DESC",
                page: 1,
                limit: 8,
                total: 0,
            },
        };
    },
    created() {
        this.getPurchasedCourses();
    },
    methods: {
        async getPurchasedCourses() {
            let self = this;

            try {
              const { data } = await axiosInstance.get(endpoints.getCourses, {params: self.query});
              this.courses = data.data.courses.data;
              this.sessions = data.data.sessions;
              this.no_record = this.courses.length === 0;
            } catch (e) {
              console.log(e)
            }

        },
    },
};
</script>

<style scoped>
.rows {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
}
.rows.head {
    background: #812bb2;
    border-radius: 5px 5px 0 0;
    padding: 8px 18px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
}
.overflow_scroll_top_purchase .rows h4 {
    font-size: 14px !important;
    font-weight: 500;
    line-height: 16.41px;
    color: #000000 !important;
    font-family: "Roboto", sans-serif;
    margin-bottom: 25px;
}
.top_purchase_under_progress {
    font-size: 14px !important;
    font-weight: 700;
    line-height: 18.75px;
    color: #fccd05 !important;
    font-family: "Roboto", sans-serif;
    margin-bottom: 0px;
}

.top_purchase_under_progress {
    background: #ffffff;
    box-shadow: 0px 3px 16px #00000029;
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    border-radius: 8px;
}
.overflow_scroll_top_purchase {
    height: 100%;
    padding: 10px 0;
}
a {
    color: #fccd05 !important;
}
</style>
