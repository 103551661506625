<template>
    <div>
        <div
            class="modal"
            style="display: block; background-color: rgba(0, 0, 0, 0.4)"
            id="postajob-01"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div
                class="modal-dialog modal-lg modal-dialog job_alert_modal modal-dialog-centered"
                role="document"
            >
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Post a Tuition
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            v-on:click="$parent.closeTuitionForm()"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="steps text-center">
                            <ul
                                id="progressbar"
                                class="progressbar-box-02 my-2"
                            >
                                <li class="active" id="general">
                                    <span>1</span
                                    ><strong>Subjects & Levels </strong>
                                </li>
                                <li id="duration">
                                    <span>2</span><strong>Preferences</strong>
                                </li>
                                <li id="classsessionroomsetting">
                                    <span>3</span><strong>Other Details</strong>
                                </li>
                            </ul>
                        </div>
                        <form class="form-style-01">
                            <div class="form-group">
                                <label class="text-uppercase">Subjects</label>
                                <multiselect
                                    v-model="selectedSubjects"
                                    :options="subjects"
                                    :multiple="true"
                                    :close-on-select="true"
                                    placeholder="Choose Subjects"
                                    label="subject"
                                    track-by="id"
                                ></multiselect>
                                <ul
                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                    v-if="
                                        errorMessages.subject.length > 0 &&
                                        selectedSubjects.length < 1
                                    "
                                >
                                  <!-- eslint-disable -->
                                    <li v-for="err in errorMessages.subject">
                                        <small>{{ err }}</small>
                                    </li>
                                </ul>
                            </div>
                            <div class="form-group">
                                <label for="level" class="text-uppercase"
                                    >Level</label
                                >
                                <select
                                    id="level"
                                    class="form-control"
                                    v-model="post.level"
                                    v-on:focus="errorMessages.level = []"
                                >
                                    <option value="0">
                                        Choose Course Level
                                    </option>
                                    <option value="beginner">Beginner</option>
                                    <option value="advanced">Advanced</option>
                                    <option value="intermediate">
                                        Intermediate
                                    </option>
                                </select>
                                <ul
                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                    v-if="errorMessages.level.length > 0"
                                >
                                    <li v-for="err in errorMessages.level">
                                        <small>{{ err }}</small>
                                    </li>
                                </ul>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer border-0 pt-0 mb-3">
                        <button
                            v-if="$parent.authenticated && $parent.selectedJob"
                            type="button"
                            class="btn btn-primary px-5 py-2"
                            v-on:click="validateForm()"
                        >
                            Update
                        </button>
                        <button
                            v-else
                            type="button"
                            class="btn btn-primary next-btn d-flex align-items-center justify-content-center px-5 py-2"
                            v-on:click="validateForm()"
                            :disabled="!!submitLoading"
                        >
                            <div
                                v-if="!!submitLoading"
                                class="spinner-border text-primary"
                                style="
                                    color: white !important;
                                    width: 20px !important;
                                    height: 20px !important;
                                "
                                role="status"
                            ></div>
                            <span v-if="!!submitLoading === false">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
    name: "JobPostStep01Component",
    data() {
        return {
            loading: false,
            submitLoading: false,
            subjects: [],
            selectedSubjects: [],
            post: {
                subject_id: [],
                level: 0,
            },
            errorMessages: {
                subject: [],
                level: [],
            },
        };
    },
    mounted() {
        let self = this;
        if (self.$parent.authenticated && self.$parent.selectedJob) {
            self.post = self.$parent.selected_job;
            self.post.subject_id = [];
            self.$parent.selected_subjects.forEach(function (subject) {
                self.selectedSubjects.push({
                    id: subject.id,
                    subject: subject.subject,
                });
            });
        }
        self.$parent.subjects.forEach(function (subject) {
            self.subjects.push({
                id: subject.id,
                subject: subject.subject,
            });
        });
    },
    created() {
        if (this.$parent.post_id > 0) {
            this.getJobPost();
        }
    },
    methods: {
        validateForm() {
            this.post.subject_id = this.selectedSubjects;
            this.checkSubjects();
            this.checkLevel();
            if (this.checkSubjects() && this.checkLevel()) {
                this.addJobPost();
            }
        },
        checkSubjects() {
            this.errorMessages.subject = [];
            console.log(
                this.selectedSubjects.length,
                this.selectedSubjects.length === 0
            );
            if (this.selectedSubjects.length === 0) {
                this.errorMessages.subject.push("Enter Subject...");
                return false;
            } else {
                this.errorMessages.subject = [];
                return true;
            }
        },
        checkLevel() {
            this.errorMessages.level = [];
            if (!this.post.level) {
                this.errorMessages.level.push("Enter Level...");
                return false;
            } else {
                this.errorMessages.level = [];
                return true;
            }
        },
        async getJobPost() {
            let query = {};
            query.id = this.$parent.post_id;
            const { data } = await axiosInstance.get(endpoints.getJobPostRoute, {params: query});
            console.log(data.subjects);
            this.selectedSubjects = data.subjects;
            this.post.level = data.data.level;
        },
        async addJobPost() {
            let formData = new FormData();
            formData.append("subject_id", JSON.stringify(this.post.subject_id));
            formData.append("level", this.post.level);
            if (this.$parent.authenticated && this.$parent.selectedJob) {
                formData.append("id", this.$parent.selected_job.id);
            } else if (this.$parent.post_id > 0) {
                formData.append("id", this.$parent.post_id);
            }
            this.submitLoading = true;
            const { data } = await axiosInstance.post(endpoints.addJobStep1Route, formData);
            this.submitLoading = false;
            if (data.type === "success") {
                this.$parent.post_id = data.data.id;
                this.$parent.step++;
            }
        },
        removeSubjects(value) {
            this.post.subject_id = this.post.subject_id.filter(
                (sub) => sub.id === value.id
            );
        },
    },
};
</script>
<!--<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>-->
<style scoped>
.multiselect__input,
.multiselect__single,
.multiselect__input input {
    border: none !important;
}
input.multiselect__input,
#postajob-01 input {
    border: none !important;
}
</style>
