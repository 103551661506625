<template>
    <div class="position-relative">
        <div
            v-if="loading"
            class="d-flex align-items-center justify-content-center w-100 h-100 position-absolute top-0 left-0 right-0 bottom-0"
            style="background-color: rgba(255, 255, 255, 0.4)"
        >
            <div
                class="spinner-border text-primary"
                style="
                    width: 35px;
                    height: 35px;
                    color: rgb(129, 43, 178) !important;
                "
                role="status"
            ></div>
        </div>
        <div class="d-flex align-items-center justify-content-between flex-row">
            <div class="d-flex align-items-center">
                <button
                    class="arrow_btn"
                    @click="
                        () => {
                            if (!this.disableBack) {
                                this.end_date = moment(
                                    this.start_date
                                ).subtract(1, 'day');
                                this.start_date = moment(
                                    this.end_date
                                ).subtract(6, 'day');
                                getSlots();
                            }
                        }
                    "
                >
                    <svg
                        height="12"
                        viewBox="0 0 8 12"
                        width="8"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            :fill="
                                this.disableBack ? 'rgba(0,0,0,0.4)' : 'black'
                            "
                            d="M3.268 5.854l4.293 4.292-1.415 1.415L.44 5.854 6.146.146l1.415 1.415z"
                        ></path>
                    </svg>
                </button>
                <button
                    class="arrow_btn"
                    @click="
                        () => {
                            this.start_date = moment(this.end_date).add(
                                1,
                                'day'
                            );
                            this.end_date = moment(this.start_date).add(
                                6,
                                'day'
                            );
                            getSlots();
                        }
                    "
                >
                    <svg
                        height="12"
                        viewBox="0 0 8 12"
                        width="8"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4.732 5.854L.44 1.56 1.854.146 7.56 5.854 1.854 11.56.439 10.146z"
                        ></path>
                    </svg>
                </button>
                <span class="ml-4"
                    >{{ moment(this.start_date)?.format("MMM DD") }} –
                    {{
                        moment(this.start_date)?.format("MMM") !==
                        moment(this.end_date)?.format("MMM")
                            ? moment(this.end_date)?.format("MMM")
                            : ""
                    }}
                    {{ moment(this.end_date)?.format("DD, YYYY") }}</span
                >
            </div>
            <div class="">
                <select
                    class="form-control"
                    id="timezone"
                    v-model="timezone"
                    style="
                        box-shadow: none;
                        border: 1px solid rgba(0, 0, 0, 0.2);
                        outline: none;
                        width: 150px;
                    "
                    disabled
                >
                    <!-- <option :value="timezone" v-for="timezone in timzones"> -->
                    <option :value="timezone">
                        {{ timezone }}
                    </option>
                </select>
            </div>
        </div>

        <!-- Calendar View -->
        <div
            class="calendar_items d-flex flex-row align-items-start justify-content-center row"
        >
            <div
                class="calendar_field d-flex align-items-center justify-content-center flex-column"
                v-for="weekDay in this.latestWeek" :key="weekDay"
            >
                <p class="m-0 mt-3 text-secondary text-14">
                    {{ moment(weekDay?.date).format("ddd") }}
                </p>
                <p class="mb-0 font-weight-bold text-14">
                    {{ moment(weekDay?.date).format("DD") }}
                </p>
                <br />
                <div class="w-100">
                    <div
                        class="mt-1"
                        v-bind:class="{
                            active_timeslot:
                                selectedTimeSlot?.filter(
                                    (_) =>
                                        _.start_date_time ===
                                        timeslot.start_date_time
                                )?.length > 0,
                            timeslot: true,
                        }"
                        @click="
                            selectedTimeSlot?.filter(
                                (_) =>
                                    _.start_date_time ==
                                    timeslot.start_date_time
                            )?.length == 0
                                ? selectedTimeSlot.push(timeslot)
                                : (selectedTimeSlot = selectedTimeSlot?.filter(
                                      (_) =>
                                          _.start_date_time !==
                                          timeslot.start_date_time
                                  ))
                        "
                        v-for="timeslot in weekDay?.slots ?? []" :key="timeslot"
                        data-toggle="tooltip"
                        data-placement="top"
                        rel="tooltip"
                        title="Book Lesson"
                    >
                        {{ moment(timeslot.start_date_time).format("hh:mm a") }}
                    </div>
                </div>
            </div>

            <!-- Book Slot Bottom bar -->
            <div class="w-100 mt-5">
                <!-- <button
                    class="book_trial_lesson w-100 d-flex align-items-center justify-content-center"
                    v-bind:class="{
                        disabled: selectedTimeSlot?.length == 0,
                    }"
                    @click="confirmTimeslot"
                    :disabled="selectedTimeSlot?.length == 0"
                >
                    Confirm Time
                    {{
                        this.selectedTimeSlot &&
                        this.selectedTimeSlot
                            ?.map((_) => {
                                return `(${moment(_?.start_date_time).format(
                                    "hh:mm a"
                                )})`;
                            })
                            ?.join(",")
                    }}
                </button> -->
                <button
                    class="book_trial_lesson w-100 d-flex align-items-center justify-content-center"
                    v-bind:class="{
                        disabled: selectedTimeSlot?.length == 0,
                    }"
                    @click="confirmTimeslot"
                    :disabled="selectedTimeSlot?.length == 0"
                >
                    Confirm Time
                </button>
            </div>
            <!-- Book Slot Bottom bar end -->
        </div>
    </div>
</template>
<script>
import moment from "moment-timezone";
import { GlobalEventBus } from "@/assets/js/eventBus.js";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
    name: "StartView",
    data() {
        return {
            slots: [],
            loading: false,
            month: moment().format("M"),
            latestWeek: [],
            start_date: moment().toDate(),
            end_date: moment().add(6, "day").toDate(),
            timezones: [],
            timezone: "Asia/Karachi",
            selectedTimeSlot: [],
            disableBack: true,
            authenticated: false,
            query: {
                id: "",
                keyword: "",
                sort: "DESC",
                page: 1,
                limit: 10,
                total: 0,
                date_from: "",
                date_to: "",
                time: "",
            },
        };
    },
    watch: {
        timezone() {
            this.getSlots();
            this.selectedTimeSlot = [];
        },
        start_date() {
            this.disableBack =
                moment(this.start_date).hour(0).minute(0).second(0).unix() ==
                moment().hour(0).minute(0).second(0).unix();
        },
    },
    created() {
        this.timzones = moment.tz.names();
        this.id = this.$parent.user;
        // this.authenticated = localStorage.getItem("authenticated") === "true";
        // this.getSlots();
    },
    methods: {
        moment(...props) {
            return moment(...props);
        },
        confirmTimeslot() {
            if (this.selectedTimeSlot) {
                // this.authenticated =
                //     localStorage.getItem("authenticated") === "true";
                if (!this.authenticated) {
                    GlobalEventBus.$emit("showLoginForm");
                } else {
                    window.location =
                        "/slot/checkout/" +
                        this.selectedTimeSlot?.map((_) => _.id)?.join(",");
                }
            }
        },
        // timezone mismatched
        async getSlots() {
            let query = {};
            query.id = this.$parent.user;
            query.month = this.month;
            query.timezone = this.timezone;
            query.start_date = moment(this.start_date)
                // .tz(this.timezone)
                .format("DD-MM-YYYY");
            query.end_date = moment(this.end_date)
                // .tz(this.timezone)
                .format("DD-MM-YYYY");
            this.loading = true;
            const { data } = await axiosInstance.get(endpoints.getSlotRoute, {params: query});
            console.log("getSlotRoute-----------------------------------------------------------------------------");
            console.log(data);
            this.loading = false;
            this.latestWeek = [0, 1, 2, 3, 4, 5, 6]?.map((i) => {
                const start_of_date = moment(this.start_date)
                    // .tz(this.timezone)
                    .add(i, "day")
                    .startOf("day");
                const end_of_date = moment(this.start_date)
                    .tz(this.timezone)
                    .add(i, "day")
                    .endOf("day");
                return {
                    date: start_of_date.toDate(),
                    slots: data?.schedule?.filter((_) => {
                        return moment(_?.start_date_time).isBetween(
                            start_of_date,
                            end_of_date,
                            null,
                            "[]"
                        );
                    }),
                };
            });
            this.currentWeekSlots = data.schedule;
            this.slots = data.data;
            this.booked = data.booked;
        },
    },
};
</script>

<style scoped>
.arrow_btn {
    font-size: 20px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.3);
    outline: none;
    padding: 0px 10px;
    background: white;
}
.calendar_field {
    border-top: 3px solid rgba(62, 0, 97, 0.79);
    flex: 1;
    font-weight: bold;
}
.calendar_items {
    margin-top: 10px;
    gap: 10px;
    padding: 0px 15px 0 15px;
}
.timeslot {
    color: rgba(62, 0, 97, 0.79);
    cursor: pointer;
    font-weight: 500;
    pointer-events: all;
    transition: border-color 0.15s;
    border: 1px solid transparent;
    border-radius: 8px;
    flex: 1 1;
    line-height: 1;
    padding: 8px 0 6px 0;
    text-align: center;
    transition: color 50ms, background 50ms;
}
.active_timeslot {
    background: rgba(62, 0, 97, 0.79);
    color: white;
}
.timeslot:hover {
    border-color: rgba(62, 0, 97, 0.79);
}
.book_trial_lesson {
    width: 100%;
    border-radius: 12px;
    background: rgba(62, 0, 97, 0.79);
    padding: 10px 0;
    color: white;
    outline: none;
    border: none;
    font-weight: bold;
}
.book_trial_lesson.disabled {
    background: rgba(0, 0, 0, 0.5);
    cursor: not-allowed;
}
</style>
