<template>
    <div>
        <div
            class="modal"
            style="display: block; background-color: rgba(0, 0, 0, 0.4)"
            id="postajob-03"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div
                class="modal-dialog modal-lg modal-dialog job_alert_modal modal-dialog-centered"
                role="document"
            >
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Post a Tuition
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            v-on:click="$parent.closeTuitionForm()"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="steps text-center">
                            <ul
                                id="progressbar"
                                class="progressbar-box-02 my-2"
                            >
                                <li class="active" id="general">
                                    <span>1</span
                                    ><strong>Subjects & Levels </strong>
                                </li>
                                <li class="active" id="duration">
                                    <span>2</span><strong>Preferences</strong>
                                </li>
                                <li id="classsessionroomsetting">
                                    <span>3</span><strong>Other Details</strong>
                                </li>
                            </ul>
                        </div>
                        <form class="form-style-01">
                          <!-- eslint-disable -->
                            <div class="form-group">
                                <label class="text-uppercase">Job Title</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name="search"
                                    style="
                                        height: 40px !important;
                                        border: 1px solid #812bb2 !important;
                                    "
                                    v-model="post.title"
                                    placeholder="Title"
                                    v-on:focus="errorMessages.title = []"
                                />
                                <ul
                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                    v-if="errorMessages.title.length > 0"
                                >
                                    <li v-for="err in errorMessages.title">
                                        <small>{{ err }}</small>
                                    </li>
                                </ul>
                            </div>
                            <div class="form-group">
                                <label class="text-uppercase"
                                    >Job Description</label
                                >
                                <textarea
                                    v-model="post.description"
                                    class="form-control"
                                    style="height: 100px"
                                    v-on:focus="errorMessages.description = []"
                                ></textarea>
                                <ul
                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                    v-if="errorMessages.description.length > 0"
                                >
                                    <li
                                        v-for="err in errorMessages.description"
                                    >
                                        <small>{{ err }}</small>
                                    </li>
                                </ul>
                            </div>
                        </form>
                    </div>
                    <div
                        class="modal-footer border-0 pt-0 mb-3 d-flex justify-content-between"
                    >
                        <button
                            type="button"
                            class="btn btn-primary next-btn px-5 py-2"
                            v-on:click="$parent.step--"
                        >
                            Back
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary d-flex align-items-center justify-content-center next-btn px-5 py-2"
                            v-on:click="validateForm()"
                            :disabled="!!submitLoading"
                        >
                            <div
                                v-if="!!submitLoading"
                                class="spinner-border text-primary"
                                style="
                                    color: white !important;
                                    width: 20px !important;
                                    height: 20px !important;
                                "
                                role="status"
                            ></div>
                            <span v-if="!!submitLoading === false">Submit</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";

export default {
    name: "JobPostStep03Component",
    data() {
        return {
            loading: false,
            submitLoading: false,
            post: {
                title: "",
                description: "",
                verified_only: false,
            },
            errorMessages: {
                title: [],
                description: [],
            },
        };
    },
    created() {
        if (this.$parent.authenticated && this.$parent.selectedJob) {
            this.post = this.$parent.selected_job;
        }
    },
    mounted() {},
    methods: {
        validateForm() {
            this.checkTitle();
            this.checkDescription();
            if (this.checkTitle() && this.checkDescription()) {
                this.addJobPost();
            }
        },
        checkTitle() {
            this.errorMessages.title = [];
            if (!this.post.title) {
                this.errorMessages.title.push("Enter Title...");
                return false;
            } else {
                this.errorMessages.title = [];
                return true;
            }
        },
        checkDescription() {
            this.errorMessages.description = [];
            if (!this.post.description) {
                this.errorMessages.description.push("Enter Description...");
                return false;
            } else {
                this.errorMessages.description = [];
                return true;
            }
        },
        async addJobPost() {
            let formData = new FormData();
            formData.append("title", this.post.title);
            formData.append("description", this.post.description);
            formData.append("verified_only", this.post.verified_only);
            formData.append("id", this.$parent.post_id);
            this.submitLoading = true;
            const { data } = await axiosInstance.post(endpoints.addJobStep3Route, formData);
            this.submitLoading = false;
            if (data.type === "success") {
                this.$parent.step = 1;
                this.$parent.showJobForm = false;
                toast.success(data.message).goAway(2000);
                await this.$parent.getJobPost();
            }
        },
    },
};
</script>

<style scoped></style>
