<template>
  <div class="home_content xxx">
    <section class="ftco-section mt-4 ">
      <div class="row pt-sm-4 pt-0 px-3 ">
        <div class="col-md-12 slot-container">
          <div class="table-wrap">
            <div class="">
              <table class="table text-center" >
                <thead class="thead-primary border-0">
                <tr>
                  <th></th>
                  <th>Transaction Id</th>
                  <th>Item</th>
                  <th>Status</th>
                  <th></th>
                </tr>
                </thead>

                <tbody >
                <tr v-for="dispute in disputes" :key="dispute.id">
                  <td></td>
                  <td>{{dispute.trx_id}}</td>
                  <td>{{dispute.model}}</td>
                  <td>{{dispute.status }}</td>
                  <td><router-link :to="'/student/dispute/'+dispute.id">Detail</router-link></td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <pagination v-show="query.total > query.limit" v-model="query.page" :records="query.total" :per-page="query.limit" @paginate="getDispute()"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
  name: "DisputeComponent",
  data() {
    return {
      disputes: [],
      query: {
        status: '',
        keyword: '',
        sort: 'DESC',
        page: 1,
        limit: 6,
        total: 0,
      },
    }
  },
  created() {
    this.getDispute();
  },
  mounted() {
  },
  methods: {
    async getDispute() {
      const {data} = await axiosInstance.get(endpoints.getDisputeRoute, this.query)
      this.disputes = data.disputes.data
      this.query.total = data.disputes.total
    }
  }
}
</script>

<style scoped>

</style>
