<template>
    <div class="row">
        <div class="col-md-12 col-12 pl-0">
            Chart here
        </div>
    </div>
</template>


<script>

export default {
  name: "WeekBookingSectionComponent",
  props: ['bookings'],
  data() {
    return {
      weekDays: [],
    }
  }
}
</script>

<style scoped>

</style>
