<template>
  <div>
    <div class="container adding-courses-newtheme course-edit-container">
      <div class="course-adding-form box-style-coruse">
        <div id="progressbar" class="progressbar-box">
          <h1>Withdraw Request</h1>
        </div>
        <div class="centered progressbar-box">
          <div class="col-md-3"><h3>Total Available Balance</h3></div>
          <div class="col-md-2"><h3>{{ balance }} {{ "Pkr" }}</h3></div>
        </div>
        <div class="container-fluid" id="grad1">
          <div class="row">
            <div class="col-md-12 mx-0">
              <form @submit.prevent="validateForm()" enctype="multipart/form-data">
                <fieldset>
                  <div class="row p-3 pb-0">
                    <div class="col-md-12">
                      <div class="form-check">
                        <input v-on:change="getAllBalance()" type="checkbox" class="form-check-input" v-model="withdraw.all" id="is_top_course">
                        <label class="form-check-label" for="is_top_course">Withdraw All Available Balance</label>
                      </div>
                    </div>
                    <div class="col-md-12"><div class="form-group">OR</div></div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="amount">Enter Other Amount ({{ "PKr" }})</label>
                        <input type="number" min="0" class="form-control input_height" v-model="withdraw.amount"  id="amount" aria-describedby="emailHelp" placeholder="Enter Amount to get withdraw" required v-on:focus="errorMessages.amount = []">
                        <ul class="text-sm text-danger text-red-600 text-xs italic mt-1" v-if="errorMessages.amount.length > 0">
                          <li v-for="err in errorMessages.amount" :key="err">
                            <small>{{ err }}</small>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Select Account</label>
                        <select v-model="withdraw.account" class="form-control input_height" id="language" name="language" v-on:focus="errorMessages.account = []">
                          <option value="0">Select Account</option>
                          <option v-for="account in accounts" :value="account.id" :key="account.id"> {{ account.bank_name }} *********{{account.account_number.slice(account.account_number.length - 4, account.account_number.length)}} </option>
                        </select>
                        <ul class="text-sm text-danger text-red-600 text-xs italic mt-1" v-if="errorMessages.account.length > 0">
                          <li v-for="err in errorMessages.account" :key="err">
                            <small>{{ err }}</small>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group"></div>
                    </div>
                  </div>
                  <div class="row px-3  mb-3">
                    <div class="col-sm-6"> </div>
                    <div class="col-sm-6 text-right">
                      <button type="submit" class="next action-button btn btn-primary btn_fontsize btn-lg">Submit Request</button>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useUserStore} from "@/store/store";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";

export default {
  name: "WithdrawRequestComponent",
  data() {
    return {
      withdraw: {
        all: false,
        id: 0,
        account: 0,
        amount: 0.00,
      },
      accounts: [],
      balance: 0.00,
      errorMessages: {
        account: [],
        amount: [],
      },
    }
  },
  mounted() {
  },
  created() {
    this.getAccountsList()

    let userStore = useUserStore();
    this.balance = userStore.walletInfo.balance
  },
  methods: {
    async getAccountsList() {
      const accList = await axiosInstance.get(endpoints.getAccountListRoute)
      this.accounts = accList.data.data.data
    },
    validateForm() {
      this.checkAccount()
      this.checkAmount()
      if (this.checkAccount() && this.checkAmount()) {
        this.withdrawAmount()
      }
    },
    checkAccount () {
      this.errorMessages.account = []
      if (!this.withdraw.account) {
        this.errorMessages.account.push('Choose Account...')
        return false
      }
      else {
        this.errorMessages.account = []
        return true
      }
    },
    checkAmount () {
      this.errorMessages.amount = []
      if (!this.withdraw.amount) {
        this.errorMessages.amount.push('Enter Valid Amount...')
        return false
      }
      else if(this.withdraw.amount > this.balance) {
        this.errorMessages.amount.push('Enter Valid Amount...')
        return false
      }
      else {
        this.errorMessages.amount = []
        return true
      }
    },
    async withdrawAmount() {
      let self = this
      let formData = new FormData()
      formData.append('account_id', self.withdraw.account)
      formData.append('amount', self.withdraw.amount)
      formData.append('all', self.withdraw.all)
      const {data} = await axiosInstance.post(endpoints.addWithdrawRequestRoute, formData)
      // await this.getAccountsList()
      if (data.type === 'success') {
        toast.success(data.message)
        await this.$router.push({path: '/tutor/billing/withdraw/request'})
      }
      else if (data.type === 'validation') {
        Object.keys(data.data).forEach(function(key) {
          self.errorMessages[key] = data.data[key]
        })
      }
      else {
        toast.error(data.message)
      }
    },
    async getAllBalance() {
      if (this.withdraw.all) {
        this.withdraw.amount = this.balance
      }
      else
        this.withdraw.amount = 0.00
    }
  },
}
</script>

<style scoped>
.centered {
  width: 100%;
  text-align: start;
}
.dashboard-newtheme form input {
  height: 13px !important;
}
h1, h2, h3, h5 {
  color: #812bb2 !important;
}
</style>
