<template>
  <div class="modal" style="display: block; background-color: rgba(0,0,0, 0.4);" id="postajob-01" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog job_alert_modal modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Report</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="$parent.reportModal = !$parent.reportModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="submitReport" class="form-style-01">
          <div class="modal-body">
            <h4>Report abuse</h4>
            <p>Flagged content is reviewed by ILMZone staff to determine whether it violates Terms of Services or Community Guidlines. If you have a question or technical issue, please contact out
              <a href="/contact-us">Support team here</a></p>
            <div>
              <select class="form-control" v-model="report.issue_type" name="issue_type" id="issue_type">
                <option value="" disabled>Select an issue</option>
                <option value="Harassment">Harassment</option>
                <option value="Spam">Spam</option>
                <option value="Copywrite">Copywrite</option>
                <option value="WrongContent">WrongContent</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div v-if="report.issue_type === 'Other'" class="form-group">
              <label for="report">Report Description</label>
              <textarea required v-model="report.description" class="form-control" id="report" rows="6"></textarea>
            </div>
            <div>
              <input v-on:change="handleFileUpload()" ref="stdImage" type="file" id="attachment"/>
            </div>
          </div>
          <div class="modal-footer border-0 pt-0 mb-3">
            <button type="submit" class="btn btn-primary next-btn px-5 py-2">Report</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import {toast} from "vue3-toastify";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
  name: "ContentReportModalComponent",
  props: ['modal'],
  data() {
    return {
      reportModal: false,
      report: {
        issue_type: '',
        model_id: 0,
        model: 'JobPost',
        description: '',
        attachment: '',
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    handleFileUpload(){
      this.report.attachment = this.$refs.stdImage.files[0];
    },
    async submitReport() {
      if (this.report.issue_type === 'Other' && !this.report.description) {
        toast.success('Description Required...')
      }
      else {
        let formData = new FormData()
        formData.append('attachment', this.report.attachment)
        formData.append('issue_type', this.report.issue_type)
        formData.append('description', this.report.description)
        formData.append('model', this.modal)
        formData.append('model_id', this.$parent.modal_id)
        const {data} = await axiosInstance.post(endpoints.reportJobPostRoute, formData);
        this.$parent.modal_id = 0
        this.$parent.reportModal = !this.$parent.reportModal
        toast.success(data.message)
      }
    },
  }
}

</script>

<style scoped>
textarea#report {
  border: 2px solid #812bb2 !important;
  height: auto !important;
}
</style>
