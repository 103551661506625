<template>
    <div>
        <div
            class="modal"
            id="SignUpModal"
            style="
                display: block;
                background-color: rgba(0, 0, 0, 0.4);
                overflow: auto;
            "
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog signup-newtheme" role="document">
                <div class="modal-content">
                    <div class="modal-body p-6">

                        <button
                            v-on:click="
                                $parent.showSignUp = !$parent.showSignUp
                            "
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="row mb-4">
                            <div class="col-md-12 col-12">
                                <div class="text-center">
                                    <a class="" href="/">
                                        <picture>
                                            <img
                                                src="@/assets/images/logo.svg"
                                                alt="Flowers"
                                                style="width: 200px"
                                            />
                                        </picture>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <div>
                                        <h3 class="login">Join ILMZone as a</h3>
                                        <div class="row py-2">
                                            <div class="col-md-6 col-sm-4 plan">
                                                <input
                                                    type="radio"
                                                    name="user"
                                                    id="Student"
                                                    v-model="data.role"
                                                    value="student"
                                                    checked
                                                />
                                                <label
                                                    class="monthly-label four col"
                                                    for="Student"
                                                    >Student</label
                                                >
                                            </div>
                                            <div class="col-md-6 col-sm-4 plan">
                                                <input
                                                    type="radio"
                                                    name="user"
                                                    id="Tutor"
                                                    v-model="data.role"
                                                    value="tutor"
                                                />
                                                <label
                                                    class="Tutor-label four col"
                                                    for="Tutor"
                                                    >Tutor</label
                                                >
                                            </div>
                                        </div>
                                        <div
                                            class="row py-0"
                                            v-if="signUpWithEmail"
                                        >
                                            <div class="col-md-12 col-12">
                                                <form class="signup-input-fields">

                                                    <div class="mt-2">
                                                        <label
                                                            class="font_size"
                                                            for="email"
                                                            >Email
                                                            Address</label
                                                        >
                                                        <input
                                                            type="email"
                                                            required
                                                            v-model="data.email"
                                                            id="email"
                                                            name="email"
                                                            placeholder="Enter Your Email Address"
                                                            class="form-control"
                                                            v-on:focus="errorMessages.email = []"
                                                        />
                                                        <ul
                                                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                            v-if="errorMessages.email.length > 0"
                                                        >
                                                            <li
                                                                v-for="(err, index) in errorMessages.email" :key="index"
                                                            >
                                                                <small>{{ err }}</small>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="mt-2">
                                                        <label
                                                            class="font_size"
                                                            for="password"
                                                        >
                                                          Password
                                                        </label>

                                                      <div class="password-container">
                                                        <input
                                                            :type="passwordFieldType"
                                                            v-model="data.password"
                                                            id="password"
                                                            name="password"
                                                            required
                                                            placeholder="Enter Your Password"
                                                            class="form-control"
                                                            @focus="errorMessages.password = []"
                                                            @focusin="toggleDropdown"
                                                            @focusout="toggleDropdown"
                                                        />
                                                        <i
                                                            :class="passwordFieldType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'"
                                                            class="toggle-password"
                                                            @click="togglePasswordVisibility"
                                                        ></i>
                                                      </div>

                                                        <ul
                                                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                            v-if="errorMessages.password.length > 0"
                                                        >
                                                            <li
                                                                v-for="(err, index) in errorMessages.password" :key="index"
                                                            >
                                                                <small>{{ err }}</small>
                                                            </li>
                                                        </ul>
                                                      <ul class="password-requirements reset text-small" v-if="activeDropdown">
                                                        <li class="spc-tiny-n">Please Include:</li>
                                                        <li class="lower-letter-check"><i class="wc-check hide"></i><span class="text-disabled">— </span> A lower case letter</li>
                                                        <li class="upper-letter-check"><i class="wc-check hide"></i><span class="text-disabled">— </span> An upper case letter</li>
                                                        <li class="number-check"><i class="wc-check hide"></i><span class="text-disabled">— </span> A number</li>
                                                        <li class="symbol-check"><i class="wc-check hide"></i><span class="text-disabled">— </span> A symbol: !@#$%^&amp;*</li>
                                                        <li class="length-check"><i class="wc-check hide"></i><span class="text-disabled">— </span> At least 6 characters</li>
                                                      </ul>
                                                    </div>

                                                  <div class="mt-2 row">
                                                    <div class="col-6">
                                                      <label
                                                          class="font_size"
                                                          for="first_name"
                                                      >First
                                                        Name</label
                                                      >
                                                      <input
                                                          type="text"
                                                          required
                                                          v-model="data.first_name"
                                                          id="first_name"
                                                          name="first_name"
                                                          placeholder="First Name"
                                                          class="form-control"
                                                          v-on:focus="errorMessages.first_name = []"
                                                      />
                                                      <ul
                                                          class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                          v-if="errorMessages.first_name.length > 0"
                                                      >
                                                        <li v-for="(err, index) in errorMessages.first_name" :key="index">
                                                          <small>{{ err }}</small>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                    <div class="col-6">
                                                      <label
                                                          class="font_size"
                                                          for="last_name"
                                                      >
                                                        Last Name
                                                      </label>
                                                      <input
                                                          type="text"
                                                          required
                                                          v-model="data.last_name"
                                                          id="last_name"
                                                          name="last_name"
                                                          placeholder="Last Name"
                                                          class="form-control"
                                                          v-on:focus="errorMessages.last_name = []"
                                                      />
                                                      <ul
                                                          class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                          v-if="errorMessages.last_name.length > 0"
                                                      >
                                                        <li v-for="(err, index) in errorMessages.last_name" :key="index">
                                                          <small>{{ err }}</small>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>

                                                </form>
                                                <div class="forgot text-left">
                                                    <label
                                                        for="conditions"
                                                        class="condition-label"
                                                    >
                                                        By continuing, I agree to ILMZone’s
                                                        <a
                                                            href="/terms-condition"
                                                        >
                                                          terms
                                                        </a>
                                                        of use and
                                                        <a href="/privacy">privacy policy</a>.</label
                                                    ><br />
                                                </div>
                                                <div class="row">
                                                    <div
                                                        class="col-md-12 col-12"
                                                    >
                                                        <button
                                                            v-on:click="register()"
                                                            :disabled="loading"
                                                            type="submit"
                                                            class="btn btn-primary width_login w-100"
                                                        >
                                                            <span
                                                                v-if="loading"
                                                                class="spinner-border text-primary"
                                                                style="
                                                                    color: white !important;
                                                                    width: 20px !important;
                                                                    height: 20px !important;
                                                                "
                                                                role="status"
                                                            >
                                                            </span>
                                                            <span
                                                                v-if="!loading"
                                                                >Join</span
                                                            >
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            v-if="!signUpWithEmail"
                                            class="py-2"
                                        >
                                            <button
                                                type="submit"
                                                v-on:click="signUpWithEmail = !signUpWithEmail"
                                                class="signup-btn py-2"
                                            >
                                                <i
                                                    class="fa fa-envelope"
                                                    aria-hidden="true"
                                                    style="font-size: 17px"
                                                ></i>
                                                Continue with Email
                                            </button>
                                        </div>
                                        <div
                                            v-if="!signUpWithEmail"
                                            class="continue"
                                        >
                                            <a href="javascript:void(0)">or</a>
                                        </div>
                                        <div
                                            v-if="!signUpWithEmail"
                                            class="py-2"
                                        >

                                          <GoogleSignInButton
                                              @success="handleGoogleLoginSuccess"
                                              @error="handleGoogleLoginError"
                                              width="370">
                                          </GoogleSignInButton>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {GlobalEventBus} from "@/assets/js/eventBus";
import endpoints from "@/assets/js/api";
import axiosInstance, {setAuthToken} from '@/assets/js/axios';
import { toast } from 'vue3-toastify';
import { GoogleSignInButton } from "vue3-google-signin";

export default {
    name: "SignUpModalComponent",
    props: ["signupRole"],
    components: {GoogleSignInButton},
    data() {
        return {
            errorMessages: {
                role: [],
                name: [],
                username: [],
                email: [],
                password: [],
                confirmed_password: [],
                rules: [],
                dob: [],
                first_name: [],
                last_name: [],
            },

            data: {
                role: this.signupRole,
                name: "",
                username: "",
                email: "",
                password: "",
                confirmed_password: "",
                rules: true,
                dob: "",
                first_name: "",
                last_name: "",
            },

            first_name: "",
            last_name: "",
            signUpWithEmail: false,
            loading: false,
            activeDropdown: false,
            passwordFieldType: 'password'
        };
    },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
    methods: {

      toggleDropdown() {
        this.activeDropdown = !this.activeDropdown;
      },

      togglePasswordVisibility() {
        this.passwordFieldType =
            this.passwordFieldType === 'password' ? 'text' : 'password';
      },

      async handleGoogleLoginSuccess(response) {

        const credential = response.credential;
        this.loading = true;

        if(!credential) { toast.error("Login Failed"); }

        try {

          let req_data = {access_token : credential, role: this.data.role};

          const { data } = await axiosInstance.post(endpoints.handleGoogleToken, req_data);

          if (data.status === true) {
            self.parent.showSignUp = !self.parent.showSignUp;

            setAuthToken(data.data.token);

            toast.success("Login Successfully.");

            setTimeout(() => {
              GlobalEventBus.emit("authentication");
            }, 1000);

          }

        } catch (e) {
          toast.error("Login Failed");
        }

        this.loading = false;
      },

      handleGoogleLoginError() {
        toast.error("Login Failed");
      },

      async register() {
          try {

              let name = this.data.name;
              let first_name = this.data.first_name;
              let last_name = this.data.last_name;
              let email = this.data.email;
              let password = this.data.password;
              let confirmed_password = this.data.confirmed_password;
              let role = this.data.role;

              let formData = new FormData();
              formData.append("first_name", first_name);
              formData.append("last_name", last_name);
              formData.append("name", name);
              formData.append("email", email);
              formData.append("password", password);
              formData.append("password_confirmation", confirmed_password);
              formData.append("role", role);

              this.loading = true;

              const { data } = await axiosInstance.post(endpoints.signUp, formData);
              this.loading = false;

              if (data.status === true) {

                  self.parent.showSignUp = !self.parent.showSignUp;
                  setAuthToken(data.data.token);

                  toast.success('You have been successfully registered as '+role);

                  setTimeout(() => {
                    GlobalEventBus.emit("authentication");
                  }, 1000);
              }

          } catch (err) {
              this.loading = false;

              if(err.response) {

                let res = err.response.data;

                if(res.status === false && res.type === 'validation') {

                  let dataObj = this;

                  Object.keys(res.errors).forEach(function (key) {
                    dataObj.errorMessages[key] = res.errors[key];
                  });

                } else {
                  toast.error("Server Error");
                }
              } else {
                toast.error("Server Error");
              }

          }
      }
    }
};
</script>

<style scoped>
.plan input:checked,
.plan input {
  display: none;
}
.plan input:checked + label {
  background-color: #4e97d2;
}

.plan input:checked {
  content: "\2713";
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  border: 2px solid #333;
  background-color: #4e97d2;
  z-index: 999;
  position: absolute;
  top: -10px;
  right: -10px;
}
.monthly-label,
.Tutor-label,
.yearly-label {
  position: relative;
  color: #03a9f4;
  font-size: 16px;
  text-align: center;
  height: 37px;
  line-height: 34px;
  border-radius: 7px;
  display: block;
  cursor: pointer;
  border: 1px solid #2196f3;
  box-sizing: border-box;
}
.plan input:checked + label {
  border: 1px solid transparent;
  background-color: #4e97d2;
  color: white;
}

.set {
  display: flex;
}
.dont_account {
  font-size: 17px;
}
.dateOfB {
  font-size: 17px;
  margin-top: 20px;
}
.dont_account .sign a {
  color: #4e97d2b5;
  font-weight: 300;
}
.width_login {
  margin-top: 10px;
  width: 100%;
}
.forgot {
  margin-top: 14px;
  font-size: 17px;
}
.forgot a {
  color: #4e97d2b5;
  font-weight: 300;
}
.margin_top {
  margin-top: 30px;
}
.font_size {
  font-size: 16px;
}
.login {
  color: black;
}
.logo_cover {
  padding: 22px;
}
.modal-header {
  border: none;
}

.modal {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ff0000;
}

.password-container {
  position: relative;
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

</style>
