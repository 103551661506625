<template>
    <div class="course-detail-container course-detail-newtheme">
        <section class="cl-wrap">
            <div class="container container-desktop">
                <div class="cl-con">
                    <div class="row">
                        <div class="col-md-3 pr-lg-3 mobile-filter">
                            <form class="form-style-01">
                                <div class="form-group rang-newtheme">
                                    <label>Hourly Rate</label>
                                    <!--<input type="range" min="-10" max="10"  step="any"  class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">-->
                                    <vue-slider
                                        :data="range"
                                        id="priceRange"
                                        :minRange="0"
                                        :maxRange="50000"
                                        :enableCross="false"
                                        v-on:drag-end="getTutors()"
                                        v-model="query.priceRange"
                                    />
                                    <div class="row">
                                      <div class="col-sm-6"><small id="start_price" class="form-text text-muted">{{ query.priceRange[0]  }}</small></div>
                                      <div class="col-sm-6 text-right"><small id="end_price" class="form-text text-muted">{{ query.priceRange[1]  }}</small></div>
                                    </div>

                                </div>
                                <hr />
                                <div class="form-group">
                                    <label>Subject</label>
                                    <select
                                        v-on:change="getTutors()"
                                        class="form-control"
                                        id="subject_id"
                                        v-model="query.subject_id"
                                    >
                                        <option value="0">
                                            Choose Subject
                                        </option>
                                      <!-- eslint-disable -->
                                        <option
                                            v-for="subject in subjects"
                                            :value="subject.id"
                                        >
                                            {{ subject.subject }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label>Student's Level</label>
                                    <select
                                        v-on:change="getTutors()"
                                        class="form-control"
                                        id="grade_id"
                                        v-model="query.grade_id"
                                    >
                                        <option value="0">Choose Grade</option>
                                        <option
                                            v-for="grade in grades"
                                            :value="grade.id"
                                        >
                                            {{ grade.name }}
                                        </option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label>City</label>
                                    <select
                                        v-on:change="getTutors()"
                                        class="form-control"
                                        id="city_id"
                                        v-model="query.city_id"
                                    >
                                        <option value="0">Choose City</option>
                                        <option
                                            v-for="city in cities"
                                            :value="city?.id"
                                        >
                                            {{ city?.title }}
                                        </option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label>Alumni Of</label>
                                    <select
                                        v-on:change="getTutors()"
                                        class="form-control"
                                        id="institute_id"
                                        v-model="query.institute_id"
                                    >
                                        <option value="0">
                                            Choose Institute
                                        </option>
                                        <option
                                            v-for="institute in institutes"
                                            :value="institute.id"
                                        >
                                            {{ institute.name }}
                                        </option>
                                    </select>
                                </div>

                                <!-- <div class="form-group">
                                    <label>Ratings</label>
                                    <select
                                        v-on:change="getTutors()"
                                        id="rating"
                                        class="form-control"
                                        v-model="query.rating"
                                    >
                                        <option value="0">Choose Rating</option>
                                        <option value="1">Ratings 01</option>
                                        <option value="2">Ratings 02</option>
                                        <option value="3">Ratings 03</option>
                                        <option value="4">Ratings 04</option>
                                        <option value="5">Ratings 05</option>
                                    </select>
                                </div> -->
                                <!--                <hr>-->
                                <div class="form-group">
                                    <button
                                        v-if="queryCheck()"
                                        v-on:click="clearFilter()"
                                        href="javascript:void(0)"
                                        class="reset-btn"
                                    >
                                        Reset filter
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="col-md-9 tutor-listing-detail-component">
                            <div class="tutor-selection-form">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="date-from">
                                            <div class="form-group">
                                                <label for="date_from"
                                                    >Preferred Date</label
                                                >
                                                <input
                                                    type="date"
                                                    class="form-control"
                                                    id="date_from"
                                                    v-model="query.date_from"
                                                    v-on:change="getTutors()"
                                                    aria-describedby="emailHelp"
                                                />
                                            </div>
                                        </div>

                                        <div class="search-field">
                                            <div class="search-style-02">
                                                <label> </label>
                                                <div class="form-inline">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="query.keyword"
                                                        v-on:change="
                                                            getTutors()
                                                        "
                                                        id="inlineFormInputName2"
                                                        placeholder="Search"
                                                    />
                                                    <button
                                                        v-on:click="getTutors()"
                                                        type="submit"
                                                        class="btn btn-primary"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="28.447"
                                                            height="28.454"
                                                            viewBox="0 0 28.447 28.454"
                                                        >
                                                            <path
                                                                id="Icon_ionic-ios-search"
                                                                data-name="Icon ionic-ios-search"
                                                                d="M32.614,30.888,24.7,22.9a11.275,11.275,0,1,0-1.711,1.733l7.86,7.934a1.218,1.218,0,0,0,1.719.044A1.226,1.226,0,0,0,32.614,30.888ZM15.842,24.732a8.9,8.9,0,1,1,6.3-2.608A8.848,8.848,0,0,1,15.842,24.732Z"
                                                                transform="translate(-4.5 -4.493)"
                                                                fill="#812BB2"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row max-course-container">
                                <div class="col-lg-6 col-md-8">
                                    <p v-if="queryCheck()" class="max-courses">
                                        {{ query.total }} results
                                    </p>
                                </div>

                                <div
                                    class="col-lg-6 col-md-4 sort-by-label-container"
                                >
                                    <div class="filter-bar">
                                        <a
                                            href="javascript:void(0)"
                                            id="filter-button"
                                            onclick="filterMenuOpen();"
                                        >
                                            <img
                                                width="24"
                                                height="24"
                                                src="@/assets/new-theme-images/bx-filter-alt.png"
                                                alt="filter"
                                            />
                                        </a>
                                    </div>
                                    <div class="form-group">
                                        <label class="sort-by-label"
                                            >Sort By</label
                                        >
                                        <div class="sort-by-content origin">
                                            <select
                                                v-on:change="getTutors()"
                                                v-model="query.order_by"
                                            >
                                                <option value="" selected="">
                                                    Best Match
                                                </option>
                                                <option value="lowest_price">
                                                    Lowest Price
                                                </option>
                                                <option value="highest_price">
                                                    Highest Price
                                                </option>
                                                <option value="rating">
                                                    Rating
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="course-con">
                                <TutorTabComponent
                                    v-for="(tutor, number) in tutors"
                                    :tutor="tutor"
                                    :key="number"
                                ></TutorTabComponent>
                            </div>

                            <div class="show-more-btn">
                                <a
                                    v-if="tutors.length < query.total"
                                    href="javascript:void(0)"
                                    v-on:click="getTutorsLoadMore()"
                                    >Show More</a
                                >
                            </div>

                            <div class="text-center">
                                <!--                <pagination v-if="query.total > 8" v-show="query.total > 0" v-model="query.page" :records="query.total" :per-page="query.limit" @paginate="getTutors"/>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div
            v-if="calendarModal"
            class="modal bd-example-modal-lg monthly-modal-calendar"
            id="addreview"
            style="
                display: block;
                background-color: rgba(0, 0, 0, 0.4);
                overflow: auto;
            "
            tabindex="-1"
            role="dialog"
        >
            <div class="modal-dialog modal-lg hello" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <!--        <h5 class="modal-title">Tutor Slot Calendar</h5>-->
                        <button
                            v-on:click="calendarModal = !calendarModal"
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12">
                            <CalendarComponent
                                :tutor_id="tutor_id"
                                :booked="booked"
                                :dependent_id="dependent_id"
                                :slot_id="slot_id"
                                :dependents="dependents"
                            ></CalendarComponent>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="filter-menu" class="filter-submenu side-menu pt-5">
            <div class="mobile-sidebar">
                <div class="sidebar-links">
                    <div
                        class="close-button close-btn"
                        id="close-filter-mob"
                        onclick="filterMenuClose();"
                    >
                        <button>
                            <img
                                width="15"
                                height="15"
                                src="@/assets/new-theme-images/red-cross.png"
                                alt="close"
                            />
                        </button>
                    </div>
                    <div class="col-md-3 pr-lg-3 mobile-filter">
                        <div class="date-filers">
                            <div class="date-from">
                                <div class="form-group">
                                    <label for="date_from">From</label>
                                    <input
                                        type="date"
                                        class="form-control"
                                        id="date_from_"
                                        v-model="query.date_from"
                                        v-on:change="getTutors()"
                                        aria-describedby="emailHelp"
                                    />
                                </div>
                            </div>

                            <div class="date-to">
                                <div class="form-group">
                                    <label for="date_to">To</label>
                                    <input
                                        type="date"
                                        class="form-control"
                                        id="date_to_"
                                        v-model="query.date_to"
                                        v-on:change="getTutors()"
                                        aria-describedby="emailHelp"
                                    />
                                </div>
                            </div>

                            <div class="time-section">
                                <div class="form-group">
                                    <label for="time">Time</label>
                                    <input
                                        type="time"
                                        class="form-control"
                                        id="time_"
                                        v-model="query.time"
                                        v-on:change="getTutors()"
                                        aria-describedby="emailHelp"
                                    />
                                </div>
                            </div>
                        </div>

                        <label>Price range</label>
                        <form class="form-style-01">
                            <div class="form-group rang-newtheme">
                                <vue-slider
                                    :data="range"
                                    id="priceRange_"
                                    :minRange="0"
                                    :maxRange="50000"
                                    :enableCross="false"
                                    v-on:drag-end="getTutors()"
                                    v-model="query.priceRange"
                                />
                            </div>
                            <hr />
                            <div class="form-group">
                                <label>Subject</label>
                                <div class="select_box">
                                    <div class="box-header">
                                        <span>User Experience</span>
                                        <span
                                            ><img
                                                width="20"
                                                height="25"
                                                src="@/assets/new-theme-images/arrow-drop-down.png"
                                        /></span>
                                    </div>
                                    <hr />
                                    <div class="radio-btns">
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="defaultCheck1"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="defaultCheck1"
                                            >
                                                Development
                                            </label>
                                        </div>

                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="defaultCheck2"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="defaultCheck2"
                                            >
                                                Illustration
                                            </label>
                                        </div>

                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="defaultCheck3"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="defaultCheck3"
                                            >
                                                Photoshop
                                            </label>
                                        </div>

                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="defaultCheck4"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="defaultCheck4"
                                            >
                                                Video Editing
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Grade</label>
                                <select
                                    v-on:change="getTutors()"
                                    class="form-control"
                                    id="grade_id_"
                                    v-model="query.grade_id"
                                >
                                    <option value="0">Choose Grade</option>
                                    <option
                                        v-for="grade in grades"
                                        :value="grade.id"
                                    >
                                        {{ grade.name }}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>City</label>
                                <select
                                    v-on:change="getTutors()"
                                    class="form-control"
                                    id="city_id_"
                                    v-model="query.city_id"
                                >
                                    <option value="0">Choose City</option>
                                    <option
                                        v-for="city in cities"
                                        :value="city?.id"
                                    >
                                        {{ city?.title }}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Alumni Of</label>
                                <select
                                    v-on:change="getTutors()"
                                    class="form-control"
                                    id="city_id_"
                                    v-model="query.institute_id"
                                >
                                    <option value="0">Choose Institute</option>
                                    <option
                                        v-for="institute in institutes"
                                        :value="institute.id"
                                    >
                                        {{ institute?.name }}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Ratings</label>
                                <select
                                    v-on:change="getTutors()"
                                    id="rating"
                                    class="form-control"
                                    v-model="query.rating"
                                >
                                    <option value="0">Choose Rating</option>
                                    <option value="1">Ratings 01</option>
                                    <option value="2">Ratings 02</option>
                                    <option value="3">Ratings 03</option>
                                    <option value="4">Ratings 04</option>
                                    <option value="5">Ratings 05</option>
                                </select>
                            </div>
                            <!--                <hr>-->
                            <div class="form-group">
                                <button
                                    v-if="queryCheck()"
                                    v-on:click="clearFilter()"
                                    href="javascript:void(0)"
                                    class="reset-btn"
                                >
                                    Reset filter
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSlider from 'vue-3-slider-component'

import { GlobalEventBus } from "@/assets/js/eventBus";
import CalendarComponent from "@/components/frontend/tutor/CalendarComponent";
import TutorTabComponent from "@/components/frontend/tutor/TutorTabComponent";
import {showLoader, hideLoader} from "@/assets/js/swal";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";
import {useUserStore} from "@/store/store";

export default {
    name: "TutorListingComponent",
    components: {
        VueSlider,
        CalendarComponent,
        TutorTabComponent,
    },
    props: ["slot_id", "dependent_id"],

    data() {
        return {
            userDetail: {},
            authenticated: false,
            cities: [],
            slots: [],
            booked: [],
            dependents: [],
            calendarModal: false,
            dependent: false,
            userWishlist: [],
            range: [
                0, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000,
                5500, 6000, 6500, 7000, 7500, 8000, 8500, 9000, 9500, 10000,
                10500, 11000, 11500, 12500, 13000, 13500, 14000, 14500, 15500,
                16000, 16500, 17000, 17500, 18000, 18500, 19000, 19500, 20000,
                20500, 21000, 21500, 22000, 22500, 23000, 23500, 24000, 24500,
                25000, 25500, 26000, 26500, 27000, 27500, 28000, 28500, 29000,
                29500, 30000, 30500,
            ],
            tutor_id: 0,
            institutes: [],
            grades: [],
            subjects: [],
            categories: [],
            educationBoards: [],
            educationLevels: [],
            tutors: [],
            page: 1,
            perPage: 5,
            query: {
                time: "",
                date_from: "",
                date_to: "",
                institute_id: 0,
                city_id: 0,
                grade_id: 0,
                subject_id: 0,
                status: "",
                priceRange: [0, 30500],
                price: "",
                role: "",
                board_id: 0,
                education_level: 0,
                level: 0,
                language: 0,
                keyword: "",
                rating: 0,
                sort_by: "DESC",
                order_by: "",
                page: 1,
                limit: 20,
                total: 0,
            },
            filter: {
                category: 0,
                board: 0,
                educationLevel: 0,
                level: 0,
                language: 0,
            },
            added: [],
            purchasedCourses: [],
            ownCourses: [],
            sessions: [],
            role: "",
            selected_date: "",
        };
    },
    computed: {},
    created() {
        this.getFilterContent();
        this.getCachedTutors();
    },
    mounted() {
        let self = this;

      const userStore = useUserStore();

        self.authenticated = userStore.isAuthenticated;
        if (self.authenticated) {
            self.userDetail = userStore.currentUser;
            self.authenticated = userStore.isAuthenticated;
        }
    },
    methods: {
        queryCheck() {
            if (
                this.query.subject_id ||
                this.query.status ||
                this.query.price ||
                this.query.role ||
                this.query.board_id ||
                this.query.education_level ||
                this.query.grade_id ||
                this.query.city_id ||
                this.query.institute_id ||
                this.query.level ||
                this.query.language ||
                this.query.keyword ||
                this.query.date_from ||
                this.query.date_to ||
                this.query.time ||
                this.query.rating
            ) {
                return true;
            } else {
                return false;
            }
        },
        clearFilter() {
            this.query.subject_id = 0;
            this.query.status = "";
            this.query.price = "";
            this.query.role = "";
            this.query.board_id = 0;
            this.query.grade_id = 0;
            this.query.city_id = 0;
            this.query.institute_id = 0;
            this.query.education_level = 0;
            this.query.level = 0;
            this.query.language = 0;
            this.query.keyword = "";
            this.query.date_from = "";
            this.query.date_to = "";
            this.query.time = "";
            this.query.rating = 0;
            this.getTutors();
        },
        async getFilterContent() {
            try {

              const { data } = await axiosInstance.get(endpoints.filterContent);
              this.categories = data.data.categories;
              this.educationBoards = data.data.boards;
              this.educationLevels = data.data.educationLevels;
              this.grades = data.data.grades;
              this.subjects = data.data.subjects;
              this.cities = data.data.cities;
              this.institutes = data.data.institutes;

            } catch (e) {
              toast.error('Server Error')
            }
        },
        async getTutors() {
            this.query.page = 1;
            if (this.query.date_from)
                this.selected_date = this.query.date_from;
            let query = this.query;
            const { data } = await axiosInstance.get(endpoints.getTutorRoute, {params: query});
            this.tutors = data.data.tutors;
            this.query.total = data.data.tutors.total;
        },
        async getCachedTutors() {
            try {

              showLoader();
              this.query.page = 1;
              this.query.timezone =
                  Intl.DateTimeFormat().resolvedOptions().timeZone;
              if (this.query.date_from)
                this.selected_date = this.query.date_from;
              let query = this.query;
              const { data } = await axiosInstance.get(endpoints.getTutorRoute, {params: query});
              this.tutors = data.data.tutors.data;
              this.query.total = data.data.tutors.total;
              hideLoader();

            } catch (e) {
              hideLoader();
              toast.error('Server Error');
            }
        },
        async getTutorsLoadMore() {
            let self = this;
            this.query.page++;
            let query = this.query;
            const { data } = await axiosInstance.get(endpoints.getTutorRoute, {params: query});
            data.data.tutors.data.forEach(function (tutor) {
                self.tutors.push(tutor);
            });
        },
        async setRating(rating, id) {
            let formData = new FormData();
            formData.append("course_id", id);
            formData.append("rating", rating);
            await axiosInstance.post(endpoints.courseRatingRoute, formData);
        },
        validateForm() {},
        async showCalendar(id) {
            if (!this.authenticated) {
                GlobalEventBus.emit("showLoginForm");
            } else {
                this.tutor_id = id;
                this.calendarModal = true;
            }
        },
        checkJoin(tutor) {
            if (this.authenticated) {
                if (this.userDetail.user.id === tutor) return true;
            }
        },
    },
};
</script>

<style scoped></style>
