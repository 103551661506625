<template>
  <div class="container_home_section tutor-dashboard-newtheme">
    <div class="row">
      <div class="col-xl-12 main-col">
        <div v-if="loaded" class="row">
          <div class="col-md-8 col-12">
            <h5>Dispute Details</h5>
            <div class="hide-total_earning_parent">
              <div class="total_earning_parent">
                <div class="rows">
                  <h5 class="total_text"> Detail </h5>
                  <p class="total_text pl-0 m-0">{{ dispute.detail }}</p>
                </div>
                <div class="rows">
                  <h5 v-if="!!dispute.user" class="total_text"> Created By </h5>
                  <p class="total_text pl-0 m-0">{{ dispute.user.name }}</p>
                </div>
                <div class="rows">
                  <h5 class="total_text"> Status </h5>
                  <p class="total_text pl-0 m-0">{{ dispute.status }}</p>
                </div>
              </div>
            </div>
            <form @submit.prevent="postThread()">
              <div class="col-12 mt-2 px-0">
                <h4>Dispute Threads</h4>
                <div class="form-group">
                  <label>Add Message</label>
                  <textarea :disabled="dispute.status !== 'open'" required class="form-control" v-model="message"></textarea>
                </div>
              </div>
              <div class="col-12 mt-2 mb-2 px-0">
                <button :disabled="dispute.status !== 'open'" type="submit" class="btn btn-success float-right">Submit</button>
              </div>
            </form>
            <br>
            <div class="col-12 mt-4 mb-4 px-0">
              <!-- eslint-disable -->
              <div v-for="thread in threads" class="hide-total_earning_parent mt-2">
                <div class="total_earning_parent">
                  <div class="post">
                    <div class="user-block">
                      <img :src="'/storage'+thread.user.image"  alt="user image" class="img-circle img-bordered-sm">
                      <span class="username">
                    <a href="#">{{ thread.user.name }}</a>
                  </span>
                      <span class="description">Thread Added - {{ thread.created_at | humanize }}</span>
                    </div>
                    <p>{{thread.message}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-12 px-0">
            <div class="row p-0 m-0">
              <div class="col-md-12">
                <h5>Disputed Items</h5>
                <div class="hide-total_earning_parent">
                  <div class="total_earning_parent">
                    <div v-if="dispute.model_id === slot.slot.id" v-for="slot in dispute.transaction.slots" class="rows">
                      <div class="coursesdetail">
                        <p class="total_text pl-0 m-0">
                          {{ slot.slot.start_date_time | DDMMMM }}
                          {{ slot.slot.start_date_time | hhmmA }} - {{ slot.slot.end_date_time | hhmmA }}
                          {{ slot.slot.price }} PKR
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-2">
                <h5>Transaction Items</h5>
                <div class="hide-total_earning_parent">
                  <div class="total_earning_parent">
                    <div v-for="slot in dispute.transaction.slots" class="rows">
                      <div class="coursesdetail">
                        <p class="total_text pl-0 m-0">
                          {{ slot.slot.start_date_time | DDMMMM }}
                          {{ slot.slot.start_date_time | hhmmA }} - {{ slot.slot.end_date_time | hhmmA }}
                          {{ slot.slot.price }} PKR
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
  name: "DisputeDetailComponent",
  data() {
    return {
      loaded: false,
      dispute: {},
      message: '',
      threads: [],
      query: {
        total: 0,
        sort: 'DESC',
        page: 1,
        limit: 6,
      },
    }
  },
  created() {
    this.getDisputeDetail()
    this.getThreads()
  },
  mounted() {
  },
  methods: {
    async postThread() {
      let formData = new FormData();
      formData.append('dispute_id', this.$route.params.id)
      formData.append('message', this.message)
      await axiosInstance.post(endpoints.addThreadMessageRoute, formData);
      this.message = ''
      this.getThreads()
    },
    async getThreads() {
      this.query.dispute_id = this.$route.params.id
      const {data} = await axiosInstance.get(endpoints.getThreadRoute, this.query);
      this.threads = data.threads.data
      this.query.total = data.threads.total
    },
    async getDisputeDetail() {
      let formData = new FormData();
      formData.append('dispute_id', this.$route.params.id)
      const {data} = await axiosInstance.post(endpoints.getStudentDisputeDetailRoute, formData);
      if (data.type === 'success') {
        this.dispute = data.dispute
        this.loaded = true
      }
    },
  }
}
</script>

<style scoped>
.tutor-dashboard-newtheme .total_earning_parent .rows {
  display: flow-root !important;
}
img.img-bordered-sm {
  width: 40px;
}
div.user-block {
  padding-left: 16px;
}
</style>
