<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <!-- DIRECT CHAT -->
        <div class="card direct-chat direct-chat-warning">
          <div class="card-header">
            <h3 class="card-title">Course Threads</h3>
          </div>
          <!-- eslint-disable -->
          <div class="card-body">
            <div class="direct-chat-messages">
              <div class="direct-chat-msg" v-for="thread in threads" :class="{'right' : thread.user.roles.findIndex(role => role.name === 'admin') > -1}">
                <div class="direct-chat-infos clearfix">
                  <span class="direct-chat-name float-left">Alexander Pierce</span>
                  <span class="direct-chat-timestamp float-right">{{ thread.created_at | DDMMMhhmmA }}</span>
                </div>
                <img class="direct-chat-img" width="20px" :src="'/storage'+thread.user.image" alt="message user image">
                <div class="direct-chat-text">
                  {{thread.message}}
                  <img v-on:click="showMedia(thread.media)" width="50px" :src="'/storage'+thread.media" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <form @submit.prevent="changeCourseStatusWithMessage()" ref="form" method="POST" enctype="multipart/form-data">
              <div class="input-group">
                <input type="text" name="message" placeholder="Type Message ..." v-model="message" class="form-control">
<!--                <input type="file" ref="file" v-on:change="handleFileUpload" name="file" id="file" >-->
                <label class="filelabel">
                  <i class="fa fa-paperclip"></i>
                  <input ref="file" v-on:change="handleFileUpload" class="FileUpload1" id="FileInput" name="booking_attachment" type="file"/>
                </label>
                <span class="">
                  <button type="submit" class="btn btn-warning">Send</button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="modal courseStatusChange" v-if="showMediaImage" style="display: block; background-color: rgba(0,0,0, 0.4); overflow-x: scroll;" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="title">Media</h3>
            <button v-on:click="showMediaImage = !showMediaImage" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!--            <form @submit.prevent="changeCourseStatusWithMessage()" ref="form" method="POST" enctype="multipart/form-data">-->

            <div class="col-md-12">
              <img :src="'/storage'+media" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>



  </div>
</template>

<script>

import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
  name: "ConversationComponent",
  data() {
    return {
      threads: [],
      message: '',
      file: '',
      media: '',
      showMediaImage: false,
    }
  },
  created() {
    this.getCourseThreads()
  },
  mounted() {
  },
  methods: {
    handleFileUpload(){
      this.file = this.$refs.file.files[0]
    },
    async changeCourseStatusWithMessage() {
      let self = this
      let id = self.$parent.selectedCourse
      let message = this.message
      let formData = new FormData()
      formData.append('course_id', id)
      formData.append('media', self.file)
      formData.append('message', message)
      await axiosInstance.post(endpoints.updateCourseStatus, formData);
      this.$parent.message = ''
      await this.getCourseThreads()
    },
    async getCourseThreads() {
      let self = this
      let id = self.$parent.selectedCourse
      let formData = new FormData()
      formData.append('course_id', id)
      const { data } = await axiosInstance.post(endpoints.getCourseThreadsRoute, formData);
      this.threads = data.threads
    },
    showMedia(path) {
      this.media = path
      this.showMediaImage = true
    },
  },
}
</script>

<style scoped>
.filelabel {
  width: 120px;
  border-radius: 5px;
  display: block;
  padding: 5px;
  transition: border 300ms ease;
  cursor: pointer;
  text-align: center;
  margin: 0;
}
.filelabel i {
  display: block;
  font-size: 30px;
  padding-bottom: 5px;
}
.filelabel i,
.filelabel .title {
  color: grey;
  transition: 200ms color;
}
/*.filelabel:hover {*/
/*  border: 2px solid #1665c4;*/
/*}*/
/*.filelabel:hover i,*/
/*.filelabel:hover .title {*/
/*  color: #1665c4;*/
/*}*/
#FileInput{
  display:none;
}

.direct-chat-messages {
  max-height: 300px;
  overflow-y: scroll;
}
</style>
