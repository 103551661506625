<template>
  <div class="home_content xxx">
    <div class="appointment_header">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-6">
        </div>
        <div class="col-12 col-sm-6 col-md-6">
          <div class="text-md-right">
            <router-link to="/tutor/billing/withdraw/request/create">
              <button class="btn add_appointment_btn"><i class="fas fa-plus-circle mr-2"></i> Create Withdraw Request </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <section class="ftco-section mt-4 ">
      <div class="row pt-sm-4 pt-0 px-3 ">
        <div class="col-md-12 slot-container">
          <div class="table-wrap">
            <div class="">
              <table class="table text-center" >
                <thead class="thead-primary border-0">
                <tr>
                  <th></th>
                  <th>Method</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th></th>
                </tr>
                </thead>

                <tbody >
                <tr v-for="withdraw in withdraws" :key="withdraw.id">
                  <td></td>
                  <td>{{withdraw.account.bank_name}}</td>
                  <td>{{withdraw.amount}} {{ $t('text.pkr') }}</td>
                  <td>{{withdraw.status }}</td>
                  <td></td>
                </tr>
                </tbody>
              </table>

              <div v-if="no_record">
                <p class="p-2">{{"No Record Found"}}</p>
              </div>

            </div>
            <div class="row">
              <pagination v-show="query.total > query.limit" v-model="query.page" :records="query.total" :per-page="query.limit" @paginate="getWithDraws()"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";

export default {
  name: "WithdrawListingComponent",
  data() {
    return {
      no_record: false,
      withdraws: [],
      balance: 0.00,
      query: {
        status: '',
        keyword: '',
        sort: 'DESC',
        page: 1,
        limit: 6,
        total: 0,
      },
    }
  },
  mounted() {
  },
  created() {
    this.getWithDraws()
  },
  methods: {
    async getWithDraws() {
      const withdrawListRes = await axiosInstance.get(endpoints.getWithdrawListRoute, {params: this.query})
      console.log(withdrawListRes.data.data.balance);
      this.balance = withdrawListRes.data.data.balance
      this.withdraws = withdrawListRes.data.data.data.data
      this.query.total = withdrawListRes.data.data.data.total
      this.no_record = this.withdraws.length === 0
    }
  }
}
</script>

<style scoped>
h3, h5 {
  color: #812bb2 !important;
}
</style>
