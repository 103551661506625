<template>
    <div class="course-detail-container course-detail-newtheme course-listing">
        <div class="container">
            <section class="cl-wrap">
                <div class="container-fluid">
                    <div class="cl-con">
                        <div class="row">
                            <div class="col-md-3 pr-lg-3 mobile-filter">
                                <form class="form-style-01">

                                    <div class="form-group">
                                        <label>Course Category</label>
                                        <select
                                            v-on:change="getCourses()"
                                            class="form-control"
                                            id="category_id"
                                            v-model="query.category_id"
                                        >
                                            <option value="0">
                                                Choose Category
                                            </option>
                                            <option
                                                v-for="(category, index) in categories" :key="index"
                                                :value="category.id"
                                            >
                                                {{ category.name }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="form-group">
                                        <label>Education Board</label>
                                        <select
                                            id="board_id"
                                            v-on:change="getCourses()"
                                            v-model="query.board_id"
                                            class="form-control"
                                        >
                                            <option value="0">
                                                Choose Educational Board
                                            </option>
                                            <option v-for="(board, index) in educationBoards" :key="index">
                                                {{ board.board }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label>Class</label>
                                        <select
                                            id="education_level"
                                            v-on:change="getCourses()"
                                            v-model="query.education_level"
                                            class="form-control"
                                        >
                                            <option value="0">
                                                Choose Educational Level
                                            </option>
                                            <option v-for="(level, index) in educationLevels" :key="index">
                                                {{ level.level }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label>Level</label>
                                        <select
                                            id="level"
                                            v-on:change="getCourses()"
                                            v-model="query.level"
                                            class="form-control"
                                        >
                                            <option value="0">
                                                Choose Course Level
                                            </option>
                                            <option value="beginner">
                                                Beginner
                                            </option>
                                            <option value="advanced">
                                                Advanced
                                            </option>
                                            <option value="intermediate">
                                                Intermediate
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label>Language</label>
                                        <select
                                            id="language"
                                            v-on:change="getCourses()"
                                            v-model="query.language_id"
                                            class="form-control"
                                        >
                                            <option value="0">
                                                Choose Course Language
                                            </option>
                                            <option value="french">
                                                French
                                            </option>
                                            <option value="english" selected>
                                                English
                                            </option>
                                        </select>
                                    </div>

                                    <button
                                        v-if="queryCheck()"
                                        v-on:click="clearFilter()"
                                        class="reset-btn"
                                    >
                                        Reset All
                                    </button>
                                </form>
                            </div>
                            <div
                                class="col-md-9 pddng-sm-0"
                                v-if="courses.length > 0"
                            >
                                <div class="row">
                                    <div class="col-lg-3 col-md-4">
                                        <form class="form-style-01">
                                            <div class="sort-by-content">
                                                <select>
                                                    <option value="">
                                                        Live Courses
                                                    </option>
                                                </select>
                                            </div>
                                        </form>
                                    </div>

                                    <div class="col-lg-9 col-md-8">
                                        <div class="search-com mt-0 mb-0">
                                            <div class="search-style-01">
                                                <form
                                                    action=""
                                                    @submit.prevent="
                                                        getCourses()
                                                    "
                                                    class="form-inline"
                                                >
                                                    <input
                                                        v-model="query.keyword"
                                                        type="text"
                                                        class="form-control"
                                                        id="inlineFormInputName2"
                                                        placeholder="Search"
                                                    />
                                                    <button
                                                        type="submit"
                                                        class="btn btn-primary"
                                                    >
                                                        <img
                                                            width="21"
                                                            height="21"
                                                            src="@/assets/new-theme-images/search.png"
                                                        />
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row max-course-container">
                                    <div class="col-lg-6 col-md-8 pddng-sm-0">
                                        <p
                                            v-if="queryCheck()"
                                            class="max-courses"
                                        >
                                            {{ query.total }} results
                                        </p>
                                    </div>

                                    <div
                                        class="col-lg-6 col-md-4 sort-by-label-container"
                                    >
                                        <div class="filter-bar">
                                            <a
                                                href="javascript:void(0)"
                                                id="filter-button"
                                                onclick="filterMenuOpen();"
                                            >
                                                <img
                                                    width="24"
                                                    height="24"
                                                    src="@/assets/new-theme-images/bx-filter-alt.png"
                                                    alt="filter"
                                                />
                                            </a>
                                        </div>
                                        <div class="form-group mt-3">
                                            <label class="sort-by-label"
                                                >Sort By</label
                                            >
                                            <div class="sort-by-content origin">
                                                <select
                                                    v-on:change="getCourses()"
                                                    v-model="query.order_by"
                                                >
                                                    <option value="0">
                                                        Sort By
                                                    </option>
                                                    <option
                                                        value="id"
                                                        selected="selected"
                                                    >
                                                        Newest
                                                    </option>
                                                    <option value="low_price">
                                                        Lowest Price
                                                    </option>
                                                    <option value="high_price">
                                                        Highest Price
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="course-con">
                                    <div
                                        v-for="(course, index) in courses" :key="index"
                                        class="course-box"
                                    >
                                        <div class="course-box-inner">
                                            <a :href="'/course/' + course.uuid">
                                                <div class="course-box-logo">
                                                    <img
                                                        width="71"
                                                        height="65"
                                                        class="course-logo"
                                                        :src="course.thumbnail"
                                                        :alt="course.title"
                                                    />
                                                </div>
                                            </a>
                                            <div class="course-box-content">
                                                <a
                                                    :href="'/course/' + course.uuid"
                                                    :title="course.title"
                                                >
                                                    <strong
                                                        class="course-box-title"
                                                        v-if="course.title.length > 40"
                                                        >{{ course.title.substring(0,40) }}...</strong
                                                    >
                                                    <strong
                                                        class="course-box-title"
                                                        v-else
                                                    >
                                                      {{ course.title }}
                                                    </strong>
                                                </a>
                                                <p
                                                    class="course-box-author-name"
                                                >
                                                    {{ course.tutor.name }}
                                                </p>
                                                <div
                                                    class="course-box-prices float-right mobile"
                                                >
                                                    {{
                                                        course.discount_flag
                                                            ? course.discounted_price
                                                            : course.price
                                                    }}
                                                    {{ $t("text.pkr") }}
                                                </div>
                                                <div
                                                    class="course-box-rating mobile"
                                                >
                                                    <star-rating
                                                        :show-rating="false"
                                                        :read-only="true"
                                                        v-model="course.rating"
                                                        :increment="0.25"
                                                        @rating-selected="
                                                            setRating(
                                                                course.rating,
                                                                course.id
                                                            )
                                                        "
                                                        v-bind:star-size="20"
                                                    ></star-rating>
                                                </div>
                                                <p
                                                    class="course-box-text desktop"
                                                >
                                                    {{
                                                        course.short_description
                                                    }}
                                                </p>
                                                <div
                                                    class="course-box-detail desktop"
                                                >
                                                    <ul class="last-update">
                                                        <li>
                                                            <span
                                                                class="update-bold"
                                                                >Updated
                                                                on</span
                                                            >
                                                            {{ course.updated_at }}
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="javascript:void(0)"
                                                                v-on:click="
                                                                    reportIt(
                                                                        course.id
                                                                    )
                                                                "
                                                                >Report</a
                                                            >
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div
                                                    class="course-box-detail desktop"
                                                >
                                                    <ul>
                                                        <li>
                                                            Starts:
                                                            {{ course.start_at }}
                                                            |
                                                        </li>
                                                        <li class="timing-li">
                                                            Timings:
                                                            {{ course.start_at }}
                                                            to
                                                            {{ course.end_at }}
                                                            |
                                                        </li>
                                                        <li>Online Lectures</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="course-box-price text-right"
                                        >
                                            <div class="course-box-fav d-flex">
                                                <div
                                                    class="course-box-prices float-right desktop"
                                                >
                                                    {{ course.discount_flag? course.discounted_price : course.price }}
                                                    {{ "Pkr" }}
                                                </div>
                                                <a
                                                    v-if="
                                                        purchasedCourses.findIndex(
                                                            (p) =>
                                                                parseInt(p) ===
                                                                parseInt(
                                                                    course.id
                                                                )
                                                        ) < 0
                                                    "
                                                    :id="
                                                        'wishlist-' + course.id
                                                    "
                                                    href="javascript:void(0)"
                                                    v-on:click="
                                                        addToWishlist(course.id)
                                                    "
                                                    class="button-wishlist"
                                                >
                                                    <img
                                                        width="26px"
                                                        height="24px"
                                                        v-if="
                                                            userWishlist.findIndex(
                                                                (x) =>
                                                                    x ===
                                                                    course.id
                                                            ) > -1
                                                        "
                                                        src="@/assets/images/wishlist/wishlist-heart-filled.svg"
                                                        alt=""
                                                    />
                                                    <img
                                                        v-else
                                                        width="26px"
                                                        height="24px"
                                                        src="@/assets/images/wishlist/wishlist-heart-empty.svg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div
                                                v-if="
                                                    sessions[course.id]
                                                        .progress > 0
                                                "
                                                class="courses-progress-bar"
                                            >
                                                <p>
                                                    <span class="bar-heading"
                                                        >IN Progress</span
                                                    >
                                                </p>
                                                <div
                                                    class="progress"
                                                    style="height: 4px"
                                                >
                                                    <div
                                                        class="progress-bar"
                                                        role="progressbar"
                                                        :style="
                                                            'width: ' +
                                                            sessions[course.id]
                                                                .progress +
                                                            '%'
                                                        "
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                                <div
                                                    class="d-flex justify-content-between"
                                                >
                                                    <span
                                                        class="bar-detail float-right"
                                                        >{{
                                                            sessions[course.id]
                                                                .pending_session
                                                        }}
                                                        Sessions</span
                                                    >
                                                    <p
                                                        class="bar-detail text-left"
                                                    >
                                                        End in
                                                        {{
                                                            sessions[course.id]
                                                                .end_at
                                                        }}
                                                    </p>
                                                </div>
                                                <div
                                                    class="course-box-rating desktop"
                                                >
                                                    <star-rating
                                                        :show-rating="false"
                                                        :read-only="true"
                                                        v-model="course.rating"
                                                        :increment="0.25"
                                                        @rating-selected="
                                                            setRating(
                                                                course.rating,
                                                                course.id
                                                            )
                                                        "
                                                        v-bind:star-size="20"
                                                    ></star-rating>
                                                </div>
                                                <div class="course-box-detail">
                                                    <ul class="last-update">
                                                        <li>
                                                            {{
                                                                course
                                                            }}+ Hours
                                                        </li>
                                                        <li>
                                                            {{
                                                                course.sessions
                                                            }}
                                                            Lectures
                                                        </li>
                                                        <li>
                                                            {{
                                                                course.level
                                                                    .charAt(0)
                                                                    .toUpperCase() +
                                                                course.level.slice(
                                                                    1
                                                                )
                                                            }}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <p
                                                    class="course-box-text mobile"
                                                >
                                                    {{
                                                        course.short_description
                                                    }}
                                                </p>
                                                <div
                                                    class="course-box-detail mobile"
                                                >
                                                    <ul>
                                                        <li>
                                                            Starts:
                                                            {{ course.start_at }}
                                                            |
                                                        </li>
                                                        <li class="timing-li">
                                                            Timings:
                                                            {{ course.start_at }}
                                                            to
                                                            {{ course.end_at }}
                                                            |
                                                        </li>
                                                        <li>Online Lectures</li>
                                                    </ul>
                                                </div>
                                                <div
                                                    class="course-box-detail mobile"
                                                >
                                                    <ul class="last-update">
                                                        <li>
                                                            <span
                                                                class="update-bold"
                                                                >Updated
                                                                on</span
                                                            >
                                                            {{ course.updated_at }}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <AddToCartButtonComponent
                                                v-if="courses"
                                                :course="course"
                                                :sessions="sessions"
                                                :added="added"
                                                :ownCourses="ownCourses"
                                                :purchasedCourses="
                                                    purchasedCourses
                                                "
                                                page="listing"
                                                :dependents="dependents"
                                                :depends="dependent"
                                                :role="rol"
                                                :showDependentDropdown="false"
                                            ></AddToCartButtonComponent>
                                        </div>
                                    </div>
                                </div>
                                <div class="show-more-btn">
                                    <a
                                        href="javascript:void(0)"
                                        v-if="courses.length < query.total"
                                        v-on:click="getCoursesLoadMore(1)"
                                        >Load More</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div id="filter-menu" class="filter-submenu side-menu pt-5">
            <div class="mobile-sidebar">
                <div class="sidebar-links">
                    <div
                        class="close-button close-btn"
                        id="close-filter-mob"
                        onclick="filterMenuClose();"
                    >
                        <button>
                            <img
                                width="15"
                                height="15"
                                src="@/assets/new-theme-images/red-cross.png"
                                alt="close"
                            />
                        </button>
                    </div>
                    <div class="col-md-3 pr-lg-3 mobile-filter">
                        <form class="form-style-01">

                            <div class="form-group">
                                <label>Category</label>
                                <select
                                    v-on:change="getCourses()"
                                    class="form-control"
                                    id="category_id_"
                                    v-model="query.category_id"
                                >
                                    <option value="0">Choose Category</option>
                                    <option
                                        v-for="(category, index) in categories" :key="index"
                                        :value="category.id"
                                    >
                                        {{ category.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>Education Board</label>
                                <select
                                    id="board_id"
                                    v-on:change="getCourses()"
                                    v-model="query.board_id"
                                    class="form-control"
                                >
                                    <option value="0">
                                        Choose Educational Board
                                    </option>
                                    <option v-for="(board, index) in educationBoards" :key="index">
                                        {{ board.board }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>Class</label>
                                <select
                                    id="education_level"
                                    v-on:change="getCourses()"
                                    v-model="query.education_level"
                                    class="form-control"
                                >
                                    <option value="0">
                                        Choose Educational Level
                                    </option>
                                    <option v-for="(level, index) in educationLevels" :key="index">
                                        {{ level.level }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>Level</label>
                                <select
                                    id="level"
                                    v-on:change="getCourses()"
                                    v-model="query.level"
                                    class="form-control"
                                >
                                    <option value="0">
                                        Choose Course Level
                                    </option>
                                    <option value="beginner">Beginner</option>
                                    <option value="advanced">Advanced</option>
                                    <option value="intermediate">
                                        Intermediate
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>Language</label>
                                <select
                                    id="language_id"
                                    v-on:change="getCourses()"
                                    v-model="query.language_id"
                                    class="form-control"
                                >
                                    <option value="0">
                                        Choose Course Language
                                    </option>
                                  <option value="1" selected>
                                    English
                                  </option>
                                    <option value="3">French</option>

                                </select>
                            </div>
                            <div class="form-group">
                                <label>Ratings</label>
                                <select
                                    v-on:change="getCourses()"
                                    id="rating"
                                    class="form-control"
                                    v-model="query.rating"
                                >
                                    <option value="0">Choose Rating</option>
                                    <option value="1">Ratings 01</option>
                                    <option value="2">Ratings 02</option>
                                    <option value="3">Ratings 03</option>
                                    <option value="4">Ratings 04</option>
                                    <option value="5">Ratings 05</option>
                                </select>
                            </div>
                            <button
                                v-if="queryCheck()"
                                v-on:click="clearFilter()"
                                class="reset-btn"
                            >
                                Reset All
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <ContentReportModalComponent
            modal="Course"
            v-if="reportModal"
        ></ContentReportModalComponent>
    </div>
</template>

<script>
import StarRating from "vue-star-rating";
import { GlobalEventBus } from "@/assets/js/eventBus";
import AddToCartButtonComponent from "@/components/utilities/button/AddToCartButtonComponent";
import ContentReportModalComponent from "@/components/utilities/report/ContentReportModalComponent";
import {showLoader, hideLoader} from "@/assets/js/swal";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";
import {useUserStore} from "@/store/store";

const moment = require("moment");

export default {
    name: "CourseListingComponent",
    props: ["rol"],
    components: {
        StarRating,
        AddToCartButtonComponent,
        ContentReportModalComponent,
    },
    data() {
        return {
            modal_id: 0,
            userDetail: {},
            dependents: [],
            reportModal: false,
            dependent: false,
            authenticated: false,
            userWishlist: [],
            range: [
                0, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000,
                5500, 6000, 6500, 7000, 7500, 8000, 8500, 9000, 9500, 10000,
                10500, 11000, 11500, 12500, 13000, 13500, 14000, 14500, 15500,
                16000, 16500, 17000, 17500, 18000, 18500, 19000, 19500, 20000,
                20500, 21000, 21500, 22000, 22500, 23000, 23500, 24000, 24500,
                25000, 25500, 26000, 26500, 27000, 27500, 28000, 28500, 29000,
                29500, 30000, 30500,
            ],
            categories: [],
            educationBoards: [],
            educationLevels: [],
            courses: [],
            coursesView: [],
            page: 1,
            perPage: 5,
            query: {
                category_id: 0,
                status: "",
                priceRange: [0, 30500],
                price: "",
                role: "",
                board_id: 0,
                education_level: 0,
                level: 0,
                language_id: 0,
                keyword: "",
                rating: 0,
                sort_by: "DESC",
                order_by: 0,

                page: 1,
                limit: 8,
                total: 0,
            },
            filter: {
                category: 0,
                board: 0,
                educationLevel: 0,
                level: 0,
                language: 0,
            },
            added: [],
            purchasedCourses: [],
            ownCourses: [],
            sessions: [],
            role: "",
        };
    },
    created() {
        this.getFilterContent();
        this.getCoursesCached();
    },
    mounted() {
        let self = this;

        const userStore = useUserStore();

        self.authenticated = userStore.isAuthenticated;
        self.userDetail = userStore.currentUser;
        self.role = self.userDetail.role;

        this.getCoursesStats();

        GlobalEventBus.on("getCartData", function (data) {
            self.added = data.courses;
        });

    },
    methods: {

        async getCoursesStats() {
          if(this.userDetail) {

            try {

              const { data } = await axiosInstance.get(endpoints.getCoursesStats);
              self.userWishlist = data.data.wishlist;
              self.purchasedCourses = data.data.purchasedCourses ?? [];
              self.ownCourses = data.data.ownCourses;

            } catch (e) {
              console.log(e);
            }

          }

        },

        async getCourses() {

          try {

            showLoader();

            let self = this;
            this.query.page = 1;
            const { data } = await axiosInstance.get(endpoints.courseRoute, { params: this.query });
            self.courses = data.data.courses.data;
            this.sessions = data.data.sessions;
            this.query.total = data.data.total;

            hideLoader();

          } catch (e) {
            hideLoader();
            toast.error('Server Error')
          }
        },

        async getCoursesCached() {
          let self = this;
          try {

            showLoader();
            this.query.page = 1;
            const { data } = await axiosInstance.get(endpoints.courseRoute, { params: this.query });
            self.courses = data.data.courses.data;
            this.sessions = data.data.sessions;
            this.query.total = data.data.total;

            hideLoader();

          } catch (e) {
            hideLoader();
            toast.error('Server Error')
          }
        },
        async getCoursesLoadMore() {

            this.query.page++;
            let self = this;

            const { data } = await axiosInstance.get(endpoints.courseRoute, {params:this.query});

            Object.keys(data.data.sessions).forEach(function (session) {
                self.sessions[session] = data.data.sessions[session];
            });

            data.data.data.forEach(function (course) {
                self.courses.push(course);
            });

            this.query.total = data.data.total;

        },

        async getFilterContent() {

          try {

            showLoader();

            const { data } = await axiosInstance.get(endpoints.filterContent);
            this.categories = data.data.categories;
            this.educationBoards = data.data.boards;
            this.educationLevels = data.data.educationLevels;

            hideLoader();

          } catch (e) {

            hideLoader();
            toast.error("Server Error");

          }

        },
        queryCheck() {
            return !!(this.query.category_id ||
                this.query.status ||
                this.query.price ||
                this.query.role ||
                this.query.board_id ||
                this.query.education_level ||
                this.query.level ||
                this.query.language_id ||
                this.query.keyword ||
                this.query.rating);
        },
        clearFilter() {
            this.query.category_id = 0;
            this.query.status = "";
            this.query.price = "";
            this.query.role = "";
            this.query.board_id = 0;
            this.query.education_level = 0;
            this.query.level = 0;
            this.query.language_id = 0;
            this.query.keyword = "";
            this.query.rating = 0;
            this.getCourses();
        },
        showPrice() {
            console.log(this.query.priceRange);
        },
        async setRating(rating, id) {
            let formData = new FormData();
            formData.append("course_id", id);
            formData.append("rating", rating);
            await axiosInstance.post(endpoints.courseRatingRoute, formData);
        },
        async addToWishlist(id) {
            if (this.authenticated) {
                if (!this.userWishlist.includes(id)) {
                    this.userWishlist?.push(id);
                } else {
                    this.userWishlist = this.userWishlist.filter(
                        (_) => _ !== id
                    );
                }

                let formData = new FormData();
                formData.append("model_id", id);
                formData.append("model", "Course");
                const { data } = await axiosInstance.post(endpoints.addToWishlistRoute, formData);

                if (data.type === "success") {
                    // console.log(data);
                    // this.userWishlist = data.wishlist;
                }
            } else {
                toast.show("You're not logged.").goAway(1500);
            }
        },
        coursePurchased(id) {
            if (this.role === "tutor") {
                let index = this.ownCourses.findIndex((p) => p === id);
                return index <= -1;
            } else {
                let index = this.purchasedCourses.findIndex((p) => p === id);
                return index <= -1;
            }
        },
        checkCourseAdded(course) {
            if (this.added.length > 0) {
                let index = this.added.findIndex((c) => c.id === course.id);
                return index > -1;
            }
        },
        timeFormat(value) {
            if (value) {
                return moment(value).tz().format("hh:mm A");
            }
        },
        dateFormat(value) {
            return moment(value).tz().format("DD MMMM");
        },
        checkCourseStatus(course) {
            let today = moment().tz().format("YYYY-MM-DD");
            let time = moment().tz().format("HH:mm:ss");
            if (this.sessions[course.id][today]) {
                return !(time >= course.start_time && time <= course.end_time);
            } else {
                return true;
            }
        },
        joinSession(id) {
            document.getElementById("meeting-" + id).submit();
        },
        reportIt(id) {
            this.modal_id = id;
            this.reportModal = !this.reportModal;
        },
    },
};
</script>
