<template>
  <div class="modal" id="AppointmentsModal" style="display: block; background-color: rgba(0,0,0, 0.4); overflow: scroll; overflow-x: hidden;"  tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" >
    <div class="modal-dialog modal-xl modal-md modal-dialog-centered" role="document">
      <div class="modal-content parent_modal">
        <div class="modal-header border-0 col-md-12">
          <div class="nav nav-tabs border-0 custom-tabs col-md-10" role="tablist">
            <div class="col-md-2">
              <b>Transaction Id: </b>
            </div>
            <div class="col-md-10">
              <b> {{ selectedTransaction.trx_id }}</b>
            </div>
            <div class="col-md-2">
              <b> Bill From </b>
            </div>
            <div class="col-md-10">
              <b> ILMZone</b>
            </div>
          </div>
          <div class="col-md-2">
            <div class="col-md-12">
              <button v-on:click="$parent.transactionModal = !$parent.transactionModal" type="button" class="close cross_btn" data-dismiss="modal" aria-label="Close">
                <span class="cross_btn" aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="tab-content">
            <div id="invoice" class="tab-pane active modal1">
              <div class="responsive-scroll">
                <table class="table tables text-center table-style-01">
                  <thead class="thead-primary thead-primarys border-0">
                  <tr class="th">
                    <th v-if="selectedTransaction.courses.length > 0 && selectedTransaction.slots.length > 0"> Course Title / Instructor</th>
                    <th v-else-if="selectedTransaction.courses.length > 0">Course Title</th>
                    <th v-else-if="selectedTransaction.slots.length > 0">Instructor</th>
                    <th>Date</th>
                    <th>Price</th>
                  </tr>
                  </thead>
                  <tbody>

                  <template v-if="selectedTransaction.courses.length > 0">
                    <tr v-for="transaction in selectedTransaction.courses" :key="transaction">
                      <td>{{ transaction.course.title }}</td>
                      <td>{{ transaction.created_at }}</td>
                      <td>{{ transaction.course.price}} PKR</td>
                    </tr>
                  </template>

                  <template v-if="selectedTransaction.slots.length > 0">
                    <tr v-for="transaction in selectedTransaction.slots" :key="transaction">
                      <td>{{ transaction.slot.tutor.name }}</td>
                      <td>{{ transaction.created_at }}</td>
                      <td>{{ transaction.slot.price }} PKR</td>
                    </tr>
                  </template>

                  <tr class="receipt">
                    <td></td>
                    <td>Total</td>
                    <td>{{ selectedTransaction.amount }} PKR</td>
                  </tr>
                  <tr class="receipt">
                    <td></td>
                    <td>Total Paid</td>
                    <td>{{ selectedTransaction.amount }} PKR</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="mx-auto save-btn-container my-3 float-right">
                <div class="col-md-12 p-0 m-0">
                  <a href="javascript:void (0)"> <i class="fa fa-download"></i> Download PDF</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "TransactionDetailComponent",
  props: ['selectedTransaction'],
  data() {
    return {

    }
  },
  created() {
  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
