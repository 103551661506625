<template>
    <div style="height: 100%">
        <div class="calendar">
            <div class="container p-0">
                <div class="row">
                    <div class="col-lg-3 p-0">
                        <div class="sec-left">
                            <div class="left-header">
                                <div class="options">
                                    <div
                                        class="circle"
                                        style="
                                            background-color: #ed6b60;
                                            margin-right: 8px;
                                        "
                                    ></div>
                                    <div
                                        class="circle"
                                        style="
                                            background-color: #f5c250;
                                            margin-right: 8px;
                                        "
                                    ></div>
                                    <div
                                        class="circle"
                                        style="background-color: #62c656"
                                    ></div>
                                </div>
                                <div class="plus-btn">
                                    <a href="javascript:void (0)"> + </a>
                                </div>
                            </div>
                            <div class="month-year">
                                <h2>
                                    {{ month }}<span>{{ year }}</span>
                                </h2>
                                <div class="nxt-pre">
                                    <a
                                        v-on:click="lastMonth"
                                        href="javascript:void (0)"
                                    >
                                        <img
                                            src="@/assets/images/prev.png"
                                        />
                                    </a>
                                    <a
                                        v-on:click="nextMonth"
                                        href="javascript:void (0)"
                                    >
                                        <img
                                            src="@/assets/images/next.png"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div class="day-date">
                                <div class="d-flex">
                                    <span class="d-txt">
                                        <p>SUN</p>
                                    </span>
                                    <span class="d-txt">
                                        <p>MON</p>
                                    </span>
                                    <span class="d-txt">
                                        <p>TUE</p>
                                    </span>
                                    <span class="d-txt">
                                        <p>WED</p>
                                    </span>
                                    <span class="d-txt">
                                        <p>THU</p>
                                    </span>
                                    <span class="d-txt">
                                        <p>FRI</p>
                                    </span>
                                    <span class="d-txt">
                                        <p>SAT</p>
                                    </span>
                                </div>
                                <div class="d-flex">
                                    <!-- eslint-disable -->
                                    <span
                                        v-for="blank in firstDayOfMonth"
                                        class="d-txt"
                                    >
                                        <p></p>
                                    </span>
                                    <!-- eslint-disable -->
                                    <span
                                        v-for="date in daysInMonth"
                                        @click="openDay(date)"
                                        :class="{
                                            'current-d':
                                                date === initialDate &&
                                                month === initialMonth &&
                                                year === initialYear,
                                            'current-d': checkToday(
                                                date + '-' + month + '-' + year
                                            ),
                                        }"
                                    >
                                        <a href="javascript:void (0)">
                                            <p>{{ date }}</p>
                                        </a>
                                        <div
                                            class="dots"
                                            v-if="
                                                date === initialDate &&
                                                month === initialMonth &&
                                                year === initialYear
                                            "
                                        >
                                            <div
                                                class="circle"
                                                style="
                                                    background-color: #a855f7;
                                                "
                                            ></div>
                                            <div
                                                class="circle"
                                                style="
                                                    background-color: #3b82f6;
                                                "
                                            ></div>
                                            <div
                                                class="circle"
                                                style="
                                                    background-color: #2dd4bf;
                                                "
                                            ></div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="weather-info">
                                <div v-if="slots.length > 0" class="weather">
                                    <div class="d-flex">
                                        <p class="today">
                                            <strong>TODAY</strong>
                                            <!-- eslint-disable -->
                                            <span>{{
                                                new Date() | DDMMMM
                                            }}</span>
                                        </p>
                                    </div>
                                    <div></div>
                                    <div
                                        class="meeting-time"
                                        v-for="(slot, index) in slots"
                                    >
                                        <div
                                            class="circle"
                                            style="background-color: #3b82f6"
                                        ></div>
                                        <div>
                                            <p class="time">
                                                {{
                                                    slot.start_date_time | hhmmA
                                                }}
                                                -
                                                {{ slot.end_date_time | hhmmA }}
                                                <span
                                                    ><img
                                                        src="@/assets/images/video-cam.png"
                                                /></span>
                                            </p>
                                            <h3 v-if="slot.model === 'Session'">
                                                {{
                                                    slot.data.course.title
                                                        | cutText(0, 15)
                                                }}
                                                (Course)
                                            </h3>
                                            <h3 v-else>
                                                {{ slot.data.slot.tutor.name }}
                                                (Tutor)
                                            </h3>
                                        </div>
                                    </div>
                                    <a
                                        href="javascript:void(0)"
                                        v-if="
                                            todayMeetingSlots.length >
                                            todayLimit
                                        "
                                        v-on:click="todayLimit += 5"
                                        >See More...</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9 p-0">
                        <div class="sec-right">
                            <div class="right-header">
                                <div class="selct-day">
                                    <button
                                        :disabled="0 === calendar_view_index"
                                        v-on:click="
                                            changeWeeklyView(
                                                calendar_view_index - 1
                                            )
                                        "
                                    >
                                        <img
                                            src="@/assets/images/prev-dark.png"
                                        />
                                    </button>
                                    <span>{{
                                        calendar_view | capitalize
                                    }}</span>
                                    <button
                                        :disabled="
                                            calendar_views.length - 1 ===
                                            calendar_view_index
                                        "
                                        v-on:click="
                                            changeWeeklyView(
                                                calendar_view_index + 1
                                            )
                                        "
                                    >
                                        <img
                                            src="@/assets/images/next-dark.png"
                                        />
                                    </button>
                                </div>
                                <div class="calendar-tabs">
                                    <span>
                                        <a
                                            href="javascript:void (0)"
                                            v-on:click="
                                                calendar_view = 'weekly'
                                            "
                                            :class="
                                                calendar_view === 'weekly'
                                                    ? 'active'
                                                    : ''
                                            "
                                            >Week</a
                                        >
                                    </span>
                                    <span>
                                        <a
                                            href="javascript:void (0)"
                                            v-on:click="
                                                calendar_view = 'monthly'
                                            "
                                            :class="
                                                calendar_view === 'monthly'
                                                    ? 'active'
                                                    : ''
                                            "
                                            >Month</a
                                        >
                                    </span>
                                </div>
                            </div>
                            <div
                                v-if="calendar_view === 'weekly'"
                                class="calendar-scroll"
                            >
                                <div class="cl-container">
                                    <div class="row">
                                        <div
                                            v-for="(week, index) in weekdays"
                                            class="day holiday thurs holiday border-right-0"
                                        >
                                            <p>{{ week }}</p>
                                            <h3>{{ weekDates[index] }}</h3>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div
                                            class="day"
                                            style="height: 20px"
                                        ></div>
                                        <div
                                            class="day"
                                            style="height: 20px"
                                        ></div>
                                        <div
                                            class="day"
                                            style="height: 20px"
                                        ></div>
                                        <div
                                            class="day"
                                            style="height: 20px"
                                        ></div>
                                        <div
                                            class="day"
                                            style="height: 20px"
                                        ></div>
                                        <div
                                            class="day"
                                            style="height: 20px"
                                        ></div>
                                        <div
                                            class="day"
                                            style="height: 20px"
                                        ></div>
                                    </div>
                                    <div class="row">
                                        <div
                                            v-for="(date, index) in weekDates"
                                            class="day"
                                        >
                                            <div
                                                v-for="slot in arrangedSlots[
                                                    date
                                                ]"
                                                class="day thurs"
                                            >
                                                <div class="join">
                                                    <p>
                                                        <span
                                                            v-on:click="
                                                                showDetailView(
                                                                    slot.id
                                                                )
                                                            "
                                                            class="name"
                                                            v-if="
                                                                slot.model ===
                                                                'Session'
                                                            "
                                                            :title="
                                                                slot.data.course
                                                                    .title
                                                            "
                                                            >{{
                                                                slot.data.course
                                                                    .title
                                                                    | cutText(
                                                                        12,
                                                                        "..."
                                                                    )
                                                            }}</span
                                                        >
                                                        <span
                                                            v-on:click="
                                                                showDetailView(
                                                                    slot.id
                                                                )
                                                            "
                                                            class="name"
                                                            v-else
                                                            >{{
                                                                slot.data.slot
                                                                    .tutor.name
                                                            }}</span
                                                        >
                                                        <br />
                                                        <span
                                                            v-on:click="
                                                                showDetailView(
                                                                    slot.id
                                                                )
                                                            "
                                                            class="time time_slot"
                                                            >{{
                                                                slot.start_date_time
                                                                    | hhmmA
                                                            }}
                                                            -
                                                            {{
                                                                slot.end_date_time
                                                                    | hhmmA
                                                            }}</span
                                                        >
                                                        <star-rating
                                                            :show-rating="false"
                                                            :read-only="
                                                                slot.rating > 0
                                                            "
                                                            v-model="
                                                                slot.rating
                                                            "
                                                            :increment="0.25"
                                                            @rating-selected="
                                                                updateRating(
                                                                    slot
                                                                )
                                                            "
                                                            v-if="
                                                                checkSlotDisputeSlot(
                                                                    slot.id
                                                                ) &&
                                                                slot.model ===
                                                                    'Slot'
                                                            "
                                                            id="Excellent"
                                                            v-bind:star-size="
                                                                18
                                                            "
                                                        ></star-rating>
                                                    </p>

                                                    <div class="button-event">
                                                        <button
                                                            v-if="
                                                                checkSlotTime(
                                                                    slot.id
                                                                ) === 'join'
                                                            "
                                                            v-on:click="
                                                                joinSession(
                                                                    slot.id,
                                                                    slot.model
                                                                )
                                                            "
                                                            :disabled="
                                                                !checkAppointmentTime(
                                                                    slot.id
                                                                )
                                                            "
                                                            class="calendar-join-button"
                                                        >
                                                            <img
                                                                src="@/assets/images/video-cam-6.png"
                                                            />Join
                                                        </button>
                                                        <button
                                                            v-if="
                                                                checkSlotTimeDifference(
                                                                    slot.id
                                                                ) &&
                                                                slot.model ===
                                                                    'Slot'
                                                            "
                                                            :disabled="
                                                                checkSlotTime(
                                                                    slot.id
                                                                ) !== true
                                                            "
                                                            v-on:click="
                                                                rescheduleRequest(
                                                                    slot
                                                                )
                                                            "
                                                            class="reschedule-event btn-primary my-1"
                                                        >
                                                            Reschedule
                                                        </button>
                                                        <button
                                                            v-if="
                                                                checkSlotTimeDifference(
                                                                    slot.id
                                                                ) &&
                                                                slot.model ===
                                                                    'Slot'
                                                            "
                                                            :disabled="
                                                                checkSlotTime(
                                                                    slot.id
                                                                ) !== true
                                                            "
                                                            v-on:click="
                                                                cancelRequest(
                                                                    slot.id
                                                                )
                                                            "
                                                            class="cancel-event"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-else-if="calendar_view === 'monthly'"
                                class="month-tab"
                            >
                                <div class="week-days">
                                    <span v-for="day in weekdays">{{
                                        day
                                    }}</span>
                                </div>
                                <div class="calendar-date">
                                    <span
                                        v-for="blank in firstDayOfMonth"
                                        class="border-left-0 border-bottom-0 plan"
                                    ></span>
                                    <span
                                        v-for="date in daysInMonth"
                                        @click="openDay(date)"
                                        class="border-left-0 border-bottom-0"
                                        :class="{
                                            today:
                                                date === initialDate &&
                                                month === initialMonth &&
                                                year === initialYear,
                                        }"
                                        style="
                                            width: 100%;
                                            height: auto;
                                            left: 0px;
                                            padding-left: 2px;
                                        "
                                    >
                                        {{ date }}
                                        <div
                                            v-for="(
                                                slot, index
                                            ) in checkSlotEvent(date)"
                                            :class="{
                                                scnd: index === 1,
                                                thrd: index === 2,
                                                'calendar-appointment-span':
                                                    slot.model === 'Slot',
                                                'calendar-session-span':
                                                    slot.model === 'Session',
                                            }"
                                            class=""
                                        >
                                            <p
                                                style="
                                                    color: #0369a1;
                                                    font-size: 12px;
                                                    line-height: 16px;
                                                    font-family: 'Inter',
                                                        sans-serif;
                                                    margin-bottom: 0px;
                                                    text-align: left;
                                                    padding: 0 2px;
                                                "
                                            >
                                                <span
                                                    v-on:click="
                                                        showDetailView(slot.id)
                                                    "
                                                    class="name"
                                                    v-if="
                                                        slot.model === 'Session'
                                                    "
                                                    :title="
                                                        slot.data.course.title
                                                    "
                                                    >{{
                                                        slot.data.course.title
                                                            | cutText(15, "...")
                                                    }}</span
                                                >
                                                <span
                                                    v-on:click="
                                                        showDetailView(slot.id)
                                                    "
                                                    class="name"
                                                    v-else
                                                    >{{
                                                        slot.data.slot.tutor
                                                            .name
                                                    }}</span
                                                >
                                                <br />
                                                <span
                                                    v-on:click="
                                                        showDetailView(slot.id)
                                                    "
                                                    class="time"
                                                    >{{
                                                        slot.start_date_time
                                                            | hhmmA
                                                    }}
                                                    -{{
                                                        slot.end_date_time
                                                            | hhmmA
                                                    }}</span
                                                >
                                                <star-rating
                                                    :show-rating="false"
                                                    :read-only="slot.rating > 0"
                                                    v-model="slot.rating"
                                                    :increment="0.25"
                                                    @rating-selected="
                                                        updateRating(slot)
                                                    "
                                                    v-if="
                                                        checkSlotDisputeSlot(
                                                            slot.id
                                                        ) &&
                                                        slot.model === 'Slot'
                                                    "
                                                    id="Excellent"
                                                    v-bind:star-size="18"
                                                ></star-rating>
                                            </p>
                                            <form
                                                @submit.prevent=""
                                                v-if="
                                                    checkSlotTime(slot.id) ===
                                                    'join'
                                                "
                                                target="_blank"
                                                action="/slot/initiate-bbb"
                                                method="post"
                                                :id="'meeting-' + slot.id"
                                                class=""
                                            >
                                                <input
                                                    type="hidden"
                                                    name="slot_id"
                                                    :id="'slot-id' + slot.id"
                                                    v-model="slot.id"
                                                />
                                            </form>
                                            <div class="button-event">
                                                <button
                                                    v-if="
                                                        checkSlotTime(
                                                            slot.id
                                                        ) === 'join'
                                                    "
                                                    :disabled="
                                                        !checkAppointmentTime(
                                                            slot.id
                                                        )
                                                    "
                                                    v-on:click="
                                                        joinSession(
                                                            slot.id,
                                                            slot.model
                                                        )
                                                    "
                                                    class="calendar-join-button"
                                                >
                                                    <img
                                                        src="@/assets/images/video-cam-6.png"
                                                    />
                                                    Join
                                                </button>
                                                <button
                                                    v-if="
                                                        checkSlotTimeDifference(
                                                            slot.id
                                                        ) &&
                                                        slot.model === 'Slot'
                                                    "
                                                    :disabled="
                                                        checkSlotTime(
                                                            slot.id
                                                        ) !== true
                                                    "
                                                    v-on:click="
                                                        rescheduleRequest(slot)
                                                    "
                                                    class="reschedule-event btn-primary my-1"
                                                >
                                                    Reschedule
                                                </button>
                                                <button
                                                    v-if="
                                                        checkSlotTimeDifference(
                                                            slot.id
                                                        ) &&
                                                        slot.model === 'Slot'
                                                    "
                                                    :disabled="
                                                        checkSlotTime(
                                                            slot.id
                                                        ) !== true
                                                    "
                                                    v-on:click="
                                                        cancelRequest(slot.id)
                                                    "
                                                    class="cancel-event"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="modal"
                v-if="showDetail"
                id="AppointmentsModal"
                style="
                    display: block;
                    background-color: rgba(0, 0, 0, 0.4);
                    overflow: scroll;
                    overflow-x: hidden;
                "
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div
                    class="modal-dialog modal-md modal-dialog-centered"
                    role="document"
                >
                    <div
                        class="modal-content parent_modal"
                        style="background-image: none !important"
                    >
                        <div class="modal-header py-1">
                            <h5 class="modal-title">Session Details</h5>
                            <button
                                v-on:click="showDetail = !showDetail"
                                type="button"
                                class="close cross_btn"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span class="cross_btn" aria-hidden="true"
                                    >&times;</span
                                >
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="tab-content">
                                <div
                                    id="scheduleDaily"
                                    class="tab-pane active modal1"
                                >
                                    <h5
                                        class="modal-title"
                                        v-if="selectedSlot.model === 'Slot'"
                                    >
                                        {{ selectedSlot.data.user.name }}
                                    </h5>
                                    <a
                                        v-else-if="
                                            selectedSlot.model === 'Session'
                                        "
                                        :href="
                                            '/course/' +
                                            selectedSlot.data.course.uuid
                                        "
                                        ><h5 class="modal-title">
                                            {{ selectedSlot.data.course.title }}
                                        </h5></a
                                    >
                                    <div class="course-requirements">
                                        <h5
                                            v-if="
                                                selectedSlot.model === 'Session'
                                            "
                                        >
                                            Students
                                        </h5>
                                        <div
                                            v-for="student in selectedSlot.data
                                                .students"
                                            class="requirement-item"
                                        >
                                            <span class="purple-bullet"
                                                >&nbsp;</span
                                            >
                                            <span class="requirement-t">{{
                                                slot.data.slot.tutor.name
                                            }}</span>
                                        </div>
                                    </div>
                                    <p>
                                        {{
                                            selectedSlot.start_date_time | hhmmA
                                        }}
                                        -
                                        {{ selectedSlot.end_date_time | hhmmA }}
                                    </p>
                                    <hr />
                                    <div
                                        class="time"
                                        v-if="!!selectedSlot.refund"
                                    >
                                        <h5>Refund</h5>
                                        <span>Refund Requested</span>
                                        <br />
                                        <a
                                            class="text-12"
                                            v-if="
                                                selectedSlot.refund.status ===
                                                'pending'
                                            "
                                            v-on:click="
                                                cancelRefundRequest(
                                                    selectedSlot.refund.id
                                                )
                                            "
                                            href="javascript:void (0)"
                                            >Cancel Request</a
                                        >
                                        <br
                                            v-if="
                                                selectedSlot.refund.status ===
                                                'pending'
                                            "
                                        />
                                        <span
                                            >Status:
                                            {{
                                                selectedSlot.refund.status
                                            }}</span
                                        >
                                        <br />
                                        <span
                                            v-if="!!selectedSlot.refund"
                                            class="time"
                                            ><router-link
                                                :to="
                                                    '/student/refund/' +
                                                    selectedSlot.refund.id
                                                "
                                                href="javascript:void(0)"
                                                >Details</router-link
                                            ></span
                                        >
                                        <hr />
                                    </div>
                                    <br />
                                    <div
                                        class="time"
                                        v-if="!!selectedSlot.dispute"
                                    >
                                        <h5>Dispute</h5>
                                        <span>Dispute Initiated</span>
                                        <br />
                                        <a
                                            class="text-12"
                                            v-if="
                                                selectedSlot.dispute.status ===
                                                'pending'
                                            "
                                            v-on:click="
                                                cancelDisputeRequest(
                                                    selectedSlot.dispute.id
                                                )
                                            "
                                            href="javascript:void (0)"
                                            >Cancel Request</a
                                        >
                                        <br
                                            v-if="
                                                selectedSlot.dispute.status ===
                                                'pending'
                                            "
                                        />
                                        <span
                                            >Status:
                                            {{ selectedSlot.dispute.status }}
                                        </span>
                                        <br />
                                        <span
                                            v-if="!!selectedSlot.dispute"
                                            class="time"
                                            ><router-link
                                                :to="
                                                    '/student/dispute/' +
                                                    selectedSlot.dispute.id
                                                "
                                                href="javascript:void(0)"
                                                >Details</router-link
                                            ></span
                                        >
                                        <hr />
                                    </div>
                                    <div
                                        class="row"
                                        style="justify-content: flex-end"
                                    >
                                        <button
                                            v-if="
                                                checkSlotTime(
                                                    selectedSlot.id
                                                ) === 'join'
                                            "
                                            v-on:click="
                                                joinSession(
                                                    selectedSlot.id,
                                                    selectedSlot.model
                                                )
                                            "
                                            :disabled="
                                                !checkAppointmentTime(
                                                    selectedSlot.id
                                                )
                                            "
                                            class="btn btn-primary schedule-btn-calendar ml-2"
                                        >
                                            Join
                                        </button>
                                        <button
                                            v-if="
                                                checkSlotTimeDifference(
                                                    selectedSlot.id
                                                ) &&
                                                selectedSlot.model === 'Slot'
                                            "
                                            :disabled="
                                                checkSlotTime(
                                                    selectedSlot.id
                                                ) !== true
                                            "
                                            v-on:click="
                                                cancelRequest(
                                                    selectedSlot.slot.id
                                                )
                                            "
                                            class="btn btn-danger schedule-btn-calendar ml-2"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            v-if="
                                                checkSlotTimeDifference(
                                                    selectedSlot.id
                                                ) &&
                                                selectedSlot.model === 'Slot'
                                            "
                                            :disabled="
                                                checkSlotTime(
                                                    selectedSlot.id
                                                ) !== true
                                            "
                                            v-on:click="
                                                rescheduleRequest(selectedSlot)
                                            "
                                            class="btn btn-primary schedule-btn-calendar ml-2"
                                        >
                                            Reschedule
                                        </button>
                                        <button
                                            v-if="
                                                !selectedSlot.dispute &&
                                                checkSlotDisputeSlot(
                                                    selectedSlot.id
                                                ) &&
                                                selectedSlot.model === 'Slot'
                                            "
                                            :disabled="
                                                checkSlotTime(
                                                    selectedSlot.id
                                                ) !== true
                                            "
                                            v-on:click="
                                                getDisputeDetail(selectedSlot)
                                            "
                                            class="btn cancel-event btn-danger"
                                            style="background: #dc3545"
                                        >
                                            Create Dispute
                                        </button>
                                        <button
                                            v-if="
                                                !selectedSlot.refund &&
                                                checkSlotRefundSlot(
                                                    selectedSlot.id
                                                ) &&
                                                selectedSlot.model === 'Slot'
                                            "
                                            :disabled="
                                                checkSlotTime(
                                                    selectedSlot.id
                                                ) !== true
                                            "
                                            v-on:click="
                                                getRefundRequestDetail(
                                                    selectedSlot
                                                )
                                            "
                                            class="btn cancel-event"
                                        >
                                            Create Refund
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ReScheduleModalComponent
                v-if="rescheduleModal"
                model="Calendar"
            ></ReScheduleModalComponent>
            <RefundScheduledSlotComponent
                v-if="refundModal"
                :refundDetail="refundDetail"
            ></RefundScheduledSlotComponent>
            <DisputeDetailComponent
                v-if="disputeModal"
                :refundDetail="refundDetail"
            ></DisputeDetailComponent>
            <RefundRequestDetailComponent
                v-if="refundDetailModal"
                :refundDetail="transaction"
            ></RefundRequestDetailComponent>
            <ReviewFormComponent
                v-if="showFeedBackModal"
                :slot_id="selectedSlot.id"
                :model_id="selectedSlot.data.slot.tutor_id"
                model="Slot"
            ></ReviewFormComponent>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";
import StarRating from "vue-star-rating";
import ReScheduleModalComponent from "@/components/tutor/sessions/ReScheduleModalComponent";
import RefundScheduledSlotComponent from "@/components/tutor/sessions/RefundScheduledSlotComponent";
import DisputeDetailComponent from "@/components/tutor/sessions/DisputeDetailComponent";
import RefundRequestDetailComponent from "@/components/student/billings/RefundRequestDetailComponent";
import ReviewFormComponent from "../../utilities/review/ReviewFormComponent";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";
import {hideLoader, showLoader} from "@/assets/js/swal";

export default {
    name: "ScheduleComponent",
    components: {
        ReScheduleModalComponent,
        RefundScheduledSlotComponent,
        DisputeDetailComponent,
        RefundRequestDetailComponent,
        StarRating,
        ReviewFormComponent,
    },
    data() {
        return {
            transaction: {},
            todayLimit: 5,
            weeklyLimit: 5,
            disputes: [],
            refunds: [],
            session: {},
            refundDetail: {},
            showFeedBackModal: false,
            refundDetailModal: false,
            disputeModal: false,
            refundModal: false,
            rescheduleModal: false,
            showDetail: false,
            slots: [],
            selectedSlot: {},
            selected_date: new Date(),
            minDate: new Date(),
            attrs: [
                {
                    key: "today",
                    highlight: {
                        color: "indigo",
                        fillMode: "solid",
                    },
                    dates: new Date(),
                },
            ],
            weekdays: moment.weekdays(),
            weekDates: [],
            days: [],
            calendar_view_index: 0,
            calendar_view: "weekly",
            calendar_views: ["monthly", "weekly"],
            months: [],
            today: moment(),
            rescheduleSlot: {},
            dateContext: moment(),
            startOfWeek: [],
            endOfWeek: [],
            arrangedSlots: [],
            todayMeetingSlots: [],
            tomorrowMeetingSlots: [],
        };
    },
    async created() {
        await this.getSlots();
        await this.getYearsList();
    },
    mounted() {},
    computed: {
        year: function () {
            let t = this;
            return t.dateContext.format("YYYY");
        },
        month: function () {
            let t = this;
            return t.dateContext.format("MMMM");
        },
        daysInMonth: function () {
            let t = this;
            return t.dateContext.daysInMonth();
        },
        currentDate: function () {
            let t = this;
            return t.dateContext.get("date");
        },
        firstDayOfMonth: function () {
            let t = this;
            let firstDay = moment(t.dateContext).subtract(
                t.currentDate - 1,
                "days"
            );
            return firstDay.weekday();
        },
        initialDate: function () {
            let t = this;
            return t.today.get("date");
        },
        initialMonth: function () {
            let t = this;
            return t.today.format("MMMM");
        },
        initialYear: function () {
            let t = this;
            return t.today.format("YYYY");
        },
    },
    methods: {
        joinSession(data, model) {
            this.joinZoomPersonalSession(data, model);
        },
        async joinZoomPersonalSession(session_id, model) {
            let formData = new FormData();
            formData.append("session_id", session_id);
            formData.append("model", model);
            const { data } = await axiosInstance.post(endpoints.zoomSessionInitiateRoute, formData);
            if (data.type === "success") {
                if (data.medium === "Zoom") window.open(data.contact, "_blank");
            } else {
                toast.error(data.message);
            }
        },
        async getSlots() {
            try {

              showLoader();

              let query = {};
              query.month = moment(this.dateContext).format("MM");
              const { data } = await axiosInstance.get(endpoints.getBookedSlotsRoute, {params: query});
              this.slots = data.data;
              this.openDay(new Date());

              hideLoader();

            } catch (e) {

              hideLoader();

              toast.error('Server Error');
            }
        },
        checkToday(date) {
            return date === moment().format("DD-MMMM-YYYY");
        },
        openDay(date) {
            let self = this;
            this.startOfWeek = moment(
                date + " " + this.month + " " + this.year
            ).startOf("week");
            this.endOfWeek = moment(
                date + " " + this.month + " " + this.year
            ).endOf("week");
            self.dates = date + " " + this.month + " " + this.year;
            this.weekListing(this.startOfWeek, this.endOfWeek);
        },
        weekListing(startOfWeek, endOfWeek) {
            let days = [];
            let self = this;
            let day = startOfWeek;
            let weekDays = [];
            let slots_ = [];
            while (day <= endOfWeek) {
                slots_[moment(day.toDate()).format("DD")] = [];
                slots_[moment(day.toDate()).format("DD")] = self.slots.filter(
                    (slot) =>
                        moment(slot.start_date_time).format("YYYY-MM-DD") ===
                        moment(day.toDate()).format("YYYY-MM-DD")
                );
                days.push(day.toDate());
                weekDays.push(moment(day.toDate()).format("DD"));
                day = day.clone().add(1, "d");
            }
            self.todayMeetingSlots = self.slots.filter(
                (slot) =>
                    moment(slot.start_date_time).format("YYYY-MM-DD") ===
                    moment().format("YYYY-MM-DD")
            );
            console.log("Today Meeting Slots", self.todayMeetingSlots);
            self.tomorrowMeetingSlots = self.slots.filter(
                (slot) =>
                    moment(slot.start_date_time).format("YYYY-MM-DD") ===
                    moment().add(1, "day").format("YYYY-MM-DD")
            );
            self.arrangedSlots = slots_;
            this.weekDates = weekDays;

            this.start_date_week = moment(days[0]).format("YYYY-MM-DD");
            this.end_date_week = moment(days[6]).format("YYYY-MM-DD");
        },
        nextMonth() {
            let t = this;
            t.dateContext = moment(t.dateContext).add(1, "month");
            this.getSlots();
        },
        lastMonth() {
            let t = this;
            t.dateContext = moment(t.dateContext).subtract(1, "month");
            this.getSlots();
        },
        changeWeeklyView(index) {
            this.calendar_view_index = index;
            this.calendar_view = this.calendar_views[index];
        },
        getYearsList() {
            const currentYear = new Date().getFullYear();
            const range = (start, stop, step) =>
                Array.from(
                    { length: (stop - start) / step + 1 },
                    (_, i) => start + i * step
                );
            this.years = range(currentYear, currentYear - 80, -1);
        },
        checkSlotEvent(date) {
            let self = this;
            date = moment(date + " " + this.month + " " + this.year).format(
                "DD-MM-YYYY"
            );
            return self.slots.filter(
                (slot) =>
                    moment(slot.start_date_time).format("DD-MM-YYYY") === date
            );
        },
        checkSlotTime(id) {
            let index = this.slots.findIndex((s) => s.id === id);
            let slot = this.slots[index];
            let hours = moment(slot.start_date_time).diff(moment(), "hours");
            if (Math.sign(hours) === -1) return true;
            else return hours > 24 ? true : "join";
        },
        checkSlotTimeDifference(id) {
            let index = this.slots.findIndex((s) => s.id === id);
            let slot = this.slots[index];
            return moment(slot.start_date_time).diff(moment(), "hours") > 24;
        },
        checkDisputeRefundMessage(id) {
            let slot = this.slots.find((s) => s.id === id);
            if (!!slot["dispute"] && !!slot["refund"]) {
                if (
                    slot["dispute"].status === "resolved" &&
                    slot["refund"].status === "resolved"
                ) {
                    return "Refund & Dispute Resolved";
                } else if (
                    slot["dispute"].status === "pending" &&
                    slot["refund"].status === "pending"
                ) {
                    return "Refund & Dispute Pending State";
                } else if (
                    slot["dispute"].status === "denied" &&
                    slot["refund"].status === "denied"
                ) {
                    return "Refund & Dispute Denied";
                } else {
                    if (slot["dispute"].status === "resolved") {
                        return "Dispute Resolved";
                    } else if (slot["dispute"].status === "pending") {
                        return "Dispute In Pending State";
                    } else if (slot["dispute"].status === "open") {
                        return "Dispute In Open State";
                    } else {
                      console.log('empty')
                    }

                    if (slot["refund"].status === "resolved") {
                        return "Refund Resolved";
                    } else if (slot["refund"].status === "denied") {
                        return "Refund Denied";
                      /* eslint-disable */
                    } else if (slot["refund"].status === "resolved") {
                        return "Refund Resolved";
                    } else if (slot["refund"].status === "pending") {
                        return "Refund In Pending State";
                    } else {
                      console.log('empty')
                    }
                }
            } else if (!!slot["refund"]) {
                if (slot["refund"].status === "resolved") {
                    return "Refund Resolved";
                } else if (slot["refund"].status === "denied") {
                    return "Refund Denied";
                } else if (slot["refund"].status === "resolved") {
                    return "Refund Resolved";
                } else if (slot["refund"].status === "pending") {
                    return "Refund In Pending State";
                }
            } else if (!!slot["dispute"]) {
                if (slot["dispute"].status === "resolved") {
                    return "Dispute Resolved";
                } else if (slot["dispute"].status === "pending") {
                    return "Dispute In Pending State";
                } else if (slot["dispute"].status === "open") {
                    return "Dispute In Open State";
                } else if (slot["dispute"].status === "denied") {
                    return "Dispute denied";
                }
            } else {
                return "";
            }
        },
        checkSlotDisputeSlot(id) {
            let slot = this.slots.find((s) => s.id === id);
            if (moment(slot.start_date_time).diff(moment(), "hours") < 0) {
                let disputes = slot["dispute"];
                let refunds = slot["refund"];
                if (!!slot["refund"] && refunds.status === "resolved") {
                    return false;
                } else if (
                    !!slot["dispute"] &&
                    (disputes.status === "denied" ||
                        disputes.status === "resolved")
                ) {
                    return false;
                }
                return true;
            }
            return false;
        },
        checkSlotRefundSlot(id) {
            let slot = this.slots.find((s) => s.id === id);
            if (moment(slot.start_date_time).diff(moment(), "hours") < 0) {
                let disputes = slot["dispute"];
                let refunds = slot["refund"];
                if (!!refunds && !!disputes) {
                    if (
                        disputes.status === "resolved" ||
                        refunds.status === "resolved"
                    ) {
                        return false;
                    } else {
                        if (
                            !!slot["dispute"] &&
                            (disputes.status === "denied" ||
                                disputes.status === "resolved")
                        ) {
                            return false;
                        }
                        if (
                            !!slot["refund"] &&
                            (refunds.status === "resolved" ||
                                refunds.status === "denied")
                        ) {
                            return false;
                        }
                    }
                } else {
                    if (
                        !!slot["dispute"] &&
                        (disputes.status === "denied" ||
                            disputes.status === "resolved")
                    ) {
                        return false;
                    } else if (
                        !!slot["refund"] &&
                        (refunds.status === "resolved" ||
                            refunds.status === "denied")
                    ) {
                        return false;
                    }
                }
                return true;
            }
            return false;
        },
        checkAppointmentTime(id) {
            // return false
            let index = this.slots.findIndex((s) => s.id === id);
            let data = this.slots[index];
            return (
                moment().format() >= moment(data.start_date_time).format() &&
                moment().format() <= moment(data.end_date_time).format()
            );
        },
        showDetailView(id) {
            this.selectedSlot = this.slots.find((s) => s.id === id);
            this.showDetail = !this.showDetail;
        },
        async checkIfUserCanCancleOrReschedule() {
            const { canCancelOrReschedule } =
                await checkCancelOrReschedulingRoute.list();
            return canCancelOrReschedule;
        },
        async cancelRequest(id) {
            let self = this;
            let formData = new FormData();
            formData.append("slot_id", id);
            let response = await this.checkIfUserCanCancleOrReschedule();
            if (!response) {
                this.$toasted.error(
                    "you have already exceeded the limit of cancel or reschedule slot"
                );
                return;
            }
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Cancel it!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const { data } = await cancelBookedSlotRoute.post(formData);
                    if (data.type === "success") {
                        this.$toasted.success(data.message);
                        self.showDetail = false;
                        await this.getSlots();
                    } else if (data.type === "error") {
                        this.$toasted.error(data.message);
                    }
                }
            });
        },
        checkSelectedDate(selectedDate, date, month, year) {
            let d = moment(date + " " + month + " " + year).format();
            let s = moment(selectedDate).format();
            return d === s;
        },
        async reScheduleSession(s) {
            let self = this;
            self.session = s;
            self.session.start_time = moment(s.start_date_time).format("HH:mm");
            self.session.end_time = moment(s.end_date_time).format("HH:mm");
            self.session.date = moment(s.start_date_time).format("YYYY-MM-DD");
            this.rescheduleModal = !this.rescheduleModal;
        },
        async rescheduleRequest(slot) {
            this.rescheduleSlot = slot;
            let response = await this.checkIfUserCanCancleOrReschedule();
            if (!response) {
                toast.error(
                    "you have already exceeded the limit of cancel or reschedule slot"
                );
                return;
            }

            let hours = moment(slot.start_date_time).diff(moment(), "hours");
            if (hours > 1) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes!",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.rescheduleModal = true;
                        localStorage.setItem("dependent_id", "0");
                    }
                });
            }
        },
        async getDisputeDetail(slot) {
            let formData = new FormData();
            formData.append("slot_id", slot.id);
            formData.append("model", "Slot");
            const { data } = await axiosInstance.post(endpoints.getDisputeDetailRoute, formData);
            if (data.type === "success") {
                this.refundDetail = data;
                this.refundDetail.model = slot.model;
                this.disputeModal = !this.disputeModal;
                this.showDetail = !this.showDetail;
            } else {
                this.$toasted.success(data.message);
            }
        },
        async getRefundRequestDetail(slot) {
            let formData = new FormData();
            formData.append("slot_id", slot.id);
            formData.append("model", "Slot");
            const { data } = await axiosInstance.post(endpoints.getRefundDetailRoute, formData);
            if (data.type === "success") {
                this.refundDetail = data;
                this.refundDetail.model = slot.model;
                this.refundModal = !this.refundModal;
                this.showDetail = !this.showDetail;
            } else {
                this.$toasted.error(data.message);
            }
        },
        async showDetails(slot_id) {
            let formData = new FormData();
            formData.append("slot_id", slot_id);
            formData.append("model", "Slot");
            const { data } = await axiosInstance.post(endpoints.getRefundDetailRoute, formData);
            this.transaction = data.refund;
            this.refundDetailModal = !this.refundDetailModal;
        },
        async cancelDisputeRequest(id) {
            let formData = new FormData();
            formData.append("dispute_id", id);
            formData.append("model", "Slot");
            formData.append("status", "closed");
            const { data } = await axiosInstance.post(endpoints.cancelDisputeRequestRoute, formData);
            await this.getSlots();
        },
        async cancelRefundRequest(id) {
            let formData = new FormData();
            formData.append("refund_id", id);
            formData.append("model", "Slot");
            formData.append("status", "closed");
            await axiosInstance.post(endpoints.cancelRefundRequestRoute, formData);
            await this.getSlots();
        },
        updateRating(tutor) {
            this.selectedSlot = tutor;
            this.showFeedBackModal = !this.showFeedBackModal;
        },
    },
};
</script>

<style scoped>

.calendar-appointment-span {
    background: rgba(139, 92, 246, 0.1);
    border-radius: 5px;
    width: 110%;
    margin-bottom: 5px;
    position: relative;
    padding-left: 8px;
    padding-bottom: 4px;
}
.calendar-appointment-span::before {
    content: "";
    position: absolute;
    width: 3px;
    height: 100%;
    background: #8b5cf6;
    top: 0;
    left: 0px;
    z-index: 9;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.calendar-session-span span.time,
.calendar-session-span span.name {
    color: #0369a1 !important;
    font-weight: 500;
}
h5 {
    color: #812bb2 !important;
}
.requirement-item {
    margin-bottom: 7px;
    display: flex;
    align-items: center;
}
.course-requirements .requirement-item .purple-bullet {
    background-color: #812bb2;
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 18px;
}
button.btn.cancel-event {
    width: 62px;
    background: #0369a1;
    border-radius: 3px;
    color: #ffffff;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    margin-left: 2px;
    border: none;
}
.time_slot {
    font-size: 10px !important;
}
@media (max-width: 1500px) {
    .time_slot {
        font-size: 9px !important;
    }
}
@media (max-width: 1377px) and (min-width: 1192px) {
    .time_slot {
        font-size: 8px !important;
    }
}
button.btn.cancel-event {
    width: 100px;
}
</style>
