<template>
  <div>
    <div class="container_home_section tutor-dashboard-newtheme">
      <div class="row">
        <div class="col-xl-8 main-col">
          <div class="row">
            <div class="profile-perc-box col-md-4 col-12">
              <progress-bar
                :value="user.profile_complete"
                :options="options" class="ring-width">
              </progress-bar>
              <div class="align-self-center">
                <div class="parent_user_info ">
                  <h6>{{ user.name }}</h6>
                  <p class="m-0">{{ capitalizeFirstLetter(role) }}</p>
                  <router-link v-if="user.profile_complete !== 100" to="/tutor/profile">
                    <small class="text-danger">Your profile need to be Completed</small>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-md-8 col-12">
              <div class="tutordash-sales-report mb-3">
                <div class="rows head">
                  <h3>Earnings</h3>
                </div>

              </div>
              <div class="hide-total_earning_parent">
                <div class="total_earning_parent">

                  <div class="rows">
                    <div class="coursesdetail">
                      <div class="">
                        <img src="@/assets/new-theme-images/incomed.png" alt="" />
                      </div>
                      <p class="total_text pl-2 m-0">Earning YTD</p>
                    </div>
                    <h5 class="total_text"> {{ wallet.earnings }} PKR </h5>
                  </div>

                  <div class="rows">
                    <div class="coursesdetail">
                      <div class="">
                        <img src="@/assets/new-theme-images/bill.png" alt="" />
                      </div>
                      <p class="total_text pl-2 m-0">Withdrawn YTD</p>
                    </div>
                    <h5 class="total_text"> {{ wallet.withdraws }} PKR </h5>
                  </div>

                  <div class="rows">
                    <div class="coursesdetail">
                      <div class="">
                        <img src="@/assets/new-theme-images/pending.png" alt="" />
                      </div>
                      <p class="total_text pl-2 m-0">Wallet Balance</p>
                    </div>
                    <h5 class="total_text"> {{ wallet.balance }} PKR </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 m-0 pl-0 my-4">
              <div class="">
                <div class="chart_parent">
                  <Chart></Chart>
                </div>
              </div>
            </div>
            <div class="dashboard-upcomming-schedule-header">
              <h5>Earnings</h5>
            </div>
            <div class="show-total_earning_parent col-12 col-md-12">
              <div class="total_earning_parent">

                <div class="rows dashboard-cards dashboard-card-blue">
                  <div class="coursesdetail">
                    <div class="">
                      <img src="@/assets/new-theme-images/incomed.png" alt="" />
                    </div>
                    <p class="total_text pl-2 m-0">Total Earning</p>
                  </div>
                  <h5 class="total_text">12000Rs</h5>
                </div>
                <div class="rows dashboard-cards dashboard-card-yellow">
                  <div class="coursesdetail ">
                    <div class="">
                      <img src="@/assets/new-theme-images/bill.png" alt="" />
                    </div>
                    <p class="total_text pl-2 m-0">Requested Withdrawal</p>
                  </div>
                  <h5 class="total_text">12000Rs</h5>
                </div>
                <div class="rows dashboard-cards dashboard-card-green">
                  <div class="coursesdetail">
                    <div class="">
                      <img src="@/assets/new-theme-images/pending.png" alt="" />
                    </div>
                    <p class="total_text pl-2 m-0">Pending Balance</p>
                  </div>
                  <h5 class="total_text">12000Rs</h5>
                </div>
              </div>
            </div>
            <div class="dashboard-upcomming-schedule-header">
              <h5>Courses</h5>
              <a href="">View All</a>
            </div>
            <div class="row courses_summary total_course_parent">
              <div class="col-4 col-md-4 dashboard-cards dashboard-card-lightgreen">
                <div class="">
                  <div class="row mb-1 rows dashboard-card-gap ">
                    <div class="summry">
                      <div class="color_circle"></div>
                      <p class="total_text m-0">Completed Courses</p>
                    </div>
                    <div class="">
                      <h5 class="total_text">{{ courses.completed }}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-4 col-lg-4 dashboard-cards dashboard-card-lightblue">
                <div class="">
                  <div class="row mb-1 rows dashboard-card-gap">
                    <div class="summry">
                      <div class="color_circle2"></div>
                      <p class="total_text m-0">Courses In Progress</p>
                    </div>
                    <div class="">
                      <h5 class="total_text">{{ courses.active }}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-4 col-lg-4 dashboard-cards dashboard-card-pink">
                <div class="">
                  <div class="row mb-1 rows dashboard-card-gap">
                    <div class="summry">
                      <div class="color_circle3"></div>
                      <p class="total_text m-0">Courses Pending Approval</p>
                    </div>
                    <div class="">
                      <h5 class="total_text">{{ courses.pending }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-4 main-col dashboard-top-purchases">

          <ThisWeekScheduleComponent :role="user.role"></ThisWeekScheduleComponent>

          <div class="row margin_top_progress">
            <div class="col-12 p-0">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "@/components/tutor/dashboard/Chart";
import ThisWeekScheduleComponent from "@/components/utilities/week-schedule/ThisWeekScheduleComponent";
import {useUserStore} from "@/store/store";

export default {
  name: "DashboardComponent",
  components: {
    ThisWeekScheduleComponent,
    Chart
  },
  data() {

    const userStore = useUserStore()
    return {
      weekDays: [],
      slots: [],
      sessions: [],
      role: userStore.currentUser.role,
      user: userStore.currentUser,
      wallet: userStore.wallet,
      courses: {
        courses: [],
        pending_courses: 0,
        active_courses: 0,
        completed: 0,
        latest: [],
      },
      bookings:[],
      options: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: '#FCCD05',
          backgroundColor: '#C9C9C9',
          inverted: false
        },
        layout: {
          height: 120,
          width: 120,
          verticalTextAlign: 85,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 10,
          progressPadding: 0,
          type: 'circle'
        }
      }
    }
  },
  mounted() {
    // let self = this

    // try {
    //   const courses_stats = axiosInstance.get(endpoints.getCoursesStats);
    //
    //   if(courses_stats.status === true) {
    //     self.courses.courses = courses_stats.data.courses
    //     self.courses.pending = courses_stats.data.pending_courses
    //     self.courses.active = courses_stats.data.active_courses
    //     self.courses.completed = courses_stats.data.completed_courses
    //     self.courses.latest = courses_stats.data.top_courses
    //   }
    // } catch (e) {
    //   console.log(e)
    // }
    //
    // try {
    //   const slots_stats = axiosInstance.get(endpoints.getSlotsStats);
    //
    //   if(slots_stats.status === true) {
    //     self.bookings = slots_stats.data.bookings
    //   }
    //
    // } catch (e) {
    //   console.log(e)
    // }

  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },

}
</script>