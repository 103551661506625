<!-- src/views/VerifyEmail.vue -->
<template>
  <div class="verify-email">
    <h1>Email Verification</h1>
    <p v-if="loading">Verifying your email, please wait...</p>
    <p v-if="message">{{ message }}</p>
    <button class="btn btn-primary" v-if="showResendButton" @click="resendEmail">Resend Verification Email</button>
  </div>
</template>

<script>
import axios from 'axios';
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";

export default {
  props: ['email', 'hash'],
  data() {
    return {
      loading: true,
      message: '',
      showResendButton: false,
    };
  },
  mounted() {
    this.verifyEmail();
  },
  methods: {
    async verifyEmail() {
      try {

        const response = await axios.get(endpoints.verifyEmail+`/${this.email}/${this.hash}`);

        this.message = response.data.message;
        toast.success(response.data.message);

        setTimeout(() => {
          this.$router.push('/')
        }, 4000);

      } catch (error) {
        this.message = error.response.data.message || 'Verification failed.';
        toast.error(error.response.data.message || 'Verification failed.')
        this.showResendButton = true;
      } finally {
        this.loading = false;
      }
    },
    async resendEmail() {
      try {
        const response = await axios.post(endpoints.resendVerificationEmail, { email: this.email });
        this.message = response.data.message;
        this.showResendButton = false;
      } catch (error) {
        this.message = error.response.data.message || 'Failed to resend verification email.';
      }
    }
  }
};
</script>

<style scoped>
.verify-email {
  text-align: center;
  margin-top: 50px;
}
</style>

