<template>
    <div class="tutor">
        <HeaderComponent></HeaderComponent>
        <div v-if="this.userDetail?.profile_status === 'changes-requested'"
            class="bg-danger text-white px-2 py-2 d-flex align-items-start">
            <i class="fas fa-info-circle mt-1"></i>
            <div class="ml-2 d-flex">
                <p class="m-0 ml-2">Changes have been requested by <b>Admin</b>. Please review and make necessary updates.
                    Thank you.</p> <button
                    v-on:click="viewRequestedChanges = true"
                    class="ml-2 border-0 bg-transparent text-white font-weight-bolder outline-none">View Requested
                    Changes</button>
            </div>
        </div>

        <div class="modal" id="SignInModal" v-if="viewRequestedChanges" style="display: block; background-color: rgba(0, 0, 0, 0.4)" tabindex="-1"
            role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modals-newtheme" role="document">
                <div class="modal-content p-2">
                    <div class="modal-body p-0">
                        <button v-on:click="
                            viewRequestedChanges = false
                        " type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>

                    </div>
                    <div class="ml-2 pb-2" v-html="
                        JSON.parse(this.userDetail?.reject_user_msgs ?? '[]')?.at(
                            -1
                        )?.message
                    "></div>
                </div>
            </div>
        </div>
        <div class="dashboard-newtheme-main-bg tutor"
            :style="{ height: userDetail?.profile_status === 'changes-requested' ? 'calc(100vh - 74px - 40px)' : 'calc(100vh - 74px)' }">
            <div class="hide-sidebar-ontoggle">
                <SidebarComponent :userDetail="userDetail" :toggleHandler="this.toggler"></SidebarComponent>
            </div>
            <button v-if="!isToggle" id="mob-left-sidebar-toggler" v-on:click="isToggle = !isToggle">
                <i class="fa fa-chevron-right"></i>
            </button>
            <div v-if="this.isToggle" class="show-sidebar-ontoggle">
                <SidebarComponent :toggleHandler="this.toggler"></SidebarComponent>
            </div>
            <div class="home_content dashboard-newtheme tutor_dashboard_main">
                <router-view></router-view>
            </div>
            <ChatUserListComponent></ChatUserListComponent>
        </div>
    </div>
</template>

<script>
import SidebarComponent from "@/components/tutor/layout/SidebarComponent";
import ChatUserListComponent from "@/components/frontend/includes/chat/ChatUserListComponent";
import HeaderComponent from "@/components/frontend/includes/header/HeaderComponent";
import {useUserStore} from "@/store/store";

export default {
    components: {
        SidebarComponent,
        ChatUserListComponent,
        HeaderComponent,
    },
    name: "App",
    data() {

      const userStore = useUserStore();

        return {
            isToggle: false,
            userDetail: userStore.currentUser,
            viewRequestedChanges: false,
        };
    },
    methods: {
        toggler() {
            this.isToggle = !this.isToggle;
        },
    },
};
</script>

<style scoped>
p {
    margin-bottom: 0 !important;
}

#mob-left-sidebar-toggler {
    display: none;
}

@media (max-width: 576px) {
    #mob-left-sidebar-toggler {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 50%;
        left: 0;
        z-index: 999;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border: none;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.7);
        width: 20px;
        height: 60px;
    }
}
</style>
