// src/api.js
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

const endpoints = {
  signUp: `${API_BASE_URL}/register`,

  loginRoute: `${API_BASE_URL}/login`,

  metaInfoRoute: `${API_BASE_URL}/meta`,

  handleGoogleToken: `${API_BASE_URL}/auth/google`,

  verifyEmail: `${API_BASE_URL}/email/verify`,

  resendVerificationEmail: `${API_BASE_URL}/email/resend`,

  passwordResetLinkRoute: `${API_BASE_URL}/forgot-password`,

  passwordResetRoute: `${API_BASE_URL}/reset-password`,

  getUserDetailRoute: `${API_BASE_URL}/user`,

  getCoursesStats: `${API_BASE_URL}/user/courses-stats`,

  getSlotsStats: `${API_BASE_URL}/user/slots-stats`,

  removeFromCartRoute: `${API_BASE_URL}/cart/delete`,

  switchRoleRoute: `${API_BASE_URL}/switch/role`,

  getNotificationRoute: `${API_BASE_URL}/notification`,

  readNotification: `${API_BASE_URL}/notification/read`,

  getUnreadNotificationRoute: `${API_BASE_URL}/notification/unread`,

  getUserChatRoute: `${API_BASE_URL}/user/chat/list`,

  getUserChatUpdateRoute: `${API_BASE_URL}/chat/update`,

  infoPro: `${API_BASE_URL}/user/info`,

  info: `${API_BASE_URL}/info`,

  getEducationLevels: `${API_BASE_URL}/user/levels`,

  updatePassword: `${API_BASE_URL}/password/update`,

  checkProfileStatusRoute: `${API_BASE_URL}/user/check/profile`,

  uploadProfileImage: `${API_BASE_URL}/profile/image/upload`,

  zoomSessionInitiateRoute: `${API_BASE_URL}/slot/initiate/zoom`,

  getCourses: `${API_BASE_URL}/student/courses`,

  courseRatingRoute: `${API_BASE_URL}/course/rating`,

  filterContent: `${API_BASE_URL}/filter/content`,

  reviewRoute: `${API_BASE_URL}/reviews/add`,

  tutorReviewRoute: `${API_BASE_URL}/tutor/review`,

  disputeRequestRoute: `${API_BASE_URL}/slot/dispute/request`,

  getBookedSlotsRoute: `${API_BASE_URL}/student/booked/slots`,

  cancelBookedSlotRoute: `${API_BASE_URL}/cancel/booked/slot`,

  cancelRefundRequestRoute: `${API_BASE_URL}/refund/request/cancel`,

  cancelDisputeRequestRoute: `${API_BASE_URL}/dispute/request/cancel`,

  getRefundDetailRoute: `${API_BASE_URL}/slot/refund/detail`,

  getDisputeDetailRoute: `${API_BASE_URL}/slot/dispute/detail`,

  checkCancelOrReschedulingRoute: `${API_BASE_URL}/student/checkCancelOrRescheduling`,

  refundRequestRoute: `${API_BASE_URL}/slot/refund/request/status`,

  getSlotRoute: `${API_BASE_URL}/tutor/public/slots`,

  getWishlistCourses: `${API_BASE_URL}/wishlist`,

  getWishlistTutors: `${API_BASE_URL}/wishlist/tutors`,

  getSessionLogs: `${API_BASE_URL}/session/logs`,

  getActivityLogsRoute: `${API_BASE_URL}/activity/logs`,

  getUserDetails: `${API_BASE_URL}/user/detail`,

  getTransaction: `${API_BASE_URL}/transaction`,

  getTopUpRoute: `${API_BASE_URL}/topup`,

  paymentV: `${API_BASE_URL}/topup/foreePay`,

  foreeBillStatus: `${API_BASE_URL}/foree-bill-status`,

  addStep1Info: `${API_BASE_URL}/become/tutor/step1`,

  getRefundRequestRoute: `${API_BASE_URL}/refund/request`,

  updateInfoRoute: `${API_BASE_URL}/update/user/info/step3`,

  InfoProfileStatusRoute: `${API_BASE_URL}/info/profile-status`,

  checkRoute: `${API_BASE_URL}/become/tutor/applied/check`,

  getUserListRoute: `${API_BASE_URL}/user/chat/list`,

  getMessagesRoute: `${API_BASE_URL}/message`,

  sendMessageRoute: `${API_BASE_URL}/message/send`,

  blockUserChatRoute: `${API_BASE_URL}/chat/user/block`,

  getDisputeRoute: `${API_BASE_URL}/student/dispute`,

  getStudentDisputeDetailRoute: `${API_BASE_URL}/student/dispute/detail`,

  getThreadRoute: `${API_BASE_URL}/student/dispute/threads`,

  addThreadMessageRoute: `${API_BASE_URL}/student/dispute/thread/store`,

  getTransactionDetailRoute: `${API_BASE_URL}/transaction/detail`,

  getStudentRefundDetailRoute: `${API_BASE_URL}/student/refund/detail`,

  courseRoute: `${API_BASE_URL}/courses`,

  addToWishlistRoute: `${API_BASE_URL}/wishlist/add`,

  reportJobPostRoute: `${API_BASE_URL}/report/store`,

  addToCartRoute: `${API_BASE_URL}/cart/add`,

  getCart: `${API_BASE_URL}/report/cart`,

  slotsPro: `${API_BASE_URL}/slots`,

  slotsProStore: `${API_BASE_URL}/slots/create`,

  slotsProDelete: `${API_BASE_URL}/slots/delete`,

  slotMarkAsCompletedRoute: `${API_BASE_URL}/slots/booked/mark/complete`,

  getTutorRoute: `${API_BASE_URL}/tutors`,

  getSlotDetailRoute: `${API_BASE_URL}/tutor/slots/detail`,

  bookSlotRoute: `${API_BASE_URL}/book/slots`,

  payment: `${API_BASE_URL}/payment`,

  getFilterContent: `${API_BASE_URL}/filter/content`,

  getJobPostsRoute: `${API_BASE_URL}/job/posts`,

  getCachedJobPostsRoute: `${API_BASE_URL}/job/posts`,

  getJobPostRoute: `${API_BASE_URL}/job/post`,

  jobPostDeleteRoute: `${API_BASE_URL}/job/post/delete`,

  getJobAlertRoute: `${API_BASE_URL}/job/post/job/alert`,

  deleteJobAlertRoute: `${API_BASE_URL}/job/post/job/alert/delete`,

  addJobStep1Route: `${API_BASE_URL}/post/job/add/step1`,

  addJobStep2Route: `${API_BASE_URL}/post/job/add/step2`,

  addJobStep3Route: `${API_BASE_URL}/post/job/add/step3`,

  applyJobRoute: `${API_BASE_URL}/job/apply`,

  savedItemsRoute: `${API_BASE_URL}/saved/item/update`,

  jobPostStatusRoute: `${API_BASE_URL}/job/post/status/close`,

  subscribeJobPostRoute: `${API_BASE_URL}/post/job/subscribe`,

  updateSubscribeJobPostRoute: `${API_BASE_URL}/post/job/subscribe/update`,

  getWeeklyScheduleRoute: `${API_BASE_URL}/tutor/week/schedule`,

  saveAvailability: `${API_BASE_URL}/availability/store`,

  getAvailability: `${API_BASE_URL}/availability`,

  getTutorDetailRoute: `${API_BASE_URL}/tutor/detail`,

  getReviews: `${API_BASE_URL}/public/reviews`,

  addToMessageList: `${API_BASE_URL}/chat/update`,

  expPro: `${API_BASE_URL}/experience`,

  resourcePro: `${API_BASE_URL}/resource`,

  instituteProRoute: `${API_BASE_URL}/institutes`,

  degreeProRoute: `${API_BASE_URL}/degrees`,

  gradeProRoute: `${API_BASE_URL}/grades`,

  institutePro: `${API_BASE_URL}/webapi/get_institutes`,

  degreePro: `${API_BASE_URL}/webapi/get_degrees`,

  gradePro: `${API_BASE_URL}/webapi/get_grades`,

  qualiPro: `${API_BASE_URL}/qualification`,

  certPro: `${API_BASE_URL}/certification`,

  userBillingDetailRoute: `${API_BASE_URL}/billing/detail`,

  userBillingDeleteRoute: `${API_BASE_URL}/billing/detail/delete`,

  getVerificationRoute: `${API_BASE_URL}/tutor/verification`,

  getDocumentsRoute: `${API_BASE_URL}/tutor/document`,

  deleteDocumentsRoute: `${API_BASE_URL}/tutor/document/delete`,

  paymentRoute: `${API_BASE_URL}/tutor/verification/payment`,

  payWithJazzCashRoute: `${API_BASE_URL}/tutor/verification/payment/jazzcash`,

  getMetaFeesRoute: `${API_BASE_URL}/meta/verification_fees`,

  getCoursesRoute: `${API_BASE_URL}/tutor/courses`,

  getCourseThreadsRoute: `${API_BASE_URL}/admin/course/threads`,

  updateCourseStatus: `${API_BASE_URL}/tutor/course/thread/comment`,

  changeCourseStatusRoute: `${API_BASE_URL}/tutor/course/step/status`,

  getOutline: `${API_BASE_URL}/outlines`,

  storeOutlineRoute: `${API_BASE_URL}/course/outline/update/steps`,

  validateOutlineRoute: `${API_BASE_URL}/course/outline/validate`,

  getSessionLogsRoute: `${API_BASE_URL}/session/logs`,

  getUserPendingWithraws: `${API_BASE_URL}/user/pending/withdraw`,

  getUserApprovedWithraws: `${API_BASE_URL}/user/approved/withdraw`,

  getUserWithraws: `${API_BASE_URL}/user/all/withdraw`,

  getTutorTransaction: `${API_BASE_URL}/tutor/transaction`,

  getTutorRefundRequestRoute: `${API_BASE_URL}/tutor/refund/request`,

  getTutorDisputeRoute: `${API_BASE_URL}/tutor/dispute`,

  getWithdrawListRoute: `${API_BASE_URL}/user/withdraw`,

  getAccountListRoute: `${API_BASE_URL}/user/account`,

  addWithdrawRequestRoute: `${API_BASE_URL}/user/withdraw/request`,

};

export default endpoints;
