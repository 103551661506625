<template>
  <div>
    <div class="hero-course-wrap bg-offwhite newtheme-cart-component">
      <div class="container">
        <div class="hero-course-con py-4">
          <h2 class="font-weight-bold color_black mb-2 mt-4">About Us</h2>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="text-center mt-3">
        <h2 class="text-center text-orange">About Us</h2>
        <p class="text-left text-large">
          ILMZone believes that academic digitalization can work wonders. We strive to make digital education accessible, affordable, and convenient for everyone. Our state-of-the-art platform connects experts in select fields to those on a mission to learn. With topic-based assistance and an array of peer-reviewed courses, ILMZone is paving the way to enhance educational accessibility in Pakistan.
        </p>

        <h2 class="text-center text-orange">Mission Statement</h2>
        <p class="text-left text-large">
          ILMZone strives to dominate as a market leader in innovation, educational excellence, and convenience for those who demand the best in education and for their future.
        </p>

        <h2 class="text-center text-orange">Vision</h2>
        <p class="text-left text-large">
          Our vision is to pioneer academic digitalization and innovation in Pakistan for years to come. We’re committed to evolving into the premier solution for online education, and to provide sustainable income opportunities for skilled professionals of Pakistan.
        </p>

        <h2 class="text-center text-orange">The ILMZone Difference</h2>
        <p class="text-left text-large">
          Our model builds on the concept of your ultimate freedom to work and study from anywhere. By introducing a robust, diverse, and multi-faceted educational service consisting of live lessons and courses, we’ve countered the shortcomings of the traditional educational model.
        </p>
        <p class="text-left text-large">We offer:</p>
      </div>

      <ul class="text-large">
        <li>A roster of top experts in their field</li>
        <li>Flexibility and control over your time and schedule</li>
        <li>Value so you stay in control of your budget</li>
        <li>Payment protection</li>
        <li>A platform on which to teach, earn, and grow</li>
      </ul>

      <p>
        <b>Meta Description:</b> We believe that academic digitalization can work wonders. With ILMZone, we strive to make digital education accessible, affordable, and convenient for everyone.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>

<style scoped>
.hero-course-wrap {
  background-color: #f7f7f7;
}

.container {
  margin-top: 20px;
}

.hero-course-con h2 {
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.text-orange {
  color: #ff8c00;
}

.text-large {
  font-size: 1.2em;
  line-height: 1.6;
}

ul {
  margin-top: 20px;
  margin-left: 40px;
  list-style-type: disc;
}

ul li {
  margin-bottom: 10px;
}
</style>