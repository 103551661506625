<template>
  <div class="modal bd-example-modal-lg" style="display: block; background-color: rgba(0,0,0, 0.4); overflow: scroll; z-index: 9999;" tabindex="5" role="dialog" id="job-alert">
    <div class="modal-dialog job_alert_modal modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="alet-modal">Job Alerts</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="$parent.jobPostSubscriptionForm = !$parent.jobPostSubscriptionForm">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="formValidate()">
          <div class="modal-body">
            <div class="form-group">
              <label for="email">Email</label>
              <input id="email" type="email" name="email" v-model="userEmail" class="form-control input_height prerequisite-input" required>


              <label for="keywords">Keywords</label>
              <input id="keywords" type="text" name="keywords" v-model="keywords" class="form-control input_height prerequisite-input">

              <!-- <label class="mt-2">Choose Subjects</label> -->
              <multiselect v-model="subjects_id" :options="subjects" :multiple="true" :close-on-select="true" placeholder="Choose Subjects" label="subject" track-by="id"></multiselect>


              <label class="mt-2">Frequency</label>
              <div class="frequency-labels">
                <!-- <div class="daily-weekly">   -->
                <!--   <input type="radio" id="daily" value="daily" name="frequency" v-model="frequency" class="form-check"> -->
                <!--   <label for="daily">Daily</label> -->
                <!-- </div> -->
                <div class="daily-weekly">
                  <label class="radiostyle-01">
                    <input type="radio" id="daily" value="daily" name="frequency" v-model="frequency" class="form-check">
                    <span class="radiostyle-01-checkmark"></span>
                    <span class="weekly-daily-lable-t">Daily</span>
                  </label>
                </div>

                <div class="daily-weekly">
                  <label class="radiostyle-01">
                    <input type="radio" value="weekly" name="frequency" v-model="frequency" class="form-check">
                    <span class="radiostyle-01-checkmark"></span>
                    <span class="weekly-daily-lable-t">Weekly</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 pt-0 mb-3">
            <button type="submit" class="btn btn-primary create-alert-btn px-5 py-2">Create Alert</button>
            <button v-on:click="$parent.jobPostSubscriptionForm = !$parent.jobPostSubscriptionForm" class="btn btn-primary cancel-btn px-5 py-2">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>

</template>

<script>
import Multiselect from 'vue-multiselect';
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";

export default {
  name: "JobPostAlertSubscriptionComponent",
  components: {Multiselect},
  props: ['email'],
  data() {
    return {
      userEmail: this.email,
      subjects_id: [],
      subjects: [],
      frequency: 'daily',
      keywords: '',
      id: 0,
    }
  },
  mounted() {
    this.subjects = this.$parent.subjects
  },
  created() {
    let self = this
    if (Object.keys(self.$parent.selected_job_alert).length > 0) {
      this.id = self.$parent.selected_job_alert.id
      this.userEmail = self.$parent.selected_job_alert.email
      this.subjects_id = self.$parent.selected_job_alert.subjects
      this.frequency = self.$parent.selected_job_alert.frequency
      this.keywords = self.$parent.selected_job_alert.keyword
    }
    this.keywords = this.$parent.query.keyword
  },
  methods: {
    formValidate() {
      if (!this.subjects_id.length > 0) {
        toast.success('Choose Subjects...!')
      }
      else if (!this.userEmail) {
        toast.success('Enter Email...!')
      }
      else {
        this.subscribeNow()
      }
    },
    async subscribeNow() {
      let self = this
      let selected_id = []
      this.subjects_id.forEach(function (subject) {
        selected_id.push(subject.id)
      })
      let formdata = new FormData()
      formdata.append('subjects_id', JSON.stringify(selected_id))
      formdata.append('keyword', self.keywords)
      formdata.append('frequency', self.frequency)
      formdata.append('email', self.userEmail)
      formdata.append('model', 'Post')
      formdata.append('model_id', 0)
      let data;
      console.log(self.id)
      if (self.id === 0) {
        data = await axiosInstance.post(endpoints.subscribeJobPostRoute, formdata);
      }
      else {
        formdata.append('id', this.id)
        data = await axiosInstance.post(endpoints.updateSubscribeJobPostRoute, formdata);
      }
      data = data.data
      if (data.type === 'success') {
        self.$parent.jobPostSubscriptionForm = !self.$parent.jobPostSubscriptionForm
        toast.success(data.message)
        this.$parent.getJobAlert()
        self.id = 0
        self.$parent.selected_job_alert = {}
      }
      else if (data.type === 'validation') {
        Object.keys(data.data).forEach(function (key) {
          data.data[key].forEach(function (err) {
            toast.error(err).goAway(2000)
          })
        })
      }
      else if (data.type === 'error') {
        toast.success(data.message)
      }
    }
  }
}
</script>

<style scoped>

.modal-dialog form input {
  height: calc(1.5em + .75rem + 2px) !important;
}
</style>
<!--<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>-->
