<template>
    <div class="account_setting_section">
        <div class="dashboard-student-profile">
            <div class="row m-0">
                <div class="col-12 padding-left-desktop">
                    <div class="">
                        <div
                            class="nav nav-pills d-flex align-items-center justify-content-center flex-row"
                            style="border-radius: 0 !important"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                        >
                            <a
                                v-on:click="showTab = 'personal_info'"
                                class="nav-link active border-bottom py-3 pl-4"
                                id="paypal"
                                data-toggle="pill"
                                href="#paypalss"
                                role="tab"
                                aria-controls="paypalss"
                                aria-selected="true"
                                style="
                                    border-radius: 10px 10px 0px 0px !important;
                                    border: 0 !important;
                                "
                            >
                                <span>Personal Information</span>
                            </a>
                            <a
                                v-on:click="showTab = 'reset_password'"
                                class="nav-link border-bottom py-3 pl-4 border-0"
                                id="offlinePayment"
                                data-toggle="pill"
                                href="#offlinePaymentss"
                                role="tab"
                                aria-controls="offlinePaymentss"
                                aria-selected="false"
                                style="
                                    border-radius: 10px 10px 0px 0px !important;
                                    border: 0 !important;
                                "
                            >
                                <span>Reset Password</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div
                    class="col-12 mb-4 d-flex mx-auto"
                    style="
                        max-width: 1200px;
                        align-content: center;
                        align-self: center;
                    "
                >
                    <div class="tabs_2 w-100">
                        <div class="tab-content" id="v-pills-tabContent">
                            <div
                                v-if="showTab === 'personal_info'"
                                class="tab-pane fade show active"
                                id="paypalss"
                                role="tabpanel"
                                aria-labelledby="paypal"
                            >
                                <form
                                    @submit.prevent="saveStudentProfile()"
                                    action=""
                                    enctype="multipart/form-data"
                                    method="post"
                                >
                                    <div class="row personal_info_imgSec">
                                        <div class="row-upload-img">
                                            <div class="change-img">
                                                <img
                                                    src="@/assets/new-theme-images/clarity_edit-line.png"
                                                    alt="edit"
                                                    class="profile-edit-image"
                                                    v-on:click="choosePic()"
                                                />
                                                <img
                                                    width="120px"
                                                    :src="
                                                        previewImage
                                                            ? previewImage
                                                            : '/storage' +
                                                              info.image
                                                    "
                                                    class="profile-uploaded-img"
                                                />
                                                <input
                                                    v-on:change="
                                                        handleFileUpload()
                                                    "
                                                    ref="stdImage"
                                                    type="file"
                                                    class="upload-img-input"
                                                    id="choosePic"
                                                />
                                            </div>
                                            <div class="row w-100 pl-5 pr-4">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="first_name"
                                                            >First Name
                                                            <span
                                                                class="text-danger"
                                                                >*</span
                                                            ></label
                                                        >
                                                        <input
                                                            type="text"
                                                            v-model="
                                                                info.first_name
                                                            "
                                                            id="first_name"
                                                            class="form-control"
                                                            aria-describedby="emailHelp"
                                                            placeholder="First Name"
                                                            readonly
                                                            v-on:focus="
                                                                errorMessages.first_name =
                                                                    []
                                                            "
                                                        />
                                                        <ul
                                                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                            v-if="
                                                                errorMessages
                                                                    .first_name
                                                                    .length > 0
                                                            "
                                                        >
                                                            <li
                                                                v-for="(err, index) in errorMessages.first_name" :key="index"
                                                            >
                                                                <small>{{
                                                                    err
                                                                }}</small>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="last_name"
                                                            >Last Name
                                                            <span class="text-danger">*</span
                                                        >
                                                        </label>
                                                        <input
                                                            type="text"
                                                            v-model="
                                                                info.last_name
                                                            "
                                                            id="last_name"
                                                            class="form-control"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Last Name"
                                                            readonly
                                                            v-on:focus="
                                                                errorMessages.last_name =
                                                                    []
                                                            "
                                                        />
                                                        <ul
                                                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                            v-if="
                                                                errorMessages
                                                                    .last_name
                                                                    .length > 0
                                                            "
                                                        >
                                                            <li v-for="(err, index) in errorMessages.last_name" :key="index">
                                                                <small>{{ err }}</small>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="email"
                                                            >Email
                                                            <span class="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            disabled
                                                            type="text"
                                                            v-model="info.email"
                                                            id="email"
                                                            class="form-control"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Email"
                                                            v-on:focus=" errorMessages.email = []"
                                                        />
                                                        <ul
                                                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                            v-if="errorMessages.email.length > 0"
                                                        >
                                                            <li v-for="(err, index) in errorMessages.email" :key="index">
                                                                <small>{{ err }}</small>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="phone"
                                                            >Mobile Number
                                                            <span
                                                                class="text-danger"
                                                                >*</span
                                                            ></label
                                                        >
                                                        <input
                                                            type="text"
                                                            v-model="info.phone"
                                                            id="phone"
                                                            class="form-control"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Mobile Number"
                                                            v-on:focus="
                                                                errorMessages.phone =
                                                                    []
                                                            "
                                                        />
                                                        <ul
                                                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                            v-if="
                                                                errorMessages
                                                                    .phone
                                                                    .length > 0
                                                            "
                                                        >
                                                            <li
                                                                v-for="(err, index) in errorMessages.phone" :key="index"
                                                            >
                                                                <small>{{
                                                                    err
                                                                }}</small>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label
                                                    >Gender
                                                    <span class="text-danger"
                                                        >*</span
                                                    ></label
                                                >
                                                <div class="radio-btns">
                                                    <div
                                                        class="form-check form-check-inline"
                                                    >
                                                        <input
                                                            class="form-check-input"
                                                            type="radio"
                                                            name="inlineRadioOptions"
                                                            id="inlineRadio1"
                                                            value="male"
                                                            v-model="
                                                                info.gender
                                                            "
                                                            :checked="
                                                                info.gender ===
                                                                'male'
                                                            "
                                                            v-on:focus="
                                                                errorMessages.gender =
                                                                    []
                                                            "
                                                        />
                                                        <label
                                                            class="form-check-label"
                                                            for="inlineRadio1"
                                                            >Male</label
                                                        >
                                                    </div>
                                                    <div
                                                        class="form-check form-check-inline"
                                                    >
                                                        <input
                                                            class="form-check-input"
                                                            type="radio"
                                                            name="inlineRadioOptions"
                                                            id="inlineRadio2"
                                                            value="female"
                                                            v-model="
                                                                info.gender
                                                            "
                                                            :checked="
                                                                info.gender ===
                                                                'female'
                                                            "
                                                            v-on:focus="
                                                                errorMessages.gender =
                                                                    []
                                                            "
                                                        />
                                                        <label
                                                            class="form-check-label"
                                                            for="inlineRadio2"
                                                            >Female</label
                                                        >
                                                    </div>
                                                    <div
                                                        class="form-check form-check-inline"
                                                    >
                                                        <input
                                                            class="form-check-input"
                                                            type="radio"
                                                            name="inlineRadioOptions"
                                                            id="inlineRadio3"
                                                            value="other"
                                                            v-model="
                                                                info.gender
                                                            "
                                                            :checked="
                                                                info.gender ===
                                                                'other'
                                                            "
                                                            v-on:focus="
                                                                errorMessages.gender =
                                                                    []
                                                            "
                                                        />
                                                        <label
                                                            class="form-check-label"
                                                            for="inlineRadio3"
                                                            >Other</label
                                                        >
                                                    </div>
                                                </div>
                                                <ul
                                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                    v-if="
                                                        errorMessages.gender
                                                            .length > 0
                                                    "
                                                >
                                                    <li
                                                        v-for="(err, index) in errorMessages.gender" :key="index"
                                                    >
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="cnic"
                                                    >CNIC/Guardian's CNIC (13
                                                    Characters)
                                                    <span class="text-danger"
                                                        >*</span
                                                    ></label
                                                >
                                                <input
                                                    type="number"
                                                    id="cnic"
                                                    name="cnic"
                                                    v-model="info.cnic"
                                                    @input="checkLength()"
                                                    class="form-control"
                                                    placeholder="CNIC"
                                                    v-on:focus="
                                                        errorMessages.cnic = []
                                                    "
                                                />
                                                <ul
                                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                    v-if="
                                                        errorMessages.cnic
                                                            .length > 0
                                                    "
                                                >
                                                    <li
                                                        v-for="(err, index) in errorMessages.cnic" :key="index"
                                                    >
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="address"
                                                    >Address
                                                    <span class="text-danger"
                                                        >*</span
                                                    ></label
                                                >
                                                <input
                                                    type="text"
                                                    v-model="info.address"
                                                    name="address"
                                                    id="address"
                                                    class="form-control"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Residential Address"
                                                    v-on:focus="
                                                        errorMessages.address =
                                                            []
                                                    "
                                                />
                                                <ul
                                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                    v-if="
                                                        errorMessages.address
                                                            .length > 0
                                                    "
                                                >
                                                    <li
                                                        v-for="(err, index) in errorMessages.address" :key="index"
                                                    >
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="address"
                                                    >Date of Birth
                                                    <span class="text-danger"
                                                        >*</span
                                                    ></label
                                                >
                                                <input
                                                    type="date"
                                                    v-model="info.dob"
                                                    name="dob"
                                                    id="dob"
                                                    class="form-control"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Residential Address"
                                                    v-on:focus="
                                                        errorMessages.dob = []
                                                    "
                                                />
                                                <ul
                                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                    v-if="
                                                        errorMessages.dob
                                                            .length > 0
                                                    "
                                                >
                                                    <li
                                                        v-for="(err, index) in errorMessages.dob" :key="index"
                                                    >
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label
                                                    >Education
                                                    <span class="text-danger"
                                                        >*</span
                                                    ></label
                                                >
                                                <select
                                                    class="form-control"
                                                    v-model="
                                                        info.education_levels
                                                    "
                                                    id="education"
                                                    v-on:focus="
                                                        errorMessages.education_levels =
                                                            []
                                                    "
                                                >
                                                    <option value="0">
                                                        Select Education
                                                    </option>
                                                    <option
                                                        v-for="(level, index) in education_levels" :key="index"
                                                        :value="level.id"
                                                    >
                                                        {{ level.level }}
                                                    </option>
                                                </select>
                                                <ul
                                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                    v-if="
                                                        errorMessages
                                                            .education_levels
                                                            .length > 0
                                                    "
                                                >
                                                    <li
                                                        v-for="(err, index) in errorMessages.education_levels" :key="index"
                                                    >
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label
                                                    >Time Zone
                                                    <span class="text-danger"
                                                        >*</span
                                                    ></label
                                                >
                                                <select
                                                    class="form-control"
                                                    id="timezone"
                                                    v-model="info.timezone"
                                                    v-on:focus="
                                                        errorMessages.timezone =
                                                            []
                                                    "
                                                >
                                                    <option
                                                        :value="timezone"
                                                        v-for="(timezone, index) in timzones" :key="index"
                                                    >
                                                        {{ timezone }}
                                                    </option>
                                                </select>
                                                <ul
                                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                    v-if="
                                                        errorMessages.timezone
                                                            .length > 0
                                                    "
                                                >
                                                    <li
                                                        v-for="(err, index) in errorMessages.timezone" :key="index"
                                                    >
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mx-auto text-center">
                                        <button
                                            type="submit"
                                            class="btn confirm_deposite_btn"
                                            :disabled="!!loading"
                                        >
                                            <div
                                                v-if="!!loading"
                                                class="spinner-border text-primary"
                                                style="
                                                    color: white !important;
                                                    width: 20px !important;
                                                    height: 20px !important;
                                                "
                                                role="status"
                                            ></div>

                                            <span v-if="!!loading === false"
                                                >Save</span
                                            >
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div
                                v-if="showTab === 'reset_password'"
                                class="tab-pane fade w-100"
                                id="offlinePaymentss"
                                role="tabpanel"
                                aria-labelledby="offlinePayment"
                            >
                                <form
                                    @submit.prevent="updatePassword()"
                                    action=""
                                    enctype="multipart/form-data"
                                    method="post"
                                >
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="current_password"
                                                    >Current Password
                                                    <span class="text-danger"
                                                        >*</span
                                                    ></label
                                                >
                                                <input
                                                    type="password"
                                                    required
                                                    class="form-control"
                                                    id="current_password"
                                                    v-model="
                                                        info.current_password
                                                    "
                                                    aria-describedby="emailHelp"
                                                    placeholder="Current Password"
                                                    v-on:focus="
                                                        errorMessages.current_password =
                                                            []
                                                    "
                                                />
                                                <ul
                                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                    v-if="errorMessages.current_password.length > 0"
                                                >
                                                    <li v-for="(err, index) in errorMessages.current_password" :key="index">
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="password"
                                                    >New Password
                                                    <span class="text-danger"
                                                        >*</span
                                                    ></label
                                                >
                                                <input
                                                    type="password"
                                                    required
                                                    class="form-control"
                                                    id="password"
                                                    v-model="info.password"
                                                    aria-describedby="emailHelp"
                                                    placeholder="New Password"
                                                    v-on:focus="
                                                        errorMessages.password =
                                                            []
                                                    "
                                                />
                                                <ul
                                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                    v-if="
                                                        errorMessages.password
                                                            .length > 0
                                                    "
                                                >
                                                    <li
                                                        v-for="(err, index) in errorMessages.password" :key="index"
                                                    >
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="confirm_password"
                                                    >Confirm Password
                                                    <span class="text-danger"
                                                        >*</span
                                                    ></label
                                                >
                                                <input
                                                    type="password"
                                                    required
                                                    class="form-control"
                                                    id="confirm_password"
                                                    v-model="
                                                        info.confirm_password
                                                    "
                                                    aria-describedby="emailHelp"
                                                    placeholder="Re-type Password"
                                                    v-on:focus="
                                                        errorMessages.confirm_password =
                                                            []
                                                    "
                                                />
                                                <ul
                                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                    v-if="
                                                        errorMessages
                                                            .confirm_password
                                                            .length > 0
                                                    "
                                                >
                                                    <li
                                                        v-for="(err, index) in errorMessages.confirm_password" :key="index"
                                                    >
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mx-auto text-center">
                                        <button
                                            class="btn confirm_deposite_btn"
                                            :disabled="!!savePasswordLoading"
                                        >
                                            <div
                                                v-if="!!savePasswordLoading"
                                                class="spinner-border text-primary"
                                                style="
                                                    color: white !important;
                                                    width: 20px !important;
                                                    height: 20px !important;
                                                "
                                                role="status"
                                            ></div>
                                            <span
                                                v-if="
                                                    !!savePasswordLoading ===
                                                    false
                                                "
                                                >Save</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import endpoints from "@/assets/js/api";
import axiosInstance from "@/assets/js/axios";
import {toast} from "vue3-toastify";
import {useUserStore} from "@/store/store";

export default {
    name: "StudentInfo",
    data() {
      const userStore = useUserStore()
        return {
            savePasswordLoading: false,
            timzones: "",
            file: "",
            previewImage: null,
            info: {
                file: "",
                first_name: "",
                middle_name: "",
                last_name: "",
                phone: "",
                cnic: "",
                gender: "",
                address: "",
                education_levels: "",
                timezone: "",
                dob: "",

                current_password: "",
                password: "",
                confirm_password: "",
            },
            errorMessages: {
                email: [],
                username: [],
                file: [],
                first_name: [],
                middle_name: [],
                last_name: [],
                phone: [],
                cnic: [],
                gender: [],
                address: [],
                education_levels: [],
                timezone: [],
                dob: [],

                current_password: [],
                password: [],
                confirm_password: [],
            },
            image: "",
            lists: null,
            education_levels: [],
            user: userStore.currentUser,
            showTab: "personal_info",
        };
    },
    created() {
      const moment = require("moment-timezone");
        this.timzones = moment.tz.names();
        this.getEducationLevels();
        this.getProfileInfo();
        this.checkProfileStatus();
    },
    methods: {
        async checkProfileStatus() {
            let self = this;
            let query = {};
            query.role = self.$store.state.role;
            const { data } = await axiosInstance.get(endpoints.checkProfileStatusRoute, {params: query});

          let userStore = useUserStore();

            if (data.type === "incomplete") {

                if (data.data.status === "incomplete") {
                  userStore.setProfileCompleted(false);
                } else if (data.data.status === "under-review") {
                  userStore.setProfileCompleted(false);
                }

                data.data.forEach(function (error) {
                    toast.error(error);
                });
                if (data.data?.length === 0) {
                    toast.error(data.message);
                }

            } else {
              userStore.setProfileCompleted(true);
            }

        },
        getImage(image) {
            if (image) return image;
            else return "/images/student/student.png";
        },
        async getProfileInfo() {
            try {
              const { data } = await axiosInstance.get(endpoints.infoPro);

              if (data) {

                this.info = data.data.info;
                this.info.dob = data.data.info.dob;
                this.info.first_name = data.data.user.first_name;
                this.info.last_name = data.data.user.last_name;
                this.info.username = data.data.user.username;
                this.info.middle_name = data.data.user.middle_name;
                this.info.email = data.data.user.email;
                this.info.image = data.data.user.profile_image;

              }

            } catch (e) {
              console.log(e)
            }

        },
        async getEducationLevels() {
            try {
              const { data } = await axiosInstance.get(endpoints.getEducationLevels);
              this.education_levels = data.data.levels;
            } catch (e) {
              console.log(e)
            }
        },
        checkFirstName() {
            this.errorMessages.first_name = [];
            if (!this.info.first_name) {
                this.errorMessages.first_name.push("Enter First Name ...");
                toast.error("First Name missing");
                return false;
            } else {
                this.errorMessages.first_name = [];
                return true;
            }
        },
        checkLastName() {
            this.errorMessages.last_name = [];
            if (!this.info.last_name) {
                this.errorMessages.last_name.push("Enter Last Name ...");
                toast.error("Last Name missing");
                return false;
            } else {
                this.errorMessages.last_name = [];
                return true;
            }
        },
        checkUsername() {
            this.errorMessages.username = [];
            if (!this.info.username) {
                this.errorMessages.username.push("Enter User Name ...");
                toast.error("User Name missing");
                return false;
            } else {
                this.errorMessages.username = [];
                return true;
            }
        },
        checkGender() {
            this.errorMessages.gender = [];
            if (!this.info.gender) {
                this.errorMessages.gender.push("Choose Gender ...");
                toast.error("Gender Missing");
                return false;
            } else {
                this.errorMessages.gender = [];
                return true;
            }
        },
        checkMobileNumber() {
            this.errorMessages.phone = [];
            if (!this.info.phone) {
                this.errorMessages.phone.push("Enter Mobile number ...");
                toast.error("Mobile number missing");
                return false;
            } else {
                this.errorMessages.phone = [];
                return true;
            }
        },
        checkCnic() {
            this.errorMessages.cnic = [];
            if (!this.info.cnic || this.info.cnic.length < 13) {
                this.errorMessages.cnic.push("Enter CNIC 13 Digits...");
                toast.error("CNIC 13 Digits missing");
                return false;
            } else {
                this.errorMessages.cnic = [];
                return true;
            }
        },
        checkAddress() {
            this.errorMessages.address = [];
            if (!this.info.address || this.info.address.length < 0) {
                this.errorMessages.address.push("Enter Address...");
                toast.error("Address missing");
                return false;
            } else {
                this.errorMessages.address = [];
                return true;
            }
        },
        checkDob() {
            this.errorMessages.dob = [];
            if (!this.info.dob || this.info.dob.length < 0) {
                this.errorMessages.dob.push("Enter Date of Birth...");
                toast.error("Date of Birth missing")
                return false;
            } else {
                this.errorMessages.dob = [];
                return true;
            }
        },
        checkEducationLevel() {
            this.errorMessages.education_levels = [];
            if (!this.info.education_levels || this.info.education_levels.length < 0) {
                this.errorMessages.education_levels.push("Enter Education Levels...");
                toast.error("Education missing");
                return false;
            } else {
                this.errorMessages.education_levels = [];
                return true;
            }
        },
        checkTimezone() {
            this.errorMessages.timezone = [];
            if (!this.info.timezone || this.info.timezone.length < 0) {
                this.errorMessages.timezone.push("Enter Timezone...");
                toast.error("Timezone missing");
                return false;
            } else {
                this.errorMessages.timezone = [];
                return true;
            }
        },

        async saveStudentProfile() {
            let self = this;
            this.checkFirstName();
            this.checkLastName();
            this.checkUsername();
            this.checkMobileNumber();
            this.checkGender();
            this.checkCnic();
            this.checkAddress();
            this.checkDob();
            this.checkEducationLevel();
            this.checkTimezone();

            if (
                this.checkFirstName() &&
                this.checkLastName() &&
                this.checkUsername() &&
                this.checkGender() &&
                this.checkMobileNumber() &&
                this.checkCnic() &&
                this.checkAddress() &&
                this.checkDob() &&
                this.checkEducationLevel() &&
                this.checkTimezone()
            ) {
                let formData = new FormData();
                formData.append("file", this.file);

                Object.keys(this.info).forEach(key => {
                  formData.append(key, this.info[key]);
                });

                const { data } = await axiosInstance.post(endpoints.infoPro);

                if (data.type === "success") {
                    await this.checkProfileStatus();
                    toast.success(data.message);
                    await this.getProfileInfo();
                } else if (data.type === "validation") {

                    Object.keys(data.data).forEach(function (key) {
                        self.errorMessages[key] = data.data[key];
                        toast.error(data.data[key]);
                    });

                } else if (data.type === "error") {
                  toast.error(data.message);
                }
            }
        },
        handleFileUpload() {
            this.file = this.$refs.stdImage.files[0];
            this.previewImage = this.file
                ? URL.createObjectURL(this.file)
                : null;
            console.log(this.file);
        },
        checkOldPassword() {
            this.errorMessages.password = [];
            if (!this.info.username) {
                this.errorMessages.username.push("Enter User Name ...");
                return false;
            } else {
                this.errorMessages.username = [];
                return true;
            }
        },
        checkNewPassword() {
            this.errorMessages.password = [];
            if (!this.info.password || this.info.password.length < 8) {
                this.errorMessages.password.push(
                    "Enter Password min 8 characters ..."
                );
                return false;
            } else {
                this.errorMessages.password = [];
                return true;
            }
        },
        checkConfirmPassword() {
            this.errorMessages.confirm_password = [];
            if (
                !this.info.confirm_password ||
                this.info.password !== this.info.confirm_password
            ) {
                if (!this.info.confirm_password) {
                    this.errorMessages.confirm_password.push(
                        "Enter Confirm Password min 8 characters"
                    );
                    return false;
                }
                if (this.info.password !== this.info.confirm_password) {
                    this.errorMessages.confirm_password.push(
                        "Confirm Password and password should be same ..."
                    );
                    return false;
                }
            } else {
                this.errorMessages.confirm_password = [];
                return true;
            }
        },

        async updatePassword() {
            this.checkNewPassword();
            this.checkConfirmPassword();
            if (this.checkNewPassword() && this.checkConfirmPassword()) {
                let self = this;
                if (!this.info.password === this.info.confirm_password) {
                    this.errorMessages.confirm_password.push(
                        "Password and Confirm Password Should Same"
                    );
                    return;
                } else if (!this.info.current_password === this.info.password) {
                    this.errorMessages.confirm_password.push(
                        "Current Password and New Password are same"
                    );
                    return;
                }
                let formData = new FormData();
                let pro = this.info;
                formData.append("email", pro.email);
                formData.append("current_password", pro.current_password);
                formData.append("password", pro.password);
                formData.append("password_confirmation", pro.confirm_password);
                this.savePasswordLoading = true;
                const { data } = await axiosInstance().post(endpoints.updatePassword, formData);
                this.savePasswordLoading = false;
                if (data.type === "success") {
                    toast.success(data.message);
                    this.info.current_password = "";
                    this.info.password = "";
                    this.info.confirm_password = "";
                } else if (data.type === "validation") {
                    Object.keys(data.message).forEach(function (key) {
                        self.errorMessages[key] = data.message[key];
                    });
                } else {
                    self.errorMessages.current_password.push(data.message);
                }
            }
        },
        choosePic() {
            document.getElementById("choosePic").click();
        },
        async uploadImage() {
            if (!this.file) {
                toast.error("Choose Image...");
            } else {
                let formData = new FormData();
                formData.append("file", this.file);
                const { data } = await axiosInstance.post(endpoints.uploadProfileImage, formData);
                this.file = "";
                toast.error(data.message);
            }
        },
        checkLength() {
            if (this.info.cnic.length > 13) {
                this.info.cnic = this.info.cnic.slice(0, 13);
            }
        },
    },
};
</script>

<style scoped>
button {
    display: flex;
    align-items: center;
    justify-content: center;
}
.tabs_1 {
    padding-bottom: 0 !important;
}
.icon_background img.img-responsive {
    max-width: 100%;
    margin: auto;
}
.account_setting_section .personal_info_imgSec .icon_background {
    padding: 0;
    height: 140px;
    display: flex;
}
.profile-uploaded-img,
.change-img {
    width: 10vw !important;
    height: 10vw !important;
}
.home_content.dashboard-newtheme .tabs_2 .row-upload-img {
    justify-content: left !important;
}
.profile-edit-image {
    right: 5px !important;
    top: 10px !important;
}
.home_content.dashboard-newtheme
    .tabs_2
    form
    .form-group
    .radio-btns
    .form-check.form-check-inline {
    margin-right: 10px !important;
}
</style>
