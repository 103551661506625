<template>
    <div style="height: 100%">
        <LoadingComponent :loading="loading"></LoadingComponent>
        <div
            class="container container-coursedetail transaction-table student-trans-sec transaction-newtheme"
            v-if="!loading"
        >
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li
                    v-on:click="modal = 'refund'"
                    class="nav-item"
                    role="presentation"
                >
                    <a
                        class="nav-link active"
                        id="refund-tab"
                        data-toggle="tab"
                        href="#refund-requests"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        >Refund</a
                    >
                </li>
                <li
                    v-on:click="modal = 'dispute'"
                    class="nav-item"
                    role="presentation"
                >
                    <a
                        class="nav-link"
                        id="dispute-tab"
                        data-toggle="tab"
                        href="#dispute-requests"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        >Dispute</a
                    >
                </li>
            </ul>

            <div class="tab-style-01">
                <div v-if="modal === 'refund'" class="tab-content" id="refund">
                    <div
                        class="tab-pane fade show active"
                        id="refund-requests"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                    >
                        <RefundRequestComponent></RefundRequestComponent>
                    </div>
                </div>
                <div
                    v-if="modal === 'dispute'"
                    class="tab-content"
                    id="dispute"
                >
                    <div
                        class="tab-pane fade show active"
                        id="dispute-requests"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                    >
                        <DisputeRequestComponent></DisputeRequestComponent>
                    </div>
                </div>
            </div>
            <TransactionDetailComponent
                v-if="transactionModal"
                :selectedTransaction="selectedTransaction"
            ></TransactionDetailComponent>
        </div>
    </div>
</template>

<script>
import RefundRequestComponent from "@/components/student/billings/RefundRequestComponent";
import DisputeRequestComponent from "@/components/student/billings/DisputeRequestComponent";
import TransactionDetailComponent from "@/components/frontend/payment/TransactionDetailComponent";
import LoadingComponent from "@/components/utilities/loading/loader.vue";
import endpoints from "@/assets/js/api";
import axiosInstance from "@/assets/js/axios";

export default {
    name: "RequestsComponent",
    components: {
        RefundRequestComponent,
        DisputeRequestComponent,
        TransactionDetailComponent,
        LoadingComponent,
    },
    data() {
        return {
            transactionModal: false,
            selectedTransaction: {},
            modal: "refund",
            balance: 0.0,
            query: {
                status: "",
                keyword: "",
                sort: "DESC",
                page: 1,
                limit: 6,
                total: 0,
            },
            loading: false,
        };
    },
    mounted() {},
    created() {},
    methods: {
        async getTransactionDetail(id) {
            let q = {};
            q.trx_id = id;
            this.loading = true;
            const { data } = await axiosInstance.get(endpoints.getTransactionDetailRoute, q);
            this.loading = false;
            this.selectedTransaction = data.transaction;
            this.transactionModal = !this.transactionModal;
        },
    },
};
</script>

<style scoped>
h3,
h5 {
    color: #812bb2 !important;
}
.container-coursedetail.transaction-table.student-trans-sec ul#myTab {
    padding: 0 !important;
}
.container.container-coursedetail.transaction-table #myTab li.nav-item a {
    height: 45px !important;
}
</style>
