<template>

  <div class="row">

    <div class="col-md-6">
      <WeeklyWiseScheduleComponent
          :availability="availability"
          :availability-messages="availabilityMessages"
          @addDuration="addDuration"
          @deleteDuration="deleteDuration"
          @valuesUpdate="valuesUpdate"
      ></WeeklyWiseScheduleComponent>
    </div>

    <div class="col-md-6">
      <DateWiseScheduleComponent
          :availability="availability"
          :availability-messages="availabilityMessages"
          @addDuration="addDuration"
          @deleteDuration="deleteDuration"
          @addAvailability="addAvailabilityToDom"
          @deleteAvailability="deleteAvailabilityToDom"
          @valuesUpdate="valuesUpdate"
      ></DateWiseScheduleComponent>
    </div>

  </div>

</template>

<script>
import WeeklyWiseScheduleComponent from "@/components/tutor/slots/WeeklyWiseScheduleComponent.vue";
import DateWiseScheduleComponent from "@/components/tutor/slots/DateWiseScheduleComponent.vue";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import moment from "moment-timezone";
import {useUserStore} from "@/store/store";

export default {
  name: "ListViewAvailabilityComponent",
  components: {DateWiseScheduleComponent, WeeklyWiseScheduleComponent},
  props: [],

  data() {

    return {
      systemHourlyRate: 0,
      hourlyRate: 0,
      currency_symbol: 0,
      availability: null,
      availabilityMessages: [],
      weeklySlotsTemplate: [
        {"type": 0, "available": 0, "duration": [] },
        {"type": 0, "available": 1, "duration": [] },
        {"type": 0, "available": 2, "duration": [] },
        {"type": 0, "available": 3, "duration": [] },
        {"type": 0, "available": 4, "duration": [] },
        {"type": 0, "available": 5, "duration": [] },
        {"type": 0, "available": 6, "duration": [] },
      ]
    }
  },

  mounted() {
    const userStore = useUserStore();
    this.hourlyRate = userStore.currentHourlyRate;
    this.currency_symbol = userStore.wallet.currency_symbol;

    this.systemHourlyRate = userStore.system.min_hourly_rate;

    this.getAvailability().then(availability => {

      if(availability === false) {

        this.saveAvailability(this.$data.weeklySlotsTemplate).then(cAvail => {
          if(cAvail === true) {
            this.getAvailability();
          }
        });

      }
    });


  },

  methods: {
    getAvailability() {
      return axiosInstance.get(endpoints.getAvailability)
          .then(response => {

            if (response.data.status === true && response.data.data.availability.length > 0) {
              this.$data.availability = response.data.data.availability;
              return true;
            } else {
              return false;
            }

          })
          .catch(error => {
            console.error("Error fetching availability:", error);
            return "error";
          });
    },

    saveAvailability(availability) {

      return axiosInstance.post(endpoints.saveAvailability, {rules: availability})
          .then(response => {
            return response.data.status === true;
          })
          .catch(error => {
            console.error("Error fetching availability:", error);
            return false;
          });

    },

    addAvailabilityToDom: function (type) {
      event.preventDefault();

      if(type === 1) {

        const type1Slots = this.availability.filter(slot => slot.type === 1);
        const availableDates = type1Slots.map(slot => slot.available);

        let nextDate = moment().format('YYYY-MM-DD');

        if(availableDates.length > 0) {
          const farthestDate = availableDates.reduce((farthest, current) => {
            return moment(current).isAfter(moment(farthest)) ? current : farthest;
          });

          nextDate = moment(farthestDate).add(1, 'days').format('YYYY-MM-DD');

          if (moment(nextDate).isBefore(moment().format('YYYY-MM-DD'))) {
            nextDate = moment().add(1, 'days').format('YYYY-MM-DD');
          }

        }

        let duration = {"type": type, "available": nextDate,  "duration": [{"price": this.hourlyRate, "start": {HH:"09",mm:"00"}, "end": {HH:"10",mm:"00"}}] };

        this.availability.push(duration);
        this.saveAvailability(this.availability);
      }

    },

    deleteAvailabilityToDom: function (awaKey) {
      event.preventDefault();

      this.availability.splice(awaKey);
      this.saveAvailability(this.availability);
    },

    addDuration(availabilityIndex) {
      let weekdayDurations = this.availability[availabilityIndex]["duration"];
      let duration = this.suggestNewDuration(weekdayDurations);
      weekdayDurations.push(duration);

      this.saveAvailability(this.availability)

    },

    deleteDuration(avaIndex, durationIndex) {
      let weekdayDurations = this.availability[avaIndex]["duration"];
      weekdayDurations.splice(durationIndex, 1);
    },


    valuesUpdate(target, value, durKey, avaKey) {

      console.log(target, value, durKey, avaKey);

      this.availabilityMessages[avaKey] = {};

      if((target === 'price') || (target === 'start') || (target === 'end')) {

        if(target === 'price' && (value < this.$data.systemHourlyRate)) {
          this.availabilityMessages[avaKey][durKey] = {"price" : "Wrong Price"}
          return;
        }

        if(target === 'start' || target === 'end') {

          let check = this.checkTime(value, target, avaKey, durKey);

          console.log("check time: " + check)

          if(check === false) {
            this.availabilityMessages[avaKey][durKey] = {"start" : "Wrong Time"}
            return;
          }

          let overlap = this.checkOverlaps(value, durKey, this.availability[avaKey]["duration"]);

          console.log("overlap: " + overlap)

          if(overlap) {
            this.availabilityMessages[avaKey][durKey] = {"start" : "This time is already contained in other slots"}
            return;
          }

        }

        this.availability[avaKey]["duration"][durKey].target = value;

      } else if(target === 'available') {
        this.availability[avaKey].target = value;
      }

      this.saveAvailability(this.availability);

    },

    checkTime: function (value, target, avaKey, durKey) {
      let durations = this.availability[avaKey]["duration"];

      const newTime = moment().set({ hour: value.HH, minute: value.mm });

      if(target === 'start') {
        const newCorTime = moment().set({ hour: durations[durKey].end.HH, minute: durations[durKey].end.mm });
        return !newTime.isAfter(newCorTime);
      }

      if(target === 'end') {
        const newCorTime = moment().set({ hour: durations[durKey].start.HH, minute: durations[durKey].start.mm });
        return !newTime.isBefore(newCorTime);
      }

    },

    checkOverlaps: function (newVal, durKey, durations) {

      console.log(durations);

      const newTime = moment().set({ hour: newVal.HH, minute: newVal.mm });

      let overlaps = false;
      for (let i = 0; i < durations.length; i++) {

        if(i === durKey) {
          continue;
        }

        let startTime = null;
        let endTime = null;

        startTime = moment().set({ hour: durations[i].start.HH, minute: durations[i].start.mm });
        endTime = moment().set({ hour: durations[i].end.HH, minute: durations[i].end.mm });

        if (newTime.isBetween(startTime, endTime, null, '[]')) {
          overlaps = true;
        }
      }

      return overlaps;
    },

    suggestNewDuration: function (durationArray) {

      const latestEnd = durationArray.reduce((latest, current) => {
        const currentEndInMinutes = parseInt(current.end.HH) * 60 + parseInt(current.end.mm);
        return currentEndInMinutes > latest ? currentEndInMinutes : latest;
      }, 0);

      const newStartInMinutes = latestEnd + 15;
      const newStartHH = Math.floor(newStartInMinutes / 60).toString().padStart(2, '0');
      const newStartMM = (newStartInMinutes % 60).toString().padStart(2, '0');

      const newEndInMinutes = newStartInMinutes + 60;
      const newEndHH = Math.floor(newEndInMinutes / 60).toString().padStart(2, '0');
      const newEndMM = (newEndInMinutes % 60).toString().padStart(2, '0');

      return {
        price: this.hourlyRate,
        start: { HH: newStartHH, mm: newStartMM },
        end: { HH: newEndHH, mm: newEndMM }
      };
    },
  }

}

</script>

<style scoped>

.border-top-custom {
  border-top: 1px solid #8354C8; /* Adjust the thickness and color */
}

.border-right-custom {
  border-right: 1px solid #8354C8; /* Adjust the thickness and color */
}

</style>
