import { defineStore } from 'pinia';

export const useUserStore = defineStore('userStore', {
    state: () => ({

        user: {
            uid: null,
            username: null,
            email: null,
            name: null,
            first_name: null,
            last_name: null,
            parent_id: null,
            verified: null,
            approved: null,
            status: null,
            role: null,
            profile_image: null,
        },

        wallet: {
            balance: 0,
            earnings: 0,
            withdraws: 0,
            currency: null,
            currency_symbol: null,
        },

        verified: false,
        approved: false,
        emailVerified: false,
        profileCompleted: false,
        authenticated: false,
        timeZone: null,
        hourlyRate: 0,
        profileStatus: null,

        system: {
            min_hourly_rate: 0,
        }
    }),

    actions: {
        setUser(user) {
            this.user = user;
        },

        setWallet(wallet) {
            this.wallet = wallet;
        },

        setAuthenticated(authenticated) {
            this.authenticated = authenticated;
        },

        setEmailVerified(isEmailVerified) {
            this.emailVerified = isEmailVerified;
        },

        setProfileCompleted(isProfileCompleted) {
            this.profileCompleted = isProfileCompleted;
        },

        setUserVerified(isUserVerified) {
            this.verified = isUserVerified;
        },

        setUserApproved(isUserApproved) {
            this.approved = isUserApproved;
        },

        setTimeZone(currentTimeZone) {
            this.timeZone = currentTimeZone;
        },

        setHourlyRate(currentHourlyRate) {
            this.hourlyRate = currentHourlyRate;
        },

        setSystem(system) {
            this.system = system;
        },

        setProfileStatus(status) {
            this.profileStatus = status;
        }
    },

    getters: {
        currentUser: (state) => state.user,
        walletInfo: (state) => state.wallet,
        isEmailVerified: (state) => state.emailVerified,
        isProfileCompleted: (state) => state.profileCompleted,
        isUserVerified: (state) => state.verified,
        isUserApproved: (state) => state.approved,
        isAuthenticated: (state) => state.authenticated,
        currentTimeZone: (state) => state.timeZone,
        currentHourlyRate: (state) => state.hourlyRate,
        systemInfo: (state) => state.system,
        currentProfileStatus: (state) => state.profileStatus,
    },
});
