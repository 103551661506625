<template>
    <div id="paypalss" aria-labelledby="paypal">
        <div class="row mb-3">
            <div class="col-md-6"></div>
            <!-- <div class="col-md-6 edit_icon">
        <div class="float-right">
          <i class="fas fa-edit"></i>
        </div>
      </div> -->
        </div>
        <ul>
            <li v-for="list in lists" :key="list.id">
                <p>{{ list.first_name }}</p>
            </li>
        </ul>
        <form
            @submit.prevent="createInfo()"
            action=""
            enctype="multipart/form-data"
            method="post"
        >
            <!-- <h4>Personal Information</h4> -->
            <div class="row personal_info_imgSec">
                <div class="row-upload-img">
                    <div class="change-img">
                        <img
                            src="@/assets/new-theme-images/clarity_edit-line.png"
                            alt="edit"
                            class="profile-edit-image"
                            v-on:click="choosePic()"
                        />
                        <img
                            width="160px"
                            :src="
                                previewImage
                                    ? previewImage
                                    : '/storage' + info?.image
                            "
                            class="profile-uploaded-img"
                        />
                        <input
                            v-on:change="handleFileUpload()"
                            ref="stdImage"
                            type="file"
                            class="upload-img-input"
                            id="choosePic"
                        />
                    </div>
                    <div class="row w-100 ml-5 mr-4">
                        <div class="form-group col-lg-6 col-12 pl-0">
                            <label for="first_name"
                                >First Name
                                <span class="text-danger">*</span></label
                            >
                            <input
                                type="text"
                                id="first_name"
                                name="first_name"
                                v-model="info.first_name"
                                class="form-control"
                                readonly
                                placeholder="First Name"
                                v-on:focus="errorMessages.password = []"
                            />
                            <ul
                                class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                v-if="errorMessages.first_name.length > 0"
                            >
                                <li v-for="err in errorMessages.first_name" :key="err">
                                    <small>{{ err }}</small>
                                </li>
                            </ul>
                        </div>
                        <div class="form-group col-lg-6 col-12 pr-0">
                            <label for="last_name"
                                >Last Name
                                <span class="text-danger">*</span></label
                            >
                            <input
                                type="text"
                                id="last_name"
                                name="last_name"
                                v-model="info.last_name"
                                class="form-control"
                                readonly
                                placeholder="Last Name"
                                v-on:focus="errorMessages.password = []"
                            />
                            <ul
                                class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                v-if="errorMessages.last_name.length > 0"
                            >
                              <!-- eslint-disable -->
                                <li v-for="err in errorMessages.last_name">
                                    <small>{{ err }}</small>
                                </li>
                            </ul>
                        </div>
                        <div class="form-group col-lg-6 col-12 pl-0">
                            <label for="username"
                                >Username
                                <span class="text-danger">*</span></label
                            >
                            <input
                                disabled
                                type="text"
                                id="username"
                                name="username"
                                v-model="info.username"
                                class="form-control"
                                placeholder="Username"
                                v-on:focus="errorMessages.password = []"
                            />
                            <ul
                                class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                v-if="errorMessages.username.length > 0"
                            >
                                <li v-for="err in errorMessages.username" :key="err">
                                    <small>{{ err }}</small>
                                </li>
                            </ul>
                        </div>
                        <div class="form-group col-lg-6 col-12 pr-0">
                            <label for="email"
                                >Email <span class="text-danger">*</span></label
                            >
                            <input
                                disabled
                                type="email"
                                id="email"
                                name="email"
                                v-model="info.email"
                                class="form-control"
                                placeholder="Last Name"
                                v-on:focus="errorMessages.password = []"
                            />
                            <ul
                                class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                v-if="errorMessages.email.length > 0"
                            >
                                <li v-for="err in errorMessages.email" :key="err">
                                    <small>{{ err }}</small>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="upload-img">
                        <button :disabled="!file" v-on:click="uploadImage()">
                            Upload your Picture
                        </button>
                    </div> -->
                </div>
            </div>
            <div class="group_inline">
                <div class="form-group col-md-6 col-12 pl-0">
                    <label>Gender</label>
                    <div class="radio-btns">
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                value="male"
                                v-model="info.gender"
                                :checked="info.gender === 'male'"
                                v-on:focus="errorMessages.password = []"
                            />
                            <label class="form-check-label" for="inlineRadio1"
                                >Male</label
                            >
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                value="female"
                                v-model="info.gender"
                                :checked="info.gender === 'female'"
                                v-on:focus="errorMessages.password = []"
                            />
                            <label class="form-check-label" for="inlineRadio2"
                                >Female</label
                            >
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio3"
                                value="other"
                                v-model="info.gender"
                                :checked="info.gender === 'other'"
                                v-on:focus="errorMessages.password = []"
                            />
                            <label class="form-check-label" for="inlineRadio3"
                                >Other</label
                            >
                        </div>
                        <ul
                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                            v-if="errorMessages.gender.length > 0"
                        >
                            <li v-for="err in errorMessages.gender" :key="err">
                                <small>{{ err }}</small>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="form-group col-md-6 col-12 pl-0">
                    <label>Intro Video (15 MB)</label>
                    <div class="d-flex flex-column">
                        <input
                            v-on:change="handleVideoUpload()"
                            ref="stdVideo"
                            type="file"
                            accept="video/*"
                            class="upload-video-input"
                            id="chooseVideo"
                        />
                        <a
                            v-if="info?.intro_video"
                            target="_blank"
                            class="mt-1"
                            :href="'/storage/' + info?.intro_video"
                            >View Video
                        </a>
                    </div>
                    <ul
                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                        v-if="errorMessages.intro_video.length > 0"
                    >
                        <li v-for="err in errorMessages.intro_video" :key="err">
                            <small>{{ err }}</small>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="group_inline">
                <div class="form-group col-md-6 col-12 pl-0">
                    <label class=""
                        >What do you teach?
                        <span class="text-danger">*</span></label
                    >
                    <multiselect
                        v-model="subjects_id"
                        :options="subjects"
                        :multiple="true"
                        :close-on-select="true"
                        placeholder="Choose Subjects"
                        label="subject"
                        track-by="id"
                    ></multiselect>
                    <ul
                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                        v-if="
                            errorMessages.subjects_id.length > 0 &&
                            subjects_id.length === 0
                        "
                    >
                        <li v-for="err in errorMessages.subjects_id" :key="err">
                            <small>{{ err }}</small>
                        </li>
                    </ul>
                </div>
                <div class="form-group col-md-6 col-12 ml-2 pl-0">
                    <label class="">What levels do you teach?</label>
                    <multiselect
                        v-model="grades_id"
                        :options="grades"
                        :multiple="true"
                        :close-on-select="true"
                        placeholder="Choose Grades"
                        label="name"
                        track-by="id"
                    ></multiselect>
                    <ul
                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                        v-if="
                            errorMessages.grades_id.length > 0 &&
                            grades_id.length === 0
                        "
                    >
                        <li v-for="err in errorMessages.grades_id" :key="err">
                            <small>{{ err }}</small>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="group_inline">
                <div class="form-group col-lg-6 col-12 pl-0">
                    <label>Time Zone <span class="text-danger">*</span></label>
                    <select
                        class="form-control"
                        id="timezone"
                        v-model="info.timezone"
                        v-on:focus="errorMessages.password = []"
                    >
                        <option :value="timezone" v-for="timezone in timzones" :key="timezone.id">
                            {{ timezone }}
                        </option>
                        <ul
                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                            v-if="errorMessages.timezone.length > 0"
                        >
                            <li v-for="err in errorMessages.timezone" :key="err">
                                <small>{{ err }}</small>
                            </li>
                        </ul>
                    </select>
                </div>
                <div class="form-group col-lg-6 col-12 pr-0">
                    <label for="phone"
                        >Mobile Number <span class="text-danger">*</span></label
                    >
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        v-model="info.phone"
                        class="form-control"
                        required
                        placeholder="Mobile Number"
                        v-on:focus="errorMessages.password = []"
                    />
                    <ul
                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                        v-if="errorMessages.phone.length > 0"
                    >
                        <li v-for="err in errorMessages.phone" :key="err">
                            <small>{{ err }}</small>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="group_inline">
                <div class="form-group col-lg-6 col-12 pl-0">
                    <label for="cnic"
                        >CNIC <span class="text-danger">*</span></label
                    >
                    <input
                        type="number"
                        id="cnic"
                        name="cnic"
                        minlength="13"
                        v-model="info.cnic"
                        class="form-control"
                        required
                        @input="checkLength()"
                        placeholder="11111-1111111-1"
                        v-on:focus="errorMessages.password = []"
                    />
                    <ul
                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                        v-if="errorMessages.cnic.length > 0"
                    >
                        <li v-for="err in errorMessages.cnic" :key="err">
                            <small>{{ err }}</small>
                        </li>
                    </ul>
                </div>
                <div class="form-group col-lg-6 col-12 pr-0">
                    <label for="dob"
                        >Date of Birth <span class="text-danger">*</span></label
                    >
                    <input
                        type="date"
                        id="dob"
                        name="dob"
                        v-model="info.dob"
                        class="form-control"
                        required
                        placeholder="DOB"
                        v-on:focus="errorMessages.password = []"
                    />
                    <ul
                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                        v-if="errorMessages.dob.length > 0"
                    >
                        <li v-for="err in errorMessages.dob" :key="err">
                            <small>{{ err }}</small>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="group_inline">
                <div class="form-group col-lg-6 col-12 pl-0">
                    <label>City <span class="text-danger">*</span></label>
                    <select
                        class="form-control"
                        id="timezone"
                        v-model="info.city_id"
                        v-on:focus="errorMessages.password = []"
                    >
                        <option value="0">Choose City</option>
                        <option :value="city.id" v-for="city in cities" :key="city.id">
                            {{ city.title }}
                        </option>
                    </select>
                    <ul
                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                        v-if="errorMessages.title.length > 0"
                    >
                        <li v-for="err in errorMessages.title" :key="err">
                            <small>{{ err }}</small>
                        </li>
                    </ul>
                </div>
                <div class="form-group col-lg-6 col-12 pr-0">
                    <label for="address"
                        >Address
                        <span class="text-danger">*</span></label
                    >
                    <input
                        type="text"
                        id="address"
                        name="address"
                        v-model="info.address"
                        class="form-control"
                        placeholder="Your Address"
                        v-on:focus="errorMessages.password = []"
                    />
                    <ul
                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                        v-if="errorMessages.address.length > 0"
                    >
                        <li v-for="err in errorMessages.address" :key="err">
                            <small>{{ err }}</small>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="group_inline">
                <div class="form-group col-lg-6 col-12 pl-0">
                    <label
                        >Hourly Rate (PKR)
                        <span class="text-danger">*</span></label
                    >
                    <input
                        type="number"
                        id="price"
                        name="price"
                        v-model="info.price"
                        class="form-control"
                        required
                        placeholder=""
                        v-on:focus="errorMessages.password = []"
                    />
                    <ul
                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                        v-if="errorMessages.price.length > 0"
                    >
                        <li v-for="err in errorMessages.price" :key="err">
                            <small>{{ err }}</small>
                        </li>
                    </ul>
                </div>
                <!-- <div class="form-group col-lg-6 col-12 pr-0">
                    <label
                        >Share What You're Upto
                        <span class="text-danger">*</span></label
                    >
                    <input
                        type="text"
                        id="about_short"
                        name="about_short"
                        v-model="info.about_short"
                        class="form-control"
                        required
                        placeholder=""
                        v-on:focus="errorMessages.password = []"
                    />
                    <ul
                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                        v-if="errorMessages.about_short.length > 0"
                    >
                        <li v-for="err in errorMessages.about_short">
                            <small>{{ err }}</small>
                        </li>
                    </ul>
                </div> -->
                <!-- <div class="form-group col-lg-6 col-12 pr-0">
                    <label> Cancellation Time (Hours)</label>
                    <input
                        type="number"
                        min="0"
                        id="cancelation_time"
                        name="cancelation_time"
                        v-model="info.cancelation_time"
                        class="form-control"
                        required
                        placeholder=""
                        v-on:focus="errorMessages.password = []"
                    />
                    <ul
                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                        v-if="errorMessages.cancelation_time.length > 0"
                    >
                        <li v-for="err in errorMessages.cancelation_time">
                            <small>{{ err }}</small>
                        </li>
                    </ul>
                </div> -->
            </div>
            <div class="group_inline">
                <div class="form-group col-lg-12 col-12 pl-0">
                    <label>Google Meet Link</label>
                    <input
                        type="text"
                        id="zoom_link"
                        name="zoom_link"
                        v-model="info.zoom_link"
                        class="form-control w-100"
                        required
                        placeholder="Zoom Personal Meeting Link"
                        v-on:focus="errorMessages.password = []"
                    />
                    <ul
                        class="text-sm text-danger text-red-600 text-xs italic mt-1"
                        v-if="errorMessages.zoom_link.length > 0"
                    >
                        <li v-for="err in errorMessages.zoom_link" :key="err">
                            <small>{{ err }}</small>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="form-group">
                <label>About <span class="text-danger">*</span></label>
<!--                <ckeditor-->
<!--                    :editor="editor"-->
<!--                    v-model="info.about"-->
<!--                    :config="editorConfig"-->
<!--                ></ckeditor>-->
                <textarea class="form-control" v-model="info.about"></textarea>
                <ul
                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                    v-if="errorMessages.about.length > 0"
                >
                    <li v-for="err in errorMessages.about" :key="err">
                        <small>{{ err }}</small>
                    </li>
                </ul>
            </div>
            <div class="d-flex align-items-center justify-content-end">
                <span></span>
                <button
                    type="submit"
                    class="btn custom-button d-flex align-items-center justify-content-center"
                    style="width: 100px"
                    :disabled="!!loading"
                >
                    <div
                        v-if="!!loading"
                        class="spinner-border text-primary"
                        style="
                            color: white !important;
                            width: 20px !important;
                            height: 20px !important;
                        "
                        role="status"
                    ></div>

                    <span v-if="!!loading == false">Next</span>
                </button>
            </div>
        </form>
    </div>
</template>
<script>

import _ from "lodash";
const moment = require("moment-timezone");
// import { ClassicEditor } from 'ckeditor5';
// import 'ckeditor5/ckeditor5.css';
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";

export default {
    name: "PersonalInfo",
    props: [],
    components: {},
    directives: {},
    data() {
        return {
            // editor: ClassicEditor,
            editorData: "",
            editorConfig: {},
            timzones: "",
            file: "",
            videoFile: null,
            previewImage: null,
            loading: false,
            info: {
                first_name: "",
                middle_name: "",
                last_name: "",
                username: "",
                email: "",
                phone: "",
                cnic: "",
                gender: "",
                address: "",
                file: "",
                image: "",
                about_short: "",
                about: "",
                dob: "",
                intro_video: "",
                city_id: 0,
                subjects_id: [],
                grades_id: [],
            },
            errorMessages: {
                title: [],
                cancelation_time: [],
                zoom_link: [],
                price: [],
                first_name: [],
                middle_name: [],
                last_name: [],
                username: [],
                email: [],
                phone: [],
                cnic: [],
                gender: [],
                address: [],
                file: [],
                image: [],
                about_short: [],
                about: [],
                dob: [],
                city_id: [],
                subjects_id: [],
                grades_id: [],
                timezone: [],
                intro_video: [],
            },
            subjects_id: [],
            grades_id: [],
            cities: [],
            subjects: [],
            grades: [],
            lists: null,
        };
    },
    computed: {},
    async created() {
        this.timzones = moment.tz.names();
        if (!this.subjects.length) await this.getFilterContent();
        if (!Object.keys(this.info.grades_id).length) await this.getList();
    },
    mounted() {
    },
    methods: {
        bytesToMB(bytes) {
            return (bytes / (1024 * 1024)).toFixed(2);
        },
        handleFileUpload() {
            this.file = this.$refs.stdImage.files[0];
            this.previewImage = this.file
                ? URL.createObjectURL(this.file)
                : null;
        },
        isVideoFile(file) {
            return file.type.includes("video");
        },
        handleVideoUpload() {
            const self = this;
            self.videoFile = self.$refs.stdVideo.files[0];
            if (self.isVideoFile(self.videoFile)) {
                self.checkIntroVideo();
            } else {
                self.$toasted.error("Please Select Video File...");
                self.videoFile = null;
                self.$refs.stdVideo.value = "";
            }
        },
        async getFilterContent() {
            const filterRes = await axiosInstance.get(endpoints.filterContent);
            console.log("______________________________________data");

            let data = null;

            if(filterRes) {
              data = filterRes.data.data;
            }

            this.subjects = data.subjects;
            this.grades = data.grades;
            this.cities = data.cities;
        },
        async getList() {
            const infoRes = await axiosInstance.get(endpoints.info);

          let data = null;
            if(infoRes.data) {
              data = infoRes.data.data;
            }

            console.log(data);

            if (data) {
              console.log('info received');
                this.info = data.info;
                this.grades_id = data.grades;
                this.subjects_id = data.subjects;
            }
            if (data?.contacts?.length > 0) {
                let zoom = data.contacts.find(
                    (contact) => contact.model === "Zoom"
                );
                if (zoom) this.info.zoom_link = zoom.contact;
                let skype = data.contacts.find(
                    (contact) => contact.model === "Skype"
                );
                if (skype) this.info.skype_id = skype.contact;
            }
            this.info.username = data?.user.username;
            this.info.email = data?.user.email;
            this.info.first_name = data?.user.first_name;
            this.info.last_name = data?.user.last_name;
            this.info.middle_name = data?.user.middle_name;
            this.info.image = data?.user.image;
            this.info.intro_video = data?.user.intro_video;
        },
        checkAbout() {
            this.errorMessages.about = [];
            if (!this.info.about || this.info.about.length < 200) {
                this.errorMessages.about.push(
                    "Enter Minimum 200 Characters Description ..."
                );
                return false;
            } else {
                this.errorMessages.about = [];
                return true;
            }
        },
        checkFirstName() {
            this.errorMessages.first_name = [];
            if (!this.info.first_name) {
                this.errorMessages.first_name.push("Enter First Name ...");
                return false;
            } else {
                this.errorMessages.first_name = [];
                return true;
            }
        },
        checkLastName() {
            this.errorMessages.last_name = [];
            if (!this.info.last_name) {
                this.errorMessages.last_name.push("Enter Last Name ...");
                return false;
            } else {
                this.errorMessages.last_name = [];
                return true;
            }
        },
        checkUsername() {
            this.errorMessages.username = [];
            if (!this.info.username) {
                this.errorMessages.username.push("Enter User Name ...");
                return false;
            } else {
                this.errorMessages.username = [];
                return true;
            }
        },
        checkGender() {
            this.errorMessages.gender = [];
            if (!this.info.gender) {
                this.errorMessages.gender.push("Choose Gender ...");
                return false;
            } else {
                this.errorMessages.gender = [];
                return true;
            }
        },
        checkMobileNumber() {
            this.errorMessages.phone = [];
            if (!this.info.phone) {
                this.errorMessages.phone.push("Enter Mobile number ...");
                return false;
            } else {
                this.errorMessages.phone = [];
                return true;
            }
        },
        checkSubjects() {
            this.errorMessages.subjects_id = [];
            if (this.subjects_id.length === 0) {
                this.errorMessages.subjects_id.push("Choose Subjects...");
                return false;
            } else {
                this.errorMessages.subjects_id = [];
                return true;
            }
        },
        checkCnic() {
            this.errorMessages.cnic = [];
            if (!this.info.cnic || this.info.cnic.length < 13) {
                this.errorMessages.cnic.push("Enter CNIC 13 Digits...");
                return false;
            } else {
                this.errorMessages.cnic = [];
                return true;
            }
        },
        checkGrades() {
            this.errorMessages.grades_id = [];
            if (this.grades_id.length === 0) {
                this.errorMessages.grades_id.push("Choose Grades...");
                return false;
            } else {
                this.errorMessages.grades_id = [];
                return true;
            }
        },
        checkCity() {
            this.errorMessages.city_id = [];
            if (this.city_id === 0) {
                this.errorMessages.city_id.push("Choose City...");
                return false;
            } else {
                this.errorMessages.city_id = [];
                return true;
            }
        },
        checkPrice() {
            this.errorMessages.price = [];
            if (this.info.price === 0) {
                this.errorMessages.price.push("Enter Price...");
                return false;
            } else {
                this.errorMessages.price = [];
                return true;
            }
        },
        checkCancellationTime() {
            this.errorMessages.cancelation_time = [];
            if (this.info.cancelation_time === 0) {
                this.errorMessages.cancelation_time.push(
                    "Enter Cancellation Time..."
                );
                return false;
            } else {
                this.errorMessages.cancelation_time = [];
                return true;
            }
        },
        checkIntroVideo() {
            this.errorMessages.intro_video = [];

            if (this.videoFile) {
                if (this.bytesToMB(this.videoFile?.size) >= 15) {
                    this.errorMessages.intro_video.push(
                        "Video size must be less than 15 MB..."
                    );
                    return false;
                }
            }

            if (this.info.intro_video === 0) {
                this.errorMessages.intro_video.push("Choose Intro Video...");
                return false;
            } else {
                this.errorMessages.intro_video = [];
                return true;
            }
        },
        async createInfo() {
            this.checkAbout();
            this.checkFirstName();
            this.checkUsername();
            this.checkMobileNumber();
            this.checkSubjects();
            this.checkCnic();
            this.checkGrades();
            this.checkCity();
            this.checkPrice();
            this.checkCancellationTime();
            this.checkIntroVideo();
            if (
                this.checkAbout() &&
                this.checkFirstName() &&
                this.checkUsername() &&
                this.checkMobileNumber() &&
                this.checkSubjects() &&
                this.checkCnic() &&
                this.checkGrades() &&
                this.checkCity() &&
                this.checkPrice() &&
                this.checkCancellationTime() &&
                this.checkIntroVideo()
            ) {
                let self = this;
                let formData = new FormData();
                formData.append("file", this.file);
                _.each(this.info, (value, key) => {
                    formData.append(key, value);
                });
                formData.append("grades", JSON.stringify(self.grades_id));
                formData.append("subjects", JSON.stringify(self.subjects_id));
                if (this.videoFile) {
                    formData.append("videoFile", this.videoFile);
                }
                this.loading = true;
                const { data } = await axiosInstance.post(endpoints.infoPro, formData);
                this.loading = false;
                if (data.type === "success") {
                    toast.success(data.message).goAway(2000);
                    this.$parent.nextTab();
                    // await self.getList();
                } else if (data.type === "validation") {
                    Object.keys(data.data).forEach(function (key) {
                        self.errorMessages[key] = data.data[key];
                    });
                } else if (data.type === "error") {
                    toast.error(data.message);
                }
            }
        },
        choosePic() {
            document.getElementById("choosePic").click();
        },
        async uploadImage() {
            if (!this.file) {
                toast.error("Choose Image...");
            } else {
                let formData = new FormData();
                formData.append("file", this.file);
                const { data } = await axiosInstance.post(endpoints.uploadProfileImage, formData);
                this.file = "";
                toast.error(data.message);
            }
        },
        checkLength() {
            if (this.info.cnic.length > 13) {
                this.info.cnic = this.info.cnic.slice(0, 13);
            }
        },
    },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.multiselect__input,
.multiselect__single,
.multiselect__input input {
    border: none !important;
}
.profile-uploaded-img,
.change-img {
    width: 10vw !important;
    height: 10vw !important;
}
.home_content.dashboard-newtheme .tabs_2 .row-upload-img {
    justify-content: left !important;
}
.profile-edit-image {
    right: 5px !important;
    top: 10px !important;
}
.home_content.dashboard-newtheme
    .tabs_2
    form
    .form-group
    .radio-btns
    .form-check.form-check-inline {
    margin-right: 10px !important;
}
</style>
