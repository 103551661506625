<template>
  <div class="col-lg-9">
    <div class="job-listing-filter-a">
      Apply Filter <img src="@/assets/new-theme-images/bx-filter-alt.png" alt="filter" />
    </div>
    <div class="course-con">
      <!-- eslint-disable -->
      <div v-for="job in jobs" class="card mb-4 card-style-01 job-post">
        <div class="card-header">
          <div class="row">
            <div class="col-9 col-sm-9 col-md-9">
              <div class="media tuition-listing-media">
                <div class="media-body">
                  <a :href="'/post/job/detail/'+job.uuid">
                    <h5 class="mt-0 mb-0">{{ job.title }}</h5>
                  </a>
                  <small class="job-posted"> Posted by <strong>{{ job.user.name }},</strong> {{ postedAt(job.created_at) }} ago</small>
                </div>
              </div>
            </div>


            <!-- <div v-on:click="showApplyForm(job)" class="col text-right d-flex justify-content-end align-items-center col-sm-6"> -->
            <div class="col text-right d-flex justify-content-end align-items-center col-3 col-sm-3 col-md-3 p-0">
              <div v-if="$parent.authenticated && $parent.user.active_role === 'tutor' && job.user_id !== $parent.user.id && checkVerifiedJob(job)" class="top-bttns">
                <button v-if="$parent.applied_jobs.findIndex(j => parseInt(j.job_id) === parseInt(job.id)) < 0 && $parent.user.active_role === 'tutor'" v-on:click="showApplyForm(job)" class="btn btn-block active roboto-font">Apply</button>
                <button v-else class="btn btn-block active roboto-font" disabled>Applied</button>
              </div>
              <div v-else-if="!$parent.authenticated && !$parent.user.active_role" class="top-bttns">
              </div>
              <svg v-on:click="addToSaved(job.id)" v-if="$parent.savedItems.findIndex(saved => saved.model === 'Post' && saved.model_id === job.id) === -1 && $parent.user.active_role === 'tutor'" class="ml-3 mr-3" xmlns="http://www.w3.org/2000/svg" width="25" height="26.089" viewBox="0 0 21.068 21.068">
                <g id="Icons" transform="translate(-16.368 -13.471)">
                  <g id="Group_6612" data-name="Group 6612">
                    <path id="Path_8299" data-name="Path 8299" d="M30.373,33.96l-5.962-8.792a.5.5,0,0,0-.823,0L17.627,33.96a.5.5,0,0,1-.909-.279V14.318a.5.5,0,0,1,.5-.5h13.57a.5.5,0,0,1,.5.5V33.681A.5.5,0,0,1,30.373,33.96Z" fill="none" stroke="#812bb2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.7"/>
                    <path id="Path_8300" data-name="Path 8300" d="M30.373,33.96l-5.962-8.792a.5.5,0,0,0-.823,0L17.627,33.96a.5.5,0,0,1-.909-.279V14.318a.5.5,0,0,1,.5-.5h13.57a.5.5,0,0,1,.5.5V33.681A.5.5,0,0,1,30.373,33.96Z" fill="none" stroke="#812bb2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.7"/>
                  </g>
                </g>
              </svg>
              <svg v-on:click="addToSaved(job.id)" v-else-if="$parent.user.active_role === 'tutor'" class="ml-3 mr-3" xmlns="http://www.w3.org/2000/svg" width="25" height="26.068" viewBox="0 0 20 21.068">
                <g id="Icons" transform="translate(-16.368 -13.471)">
                  <g id="Group_6612" data-name="Group 6612">
                    <path id="Path_8299" data-name="Path 8299" d="M30.373,33.96l-5.962-8.792a.5.5,0,0,0-.823,0L17.627,33.96a.5.5,0,0,1-.909-.279V14.318a.5.5,0,0,1,.5-.5h13.57a.5.5,0,0,1,.5.5V33.681A.5.5,0,0,1,30.373,33.96Z" fill="yellow" stroke="#812bb2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.7"/>
                    <path id="Path_8300" data-name="Path 8300" d="M30.373,33.96l-5.962-8.792a.5.5,0,0,0-.823,0L17.627,33.96a.5.5,0,0,1-.909-.279V14.318a.5.5,0,0,1,.5-.5h13.57a.5.5,0,0,1,.5.5V33.681A.5.5,0,0,1,30.373,33.96Z" fill="#812bb2" stroke="#812bb2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.7"/>
                  </g>
                </g>
              </svg>
              <div v-if="$parent.authenticated" class="dropdown px-2 md:py-4 mr-3">
                <i class="fas fa-ellipsis-h ellipsis_icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                <div class="dropdown-content">
                  <span class="item-name">
                    <a v-if="$parent.authenticated && job.user_id !== $parent.user.id" v-on:click="reportJob(job)" class="dropdown-item border-bottom py-2" href="javascript:void (0)"><i class="fa fa-exclamation-triangle mr-3" aria-hidden="true"></i>Report </a>
                    <a v-if="$parent.authenticated" v-on:click="copyJobLink(job.uuid)" class="dropdown-item border-bottom py-2" href="javascript:void (0)"> <img src="/new-theme-images/copy-link.png" class="mr-2" alt="copy" />Copy Link</a>
                    <input type="hidden" :value="'/job/post/detail/'+job.uuid" :id="'job-detail'+job.uuid">
                    <a v-if="$parent.authenticated && job.user_id === $parent.user.id" v-on:click="$parent.editPost(job.id)" class="dropdown-item border-bottom py-2" href="javascript:void (0)"> <i class="fa fa-pen mr-3"></i> Edit Job</a>
                    <a v-if="$parent.authenticated && job.user_id === $parent.user.id && job.status === 'open'" v-on:click="jobPostClose(job.id, 'close')" class="dropdown-item border-bottom py-2" href="javascript:void (0)"> <i class="fa fa-window-close mr-3"></i> Close Job</a>
                    <a v-if="$parent.authenticated && job.user_id === $parent.user.id && job.status === 'close'" v-on:click="jobPostClose(job.id, 'open')" class="dropdown-item border-bottom py-2" href="javascript:void (0)"> <i class="fa fa-window-maximize mr-3"></i> Open Job</a>
                    <!--                    <a v-on:click="$parent.deletePost(job.id)" href="javascript:void(0)" class="dropdown-item border-bottom py-2"> <img src="/new-theme-images/apply-job.png" class="mr-2" alt="apply job" /> Easy Apply</a>-->
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body" v-if="!!job.description">
          <p class="card-text" v-html="job.description.substring(0,500)+`<a href='/post/job/detail/${job.uuid}'>....View More</a>`"></p>
        </div>

        <div class="card-footer text-muted">
          <div class="row">
            <div class="subject col-md-12"><strong>Subjects: </strong> <span v-for="subject in job.subjects"> {{ subject.subject.subject }} </span> </div>
            <div class="level col-md-12"><strong>Level: </strong> {{  job.level.charAt(0).toUpperCase() + job.level.slice(1)}}</div>
            <div :title="job.institute.name" class="alumni col-md-12"><strong>Alumuni of: </strong> {{ job.institute.name.substr(0, 70) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$parent.query.total > 8" class="text-center mb-5">
      <pagination v-show="$parent.query.total > 0" v-model="$parent.query.page" :records="$parent.query.total" :per-page="$parent.query.limit" @paginate="$parent.getJobPost()"/>
    </div>

    <ContentReportModalComponent modal="JobPost" v-if="reportModal"></ContentReportModalComponent>

  </div>
</template>

<script>
import moment from 'moment-timezone'
import ContentReportModalComponent from "@/components/utilities/report/ContentReportModalComponent";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";

export default {
  name: "JobListingComponent",
  components: {ContentReportModalComponent},
  props: ['jobs'],
  data() {
    return {
      reportModal: false,
      job_id: 0,
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    postedAt(date) {
      let created = moment(date).tz()
      let today = moment(new Date()).tz()
      return moment.duration(today.diff(created)).humanize()
    },
    showApplyForm(job) {
      this.$parent.selected_job = job
      this.$parent.applyJobFrom = !this.$parent.applyJobFrom
    },
    async addToSaved(id) {
      let self = this
      if (!this.$parent.authenticated) {
        this.showLoginForm()
      }
      else {
        this.selectedJob = id
        let formdata = new FormData()
        formdata.append('model', 'Post')
        formdata.append('model_id', id)

        const {data} = await axiosInstance.post(endpoints.savedItemsRoute, formdata);
        if (data.type === 'success') {
          self.$parent.savedItems = data.data
          toast.success(data.message)
        }
      }
    },
    async jobPostClose(id, status) {
      let formdata = new FormData()
      formdata.append('job_id', id)
      formdata.append('status', status)
      const {data} = await axiosInstance.post(endpoints.jobPostStatusRoute, formdata);
      await this.$parent.getJobPost()
      if (data.type === 'success')
        toast.success(data.message)
    },
    reportJob(job) {
      this.modal_id = job.id
      this.reportModal = !this.reportModal
    },
    async copyJobLink(id) {
      await navigator.clipboard.writeText(window.location.origin + '/post/job/detail/' + id);
      toast.success('Link Copied Successfully...!')
    },
    checkVerifiedJob(job) {
      if (job.verified_only) {
        return this.$parent.user.verified === job.verified_only
      }
      else
        return true;
    }
  }
}

</script>

<style scoped>
.course-con .card.mb-4.card-style-01 .card-footer.text-muted {
  font-size: 12px;
  width: auto;
  margin-left: 0;
  padding-left: 30px !important;
}
.card.mb-4.card-style-01.job-post .card-body {
  width: auto;
  padding-top: 10px;
}
.course-con .card-header .media-body h5 {
  color: #812bb2 !important;
}

</style>
