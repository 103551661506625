<template>
    <div id="reference">
        <div class="experience-header">
            <h2>Certifications</h2>
            <img
                src="@/assets/new-theme-images/bi_plus-square.png"
                alt="plus"
                @click="openAddExp()"
            />
        </div>

        <div v-for="license in licenses" class="row experience-item" :key="license.id">
            <div class="col-md-6">
                <h5 class="experience-title">{{ license.title }}</h5>
                <p class="experience-desc">
                    <u>{{ license.organization }}</u>
                </p>
            </div>

            <div class="col-md-6 experience-edit">
                <img
                    @click="editLicense(license.id)"
                    src="@/assets/new-theme-images/clarity_edit-line.png"
                    alt="edit"
                />
                <img
                    @click="deleteLicense(license.id)"
                    src="@/assets/new-theme-images/trash.png"
                    alt="trash"
                />
            </div>
        </div>

        <div
            v-if="referenceModal"
            style="
                display: block;
                background-color: rgba(0, 0, 0, 0.4);
                overflow: auto;
            "
            id="addqualification"
            class="modal bd-example-modal-lg"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
        >
            <form action="" @submit.prevent="addLicense()">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Add Certification</h5>
                            <button
                                type="button"
                                class="close"
                                v-on:click="referenceModal = !referenceModal"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="name">Name</label>
                                        <input
                                            required
                                            type="text"
                                            v-model="license.title"
                                            class="form-control"
                                            id="name"
                                            aria-describedby="emailHelp"
                                            placeholder="Name"
                                            v-on:focus="
                                                errorMessages.title = []
                                            "
                                        />
                                        <ul
                                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                            v-if="
                                                errorMessages.title.length > 0
                                            "
                                        >
                                            <li
                                                v-for="err in errorMessages.title" :key="err"
                                            >
                                                <small>{{ err }}</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="organization"
                                            >Issuing Organization</label
                                        >
                                        <input
                                            required
                                            type="text"
                                            v-model="license.organization"
                                            class="form-control"
                                            id="organization"
                                            aria-describedby="emailHelp"
                                            placeholder="Issuing Organization"
                                            v-on:focus="
                                                errorMessages.organization = []
                                            "
                                        />
                                        <ul
                                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                            v-if="
                                                errorMessages.organization
                                                    .length > 0
                                            "
                                        >
                                            <li
                                                v-for="err in errorMessages.organization" :key="err"
                                            >
                                                <small>{{ err }}</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="issue_date"
                                            >Issue Date</label
                                        >
                                        <input
                                            required
                                            type="date"
                                            v-model="license.issue_date"
                                            class="form-control"
                                            id="issue_date"
                                            aria-describedby="emailHelp"
                                            placeholder="Start Date"
                                            v-on:focus="
                                                errorMessages.issue_date = []
                                            "
                                        />
                                        <ul
                                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                            v-if="
                                                errorMessages.issue_date
                                                    .length > 0
                                            "
                                        >
                                            <li
                                                v-for="err in errorMessages.issue_date" :key="err"
                                            >
                                                <small>{{ err }}</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="expiration_date"
                                            >Expiration Date</label
                                        >
                                        <input
                                            required
                                            type="date"
                                            v-model="license.expiration_date"
                                            class="form-control"
                                            id="expiration_date"
                                            aria-describedby="emailHelp"
                                            placeholder="End Date"
                                            v-on:focus="
                                                errorMessages.expiration_date =
                                                    []
                                            "
                                        />
                                        <ul
                                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                            v-if="
                                                errorMessages.expiration_date
                                                    .length > 0
                                            "
                                        >
                                            <li
                                                v-for="err in errorMessages.expiration_date" :key="err"
                                            >
                                                <small>{{ err }}</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="credential_id"
                                            >Credential Id</label
                                        >
                                        <input
                                            required
                                            type="text"
                                            v-model="license.credential_id"
                                            class="form-control"
                                            id="credential_id"
                                            aria-describedby="emailHelp"
                                            placeholder="Credential Id"
                                            v-on:focus="
                                                errorMessages.credential_id = []
                                            "
                                        />
                                        <ul
                                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                            v-if="
                                                errorMessages.credential_id
                                                    .length > 0
                                            "
                                        >
                                            <li
                                                v-for="err in errorMessages.credential_id" :key="err"
                                            >
                                                <small>{{ err }}</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="credential_url"
                                            >Credential Url</label
                                        >
                                        <input
                                            required
                                            type="text"
                                            v-model="license.credential_url"
                                            class="form-control"
                                            id="credential_url"
                                            aria-describedby="emailHelp"
                                            placeholder="Credential Url"
                                            v-on:focus="
                                                errorMessages.credential_url =
                                                    []
                                            "
                                        />
                                        <ul
                                            class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                            v-if="
                                                errorMessages.credential_url
                                                    .length > 0
                                            "
                                        >
                                            <li
                                                v-for="err in errorMessages.credential_url" :key="err"
                                            >
                                                <small>{{ err }}</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="mx-auto text-center mt-2">
                                <button
                                    type="submit"
                                    class="btn confirm_deposite_btn"
                                    :disabled="!!loading"
                                >
                                    <div
                                        v-if="!!loading"
                                        class="spinner-border text-primary"
                                        style="
                                            color: white !important;
                                            width: 20px !important;
                                            height: 20px !important;
                                        "
                                        role="status"
                                    ></div>

                                    <span v-if="!!loading == false">Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="d-flex align-items-center justify-content-end mt-3">
            <span></span>
            <button
                type="submit"
                class="btn custom-button d-flex align-items-center justify-content-center"
                style="width: 100px"
                v-on:click="$parent.finishProfileSetup()"
                :disabled="!!this.$parent?.finishBtnLoading"
            >
                <div
                    v-if="!!this.$parent?.finishBtnLoading"
                    class="spinner-border text-primary"
                    style="
                        color: white !important;
                        width: 20px !important;
                        height: 20px !important;
                    "
                    role="status"
                ></div>
                <span v-if="!!this.$parent?.finishBtnLoading == false"
                    >Finish</span
                >
            </button>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";

export default {
    name: "ReferenceComponent",
    props: [],
    components: {},
    directives: {},
    data() {
        return {
            licenses: [],
            license: {
                id: "",
                title: "",
                organization: "",
                issue_date: "",
                expiration_date: "",
                credential_id: "",
                credential_url: "",
            },
            errorMessages: {
                id: [],
                title: [],
                organization: [],
                issue_date: [],
                expiration_date: [],
                credential_id: [],
                credential_url: [],
            },
            grades: [],
            qualifications: [],
            referenceModal: false,
            loading: false,
            qualification: {},
        };
    },
    computed: {},
    created() {
        this.getList();
    },
    methods: {
        openAddExp() {
            this.license.id = "";
            this.license.title = "";
            this.license.organization = "";
            this.license.issue_date = "";
            this.license.expiration_date = "";
            this.license.credential_id = "";
            this.license.credential_url = "";
            this.referenceModal = !this.referenceModal;
        },
        async getList() {
            const certRes = await axiosInstance.get(endpoints.certPro);
            this.licenses = certRes.data.data;
        },
        async editLicense(id) {
            const data = await axiosInstance.get(endpoints.certPro, {params: id} );
            this.license.id = data.data.id;
            this.license.title = data.data.title;
            this.license.organization = data.data.organization;
            this.license.issue_date = data.data.issue_date;
            this.license.expiration_date = data.data.expiration_date;
            this.license.credential_id = data.data.credential_id;
            this.license.credential_url = data.data.credential_uri;
            this.referenceModal = !this.referenceModal;
        },
        async deleteLicense(id) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axiosInstance.delete(endpoints.certPro+'/'+ id);
                    await this.getList();
                    toast.success("Certification has been deleted.");
                }
            });
        },
        async addLicense() {
            let self = this;
            this.loading = true;
            if (this.license.id) {
                await axiosInstance
                    .patch(endpoints.certPro+'/'+this.license.id, this.license)
                    .then((response) => {
                        if (response.data.type === "validation") {
                            Object.keys(response.data.message).forEach(
                                function (key) {
                                    self.errorMessages[key] =
                                        response.data.message[key];
                                }
                            );
                        } else if (response.data.status === "Success") {
                            this.loading = false;
                            this.referenceModal = !this.referenceModal;
                            this.getList();
                            this.license.title = "";
                            this.license.organization = "";
                            this.license.issue_date = "";
                            this.license.expiration_date = "";
                            this.license.credential_id = "";
                            this.license.credential_url = "";
                        }
                    })
                    .catch(() => {
                        this.loading = false;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                await axiosInstance
                    .post(endpoints.certPro, this.license)
                    .then((response) => {
                        if (response.data.type === "validation") {
                            Object.keys(response.data.message).forEach(
                                function (key) {
                                    self.errorMessages[key] =
                                        response.data.message[key];
                                }
                            );
                        } else if (response.data.status === "Success") {
                            this.loading = false;
                            this.referenceModal = !this.referenceModal;
                            this.getList();
                            this.license.title = "";
                            this.license.organization = "";
                            this.license.issue_date = "";
                            this.license.expiration_date = "";
                            this.license.credential_id = "";
                            this.license.credential_url = "";
                        }
                    })
                    .catch(() => {
                        this.loading = false;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.$parent.checkProfileStatus();
                    });
            }
        },
    },
};
</script>
<style scoped>
button {
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom-button {
    background-color: #812bb2;
    font-size: 15px;
    font-weight: 700;
    line-height: 5px;
    font-family: "Roboto", sans-serif;
    color: #fccd05 !important;
    height: 32px !important;
    border-radius: 5px !important;
    margin-top: 15px;
}
</style>
