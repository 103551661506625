<template>
  <div>
    <div class="hero-course-wrap bg-offwhite newtheme-cart-component">
      <div class="container">
        <div class="hero-course-con py-4">
          <h2 class="font-weight-bold color_black mb-2 mt-4">STUDENT FAQS</h2>
        </div>
      </div>
    </div>

    <div class="container mt-5">
      <h4>How do I Find a tutor?</h4>
      <p>
        There are four ways to find your ideal tutor at ILMZone. You can search
        for a tutor by name, browse tutor profiles in our tutor section, or you
        can post a job to invite tutors to apply. Here’s how all options work:
      </p>

      <p><b>To browse our tutor roster:</b>log in → main page → tutors. Our tutor roster will appear. Scroll up and down to browse through ILMZone’s many tutors.</p>
      <p><b>To search for a tutor by name:</b>log in → main page → tutors. Our roster of tutors will appear. In the search box at the upper right enter the tutor’s name if you know it. If there are tutors by this name on ILMZone, their profiles will appear.</p>
      <p><b>To find a tutor using filters:</b>log in → main page → tutors. To the left of the Tutors page you’ll see a set of 5 filters to use in narrowing your search. Click on one or more filters to find a tutor that offers the attributes you're looking for. You can filter (search) by:</p>
      <ul>
        <li>The SUBJECT you want to learn about</li>
        <li>By the GRADE a tutor teaches</li>
        <li>By the CITY a tutors is in</li>
        <li>By the educational institution the tutor is an ALUMNI of</li>
        <li>By the tutor’s RATING</li>
        <li>You can also choose to search only verified tutors.</li>
      </ul>
      <p><b>Find a tutor by posting a job:</b> Simply log in → main page → tutoring Jobs→ Post a Job. In the pop-up choose Subject, Grade Level, optionally the educational institution the tutor is an alumni of and the tutor’s gender. Create a Job Title and Job Description. Select if you want only verified tutors for your job.</p>
      <p>After posting, your job will start receiving applications from eligible tutors. You can review each tutor and their availability</p>

      <h4>How do I request a refund?</h4>
      <p>Students may submit a Refund Request within four days after a scheduled lesson as follows:</p>
      <ul>
        <li>Log in to your student account</li>
        <li>Go to your Dashboard > Billing</li>
        <li>Locate the lesson and click “Request a Refund”</li>
        <li>Fill in the necessary details, and submit</li>
        <li>Please keep in mind that the “Request a Refund” Button disappears once the review period for a certain lesson is over</li>
      </ul>
      <p>
        For more information on ILMZone’s refund and dispute process, please see
        our policies <a href="#">here.</a>
      </p>

      <h4>How do I pay for a lesson?</h4>
      <p>
        ILMZone offers several ways to pay for Lessons and Courses. You can
        choose to pay through your debit/ credit card, JazzCash, Foree pay, or
        your ILMZone wallet.
      </p>
      <p>
        To pay with your credit/debit card, enter your billing information in
        your profile, and select your card to pay at checkout.
      </p>
      <p>
        To pay through your wallet, either top up your wallet using a
        credit/debit card, or simply go to any TCS location and top up your
        wallet using cash. The top up amount will be credited into your wallet
        after confirmation, and can be then used to purchase lessons and
        courses.
      </p>

      <h4>What if I cancel a lesson?</h4>
      <p>
        Cancellation at the last moment is often troublesome for tutors and the
        students. All lessons must be canceled at least 24 hours prior to the
        scheduled time.
      </p>
      <p>
        If you must cancel in case of an emergency, you should call ILMZone
        Customer Care. You can cancel or reschedule lessons only a maximum of
        three times per month.
      </p>
      <ul>
        <li>Cancellation least 24 hours prior will not result in any deduction</li>
        <li>Not showing up to a lesson will result in payment of the full fee.</li>
      </ul>
      <p>
        <b>Meta Description:</b> Student related FAQs to address all Sign-up,
        online tutoring, online job posting and refund related issues
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "StudentFAQs",
};
</script>

<style scoped>
</style>
