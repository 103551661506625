<template>
  <div>
    <div class="container instructor-newtheme">
      <div class="course-adding-form box-style-coruse student_become_tutor">
        <ul id="progressbar" class="progressbar-box student-dashboard">
          <li class="active" id="general"><strong class="color-light-blue">Teaching </strong><span>1</span></li>
          <li id="duration"><strong class="color-light-blue">What are your Qualifications?</strong><span>2</span></li>
          <li id="courseoutline"><strong>Where can you teach?</strong><span>3</span></li>
        </ul> <!-- fieldsets -->
        <!-- MultiStep Form -->
        <div class="container-fluid" id="grad1">
          <div class="row">
            <div class="col-md-12 mx-0">
              <form @submit.prevent="checkCourseForm()" enctype="multipart/form-data">
                <fieldset>
                  <div class="row px-3 pt-1 pb-0">
                    <div class="col-md-6">
                      <div class="form-group">

                        <label>What do you teach?</label>
                        <multiselect
                          :multiple="true"
                          v-model="data.subject_id"
                          :options="subjects"
                          label="subject"
                          track-by="subject"
                          @input="updateLeaves"
                        >
                          <!-- eslint-disable -->
                          <template slot="selection" slot-scope="{ values, search, isOpen }">
                            <span class="multiselect__single" v-if="values.length &amp;&amp; isOpen">{{ values.name }}</span>
                          </template>
                        </multiselect>

                      </div>
                    </div>


                    <div class="col-md-6">
                      <div class="form-group">

                        <label>What do you teach?</label>
                        <multiselect
                          :multiple="true"
                          v-model="data.grade_id"
                          :options="grades"
                          label="name"
                          track-by="name"
                          @input="updateLeaves"
                        >
                          <template slot="selection" slot-scope="{ values, search, isOpen }">
                            <span class="multiselect__single" v-if="values.length &amp;&amp; isOpen">{{ values.name }}</span>
                          </template>
                        </multiselect>

                      </div>
                    </div>



                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="phone" >Phone Number</label>
                        <input type="text" class="form-control" v-model="data.phone" id="phone">
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label>Description</label>
                        <div class="form-group become_instructor_editor">
                          <ckeditor :editor="editor" v-model="data.description" :rows="1" height="120" :config="editorConfig"></ckeditor>
                        </div>
                      </div>
                    </div>
                  </div>



<!--                  <div class="col-md-6">-->
<!--                    <div class="form-check">-->
<!--                      <input type="radio" class="form-check-input" v-model="data.is_top_course" id="online">-->
<!--                      <label class="form-check-label" for="online" >I Can Teach Online</label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-md-6">-->
<!--                    <div class="form-check">-->
<!--                      <input type="radio" class="form-check-input" v-model="data.is_top_course" id="personal">-->
<!--                      <label class="form-check-label" for="personal" >I Can Teach Personal</label>-->
<!--                    </div>-->
<!--                  </div>-->


                  <div class="row px-3  mb-3">
                    <!-- <div class="col-sm-6"> </div> -->
                    <div class="col-sm-12 text-center">
<!--                      <a href="javascript:void(0)" v-if="!!$route.params.id" v-on:click="$parent.steps++" class="next action-button btn btn-primary btn-lg">Next Step</a>-->
                      <a href="javascript:void(0)" v-on:click="checkCourseForm" class="next action-button btn btn-primary btn_fontsize btn-lg">Continue</a>
<!--                      <a href="javascript:void(0)" v-else v-on:click="checkCourseForm" class="next action-button btn btn-primary btn_fontsize btn-lg">Create Course</a>-->
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {GlobalEventBus} from "@/assets/js/eventBus";
import axiosInstance from "@/assets/js/axios";
import endpoints from "@/assets/js/api";
import {toast} from "vue3-toastify";


export default {
  name: "Step1Component",
  data() {
    return {
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {

      },
      grades: [],
      subjects: [],
      categories: [],
      educationBoards: [],
      educationLevels: [],
      user: [],
      data: {
        description: '',
        grade_id: [],
        subject_id: [],
        phone: [],
      },
    }
  },
  created() {
    this.getFilterContent()
    this.getInfo()
  },
  mounted() {
    let self = this
    GlobalEventBus.$on('getUserDetail', function (info) {
      self.user = info
      self.data.phone = info.user.phone
      self.data.description = info.user.info.about
      self.data.subject_id = info.tutor.subjects
      self.data.grade_id = info.tutor.grades
    })
  },
  methods: {
    updateLeaves() {
    },
    async getFilterContent() {
      const {data} = await axiosInstance.get(endpoints.filterContent);
      this.subjects = data.subjects
      this.grades = data.grades
      this.categories = data.categories
      this.educationBoards = data.educationBoards
      this.educationLevels = data.educationLevels
    },
    checkCourseForm() {
      if (!this.data.description) {
        toast.error('Description Required.')
      }
      else if (!this.data.grade_id) {
        toast.error('Choose Grades.')
      }
      else if (!this.data.subject_id) {
        toast.error('Choose Subjects.')
      }
      else {
        this.addInfo()
      }

    },
    async addInfo() {
      let self = this
      let formData = new FormData()
      formData.append('description', self.data.description)
      formData.append('grade_id', JSON.stringify(self.data.grade_id))
      formData.append('subject_id', JSON.stringify(self.data.subject_id))
      formData.append('phone', self.data.phone)
      await axiosInstance.post(endpoints.addStep1Info, formData);
      this.$parent.steps++
    },
    getInfo() {
      let self = this
      if (this.$store.state.userData) {
        self.user = this.$store.state.userData
        self.data.phone = this.$store.state.userData.user.phone
        self.data.description = this.$store.state.userData.user.info.about
        self.data.subject_id = this.$store.state.userData.tutor.subjects
        self.data.grade_id = this.$store.state.userData.tutor.grades
      }

    }
  },

}
</script>
<!--<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>-->

<style scoped>
.multiselect__input, .multiselect__single, .multiselect__input input {
  border: none !important;
}
#phone{
  display: block;
}

@media screen and (min-width: 1850px) {
  .container {
    max-width:2150px  !important;

  }
}
.progressbar-box li {
  width: 33%;
}

</style>
