<template>
    <div class="card user-card">
      <div class="card-header">
        <div class="top">
          <span>Top Rated</span></div>
      </div>
      <div class="card-block">
        <div class="user-image">
          <img :src="'/storage'+data.image" class="img-radius" :alt="data.name">
        </div>
        <h6 class="f-w-600 m-t-25 m-b-10 text-left">{{ data.name }}</h6>
        <div class="text-left">
          <!-- eslint-disable -->
          <star-rating :show-rating="false" :read-only="true" v-model="data.info.rating" :increment="0.25" v-bind:star-size="20"></star-rating>
        </div>
        <p class="text-muted text-left">Adobe XD</p>
        <div class="down_profile_cover">
          <p class="price_11 text-left mt-3"> {{data.info.price}} {{ $t('text.pkr') }}
            <small class="price_20">/hr</small>
          </p>
        </div>
      </div>
      <div class="card-buttons">
        <button v-on:click="redirectProfilePage(data.id)" class="view-btn"> View </button>
        <button v-on:click="redirectProfilePage(data.id)" class="book_now"> Hire me </button>
      </div>
    </div>
</template>

<script>
import StarRating from 'vue-star-rating'

export default {
  name: "TutorCardComponent",
  components: {StarRating},
  props: ['data'],
  created() {},
  methods: {
    redirectProfilePage(id) {
      window.location.href = '/profile/tutor/'+id
    }
  }
}
</script>

<style scoped>

</style>
